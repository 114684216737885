import { Form, Table } from 'antd';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import {
  setBultoLineaEditingKey,
  setDeleteDucaBultoLinea,
  setSaveDucaBultoLinea,
} from 'features/DucaDetalles/store/store';
import { setError } from 'features/InitialData/store/initialDataStore';
import { IDucaBultoLineaRequest } from 'models/Duca';
import { PapierError } from 'models/PapierException';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { Columns } from './Columns';

interface IListadoLineasProps {
  onCancelEditMode?: () => void;
}

export const ListadoLineas = ({ onCancelEditMode }: IListadoLineasProps) => {
  const dispatch = useDispatch();
  const { bultosLineas, bultosLineasEditingKey, ducaDetalles } = useAppSelector(
    state => state.ducaDetalles,
  );
  const [formLinea] = Form.useForm<IDucaBultoLineaRequest>();

  const isEditing = (values: IDucaBultoLineaRequest) => values.key === bultosLineasEditingKey;

  const onSave = async (key: string) => {
    try {
      const row = await formLinea.validateFields();
      row.numeroLinea = Number(row.numeroLinea);
      row.cantidadUnidadesAfectadas = Number(row.cantidadUnidadesAfectadas);
      row.pesoAfectado = Number(row.pesoAfectado);

      if (
        bultosLineas.some(
          b =>
            b.numeroLinea === row.numeroLinea &&
            b.key !== key &&
            b.estado !== EstadoDucaItem.Delete,
        )
      )
        throw new PapierError('Ya existe una línea con ese número');

      dispatch(setSaveDucaBultoLinea({ record: row, key }));
      setEditingKey('');
    } catch (error) {
      if (error instanceof PapierError) {
        dispatch(
          setError({
            error: true,
            message: error.errorMessage,
            duration: 5,
          }),
        );
      } else {
        console.log('Validate Failed:', error);
      }
    }
  };

  const onEdit = (value: Partial<IDucaBultoLineaRequest> & { key: React.Key }) => {
    formLinea.setFieldsValue({
      numeroLinea: 0,
      cantidadUnidadesAfectadas: 0,
      pesoAfectado: 0,
      ...value,
    });
    setEditingKey(value.key);
    onCancelEditMode?.();
  };

  const onDelete = (key: string) => {
    dispatch(setDeleteDucaBultoLinea(key));
    onCancelEditMode?.();
  };

  const onCancel = () => {
    if (bultosLineas.some(l => l.key === 'new')) dispatch(setDeleteDucaBultoLinea('new'));

    setEditingKey('');
  };

  const setEditingKey = (key: string) => {
    dispatch(setBultoLineaEditingKey(key));
  };

  const cols = Columns({
    isEditing,
    onSave,
    onEdit,
    onDelete,
    onCancel,
    ordenCerrada: ducaDetalles?.ordenCerrada ?? false,
  });
  const mergedColumns = cols.map((col: any) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: IDucaBultoLineaRequest) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const TableLineaTemp = (
    <Form form={formLinea} component={false}>
      <Table
        components={{ body: { cell: EditableCell } }}
        bordered
        dataSource={bultosLineas.filter(x => x.estado !== EstadoDucaItem.Delete)}
        columns={mergedColumns as any}
        pagination={false}
        size='small'
        style={{ marginTop: 16 }}
      />
    </Form>
  );

  const TableLinea = () => TableLineaTemp;

  return { TableLinea, setEditingKey, formLinea, onCancel };
};

interface IEditableCell extends React.HTMLAttributes<HTMLDivElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: IDucaBultoLineaRequest;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<IEditableCell> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <CustomInputNumber
          name={dataIndex}
          type='normal'
          autoFocus={dataIndex === 'numeroLinea'}
          ruler={{ required: true, message: 'Campo requerido' }}
          style={{ margin: 0 }}
          min={0}
        />
      ) : (
        children
      )}
    </td>
  );
};
