import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IEstadosMercanciasState } from '../estadosMercanciasStore';

export const getEstadosMercancias = createAsyncThunk('EstadosMercancias/GetAll', httpService);

export const getEstadosMercanciasReducer = (
  builder: ActionReducerMapBuilder<IEstadosMercanciasState>,
) => {
  builder.addCase(getEstadosMercancias.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.data = action.payload;
  });
  builder.addCase(getEstadosMercancias.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getEstadosMercancias.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
