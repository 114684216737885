import { AutoComplete, Button, Drawer, Form, FormInstance, Input, Select, Spin } from 'antd';
import { InvisibleItem } from 'components/FormControls/InvisibleItem';
import { queryPartidasArancelarias } from 'features/PartidasArancelarias/store/extraReducers/queryPartidasArancelarias';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useDebounce } from 'hooks/useDebounce';
import { QuerySpec } from 'models/QuerySpec';
import { FC, useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';
import { Producto } from '../../../models/Producto';

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

interface IProps {
  validarProducto: boolean;
  visible: boolean;
  form: FormInstance<Producto>;
  status: StatusApp.Status;
  elementoSeleccionado: Producto | undefined;
  onCreate: (values: Producto) => void;
  onUpdate: (values: Producto) => void;
  setCerrarModal: () => void;
  container?: HTMLElement;
}

export const DrawerUpsert: FC<IProps> = ({
  validarProducto,
  visible,
  form,
  status,
  elementoSeleccionado,
  onCreate,
  onUpdate,
  setCerrarModal,
  container,
}) => {
  const customDispatch = useCustomDispatch();
  const {
    proveedores,
    partidasArancelarias,
    designacionesProductos,
    paises,
    unidadesMedidasNetas,
    estadosMercancias,
    marcasComerciales,
  } = useAppSelector((state: RootState) => ({
    proveedores: state.proveedores.data,
    partidasArancelarias: state.partidasArancelarias.pagedData.items,
    designacionesProductos: state.designacionesProductos.data,
    paises: state.paises.data,
    unidadesMedidasNetas: state.unidadesMedidasNetas.data,
    estadosMercancias: state.estadosMercancias.data,
    marcasComerciales: state.marcasComerciales.data,
  }));

  useEffect(() => {
    if (elementoSeleccionado) {
      dispatchPartidasArancelaria(elementoSeleccionado.partidaArancelaria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementoSeleccionado]);

  const onSearchPartidaArancelaria = useDebounce((value: string) => {
    dispatchPartidasArancelaria(value);
  });

  const dispatchPartidasArancelaria = (filterValue: string) => {
    customDispatch({
      asyncThuckFuction: queryPartidasArancelarias,
      endPoint: '/api/PartidasArancelarias/Query',
      body: {
        simpleTextFilter: filterValue,
        pageNumber: 1,
        pageSize: 50,
      } as QuerySpec,
    });
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then(values => {
        if (elementoSeleccionado) {
          onUpdate(values);
        } else {
          onCreate(values);
        }
      })
      .catch(error => {
        console.log(error);
        form.scrollToField(error.errorFields[0]?.name, {
          behavior: 'smooth',
          block: 'center',
        });
      });
  };

  return (
    <Drawer
      getContainer={container}
      title={elementoSeleccionado ? 'Editar Producto' : 'Crear Producto'}
      width={600}
      onClose={setCerrarModal}
      visible={visible}
      bodyStyle={{ paddingBottom: 0 }}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={setCerrarModal} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={() => onFinish()} type='primary'>
            {elementoSeleccionado ? 'Guardar' : 'Crear'}
          </Button>
        </div>
      }
    >
      <Spin spinning={status === 'pending'}>
        <Form
          form={form}
          name='PartidaArancelaria'
          layout='vertical'
          defaultValue={elementoSeleccionado as any}
          scrollToFirstError={{ behavior: 'smooth' }}
        >
          <Item
            name='sku'
            label='SKU (Modelo)'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={validarProducto} autoFocus={!validarProducto} />
          </Item>
          <Item name='proveedorId' label='Proveedor' rules={[{ required: true }]}>
            {validarProducto ? (
              <Input disabled />
            ) : (
              <Select showSearch placeholder='Selecciona un proveedor' optionFilterProp='children'>
                {proveedores.map(proveedor => (
                  <Option key={proveedor.id} value={proveedor.id}>
                    {proveedor.nombre}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <InvisibleItem name='partidaArancelaria' />
          <Item
            name='partidaArancelariaId'
            label='Partida Arancelaria'
            rules={[
              { required: true },
              ({ setFieldsValue }) => ({
                validator(_, value) {
                  setFieldsValue({
                    partidaArancelaria: partidasArancelarias?.find(x => x?.id === value)?.nombre,
                  } as Producto);

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              showSearch
              placeholder='Selecciona una partida arancelaria'
              optionFilterProp='children'
              autoFocus={validarProducto}
              onSearch={onSearchPartidaArancelaria}
            >
              {partidasArancelarias.map(partidaArancelaria => (
                <Option key={partidaArancelaria.id} value={partidaArancelaria.id}>
                  {partidaArancelaria.nombre}
                </Option>
              ))}
            </Select>
          </Item>
          <Item name='designacionProducto' label='Designación del Producto'>
            <AutoComplete
              options={designacionesProductos.map(designacionProducto => ({
                key: designacionProducto.id,
                value: designacionProducto.nombre,
              }))}
              filterOption
            />
          </Item>
          <Item name='caracteristicas' label='Características' rules={[{ required: true }]}>
            <TextArea
              rows={3}
              onChange={e => {
                for (let i = 0; i < e.target.value.length; i++) {
                  if (Number(e.target.value[i])) {
                    form.setFieldsValue({
                      caracteristicaPrincipal: e.target.value.substring(0, i),
                    });

                    form.setFieldsValue({
                      caracteristicaSecundaria: e.target.value.substring(i, e.target.value.length),
                    });

                    return;
                  }
                }
              }}
            />
          </Item>
          <Item
            name='caracteristicaPrincipal'
            label='Característica Principal'
            rules={[{ required: true }]}
          >
            <TextArea rows={3} />
          </Item>
          <Item
            name='caracteristicaSecundaria'
            label='Característica Secundaria'
            rules={[{ required: true }]}
          >
            <TextArea rows={3} />
          </Item>
          <Item name='marcaComercial' label='Marca Comercial' rules={[{ required: true }]}>
            <AutoComplete
              options={marcasComerciales.map(marca => ({
                key: marca.id,
                value: marca.nombre,
              }))}
              filterOption
            />
          </Item>
          <Item name='paisOrigenId' label='Pais Origen' rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder='Selecciona un pais de origen'
              optionFilterProp='children'
            >
              {paises.map(pais => (
                <Option key={pais.id} value={pais.id}>
                  {pais.nombre}
                </Option>
              ))}
            </Select>
          </Item>
          <Item name='unidadMedidaId' label='Unidad de Medida' rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder='Selecciona una unidad de medida'
              optionFilterProp='children'
            >
              {unidadesMedidasNetas.map(unidadMedidaNeta => (
                <Option key={unidadMedidaNeta.id} value={unidadMedidaNeta.id}>
                  {unidadMedidaNeta.nombre}
                </Option>
              ))}
            </Select>
          </Item>
          <Item name='estadoMercanciaId' label='Estado de Mercancia' rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder='Selecciona un estado de mercancia'
              optionFilterProp='children'
            >
              {estadosMercancias.map(estadoMercancia => (
                <Option key={estadoMercancia.id} value={estadoMercancia.id}>
                  {estadoMercancia.nombre}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name='mostrarDescripcionConPresentacion'
            label='Mostrar Descripción con Presentación'
            rules={[{ required: true }]}
          >
            <Select>
              <Option key='true' value='true'>
                Si
              </Option>
              <Option key='false' value='false'>
                No
              </Option>
            </Select>
          </Item>
          <Item name='codigoDeBarras' label='Codigo de Barras'>
            <Input />
          </Item>
          <Item name='codigoFitosanitario' label='Codigo Fitosanitario'>
            <Input />
          </Item>
        </Form>
      </Spin>
    </Drawer>
  );
};
