import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Tag } from 'antd';
import { FC, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { Producto } from '../../../models/Producto';
import { setProductoCoincidenciaSeleccionada } from '../store/store';
import { ItemProducto } from './ItemProducto/ItemProducto';
import styles from './ValidarProductos.module.less';

interface IProps {
  productoSeleccioando: Producto | undefined;
  setProductoSeleccionado: (producto: Producto | undefined) => void;
  setDrawerUpsertProducto: (producto: Producto) => void;
}

export const ValidarProductos: FC<IProps> = ({
  productoSeleccioando,
  setProductoSeleccionado,
  setDrawerUpsertProducto,
}) => {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [coincidencias, setCoincidencias] = useState<Array<Producto>>([]);
  const {
    validationDevaMessage,
    proveedor,
    productosValidados,
    productosValidadosStatus,
    productosPendientesCreateStatus,
  } = useAppSelector(state => state.devas);

  const showConfirm = (
    skuSeleccionado: string,
    proveedorSeleccionado: string,
    coincidenciasSeleccionadas: Array<Producto>,
  ) => {
    setVisibleModal(true);
    setProductoSeleccionado({ sku: skuSeleccionado, proveedor: proveedorSeleccionado } as Producto);
    setCoincidencias(coincidenciasSeleccionadas);
  };

  const closeModal = () => {
    setVisibleModal(false);
    setProductoSeleccionado(undefined);
    setCoincidencias([]);
  };

  const setSeleccionarCoincidencia = (producto: Producto, coincidencia: Producto) => {
    dispatch(setProductoCoincidenciaSeleccionada({ producto, coincidencia }));
  };

  return (
    <div className={styles.container}>
      {productosPendientesCreateStatus === 'pending' || productosValidadosStatus === 'pending' ? (
        <div className={styles.loadingContainer}>
          <LoadingOutlined className={styles.loadingIcon} />
          <span className={styles.loadingText}>{validationDevaMessage}</span>
        </div>
      ) : (
        <Fragment>
          <div className={styles.scrollContainer}>
            <div className={styles.listContainer}>
              {productosValidados.map((p, i) => (
                <ItemProducto
                  key={`${p.product.sku + i}`}
                  completo={p.completo}
                  estado={p.estado}
                  producto={{ ...p.product, proveedor: proveedor.nombre }}
                  coincidencias={p.coincidencias}
                  showCoincidencias={showConfirm}
                  showDrawer={setDrawerUpsertProducto}
                />
              ))}
            </div>
          </div>

          <Row gutter={[24, 24]} justify='space-between' align='middle' style={{ marginTop: 24 }}>
            <Col>
              <span>
                Todos los productos que tengan la etiqueta <Tag color='red'>no creado</Tag>, se
                enviaran automáticamente a la cola de creación.
              </span>
            </Col>
          </Row>
          <Modal
            title={
              <Fragment>
                <CheckCircleOutlined style={{ color: '#1890ff', fontSize: 20 }} /> Selecciona alguna
                coincidencia
              </Fragment>
            }
            visible={visibleModal}
            width={400}
            onCancel={() => closeModal()}
            footer={
              <Button children='Cancelar' type='primary' ghost onClick={() => closeModal()} />
            }
          >
            <div className={styles.modalBodyContainer}>
              <div className={styles.coincidenciasContainer}>
                {coincidencias.map(coincidencia => (
                  <Button
                    key={coincidencia.sku}
                    onClick={() => {
                      setSeleccionarCoincidencia(
                        productoSeleccioando ?? ({} as Producto),
                        coincidencia,
                      );
                      closeModal();
                    }}
                    block
                    style={{ height: 'auto', textAlign: 'left' }}
                  >
                    <b>SKU</b> : {coincidencia.sku} <br />
                    <b>Proveedor</b> : {coincidencia.proveedor}
                  </Button>
                ))}
              </div>
            </div>
          </Modal>
        </Fragment>
      )}
    </div>
  );
};
