import { Form } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { getMarcasComerciales } from 'features/MarcasComerciales/store/extraReducers/getMarcasComerciales';
import { useAuth0UserData } from 'hooks/useAuth0UserData';
import { debounce } from 'hooks/useDebounce';
import { QuerySpec } from 'models/QuerySpec';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { DesignacionProducto } from '../../models/DesignacionProducto';
import { Producto } from '../../models/Producto';
import { addDesignacionProducto } from '../DesignacionesProductos/store/designacionesProductosStore';
import { BulkDeleteModal } from './BulkDeleteModal/BulkDeleteModal';
import { BulkImportModal } from './BulkImportModal/BulkImportModal';
import { BulkUpdateModal } from './BulkUpdateModal/BulkUpdateModal';
import { Columns } from './Columns';
import { DrawerDetalles } from './DrawerDetalles/DrawerDetalles';
import { DrawerUpsert } from './DrawerUpsert/DrawerUpsert';
import styles from './Productos.module.less';
import { bulkImportProductos } from './store/extraReducer/bulkImportProductos';
import { createProducto } from './store/extraReducer/createProducto';
import { deleteProducto } from './store/extraReducer/deleteProducto';
import { queryProductos } from './store/extraReducer/queryProductos';
import { updateProducto } from './store/extraReducer/updateProducto';
import {
  resetUltimoProductoCreado,
  setArchivoBulkImport,
  setBulkImportStatusIdle,
  setCrudStatusIdle,
  setProductoTemporal,
  toggleBulkImportVisible,
  toggleDrawerVisible,
  toggleSeleccionarProducto,
} from './store/productosStore';
import { Toolbar } from './Toolbar/Toolbar';

export const Productos: FC = () => {
  const [querySpec, setQuery] = useState<QuerySpec>({
    pageNumber: 1,
    pageSize: 50,
    simpleTextFilter: undefined,
  });
  const [drawerDetailsVisible, setDrawerDetailsVisible] = useState<boolean>(false);

  const {
    pagedData,
    status,
    crudStatus,
    bulkImportStatus,
    drawerVisible,
    bulkImportVisible,
    elementoSeleccionado,
    apiURL,
    ultimoProductoCreado,
    archivoBulkImport,
    bulkImportCreatedProducts,
    bulkImportFeedback,
  } = useAppSelector(state => state.productos);

  const {
    proveedores,
    designacionesProductos,
    paises,
    unidadesMedidasNetas,
    estadosMercancias,
    marcasComerciales,
    clasificacionesFenag,
  } = useAppSelector(state => ({
    proveedores: state.proveedores.data,
    designacionesProductos: state.designacionesProductos.data,
    paises: state.paises.data,
    unidadesMedidasNetas: state.unidadesMedidasNetas.data,
    estadosMercancias: state.estadosMercancias.data,
    marcasComerciales: state.marcasComerciales.data,
    clasificacionesFenag: state.clasificacionesFenag.data,
  }));

  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [bulkImportForm] = Form.useForm();
  const user = useAuth0UserData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callQuery = useCallback(
    debounce((q: QuerySpec) => {
      customDispatch({
        asyncThuckFuction: queryProductos,
        endPoint: '/api/Productos/Query',
        body: q,
      });
    }),
    [],
  );

  useEffect(() => {
    callQuery(querySpec);
  }, [callQuery, querySpec]);

  useEffect(() => {
    return () => {
      dispatch(resetUltimoProductoCreado());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    !window.document.title.includes('Productos') && dispatch(setTitleName('Productos'));

    if (crudStatus === 'succeeded') {
      dispatch(setCrudStatusIdle());
      form.resetFields();
    }

    if (ultimoProductoCreado) {
      dispatch(
        addDesignacionProducto({
          id: ultimoProductoCreado.designacionProductoId,
          nombre: ultimoProductoCreado.designacionProducto,
        } as DesignacionProducto),
      );
      dispatch(resetUltimoProductoCreado());
    }
  }, [crudStatus, dispatch, form, ultimoProductoCreado, bulkImportForm, bulkImportStatus]);

  const setUpdateSelection = (values: Producto) => {
    dispatch(toggleSeleccionarProducto(values));
    setDrawerVisible();
    form.setFieldsValue({
      ...values,
      mostrarDescripcionConPresentacion: String(values.mostrarDescripcionConPresentacion),
    } as Producto);
  };

  const onChangeArchivoExcel = (values: UploadFile<any>[] | undefined) => {
    setArchivoBulkImport(values);
  };

  const setDeleteSelection = (values: Producto) => {
    dispatch(toggleSeleccionarProducto(values));
    onDelete(values.id);
  };

  const setDrawerVisible = () => {
    dispatch(toggleDrawerVisible());
  };

  const setBulkImportVisible = () => {
    dispatch(toggleBulkImportVisible());
    dispatch(setBulkImportStatusIdle());
    bulkImportForm.resetFields();
  };

  const setDrawerClose = () => {
    dispatch(toggleDrawerVisible());
    dispatch(toggleSeleccionarProducto(undefined));
    form.resetFields();
  };

  const onCreate = (values: Producto) => {
    const designacionProducto = designacionesProductos.filter(
      d => d.nombre === values.designacionProducto,
    )[0];
    const marcaComercial = marcasComerciales.filter(m => m.nombre === values.marcaComercial)[0];

    const newValues: Producto = {
      ...values,
      mostrarDescripcionConPresentacion:
        values.mostrarDescripcionConPresentacion.toString() === 'true' ? true : false,
      proveedor: proveedores.filter(proveedor => proveedor.id === values.proveedorId)[0].nombre,
      designacionProductoId:
        designacionProducto !== undefined
          ? designacionProducto.id
          : '00000000-0000-0000-0000-000000000000',
      marcaComercialId:
        marcaComercial !== undefined ? marcaComercial.id : '00000000-0000-0000-0000-000000000000',
      paisOrigen: paises.filter(pais => pais.id === values.paisOrigenId)[0].nombre,
      unidadMedida: unidadesMedidasNetas.filter(
        unidadMedidaNeta => unidadMedidaNeta.id === values.unidadMedidaId,
      )[0].nombre,
      estadoMercancia: estadosMercancias.filter(
        estadoMercancia => estadoMercancia.id === values.estadoMercanciaId,
      )[0].nombre,
      clasificacionFenag:
        values.clasificacionFenagId &&
        clasificacionesFenag.filter(
          clasificacionFenag => clasificacionFenag.id === values.clasificacionFenagId,
        )[0].nombre,
    };

    dispatch(setProductoTemporal(newValues));
    customDispatch({
      asyncThuckFuction: createProducto,
      endPoint: `${apiURL}/Create`,
      body: newValues,
      onFinish: () => {
        customDispatch({
          asyncThuckFuction: getMarcasComerciales,
          endPoint: '/api/MarcasComerciales/GetAll',
        });
      },
    });
  };

  const onBulkImport = (formValues: any) => {
    const formData = new FormData();
    const file = formValues.productsList ? formValues.productsList.fileList[0].originFileObj : null;

    formData.append('productsList', file);

    customDispatch({
      asyncThuckFuction: bulkImportProductos,
      endPoint: `${apiURL}/BulkCreateProductos`,
      body: formData,
      onFinish: () => {
        customDispatch({
          asyncThuckFuction: getMarcasComerciales,
          endPoint: '/api/MarcasComerciales/GetAll',
        });
      },
    });
  };

  const onUpdate = (values: Producto) => {
    const designacionProducto = designacionesProductos.filter(
      d => d.nombre === values.designacionProducto,
    )[0];
    const marcaComercial = marcasComerciales.filter(m => m.nombre === values.marcaComercial)[0];

    const newValues: Producto = {
      ...values,
      mostrarDescripcionConPresentacion:
        values.mostrarDescripcionConPresentacion.toString() === 'true' ? true : false,
      proveedor: proveedores.filter(proveedor => proveedor.id === values.proveedorId)[0].nombre,
      designacionProductoId:
        designacionProducto !== undefined
          ? designacionProducto.id
          : '00000000-0000-0000-0000-000000000000',
      marcaComercialId:
        marcaComercial !== undefined ? marcaComercial.id : '00000000-0000-0000-0000-000000000000',
      paisOrigen: paises.filter(pais => pais.id === values.paisOrigenId)[0].nombre,
      unidadMedida: unidadesMedidasNetas.filter(
        unidadMedidaNeta => unidadMedidaNeta.id === values.unidadMedidaId,
      )[0].nombre,
      estadoMercancia: estadosMercancias.filter(
        estadoMercancia => estadoMercancia.id === values.estadoMercanciaId,
      )[0].nombre,
      clasificacionFenag:
        values.clasificacionFenagId &&
        clasificacionesFenag.filter(
          clasificacionFenag => clasificacionFenag.id === values.clasificacionFenagId,
        )[0].nombre,
    };

    dispatch(setProductoTemporal(newValues));
    customDispatch({
      asyncThuckFuction: updateProducto,
      endPoint: `${apiURL}/Update/${elementoSeleccionado?.id}`,
      body: newValues,
      method: 'PUT',
      onFinish: () => {
        customDispatch({
          asyncThuckFuction: getMarcasComerciales,
          endPoint: '/api/MarcasComerciales/GetAll',
        });
      },
    });
  };

  const onDelete = (id: string) => {
    customDispatch({
      asyncThuckFuction: deleteProducto,
      endPoint: `${apiURL}/Delete/${id}`,
      method: 'DELETE',
    });
  };

  const onDetails = (values: Producto) => {
    form.setFieldsValue({
      ...values,
      mostrarDescripcionConPresentacion: values.mostrarDescripcionConPresentacion ? 'Sí' : 'No',
    });
    setDrawerDetailsVisible(true);
  };

  const cols = Columns({
    onUpdate: setUpdateSelection,
    onDelete: setDeleteSelection,
    onDetails,
    isAdmin: user?.isAdmin ?? false,
  });

  const setCerrarDrawerDetalles = () => {
    form.resetFields();
    setDrawerDetailsVisible(false);
  };

  return (
    <section className={styles.container}>
      <Toolbar
        setSku={e => {
          setQuery({ ...querySpec, pageNumber: 1, simpleTextFilter: e });
        }}
        toggleBulkImportModal={setBulkImportVisible}
        setToggleDrawer={setDrawerVisible}
      />
      <BaseTable
        cols={cols}
        data={pagedData.items}
        pagination={{
          defaultPageSize: 50,
          pageSize: querySpec.pageSize,
          total: pagedData.totalCount,
          onChange: (page: number, size?: number) => {
            setQuery({
              pageNumber: page,
              pageSize: size || 50,
            });
          },
        }}
        status={status}
        size='small'
        style={{ height: 'calc(100% - 40px)' }}
      />
      <DrawerUpsert
        validarProducto={false}
        visible={drawerVisible}
        form={form}
        status={crudStatus}
        elementoSeleccionado={elementoSeleccionado}
        onCreate={onCreate}
        onUpdate={onUpdate}
        setCerrarModal={setDrawerClose}
      />

      <DrawerDetalles
        visible={drawerDetailsVisible}
        setCerrarModal={setCerrarDrawerDetalles}
        form={form}
      />

      <BulkImportModal
        createdProducts={bulkImportCreatedProducts}
        feedbackMessages={bulkImportFeedback}
        form={bulkImportForm}
        visible={bulkImportVisible}
        status={bulkImportStatus}
        onImportSave={onBulkImport}
        setCerrarModal={setBulkImportVisible}
        archivoExcel={archivoBulkImport}
        onChangeArchivoExcel={onChangeArchivoExcel}
      />
      <BulkUpdateModal />
      <BulkDeleteModal />
    </section>
  );
};
