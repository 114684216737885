import Icon, {
  ArrowLeftOutlined,
  FileOutlined,
  PrinterOutlined,
  ProfileOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Button, Form, Modal, Spin, Tabs, Tooltip } from 'antd';
import { IconTags } from 'assets/icons';
import { setTitleName } from 'components/Sidebar/store/sidebarStore';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { UseCustomDispatch, useCustomDispatch } from 'hooks/useCustomDispatch';
import { useInitialData } from 'hooks/useInitialData';
import {
  DucaBulto,
  DucaCaratula,
  DucaDetalles as IDucaDetalles,
  IDucaDetallesRequest,
} from 'models/Duca';
import { QuerySpec } from 'models/QuerySpec';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import styles from './DucaDetalles.module.less';
import { getConversionOnzasLitros } from './store/extraReducer/getConversionOnzasLitros';
import { getDucaDetalles } from './store/extraReducer/getDucaDetalles';
import { getDucaItems } from './store/extraReducer/getDucaItems';
import { queryCuotaArancelaria } from './store/extraReducer/queryCuotaArancelaria';
import { queryDepositoAduanaTransito } from './store/extraReducer/queryDepositoAduanaTransito';
import { queryDepositoAduanero } from './store/extraReducer/queryDepositoAduanero';
import { queryModalidadEspecial } from './store/extraReducer/queryModalidadEspecial';
import { queryMotivoOperacionSuspensiva } from './store/extraReducer/queryMotivoOperacionSuspensiva';
import { queryRegimenAduanero } from './store/extraReducer/queryRegimenAduanero';
import { queryRutaTransito } from './store/extraReducer/queryRutaTransito';
import { queryTipoDatoComplementario } from './store/extraReducer/queryTipoDatoComplementario';
import { queryTipoDocumento } from './store/extraReducer/queryTipoDocumento';
import { queryTipoItem } from './store/extraReducer/queryTipoItem';
import { queryVentaja } from './store/extraReducer/queryVentaja';
import { updateDucaDetalles } from './store/extraReducer/updateDucaDetalles';
import { ducaDetallesHardReset, setCaratula, setDucaBultos } from './store/store';
import { TabPaneGeneral } from './TabPaneGeneral/TabPaneGeneral';
import { TabPaneItems } from './TabPaneItems/TabPaneItems';
import { validarCambios } from './utils/validarCambios';

const { TabPane } = Tabs;
const { confirm } = Modal;

const initialDataToLoad: UseCustomDispatch[] = [
  {
    asyncThuckFuction: queryRegimenAduanero,
    endPoint: '/api/ConfiguracionesSarah/RegimenesAduaneros/Query',
  },
  {
    asyncThuckFuction: queryModalidadEspecial,
    endPoint: '/api/ConfiguracionesSarah/ModalidadesEspeciales/Query',
  },
  {
    asyncThuckFuction: queryRutaTransito,
    endPoint: '/api/ConfiguracionesSarah/RutasTransito/Query',
  },
  {
    asyncThuckFuction: queryMotivoOperacionSuspensiva,
    endPoint: '/api/MotivosOperacionesSuspensiva/Query',
  },
  {
    asyncThuckFuction: queryDepositoAduanero,
    endPoint: '/api/ConfiguracionesSarah/DepositosAlmacenamiento/Query',
  },
  {
    asyncThuckFuction: queryDepositoAduanaTransito,
    endPoint: '/api/ConfiguracionesSarah/DepositosAduanaTransito/Query',
  },
  {
    asyncThuckFuction: queryTipoDocumento,
    endPoint: '/api/ConfiguracionesSarah/TiposDocumento/Query',
    body: { pageNumber: 1, pageSize: 100 },
  },
  {
    asyncThuckFuction: queryTipoDatoComplementario,
    endPoint: '/api/ConfiguracionesSarah/DatosComplementarios/Query',
    body: { pageNumber: 1, pageSize: 80 },
  },
  {
    asyncThuckFuction: queryTipoItem,
    endPoint: '/api/ConfiguracionesSarah/TiposItemDeclaracion/Query',
    body: { pageNumber: 1, pageSize: 10 },
  },
  {
    asyncThuckFuction: queryCuotaArancelaria,
    endPoint: '/api/ConfiguracionesSarah/CuotasArancelarias/Query',
    body: { pageNumber: 1, pageSize: 50 },
  },
  {
    asyncThuckFuction: queryVentaja,
    endPoint: '/api/ConfiguracionesSarah/Ventajas/Query',
    body: { pageNumber: 1, pageSize: 80 },
  },
  {
    asyncThuckFuction: getConversionOnzasLitros,
    endPoint: '/api/ConversionUnidadesDeMedidas/GetByUnidadOrigenUnidadDestino/oz&lit',
    method: 'GET',
  },
];

export type TParams = {
  ordenId: string;
  ducaId: string;
};

export const DucaDetalles = () => {
  const { replace, push } = useHistory();
  const { ordenId, ducaId } = useParams<TParams>();
  const { state: stateParams } = useLocation<{ creando: boolean }>();
  const [activeKey, setActiveKey] = useState<string>('1');
  const [filtrarPorIPC, setFiltrarPorIPC] = useState<boolean>(false);
  const [filtrarPorLitrosDeclarados, setFiltrarPorLitrosDeclarados] = useState<boolean>(false);
  const [form] = Form.useForm<IDucaDetallesRequest>();
  const {
    status,
    ducaDetalles,
    RegimenesAduanerosStatus,
    DepositoAduaneroStatus,
    ModalidadesEspecialesStatus,
    RutasTransitoStatus,
    MotivoOperacionSuspensivaStatus,
    DepositoAduanaTransitoStatus,
    TipoDocumentoStatus,
    TipoDatoComplementarioStatus,
    TipoItemStatus,
    VentajaStatus,
    ConversionOnzasLitrosStatus,
    bultosLineas,
    documentos,
    datosComplementarios,
    items,
    itemStatus,
  } = useAppSelector(state => state.ducaDetalles);
  const { Configuraciones } = useAppSelector(state => state.initialData);
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();

  useEffect(() => {
    !window.document.title.includes(`Duca - ${ducaDetalles?.caratula?.importador}`) &&
      dispatch(setTitleName(`Duca - ${ducaDetalles?.caratula?.importador ?? ''}`));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ducaDetalles]);

  const conditionDataToLoad =
    RegimenesAduanerosStatus === 'idle' &&
    DepositoAduaneroStatus === 'idle' &&
    ModalidadesEspecialesStatus === 'idle' &&
    RutasTransitoStatus === 'idle' &&
    MotivoOperacionSuspensivaStatus === 'idle' &&
    DepositoAduanaTransitoStatus === 'idle' &&
    TipoDocumentoStatus === 'idle' &&
    TipoDatoComplementarioStatus === 'idle' &&
    TipoItemStatus === 'idle' &&
    VentajaStatus === 'idle' &&
    ConversionOnzasLitrosStatus === 'idle';

  useInitialData(
    initialDataToLoad.map(x =>
      x.method === 'GET'
        ? x
        : {
            ...x,
            body: x.body ?? ({ pageNumber: 1, pageSize: 50 } as QuerySpec),
            method: x.method ?? 'POST',
          },
    ),
    conditionDataToLoad,
  );

  // Carga inicial de la Duca
  useEffect(() => {
    customDispatch({
      asyncThuckFuction: getDucaDetalles,
      endPoint: `/api/Ducas/GetById/${ordenId}`,
    });

    customDispatch({
      asyncThuckFuction: getDucaItems,
      endPoint: `/api/Ducas/GetDucaItems/${ordenId}`,
    });

    return () => {
      dispatch(ducaDetallesHardReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ducaDetalles?.caratula) {
      const caratula = ducaDetalles.caratula;
      const bultos = ducaDetalles.bultos;
      const proveedores = ducaDetalles.proveedores;

      const fields = {
        ...caratula,
        ...bultos,
        cantidadBultos: Number(caratula.cantidadBultos.toFixed(Configuraciones?.precisionDecimal)),
        pesoBrutoBultos: Number(
          caratula.pesoBrutoBultos.toFixed(Configuraciones?.precisionDecimal),
        ),
        totalFactura: Number(caratula.totalFactura.toFixed(Configuraciones?.precisionDecimal)),
        totalOtrosGastos: Number(
          caratula.totalOtrosGastos.toFixed(Configuraciones?.precisionDecimal),
        ),
        totalSeguro: Number(caratula.totalSeguro.toFixed(Configuraciones?.precisionDecimal)),
        totalFlete: Number(caratula.totalFlete.toFixed(Configuraciones?.precisionDecimal)),
        rtnAgenciaAduanera: Configuraciones?.rtn ?? '',
        agenciaAduanera: Configuraciones?.nombreLegal ?? '',
        proveedorId: caratula?.proveedorId ?? String(proveedores[0]?.id ?? ''),
        direccionProveedorId: caratula?.direccionProveedorId ?? String(proveedores[0]?.id ?? ''),
      };

      form.setFieldsValue(fields);

      if (ducaDetalles?.ordenCerrada) {
        Object.keys(fields).forEach(key => {
          if (form.getFieldInstance(key)) {
            const fieldKeys = Object.keys(form.getFieldInstance(key));
            if (fieldKeys.includes('input')) {
              form.getFieldInstance(key).input.disabled = true;
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ducaDetalles]);

  useEffect(() => {
    if (ducaDetalles?.id) {
      replace({
        pathname: `/ordenDeTrabajo/${ordenId}/detallesduca/${ducaDetalles?.id}`,
        state: stateParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ducaDetalles?.id]);

  const onRegresarAOrdenDeTrabajo = async () => {
    let result = false;
    if (itemStatus === 'pending') {
      result = validarCambios(
        {
          ...form.getFieldsValue(),
          bultosLineas,
          documentos,
          datosComplementarios,
          items: [],
        },
        { ...ducaDetalles, items: [] } as IDucaDetalles,
      );
    } else {
      result = validarCambios(
        {
          ...form.getFieldsValue(),
          bultosLineas,
          documentos,
          datosComplementarios,
          items,
        },
        ducaDetalles,
      );
    }

    if (result) {
      confirm({
        title: 'Hay cambios pendientes de guardar',
        content: '¿Desea continuar?',
        cancelText: 'Continuar',
        okText: 'Guardar',
        onOk() {
          form
            .validateFields()
            .then(values => onFinish(values))
            .catch(error => {
              Modal.destroyAll();
              setActiveKey('1');
              console.log(error);
              form.scrollToField(error.errorFields[0]?.name, {
                behavior: 'smooth',
                block: 'center',
              });
            });
        },
        onCancel() {
          onConditionRegresar();
        },
      });
    } else {
      onConditionRegresar();
    }
  };

  const onConditionRegresar = () => {
    if (stateParams?.creando)
      replace({
        pathname: `/ordenDeTrabajo`,
      });
    else
      replace({
        pathname: `/ordenDeTrabajo/${ordenId}`,
      });
  };

  const onGenerarPreliquidacion = () => {
    push({
      pathname: `/ordenDeTrabajo/${ordenId}/preliquidacion`,
      state: stateParams,
    });
  };

  const onImprimirPreliquidacion = () => {
    push({
      pathname: `/ordenDeTrabajo/${ordenId}/detallesduca/${ducaId}/preliquidacion/imprimir`,
    });
  };

  const extraActions = (
    <>
      {ducaDetalles?.id ? (
        <Tooltip placement='left' title='Imprime la duca en el formato preimpreso'>
          <Button
            icon={<PrinterOutlined />}
            style={{ marginRight: 8 }}
            loading={status === 'pending'}
            onClick={onImprimirPreliquidacion}
          >
            Imprimir Preliquidacion
          </Button>
        </Tooltip>
      ) : (
        <Tooltip placement='left' title='Faltan detalles para imprimir la preliquidacion'>
          <Button disabled style={{ marginRight: 8 }}>
            Imprimir Preliquidacion
          </Button>
        </Tooltip>
      )}
      {ducaDetalles?.id ? (
        <Button
          icon={<FileOutlined />}
          style={{ marginRight: 8 }}
          loading={status === 'pending'}
          onClick={onGenerarPreliquidacion}
        >
          Generar Preliquidacion
        </Button>
      ) : (
        <Tooltip placement='left' title='Faltan detalles para generar la preliquidacion'>
          <Button disabled style={{ marginRight: 8 }}>
            Generar Preliquidacion
          </Button>
        </Tooltip>
      )}
      <Button
        icon={<ArrowLeftOutlined />}
        style={{ marginRight: 8 }}
        onClick={onRegresarAOrdenDeTrabajo}
        loading={status === 'pending'}
      >
        Regresar a orden de trabajo
      </Button>
      {!ducaDetalles?.ordenCerrada && (
        <Button
          icon={<SaveOutlined />}
          type='primary'
          onClick={() =>
            form
              .validateFields()
              .then(values => onFinish(values))
              .catch(error => {
                setActiveKey('1');
                console.log(error);
                form.scrollToField(error.errorFields[0]?.name, {
                  behavior: 'smooth',
                  block: 'center',
                });
              })
          }
          loading={status === 'pending'}
        >
          Guardar Cambios
        </Button>
      )}
    </>
  );

  const onFinish = (values: IDucaDetallesRequest) => {
    dispatch(
      setCaratula({
        declarante: values.declarante,
        aduanaDespachoId: values.aduanaDespachoId,
        regimenAduaneroId: values.regimenAduaneroId,
        rtnImportador: values.rtnImportador,
        importador: values.importador,
        rtnAgenciaAduanera: values.rtnAgenciaAduanera,
        agenciaAduanera: values.agenciaAduanera,
        idManifiestoEntregaRapida: values.idManifiestoEntregaRapida,
        proveedorId: values.proveedorId,
        contratoProveedorDestinatario: values.contratoProveedorDestinatario,
        // domicilioProveedor: values.domicilioProveedor,
        direccionProveedorId: values.direccionProveedorId,
        numeroPreImpreso: values.numeroPreImpreso,
        entidadMediacion: values.entidadMediacion,
        depositoAlmacenamientoId: values.depositoAlmacenamientoId,
        aduanaIngresoSalidaId: values.aduanaIngresoSalidaId,
        paisOrigenId: values.paisOrigenId,
        paisProcedenciaDestinoId: values.paisProcedenciaDestinoId,
        formaPagoId: values.formaPagoId,
        condicionEntregaId: values.condicionEntregaId,
        aduanaTransitoDestinoId: values.aduanaTransitoDestinoId,
        modalidadEspecialId: values.modalidadEspecialId,
        depositoAduanaTransitoId: values.depositoAduanaTransitoId,
        plazoDiasMeses: values.plazoDiasMeses,
        rutaTransitoId: values.rutaTransitoId,
        motivoOperacionSuspensivaId: values.motivoOperacionSuspensivaId,
        observaciones: values.observaciones,
        claseBultosId: values.claseBultosId,
        cantidadBultos: values.cantidadBultos,
        pesoBrutoBultos: values.pesoBrutoBultos,
        prorratear: values.prorratear,
        totalFactura: values.totalFactura,
        totalOtrosGastos: values.totalOtrosGastos,
        totalSeguro: values.totalSeguro,
        totalFlete: values.totalFlete,
        divisaFacturaId: values.divisaFacturaId,
        tipoCambioDivisaFact: values.tipoCambioDivisaFact,
        divisaSeguroId: values.divisaSeguroId,
        divisaFleteId: values.divisaFleteId,
      } as DucaCaratula),
    );
    dispatch(
      setDucaBultos({
        manifiesto: values.manifiesto,
        tituloTransporte: values.tituloTransporte,
        indicadorCancelacionGlobal: values.indicadorCancelacionGlobal,
        bultosLineas: [],
      } as DucaBulto),
    );

    const newBultosLineas = bultosLineas.filter(linea => linea.estado !== EstadoDucaItem.Completed);

    const newDocumentos = documentos.filter(
      documento => documento.estado !== EstadoDucaItem.Completed,
    );

    const newDatosComplementarios = datosComplementarios.filter(
      complementario => complementario.estado !== EstadoDucaItem.Completed,
    );

    const newItems = items
      .filter(item => item.estado !== EstadoDucaItem.Completed)
      .map(item =>
        item.estado === EstadoDucaItem.Delete
          ? item
          : {
              ...item,
              documentos: item.documentos?.filter(
                documento => documento.estado !== EstadoDucaItem.Completed,
              ),
              ventajas: item.ventajas?.filter(
                ventaja => ventaja.estado !== EstadoDucaItem.Completed,
              ),
              datosComplementarios: item.datosComplementarios?.filter(
                complementario => complementario.estado !== EstadoDucaItem.Completed,
              ),
            },
      );

    values.bultosLineas = newBultosLineas;
    values.documentos = newDocumentos;
    values.datosComplementarios = newDatosComplementarios;
    values.items = newItems;

    // validar si se le aplico el IPC a los productos
    const productsWithIPCAplicado = items.filter(
      i => i.estado !== EstadoDucaItem.Delete && i.ipcFactor > 0 && i.ipc > 0,
    ).length;
    const existProductoWithIPC = items.filter(
      i => i.estado !== EstadoDucaItem.Delete && i.ipcFactor > 0,
    ).length;

    if (productsWithIPCAplicado !== existProductoWithIPC) {
      Modal.error({
        title: 'Existen productos con IPC',
        content: 'No se podran guardar los cambios hasta que se aplique correctamente el IPC',
        okText: 'Ok',
        onOk() {
          setActiveKey('2');
          setFiltrarPorIPC(true);
        },
      });

      return;
    }

    // Guardar datos
    customDispatch({
      asyncThuckFuction: updateDucaDetalles,
      endPoint: `/api/Ducas/Update/${ordenId}`,
      method: 'PUT',
      body: values,
      extra: { isCreating: stateParams?.creando ?? false },
    });
  };

  return (
    <section className={styles.container}>
      <Spin spinning={status === 'pending'}>
        <Tabs
          tabBarExtraContent={extraActions}
          className={styles.tabContainer}
          activeKey={activeKey}
          onChange={setActiveKey}
        >
          <TabPane
            tab={
              <span>
                <ProfileOutlined />
                General
              </span>
            }
            key='1'
          >
            <TabPaneGeneral form={form} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon component={IconTags} />
                Items
              </span>
            }
            key='2'
          >
            <TabPaneItems
              filtrarPorIPC={filtrarPorIPC}
              setFiltrarPorIPC={setFiltrarPorIPC}
              filtrarPorLitrosDeclarados={filtrarPorLitrosDeclarados}
              setFiltrarPorLitrosDeclarados={setFiltrarPorLitrosDeclarados}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </section>
  );
};
