import { FC, Fragment } from 'react';
import { Tag, Tooltip } from 'antd';
import { FormOutlined, SwapOutlined } from '@ant-design/icons';
import { Producto } from '../../../../models/Producto';
import { EstadoEntidad } from '../../../../enum/EstadoEntidad';
import styles from './ItemProducto.module.less';

const { Confirmado, Pendiente, NoCreado } = EstadoEntidad;

interface IProps {
  estado: EstadoEntidad;
  completo: boolean;
  producto: Producto;
  coincidencias: Array<Producto>;
  showCoincidencias: (sku: string, proveedor: string, coincidencias: Array<Producto>) => void;
  showDrawer: (producto: Producto) => void;
}

export const ItemProducto: FC<IProps> = ({
  estado,
  completo,
  producto,
  coincidencias,
  showCoincidencias,
  showDrawer,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {estado === Confirmado && (
          <Tag color='green' className={styles.tag}>
            creado
          </Tag>
        )}

        {estado === NoCreado && (
          <Fragment>
            <Tag color='red' className={styles.tag}>
              no creado
            </Tag>
            <Tag color={completo ? 'blue' : 'orange'} className={styles.tag}>
              {completo ? 'información completa' : 'información incompleta'}
            </Tag>
          </Fragment>
        )}

        {estado === Pendiente && (
          <Tag color='orange' className={styles.tag}>
            pendiente
          </Tag>
        )}
        <br />
        <span className={styles.text}>{producto.sku}</span>
        <br />
        <span className={styles.text}>{producto.proveedor}</span>
      </div>
      <div className={styles.icon}>
        {estado === NoCreado && (
          <Tooltip title='Agregar información'>
            <FormOutlined
              style={{ fontSize: 20 }}
              onClick={() =>
                showDrawer({
                  ...producto,
                  proveedorId: producto.proveedor,
                } as Producto)
              }
            />
          </Tooltip>
        )}

        {coincidencias?.length ? (
          <Tooltip title='Mostrar coincidencias'>
            <SwapOutlined
              style={{ fontSize: 20 }}
              onClick={() => showCoincidencias(producto.sku, producto.proveedor, coincidencias)}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
