import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EstadoMercancia } from '../../../../models/EstadoMercancia';
import httpService from '../../../../services/httpService';
import { IEstadosMercanciasState } from '../estadosMercanciasStore';

export const updateEstadoMercancia = createAsyncThunk('EstadosMercancias/Update', httpService);

export const updateEstadoMercanciaReducer = (
  builder: ActionReducerMapBuilder<IEstadosMercanciasState>,
) => {
  builder.addCase(updateEstadoMercancia.fulfilled, (state, _action) => {
    const index = state.data.findIndex(
      estadoMercancia => estadoMercancia.id === state.elementoSeleccionado?.id,
    );

    state.crudStatus = 'succeeded';
    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as EstadoMercancia);
  });
  builder.addCase(updateEstadoMercancia.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updateEstadoMercancia.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
