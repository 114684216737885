import { Form, Input, Select, Table } from 'antd';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import {
  setDeleteDucaDocumento,
  setDocumentosEditingKey,
  setSaveDucaDocumento,
} from 'features/DucaDetalles/store/store';
import { setError } from 'features/InitialData/store/initialDataStore';
import { IDucaDocumentoRequest } from 'models/Duca';
import { PapierError } from 'models/PapierException';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { Columns, IDocumentoColumnType } from './Columns';

const { Item } = Form;
const { Option } = Select;

interface IListadoDocuerosProps {
  onCancelEditMode?: () => void;
}

export const ListadoDocumentos = ({ onCancelEditMode }: IListadoDocuerosProps) => {
  const dispatch = useDispatch();
  const { documentos, TiposDocumentos, documentosEditingKey, ducaDetalles } = useAppSelector(
    state => state.ducaDetalles,
  );
  const [formDocumentos] = Form.useForm<IDucaDocumentoRequest>();

  const Presencia = [
    { id: true, descripcion: 'Sí' },
    { id: false, descripcion: 'No' },
  ];

  const isEditing = (values: IDucaDocumentoRequest) => values.key === documentosEditingKey;

  const onSave = async (key: string) => {
    try {
      const row = await formDocumentos.validateFields();
      row.documentoId = TiposDocumentos.find(b => b.codigo === row.codigo)?.id ?? '';

      dispatch(setSaveDucaDocumento({ record: row, key }));
      setEditingKey('');
    } catch (error) {
      if (error instanceof PapierError) {
        dispatch(
          setError({
            error: true,
            message: error.errorMessage,
            duration: 5,
          }),
        );
      } else {
        console.log('Validate Failed:', error);
      }
    }
  };

  const onEdit = (value: Partial<IDucaDocumentoRequest> & { key: React.Key }) => {
    formDocumentos.setFieldsValue({
      documentoId: '',
      codigo: '',
      descripcion: '',
      referencia: '',
      presencia: true,
      ...value,
    });
    setEditingKey(value.key);
    onCancelEditMode?.();
  };

  const onDelete = (key: string) => {
    dispatch(setDeleteDucaDocumento(key));
    onCancelEditMode?.();
  };

  const onCancel = () => {
    if (documentos.some(d => d.key === 'new')) dispatch(setDeleteDucaDocumento('new'));
    setEditingKey('');
  };

  const setEditingKey = (key: string) => {
    dispatch(setDocumentosEditingKey(key));
  };

  const cols = Columns({
    isEditing,
    onSave,
    onEdit,
    onDelete,
    onCancel,
    ordenCerrada: ducaDetalles?.ordenCerrada ?? false,
  });
  const mergedColumns = cols.map((col: IDocumentoColumnType) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: IDucaDocumentoRequest) => ({
        width: col.width,
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType:
          col.dataIndex === 'descripcion' || col.dataIndex === 'referencia' ? 'input' : 'dropdown',
        options:
          col.dataIndex === 'codigo'
            ? TiposDocumentos
            : col.dataIndex === 'presencia'
            ? Presencia
            : undefined,
        editing: isEditing(record),
      }),
    };
  });

  const TableDocumentosTemp = (
    <Form form={formDocumentos} component={false}>
      <Table
        components={{ body: { cell: EditableCell } }}
        bordered
        dataSource={documentos.filter(x => x.estado !== EstadoDucaItem.Delete)}
        columns={mergedColumns as any}
        pagination={false}
        size='small'
        style={{ marginTop: 16 }}
      />
    </Form>
  );

  const TableDocumentos = () => TableDocumentosTemp;

  return { TableDocumentos, setEditingKey, formDocumentos, onCancel };
};

interface IEditableCell extends React.HTMLAttributes<HTMLDivElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'dropdown' | 'input';
  options?: any[];
  record: IDucaDocumentoRequest;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<IEditableCell> = ({
  editing,
  dataIndex,
  title,
  inputType,
  options,
  record,
  index,
  children,
  ...restProps
}) => {
  const InputNode =
    inputType === 'input' ? (
      <Input
        type='text'
        disabled={dataIndex === 'descripcion'}
        style={dataIndex === 'descripcion' ? { color: '#666' } : undefined}
      />
    ) : (
      <Select
        autoFocus={dataIndex === 'codigo'}
        showSearch
        optionFilterProp='children'
        style={{ width: dataIndex === 'codigo' ? 250 : 90 }}
        filterOption
      >
        {options?.map(item =>
          dataIndex === 'codigo' ? (
            <Option key={item.id} value={item.codigo}>
              {`${item.codigo} - ${item.descripcion}`}
            </Option>
          ) : (
            <Option key={item.id} value={item.id}>
              {item.descripcion}
            </Option>
          ),
        )}
      </Select>
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            { required: true, message: `Campo requerido` },
            ({ setFieldsValue }) => ({
              validator(_, value) {
                if (dataIndex === 'codigo') {
                  setFieldsValue({
                    descripcion: options?.find(x => x?.codigo === value)?.descripcion,
                  });
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          {InputNode}
        </Item>
      ) : (
        children
      )}
    </td>
  );
};
