import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { DesignacionProducto } from '../../../../models/DesignacionProducto';
import httpService from '../../../../services/httpService';
import { IDesignacionesProductosState } from '../designacionesProductosStore';

export const createDesignacionProducto = createAsyncThunk(
  'DesignacionesProductos/Create',
  httpService,
);

export const createDesignacionProductoReducer = (
  builder: ActionReducerMapBuilder<IDesignacionesProductosState>,
) => {
  builder.addCase(createDesignacionProducto.fulfilled, (state, action) => {
    state.crudStatus = 'succeeded';
    state.data.unshift({
      ...state.elementoTemporal,
      id: action.payload,
    } as DesignacionProducto);
  });
  builder.addCase(createDesignacionProducto.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createDesignacionProducto.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
