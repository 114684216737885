import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrdenDeTrabajoRequest } from 'models/OrdenTrabajo';
import { RootState } from '../../../store/store';
import { crearOrdenTrabajoReducer } from './extraReducer/crearOrdenTrabajo';
import { updateOrdenDeTrabajoReducer } from './extraReducer/updateOrdenDeTrabajo';

export interface IOrdenDeTrabajoState {
  idOrdenTrabajoCreada: string;
  crearOrdenTrabajoStatus: StatusApp.Status;
  updateOrdenTrabajoStatus: StatusApp.Status;
  ordenTrabajo?: IOrdenDeTrabajoRequest;
}

const initialState: IOrdenDeTrabajoState = {
  idOrdenTrabajoCreada: '',
  crearOrdenTrabajoStatus: 'idle',
  updateOrdenTrabajoStatus: 'idle',
};

export const ordenDeTrabajoSlice = createSlice({
  name: 'OrdenDeTrabajo',
  initialState,
  reducers: {
    resetData: state => {
      state.crearOrdenTrabajoStatus = 'idle';
      state.idOrdenTrabajoCreada = '';
    },
    setOrdenTrabajoForm: (state, action: PayloadAction<IOrdenDeTrabajoRequest | undefined>) => {
      state.ordenTrabajo = action.payload;
    },
    setOrdenTrabajoDucaId: (state, action: PayloadAction<string>) => {
      if (state.ordenTrabajo) state.ordenTrabajo.ducaId = action.payload;
    },
    setValoresTotalesOrdenDeTrabajo: (
      state,
      action: PayloadAction<{
        TotalFOB: number;
        TotalFlete: number;
        TotalSeguro: number;
        TotalOtrosGastos: number;
        TotalCIF: number;
      }>,
    ) => {
      const payload = action.payload;
      if (state.ordenTrabajo) {
        state.ordenTrabajo = {
          ...state.ordenTrabajo,
          fob: payload.TotalFOB,
          flete: payload.TotalFlete,
          seguro: payload.TotalSeguro,
          og: payload.TotalOtrosGastos,
          cif: payload.TotalCIF,
        };
      }
    },
  },
  extraReducers: builder => {
    crearOrdenTrabajoReducer(builder);
    updateOrdenDeTrabajoReducer(builder);
  },
});

export const {
  resetData,
  setOrdenTrabajoForm,
  setOrdenTrabajoDucaId,
  setValoresTotalesOrdenDeTrabajo,
} = ordenDeTrabajoSlice.actions;

export const OrdenDeTrabajoState = (state: RootState) => state.ordenDeTrabajo;

export const OrdenDeTrabajoReducer = ordenDeTrabajoSlice.reducer;
