import { LoadingOutlined } from '@ant-design/icons';
import { RowValueChangedEvent } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { AgDateCellEditor } from 'components/AgGridCellEditor/AgDateCellEditor';
import { AgDesignacionCellEditor } from 'components/AgGridCellEditor/AgDesignacionCellEditor';
import { AgEstadoMercanciaCellEditor } from 'components/AgGridCellEditor/AgEstadoMercanciaCellEditor';
import { AgInputCellEditor } from 'components/AgGridCellEditor/AgInputCellEditor';
import { AgInputNumberCellEditor } from 'components/AgGridCellEditor/AgInputNumberCellEditor';
import { AgMarcasComercialesCellEditor } from 'components/AgGridCellEditor/AgMarcasComercialesCellEditor';
import { AgPaisesCellEditor } from 'components/AgGridCellEditor/AgPaisesCellEditor';
import { AgPartidasArancelariasCellEditor } from 'components/AgGridCellEditor/AgPartidasArancelariasCellEditor';
import { AgUnidadesMedidaCellEditor } from 'components/AgGridCellEditor/AgUnidadesMedidaCellEditor';
import { DevaProductoDrawer } from 'components/Devas/DevaProductoDrawer/DevaProductoDrawer';
import { DevaProductosToolbar } from 'components/Devas/DevaProductosToolbar/DevaProductosToolbar';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { formatCurrency } from 'utils/formatCurrency';
import { formatUTCToLocalDate } from 'utils/formatDate';
import { DropdownCellRendererDevaProductos } from '../components/dropdownCellRenderDevaProductos';
import { deleteDevaProducto } from '../store/extraReducers/deleteDevaProducto';
import { updateProductoDeva } from '../store/extraReducers/updateProductoDeva';
import { setDevaProductoIdEliminado } from '../store/store';
import { Columns } from './columns';
import { useFormatData } from './useFormatData';

export const Productos = () => {
  const [rowBottomPinned, setRowBottomPinned] = useState<object | undefined>(undefined);
  const [itemsFormat, setItemsFormat] = useState<any[]>([]);
  const { devaDetalles, status } = useAppSelector(state => state.devaDetalles);
  const Configuraciones = useAppSelector(state => state.initialData.Configuraciones);
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const formatData = useFormatData();

  useEffect(() => {
    const productos = devaDetalles?.productos ?? [];

    // Calcular totales
    const totales = productos?.reduce(
      (acc, item) => {
        acc.cantidad += item.cantidad;
        acc.valorUnitario += item.valorUnitario;
        acc.valorTotal += item.valorTotal;
        return acc;
      },
      {
        cantidad: 0,
        valorUnitario: 0,
        valorTotal: 0,
      },
    );

    // Data para fila fijata en la tabla
    setRowBottomPinned({
      cantidad: formatCurrency(totales.cantidad.toFixed(Configuraciones?.precisionDecimal)),
      valorUnitario: formatCurrency(
        totales.valorUnitario.toFixed(Configuraciones?.precisionDecimal),
      ),
      valorTotal: formatCurrency(totales.valorTotal.toFixed(Configuraciones?.precisionDecimal)),
      pinnedBottom: true,
    });

    // Data con formato en los numeros
    setItemsFormat(
      productos?.map(x => ({
        ...x,
        fechaFactura: formatUTCToLocalDate(x.fechaFactura.toLocaleString(), 'DD/MM/YY'),
        cantidad: formatCurrency(x.cantidad?.toFixed(Configuraciones?.precisionDecimal)),
        valorUnitario: formatCurrency(x.valorUnitario?.toFixed(Configuraciones?.precisionDecimal)),
        valorTotal: formatCurrency(x.valorTotal?.toFixed(Configuraciones?.precisionDecimal)),
      })),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devaDetalles?.productos]);

  const onRowValueChanged = useCallback(
    (event: RowValueChangedEvent) => {
      const id = event.data.id;
      const data = formatData(event.data);
      customDispatch({
        asyncThuckFuction: updateProductoDeva,
        endPoint: `/api/Devas/UpdateDevaProducto/${id}`,
        method: 'POST',
        body: { ...data },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatData],
  );

  const onDelete = (id: string) => {
    dispatch(setDevaProductoIdEliminado(id));
    customDispatch({
      asyncThuckFuction: deleteDevaProducto,
      endPoint: `/api/Devas/DeleteDevaProducto/${id}`,
      method: 'DELETE',
    });
  };

  const columns = Columns({
    onDelete,
  });

  return status === 'pending' ? (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 50,
      }}
    >
      <LoadingOutlined style={{ fontSize: 50, color: '#1890ff' }} />
    </div>
  ) : (
    <>
      <DevaProductosToolbar />
      <DevaProductoDrawer />
      <div
        className='ag-theme-alpine'
        style={{
          height: 'calc(100vh - 224px)',
          paddingBottom: 16,
          marginTop: 16,
          width: '100%',
        }}
      >
        <AgGridReact
          defaultColDef={{
            resizable: true,
            width: 150,
            filter: true,
            sortable: false,
          }}
          editType='fullRow'
          pagination={false}
          rowData={itemsFormat}
          columnDefs={columns}
          frameworkComponents={{
            dropdownCellRenderer: DropdownCellRendererDevaProductos,
            AgDateCellEditor,
            AgInputCellEditor,
            AgInputNumberCellEditor,
            AgPartidasArancelariasCellEditor,
            AgUnidadesMedidaCellEditor,
            AgDesignacionCellEditor,
            AgMarcasComercialesCellEditor,
            AgEstadoMercanciaCellEditor,
            AgPaisesCellEditor,
          }}
          pinnedBottomRowData={[rowBottomPinned]}
          onRowValueChanged={onRowValueChanged}
          onGridReady={params => {
            params.columnApi.autoSizeColumns(Object.keys(columns));
          }}
        />
      </div>
    </>
  );
};
