import { SerializedError } from '@reduxjs/toolkit';
import { setError } from 'features/InitialData/store/initialDataStore';

export interface IUseFetch {
  endPoint?: string;
  token?: Promise<string>;
  body?: {};
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET';
  onFinish?: () => void;
  extra?: {};
}

export interface ThunckApi {
  dispatch: (arg0: any) => void;
  getState: () => unknown;
  rejectWithValue: (arg0: any) => void;
  requestId: string;
  signal: { aborted: boolean; onabort: any };
}

let refetchCount = 0;
const httpService = async (
  { endPoint, token, body, method, onFinish }: IUseFetch,
  thunckApi: ThunckApi,
) => {
  const api: string = String(process.env.REACT_APP_API_URL);

  const headers = {
    Authorization: `Bearer ${await token}`,
    'Content-Type': 'application/json',
  };

  const config: RequestInit = {
    method: method ? method : body ? 'POST' : 'GET',
    headers: {
      ...headers,
    },
  };

  if (body) config.body = JSON.stringify(body);

  try {
    const response = await fetch(`${api}${endPoint}`, config).finally(() => {
      onFinish && onFinish();
    });

    if (response.ok) return response.json();

    throw await response.json();
  } catch (error: any) {
    method === 'GET' &&
      (await reFetch({ endPoint, body, method, onFinish, token }, thunckApi, refetchCount++));

    const err = {
      message: error?.FriendlyMessage,
      name: error?.ErrorMessage || 'Error ',
    } as SerializedError;

    thunckApi.dispatch(
      setError({
        error: true,
        message: String(err?.message || 'Error de conexión'),
      }),
    );
    throw err;
  }
};

const reFetch = async (props: IUseFetch, thunckApi: ThunckApi, count: number) => {
  if (count >= 3) {
    refetchCount = 0;
    return;
  }
  return await httpService(props, thunckApi);
};

export const httpServiceFormData = async (
  { endPoint, token, body, method, onFinish }: IUseFetch,
  thunkApi: ThunckApi,
) => {
  const api: string = String(process.env.REACT_APP_API_URL);
  const headers = {
    Authorization: `Bearer ${await token}`,
  };

  const config: RequestInit = {
    method: method ? method : body ? 'POST' : 'GET',
    headers: {
      ...headers,
    },
  };

  if (body) config.body = body as FormData;
  try {
    const response = await fetch(`${api}${endPoint}`, config).finally(() => onFinish && onFinish());

    if (response.ok) return response.json();

    throw response.json();
  } catch (error: any) {
    const err = {
      message: error?.FriendlyMessage,
      name: error?.ErrorMessage || 'Error ',
    } as SerializedError;

    thunkApi.dispatch(
      setError({
        error: true,
        message: String(err?.message || 'Error de conexión'),
      }),
    );
    throw err;
  }
};

export default httpService;
