import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Proveedor } from '../../../../models/Proveedor';
import { IProveedoresState } from '../proveedoresStore';
import httpService from '../../../../services/httpService';
import { EstadoEntidad } from 'enum/EstadoEntidad';

interface IResponse {
  id: string;
  estado: EstadoEntidad;
}

export const createProveedor = createAsyncThunk('Proveedors/Create', httpService);

export const createProveedorReducer = (builder: ActionReducerMapBuilder<IProveedoresState>) => {
  builder.addCase(createProveedor.fulfilled, (state, action: PayloadAction<IResponse>) => {
    const payload = action.payload;
    state.data.unshift({
      ...state.elementoTemporal,
      id: payload.id,
      estado: payload.estado,
      direcciones: [],
    } as Proveedor);

    state.crudStatus = 'succeeded';
  });
  builder.addCase(createProveedor.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createProveedor.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
