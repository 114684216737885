import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';
import { IInitialDataState } from '../initialDataStore';

export const getFormasDeEnvio = createAsyncThunk(
  'getFormasDeEnvio/GetAll',
  async (fetchOp: IUseFetch, thunkApi: ThunckApi) => {
    const res = httpService(fetchOp, thunkApi);
    return res;
  },
);

export const getFormasDeEnvioReducer = (builder: ActionReducerMapBuilder<IInitialDataState>) => {
  builder.addCase(
    getFormasDeEnvio.fulfilled,
    (state, action: PayloadAction<ConfiguracionesPetch[]>) => {
      state.FormasDeEnvio = action.payload;
      state.FormasDeEnvioStatus = 'succeeded';
    },
  );

  builder.addCase(getFormasDeEnvio.pending, state => {
    state.FormasDeEnvioStatus = 'pending';
  });

  builder.addCase(getFormasDeEnvio.rejected, state => {
    state.FormasDeEnvioStatus = 'failed';
  });
};
