import { Col, Form, FormItemProps, Input, InputProps } from 'antd';
import { Rule } from 'antd/lib/form';
const { Item } = Form;

interface IInput extends FormItemProps {
  type?: InputProps['type'];
  layout?: { lg?: number; md?: number; xs?: number; span?: number };
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: string) => void;
  maxLength?: number;
  initialValue?: string | number | string[] | undefined;
  minNumber?: number;
  rules?: Rule[] | undefined;
  step?: string;
}

export const CustomInput = ({
  label,
  name,
  type = 'text',
  disabled,
  layout,
  required = false,
  onChange,
  maxLength,
  initialValue,
  minNumber,
  rules,
  step,
  ...rest
}: IInput) => {
  // const disabledByStatus = useAppSelector();
  return (
    <Col span={6} {...layout}>
      <Item
        label={label}
        name={name}
        initialValue={initialValue}
        rules={
          required
            ? [
                {
                  required,
                  message: `Ingrese un valor para ${label}`,
                  validator: (_rule, value) => {
                    if (value < 0) {
                      return Promise.reject(`${label} no puede ser menor a 0`);
                    }
                    if (String(value).length === 0 || value === '' || !value) {
                      return Promise.reject(`${label} no puede estar vacío`);
                    }
                    return Promise.resolve();
                  },
                },
              ]
            : rules
        }
        {...rest}
      >
        <Input
          disabled={disabled}
          type={type}
          onChange={e => onChange && onChange(e.target.value)}
          maxLength={maxLength}
          min={minNumber}
          step={step}
          style={{ color: disabled ? '#666' : '#000' }}
        />
      </Item>
    </Col>
  );
};
