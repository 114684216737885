import { Form, FormItemProps, Input, InputProps } from 'antd';

const { Item } = Form;

interface IInputDisabled extends FormItemProps {
  name: string;
  label: string;
  type?: InputProps['type'];
}

export const InputDisabled = ({ name, label, type, ...rest }: IInputDisabled) => (
  <Item name={name} label={label} {...rest}>
    <Input disabled type={type} style={{ color: '#666', width: '100%' }} />
  </Item>
);
