import { EllipsisOutlined, SaveOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { tableColumnTextFilter } from 'utils/tableColumnFilter';
import { Producto } from '../../../models/Producto';

interface IProps {
  onSave: (values: Producto) => void;
  onDetails: (values: Producto) => void;
  onDelete: (values: Producto) => void;
}

export const Columns = ({ onSave, onDetails, onDelete }: IProps): ColumnsType<any> => {
  return [
    {
      title: 'SKU (Modelo)',
      dataIndex: 'sku',
      width: 200,
      ...tableColumnTextFilter<Producto>('sku'),
    },
    {
      title: 'Proveedor',
      dataIndex: 'proveedor',
      width: 200,
      ...tableColumnTextFilter<Producto>('proveedor'),
    },
    {
      title: 'Partida Arancelaria',
      dataIndex: 'partidaArancelaria',
      width: 200,
      ...tableColumnTextFilter<Producto>('partidaArancelaria'),
    },
    {
      title: 'Designacion de Producto',
      dataIndex: 'designacionProducto',
      width: 200,
      ...tableColumnTextFilter<Producto>('designacionProducto'),
    },
    {
      title: 'Caracteristicas',
      dataIndex: 'caracteristicas',
      width: 200,
      ...tableColumnTextFilter<Producto>('caracteristicas'),
    },
    {
      title: 'Caracteristica Principal',
      dataIndex: 'caracteristicaPrincipal',
      width: 200,
      ...tableColumnTextFilter<Producto>('caracteristicaPrincipal'),
    },
    {
      title: 'Caracteristica Secundaria',
      dataIndex: 'caracteristicaSecundaria',
      width: 200,
      ...tableColumnTextFilter<Producto>('caracteristicaSecundaria'),
    },
    {
      title: 'Marca Comercial',
      dataIndex: 'marcaComercial',
      width: 300,
      ...tableColumnTextFilter<Producto>('marcaComercial'),
    },
    {
      title: 'Pais de Origen',
      dataIndex: 'paisOrigen',
      width: 200,
      ...tableColumnTextFilter<Producto>('paisOrigen'),
    },
    {
      title: 'Unidad de Medida',
      dataIndex: 'unidadMedida',
      width: 200,
      ...tableColumnTextFilter<Producto>('unidadMedida'),
    },
    {
      title: 'Estados de Mercancia',
      dataIndex: 'estadoMercancia',
      width: 200,
      ...tableColumnTextFilter<Producto>('estadoMercancia'),
    },
    {
      title: 'Mostrar Descripcion con Presentación',
      dataIndex: 'mostrarDescripcionConPresentacion',
      width: 300,
      render: (_: any, record: Producto) =>
        record.mostrarDescripcionConPresentacion ? 'Si' : 'No',
      filters: [
        { text: 'Si', value: true },
        { text: 'No', value: false },
      ],
      onFilter: (value: any, record: any) => {
        return record.mostrarDescripcionConPresentacion === value;
      },
    },
    {
      title: 'Clasificación Fenag',
      dataIndex: 'clasificacionFenag',
      width: 200,
      ...tableColumnTextFilter<Producto>('clasificacionFenag'),
    },
    {
      title: 'Codigo de Barras',
      dataIndex: 'codigoDeBarras',
      width: 200,
      ...tableColumnTextFilter<Producto>('codigoDeBarras'),
    },
    {
      title: 'Codigo Fitosanitario',
      dataIndex: 'codigoFitosanitario',
      width: 200,
      ...tableColumnTextFilter<Producto>('codigoFitosanitario'),
    },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 50,
      render: (_: any, record: Producto) => (
        <Dropdown
          getPopupContainer={() =>
            document.getElementById('ProductosPendientesTabla') as HTMLElement
          }
          placement='bottomRight'
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item key='save' icon={<SaveOutlined />} onClick={() => onSave(record)}>
                Aprobar
              </Menu.Item>
              <Menu.Item key='details' icon={<EyeOutlined />} onClick={() => onDetails(record)}>
                Detalles
              </Menu.Item>
              <Popconfirm
                title='¿Estás seguro de eliminar este producto?'
                okText='Eliminar'
                cancelText='Cancelar'
                onConfirm={() => onDelete(record)}
                getPopupContainer={() =>
                  document.getElementById('ProductosPendientesTabla') as HTMLElement
                }
              >
                <Menu.Item key='delete' icon={<DeleteOutlined />} danger>
                  Eliminar
                </Menu.Item>
              </Popconfirm>
            </Menu>
          }
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Dropdown>
      ),
    },
  ];
};
