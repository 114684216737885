import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EstadoMercancia } from '../../../models/EstadoMercancia';
import { RootState } from '../../../store/store';
import { createEstadoMercanciaReducer } from './extraReducer/create';
import { deleteEstadoMercanciaReducer } from './extraReducer/delete';
import { getEstadosMercanciasReducer } from './extraReducer/get';
import { updateEstadoMercanciaReducer } from './extraReducer/update';

export interface IEstadosMercanciasState {
  data: Array<EstadoMercancia>;
  status: StatusApp.Status;
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado: EstadoMercancia | undefined;
  elementoTemporal: EstadoMercancia | undefined;
  error?: string;
  apiURL: string;
}

const initialState: IEstadosMercanciasState = {
  data: [],
  status: 'idle',
  crudStatus: 'idle',
  modalVisible: false,
  elementoSeleccionado: undefined,
  elementoTemporal: undefined,
  apiURL: '/api/EstadosMercancias',
};

export const estadosMercanciasSlice = createSlice({
  name: 'EstadosMercancias',
  initialState,
  reducers: {
    toggleSeleccionarEstadoMercancia: (
      state,
      action: PayloadAction<EstadoMercancia | undefined>,
    ) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setEstadoMercanciaTemporal: (state, action: PayloadAction<EstadoMercancia>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
    resetError: state => {
      state.error = undefined;
    },
  },
  extraReducers: builder => {
    getEstadosMercanciasReducer(builder);
    createEstadoMercanciaReducer(builder);
    updateEstadoMercanciaReducer(builder);
    deleteEstadoMercanciaReducer(builder);
  },
});

export const {
  toggleSeleccionarEstadoMercancia,
  toggleModalVisible,
  setEstadoMercanciaTemporal,
  setCrudStatusIdle,
} = estadosMercanciasSlice.actions;

export const EstadosMercanciasState = (state: RootState) => state.estadosMercancias;

export default estadosMercanciasSlice.reducer;
