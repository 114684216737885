import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { OrganizacionPais } from 'models/OrganizacionPais';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';
import { IInitialDataState } from '../initialDataStore';

export const getOrganizacionesPaises = createAsyncThunk(
  'OrganizacionesPaises/GetAll',
  async (fetchOp: IUseFetch, thunkApi: ThunckApi) => {
    const res = httpService(fetchOp, thunkApi);
    return res;
  },
);

export const getOrganizacionesPaisesReducer = (
  builder: ActionReducerMapBuilder<IInitialDataState>,
) => {
  builder.addCase(
    getOrganizacionesPaises.fulfilled,
    (state, action: PayloadAction<OrganizacionPais[]>) => {
      state.OrganizacionesPaises = action.payload;
      state.OrganizacionesPaisesStatus = 'succeeded';
    },
  );

  builder.addCase(getOrganizacionesPaises.pending, state => {
    state.OrganizacionesPaisesStatus = 'pending';
  });

  builder.addCase(getOrganizacionesPaises.rejected, state => {
    state.OrganizacionesPaisesStatus = 'failed';
  });
};
