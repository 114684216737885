import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IUsuariosState } from '../usuariosStore';

export const deleteUsuario = createAsyncThunk('Usuarios/Delete', httpService);

export const deleteUsuarioReducer = (builder: ActionReducerMapBuilder<IUsuariosState>) => {
  // Delete
  builder.addCase(deleteUsuario.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.data.findIndex(usuario => usuario.id === state.elementoSeleccionado?.id);
    state.data.splice(index, 1);
  });
  builder.addCase(deleteUsuario.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deleteUsuario.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
