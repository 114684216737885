import { Button, Form, Modal, Row, Spin, Table } from 'antd';
import { CustomInput } from 'components/FormControls/CustomInput';
import { CustomTextarea } from 'components/FormControls/CustomTextarea';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { DireccionProveedor } from 'models/Proveedor';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { createDireccionProveedorPendiente } from '../store/extraReducers/proveedor/createDireccionProveedorPendienteReducer';
import { deleteDireccionProveedorPendiente } from '../store/extraReducers/proveedor/deleteDireccionProveedorPendienteReducer';
import { updateDireccionProveedorPendiente } from '../store/extraReducers/proveedor/updateDireccionProveedorPendienteReducer';
import {
  addDireccionProveedorPendiente,
  setDireccionProveedorPendienteEdigingKey,
  setDireccionesProveedorPendiente,
  setDireccionProveedorPendienteTemporal,
  toggleModalDireccionesProveedorPendienteVisible,
  setProveedorPendienteSeleccionado,
} from '../store/inicioStore';
import { DireccionesProveedorPendienteColumns } from './DireccionesProveedorPendienteColumns';

export const ModalDireccionesProveedorPendiente = () => {
  const apiURL = '/api/Proveedores';
  const [form] = Form.useForm<DireccionProveedor>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const {
    modalDireccionesVisible,
    crudDireccionesStatus,
    direccionEditingKey,
    proveedorPendienteSeleccionado,
    direcciones,
  } = useAppSelector(state => state.inicio);
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();

  useEffect(() => {
    setLoading(crudDireccionesStatus === 'pending');
  }, [crudDireccionesStatus]);

  const onClose = () => {
    if (!isLoading) {
      dispatch(toggleModalDireccionesProveedorPendienteVisible());
      dispatch(setProveedorPendienteSeleccionado(undefined));
      dispatch(setDireccionProveedorPendienteEdigingKey(''));
      dispatch(setDireccionesProveedorPendiente([]));
    }
  };

  const isEditing = (values: DireccionProveedor) => values.key === direccionEditingKey;

  const onCancel = () => {
    if (direcciones.some(l => l.key === 'new')) {
      dispatch(setDireccionesProveedorPendiente(direcciones.filter(_ => _.key !== 'new')));
    }

    form.resetFields();

    setEditingKey('');
  };

  const onSave = async (key: string) => {
    try {
      const row = await form.validateFields();
      const proveedorId = String(proveedorPendienteSeleccionado?.id);

      // Crear Direccion
      if (key === 'new') {
        dispatch(setDireccionProveedorPendienteTemporal({ ...row, proveedorId }));
        customDispatch({
          asyncThuckFuction: createDireccionProveedorPendiente,
          endPoint: `${apiURL}/CreateDireccion`,
          body: {
            ...row,
            proveedorId,
          },
        });
      } else {
        // Actualizar direccion
        const direccion = {
          ...direcciones.find(_ => _.key === key),
          ...row,
          proveedorId,
        } as DireccionProveedor;
        dispatch(setDireccionProveedorPendienteTemporal(direccion));

        customDispatch({
          asyncThuckFuction: updateDireccionProveedorPendiente,
          endPoint: `${apiURL}/UpdateDireccion`,
          body: {
            ...direccion,
          },
          method: 'PUT',
        });
      }

      setEditingKey('');
    } catch (error) {
      console.log('Validate Failed:', error);
    }
  };

  const onEdit = (value: Partial<DireccionProveedor> & { key: React.Key }) => {
    form.setFieldsValue({
      ...value,
    });
    setEditingKey(value.key);
  };

  const onDelete = (key: string) => {
    const direccion = {
      ...direcciones.find(_ => _.key === key),
      proveedorId: String(proveedorPendienteSeleccionado?.id),
    } as DireccionProveedor;
    dispatch(setDireccionProveedorPendienteTemporal(direccion));

    customDispatch({
      asyncThuckFuction: deleteDireccionProveedorPendiente,
      endPoint: `${apiURL}/DeleteDireccion/${direccion.id}`,
      method: 'DELETE',
    });

    onCancel();
  };

  const setEditingKey = (key: string) => {
    dispatch(setDireccionProveedorPendienteEdigingKey(key));
  };

  const cols = DireccionesProveedorPendienteColumns({
    isEditing,
    onSave,
    onEdit,
    onDelete,
    onCancel,
  });
  const mergedColumns = cols.map((col: any) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: DireccionProveedor) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onNewItem = () => {
    form.resetFields();
    dispatch(
      addDireccionProveedorPendiente({
        id: '',
        key: 'new',
        proveedorId: proveedorPendienteSeleccionado?.id ?? '',
        direccion: '',
        ciudad: '',
        telefono: '',
        fax: '',
        email: '',
      }),
    );
    setEditingKey('new');
  };

  if (!proveedorPendienteSeleccionado) return <></>;

  return (
    <Modal
      title={`Direcciones de ${proveedorPendienteSeleccionado.nombre}`}
      onCancel={onClose}
      visible={modalDireccionesVisible}
      closable={!isLoading}
      width={900}
      footer={[
        <Button onClick={onClose} loading={isLoading} key='aceptar'>
          Aceptar
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <Row justify='end' style={{ marginBottom: 16 }}>
          <Button type='primary' onClick={onNewItem}>
            Agregar Dirección
          </Button>
        </Row>
        <Form form={form} component={false}>
          <Table
            components={{ body: { cell: EditableCell } }}
            bordered
            dataSource={direcciones}
            columns={mergedColumns as any}
            pagination={false}
            size='small'
          />
        </Form>
      </Spin>
    </Modal>
  );
};

interface IEditableCell extends React.HTMLAttributes<HTMLDivElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: DireccionProveedor;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<IEditableCell> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        dataIndex === 'direccion' ? (
          <CustomTextarea name={dataIndex} style={{ margin: 0 }} layout={{ span: 24 }} />
        ) : (
          <CustomInput name={dataIndex} type='normal' style={{ margin: 0 }} layout={{ span: 24 }} />
        )
      ) : (
        children
      )}
    </td>
  );
};
