import { Col, Row, Tag, Typography } from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';
import { EstadoOrdenTrabajo } from 'enum/EstadoOrdenTrabajo';
import { useAppSelector } from 'store/hooks';
import styles from './StatusBar.module.less';

const Tags = {
  [EstadoOrdenTrabajo.Borrador]: { color: 'orange', text: 'Borrador' },
  [EstadoOrdenTrabajo.EnProgreso]: { color: 'blue', text: 'En Progreso' },
  [EstadoOrdenTrabajo.Cerrada]: { color: 'cyan', text: 'Cerrada' },
  [EstadoOrdenTrabajo.EnEsperaAprobacion]: { color: 'magenta', text: 'En Espera Aprobacion' },
  [EstadoOrdenTrabajo.Inconsistente]: { color: 'red', text: 'Inconsistente' },
};

export const EstatusBar = () => {
  const estado: EstadoOrdenTrabajo = useAppSelector(
    state => state.ordenTrabajoDetalles.data?.estado || EstadoOrdenTrabajo.Borrador,
  );
  const data = useAppSelector(state => state.ordenTrabajoDetalles.data);

  const tag = Tags[estado];

  return (
    <Row className={styles.statusBar} justify='space-between' align='middle'>
      <Col>
        <Typography.Text style={{ marginRight: '.5rem' }}>Estatus:</Typography.Text>
        {!data ? <SkeletonButton active size='small' /> : <Tag color={tag.color}>{tag.text}</Tag>}
      </Col>
      {estado === EstadoOrdenTrabajo.Cerrada && (
        <Col>
          <Typography.Text mark>Como la orden está cerrada, ya no se podrá editar</Typography.Text>
        </Col>
      )}
      {estado === EstadoOrdenTrabajo.Inconsistente && (
        <Col>
          <Typography.Text mark>
            Productos asociados a las Devas de esta orden fueron, borrados. Debe cargarse la
            información de nuevo.
          </Typography.Text>
        </Col>
      )}
    </Row>
  );
};
