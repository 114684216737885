import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversionUnidadDeMedida } from '../../../models/ConversionUnidadDeMedida';
import { RootState } from '../../../store/store';
import { createConversionUnidadDeMedidaReducer } from './extraReducer/create';
import { deleteConversionUnidadDeMedidaReducer } from './extraReducer/delete';
import { getConversionesUnidadedDeMedidasReducer } from './extraReducer/get';
import { updateConversionUnidadDeMedidaReducer } from './extraReducer/update';

export interface IConversionUnidadDeMedidaState {
  data: Array<ConversionUnidadDeMedida>;
  status: StatusApp.Status;
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado: ConversionUnidadDeMedida | undefined;
  elementoTemporal: ConversionUnidadDeMedida | undefined;
  error?: string;
  apiURL: string;
}

const initialState: IConversionUnidadDeMedidaState = {
  data: [],
  status: 'idle',
  crudStatus: 'idle',
  modalVisible: false,
  elementoSeleccionado: undefined,
  elementoTemporal: undefined,
  apiURL: '/api/ConversionUnidadesDeMedidas',
};

export const conversionesUnidadesDeMedidaSlice = createSlice({
  name: 'ConversionUnidadesDeMedidas',
  initialState,
  reducers: {
    toggleSeleccionarConversionUnidadDeMedida: (
      state,
      action: PayloadAction<ConversionUnidadDeMedida | undefined>,
    ) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setConversionUnidadDeMedidaTemporal: (
      state,
      action: PayloadAction<ConversionUnidadDeMedida>,
    ) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
    addConversionUnidadDeMedida: (state, action: PayloadAction<ConversionUnidadDeMedida>) => {
      state.data.push(action.payload);
    },
    resetError: state => {
      state.error = undefined;
    },
  },
  extraReducers: builder => {
    getConversionesUnidadedDeMedidasReducer(builder);
    createConversionUnidadDeMedidaReducer(builder);
    updateConversionUnidadDeMedidaReducer(builder);
    deleteConversionUnidadDeMedidaReducer(builder);
  },
});

export const {
  toggleSeleccionarConversionUnidadDeMedida,
  toggleModalVisible,
  setConversionUnidadDeMedidaTemporal,
  setCrudStatusIdle,
  addConversionUnidadDeMedida,
} = conversionesUnidadesDeMedidaSlice.actions;

export const ConversionesUnidadDeMedidasState = (state: RootState) =>
  state.conversionesUnidadesDeMedidas;

export default conversionesUnidadesDeMedidaSlice.reducer;
