import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ConversionUnidadDeMedida } from 'models/ConversionUnidadDeMedida';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const getConversionOnzasLitros = createAsyncThunk(
  'DucaDetalles/GetConversionOnzasLitros',
  httpService,
);

export const getConversionOnzasLitrosReducer = (
  builder: ActionReducerMapBuilder<IDucaDetallesState>,
) => {
  builder.addCase(
    getConversionOnzasLitros.fulfilled,
    (state, action: PayloadAction<ConversionUnidadDeMedida>) => {
      state.ConversionOnzasLitros = action.payload;
      state.ConversionOnzasLitrosStatus = 'succeeded';
    },
  );
  builder.addCase(getConversionOnzasLitros.pending, (state, _action) => {
    state.ConversionOnzasLitrosStatus = 'pending';
  });
  builder.addCase(getConversionOnzasLitros.rejected, (state, _action) => {
    state.ConversionOnzasLitrosStatus = 'failed';
  });
};
