import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setError } from 'features/InitialData/store/initialDataStore';
import { IOrdenDeTrabajoRequest } from 'models/OrdenTrabajo';
import httpService, { IUseFetch, ThunckApi } from 'services/httpService';
import { IOrdenDeTrabajoState, setOrdenTrabajoForm } from '../store';

export const crearOrdenTrabajo = createAsyncThunk(
  'OrdenDeTrabajo/CreateOrdenTrabajo',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      thunkApi.dispatch(setOrdenTrabajoForm(fetch?.body as IOrdenDeTrabajoRequest));
      thunkApi.dispatch(setError({ message: 'Orden de trabajo creada.', error: false }));
    }
    return res;
  },
);

export const crearOrdenTrabajoReducer = (
  builder: ActionReducerMapBuilder<IOrdenDeTrabajoState>,
) => {
  builder.addCase(crearOrdenTrabajo.fulfilled, (state, action: PayloadAction<string>) => {
    state.idOrdenTrabajoCreada = action.payload;
    state.crearOrdenTrabajoStatus = 'succeeded';
  });
  builder.addCase(crearOrdenTrabajo.pending, (state, _action) => {
    state.crearOrdenTrabajoStatus = 'pending';
  });
  builder.addCase(crearOrdenTrabajo.rejected, (state, _action) => {
    state.crearOrdenTrabajoStatus = 'failed';
  });
};
