import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';

export function DropdownCellRendererDevaProductos(props: any) {
  const onDelete = (id: string) => {
    props?.values[0]?.onDelete?.(id);
  };

  return (
    !props.data?.pinnedBottom && (
      <Popconfirm
        title='¿Estás seguro de eliminar este producto?'
        okText='Eliminar'
        cancelText='Cancelar'
        onConfirm={() => onDelete(props?.data?.id)}
        placement='left'
      >
        <Button key='eliminar' icon={<DeleteOutlined />} danger />
      </Popconfirm>
    )
  );
}
