import { Form } from 'antd';
import { useFilter } from 'hooks/useFilter';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { Pais } from '../../models/Pais';
import { Columns } from './Columns';
import { ModalUpsert } from './ModalUpsert/ModalUpsert';
import styles from './Paises.module.less';
import { createPais } from './store/extraReducers/createPais';
import { deletePais } from './store/extraReducers/deletePais';
import { updatePais } from './store/extraReducers/updatePais';
import {
  PaisesState,
  setCrudStatusIdle,
  setPaisTemporal,
  toggleModalVisible,
  toggleSeleccionarPais,
} from './store/paisesStore';
import { Toolbar } from './Toolbar/Toolbar';

export const Paises: FC = () => {
  const { data, status, crudStatus, modalVisible, elementoSeleccionado, apiURL } =
    useSelector(PaisesState);
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    !window.document.title.includes('Países') && dispatch(setTitleName('Países'));

    if (crudStatus === 'succeeded') {
      dispatch(setCrudStatusIdle());
      form.resetFields();
    }
  }, [crudStatus, dispatch, form]);

  const setUpdateSelection = (values: Pais) => {
    dispatch(toggleSeleccionarPais(values));
    setToggleModalVisible();
    form.setFieldsValue({ ...values });
  };

  const setDeleteSelection = (values: Pais) => {
    dispatch(toggleSeleccionarPais(values));
    onDelete(values.id);
  };

  const setToggleModalVisible = () => {
    dispatch(toggleModalVisible());
  };

  const cols = Columns({
    onUpdate: setUpdateSelection,
    onDelete: setDeleteSelection,
  });

  const onCreate = (values: Pais) => {
    dispatch(setPaisTemporal(values));
    customDispatch({
      asyncThuckFuction: createPais,
      endPoint: `${apiURL}/Create`,
      body: values,
    });
  };

  const onUpdate = (values: Pais) => {
    dispatch(setPaisTemporal(values));
    customDispatch({
      asyncThuckFuction: updatePais,
      endPoint: `${apiURL}/Update/${elementoSeleccionado?.id}`,
      body: values,
      method: 'PUT',
    });
  };

  const onDelete = (id: string) => {
    customDispatch({
      asyncThuckFuction: deletePais,
      endPoint: `${apiURL}/Delete/${id}`,
      method: 'DELETE',
    });
  };

  const [onfilter, filteredData] = useFilter<Pais>({
    data,
    filterPredicate: (item, filter) => item.nombre.toLowerCase().includes(filter.toLowerCase()),
  });

  return (
    <section className={styles.container}>
      <Toolbar setNombre={onfilter} setToggleModal={setToggleModalVisible} />
      <BaseTable
        cols={cols}
        data={filteredData}
        status={status}
        size='small'
        style={{ height: 'calc(100% - 40px)' }}
      />
      <ModalUpsert
        visible={modalVisible}
        form={form}
        status={crudStatus}
        elementoSeleccionado={elementoSeleccionado}
        onCreate={onCreate}
        onUpdate={onUpdate}
        setCerrarModal={setToggleModalVisible}
      />
    </section>
  );
};
