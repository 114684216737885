import { PapierError } from 'models/PapierException';
import { UnidadMedidaNeta } from 'models/UnidadMedidaNeta';

export const ValidarProductoImportado = (
  producto: any,
  index: number,
  unidadesMedidas: UnidadMedidaNeta[],
) => {
  if (producto['Numero de Factura'] === undefined || producto['Numero de Factura'] === '') {
    throw new PapierError(`La celda A${index + 2} no tiene numero de factura`);
  }

  if (producto['Fecha de Factura'] === undefined || producto['Fecha de Factura'] === '') {
    throw new PapierError(`La celda B${index + 2} no tiene fecha de factura`);
  }

  /*if (producto['Fecha de Factura'] === undefined || producto['Fecha de Factura'] === '') {
    throw new PapierError(`La celda B${index + 2} no tiene fecha de factura`);
  } else {
    const fecha = producto['Fecha de Factura'].split('/');
    const day = fecha[0];
    const month = fecha[1];
    const year = fecha[2];
    const fechaFactura = new Date(`${month}/${day}/${year}`);
    if (fechaFactura.toString() === 'Invalid Date') {
      throw new PapierError(
        `La celda B${index + 2} tiene una fecha de factura inválida, debe ser dd/mm/yyyy`,
      );
    } else {
      producto['Fecha de Factura'] = fechaFactura.toISOString();
    }
  }*/

  if (producto.Modelo === undefined || producto.Modelo === '') {
    throw new PapierError(`La celda C${index + 2} no tiene modelo`);
  }

  if (producto['Cantidad Comercial'] === undefined || producto['Cantidad Comercial'] === '') {
    throw new PapierError(`La celda D${index + 2} no tiene cantidad`);
  } else {
    const cantidad = Number(producto['Cantidad Comercial']);
    if (isNaN(cantidad)) {
      throw new PapierError(`La celda D${index + 2} tiene una cantidad inválida`);
    }
  }

  if (producto['Valor Unitario'] === undefined || producto['Valor Unitario'] === '') {
    throw new PapierError(`La celda E${index + 2} no tiene valor unitario`);
  } else {
    const valorUnitario = Number(producto['Valor Unitario']);
    if (isNaN(valorUnitario)) {
      throw new PapierError(`La celda E${index + 2} tiene un valor unitario inválido`);
    }
  }

  if (producto['Valor Total'] === undefined || producto['Valor Total'] === '') {
    throw new PapierError(`La celda F${index + 2} no tiene valor total`);
  } else {
    const valorTotal = Number(producto['Valor Total']);
    if (isNaN(valorTotal)) {
      throw new PapierError(`La celda F${index + 2} tiene un valor total inválido`);
    }
  }

  if (producto['Unidad Medida'] === undefined || producto['Unidad Medida'] === '') {
    throw new PapierError(`La celda G${index + 2} no tiene unidad de medida`);
  } else {
    const unidadMedida = producto['Unidad Medida'].toLowerCase();
    const exist = unidadesMedidas.find(um => um.nombre.toLowerCase() === unidadMedida);
    if (exist === undefined) {
      throw new PapierError(`La celda G${index + 2} tiene una unidad de medida inválida`);
    }
  }
};
