import { Button, Form, Modal, Typography } from 'antd';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { addVentajaToItem } from 'features/DucaDetalles/store/store';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { IDucaVentajaRequest } from 'models/Duca';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { DataType } from '../TabPaneItems';
import { FormVentaja } from './FormVentaja';
import { TablaAsignarVentajas } from './TablaAsignarVentaja';

const { Text } = Typography;

interface IModalAsignarVentajas {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export const ModalAsignarVentajas = ({ visible, setVisible }: IModalAsignarVentajas) => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [data, setData] = useState<DataType[]>([]);
  const { Ventajas, items } = useAppSelector(state => state.ducaDetalles);
  const [form] = Form.useForm<IDucaVentajaRequest>();

  useEffect(() => {
    const tempData = items
      .filter(item => item.estado !== EstadoDucaItem.Delete)
      .map(item => ({
        key: item.key,
        numeroItem: item.numeroItem,
        posicionArancelaria: item.posicionArancelaria,
        descripcion: item.descripcion,
        paisOrigen: item.paisOrigen,
        dai: item.dai,
        tarifaTLC: item.tarifaTLC,
        tarifaTLCAplicada: item.tarifaTLCAplicada,
        existencia: false,
      }));
    setData(tempData);
  }, [items]);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRow([]);
  };

  const onFinish = (values: IDucaVentajaRequest) => {
    const ventaja = Ventajas.find(x => x.id === values.ventajaId);
    values.codigo = ventaja?.codigo ?? '';
    values.descripcion = ventaja?.descripcion ?? '';
    values.estado = EstadoDucaItem.Create;
    values.key = GenerateKey();

    dispatch(addVentajaToItem({ itemsKey: selectedRow, ventaja: values }));

    setSelectedRow([]);
    setData(item => item.map(x => ({ ...x, existencia: false })));
    form.resetFields();
  };

  const onChangeVentaja = (value: string, _option: any) => {
    setData(data.map(x => ({ ...x, existencia: onExisteAsignacion(x.key, value) })));
  };

  const onExisteAsignacion = (key: React.Key, ventajaId: string): boolean => {
    const item = items.find(x => x.key === key);
    return item?.ventajas?.some(x => x.ventajaId === ventajaId) ?? false;
  };

  return (
    <Modal
      title='Asignar Ventajas'
      visible={visible}
      onCancel={onClose}
      width={763}
      footer={<Button onClick={onClose}>Cerrar</Button>}
    >
      <FormVentaja
        form={form}
        onFinish={onFinish}
        onChange={onChangeVentaja}
        disabled={selectedRow.length === 0}
      />
      <TablaAsignarVentajas data={data} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
      <Text>Los productos con un asterisco (*) ya tiene la ventaja que se trata de asignar</Text>
    </Modal>
  );
};
