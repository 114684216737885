import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const queryRegimenAduanero = createAsyncThunk(
  'DucaDetalles/RegimenAduanero/Query',
  httpService,
);

export const queryRegimenAduaneroReducer = (
  builder: ActionReducerMapBuilder<IDucaDetallesState>,
) => {
  builder.addCase(
    queryRegimenAduanero.fulfilled,
    (state, action: PayloadAction<PagedList<ConfiguracionesPetch>>) => {
      state.RegimenesAduaneros = action.payload.items;
      state.RegimenesAduanerosStatus = 'succeeded';
    },
  );

  builder.addCase(queryRegimenAduanero.pending, state => {
    state.RegimenesAduanerosStatus = 'pending';
  });

  builder.addCase(queryRegimenAduanero.rejected, state => {
    state.RegimenesAduanerosStatus = 'failed';
  });
};
