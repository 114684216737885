import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pais } from '../../../models/Pais';
import { RootState } from '../../../store/store';
import { getPaisesReducer } from './extraReducers/getPaises';
import { createPaisReducer } from './extraReducers/createPais';
import { updatePaisReducer } from './extraReducers/updatePais';
import { deletePaisReducer } from './extraReducers/deletePais';

export interface IPaisesState {
  data: Pais[];
  status: StatusApp.Status;
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado: Pais | undefined;
  elementoTemporal: Pais | undefined;
  apiURL: string;
}

const initialState: IPaisesState = {
  data: [],
  status: 'idle',
  crudStatus: 'idle',
  modalVisible: false,
  elementoSeleccionado: undefined,
  elementoTemporal: undefined,
  apiURL: '/api/Paises',
};

export const paisesSlice = createSlice({
  name: 'Paises',
  initialState,
  reducers: {
    toggleSeleccionarPais: (state, action: PayloadAction<Pais | undefined>) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setPaisTemporal: (state, action: PayloadAction<Pais>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
  },
  extraReducers: builder => {
    getPaisesReducer(builder);
    createPaisReducer(builder);
    updatePaisReducer(builder);
    deletePaisReducer(builder);
  },
});

export const { toggleSeleccionarPais, toggleModalVisible, setPaisTemporal, setCrudStatusIdle } =
  paisesSlice.actions;

export const PaisesState = (state: RootState) => state.paises;

export default paisesSlice.reducer;
