import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Importador } from '../../models/Importador';

interface IProps {
  onUpdate: (values: Importador) => void;
}

export const Columns = ({ onUpdate }: IProps): ColumnsType<Importador> => {
  return [
    {
      title: 'Id Tams',
      dataIndex: 'clienteTamsId',
      width: 150,
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      width: 250,
    },
    {
      title: 'Rtn',
      dataIndex: 'rtn',
      width: 200,
    },
    {
      title: 'Numero De Registro',
      dataIndex: 'numeroDeRegistro',
      width: 200,
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion',
      width: 250,
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      width: 250,
    },
    {
      title: 'País',
      dataIndex: 'pais',
      width: 250,
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      width: 250,
    },
    {
      title: 'Fax',
      dataIndex: 'fax',
      width: 250,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 250,
    },
    {
      title: 'Nivel Comercial',
      dataIndex: 'nivelComercial',
      width: 250,
    },
    {
      title: 'Otro Nivel Comercial',
      dataIndex: 'otroNivelComercial',
      width: 250,
    },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 50,
      render: (_: any, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='editar' onClick={() => onUpdate(record)}>
                <Button type='primary' ghost block>
                  Editar
                </Button>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
          placement='bottomRight'
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Dropdown>
      ),
    },
  ];
};
