import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Importador } from '../../../../models/Importador';
import httpService from '../../../../services/httpService';
import { IImportadoresState } from '../store';

export const getImportadores = createAsyncThunk('Importadores/GetAll', httpService);

export const getImportadoresReducer = (builder: ActionReducerMapBuilder<IImportadoresState>) => {
  builder.addCase(getImportadores.fulfilled, (state, action: PayloadAction<Importador[]>) => {
    state.data = action.payload;
    state.status = 'succeeded';
  });

  builder.addCase(getImportadores.pending, state => {
    state.status = 'pending';
  });

  builder.addCase(getImportadores.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
  });
};
