import { Input } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';

export const AgInputCellEditor = forwardRef((props: any, ref) => {
  const [value, setValue] = useState<string>(props.value);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  const onChange = (e: string) => {
    setValue(e);
  };

  return (
    <div>
      <Input onChange={e => onChange(e.target.value)} defaultValue={value} />
    </div>
  );
});
