import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IProveedoresState } from '../proveedoresStore';

export const deleteProveedor = createAsyncThunk('Proveedors/Delete', httpService);

export const deleteProveedorReducer = (builder: ActionReducerMapBuilder<IProveedoresState>) => {
  builder.addCase(deleteProveedor.fulfilled, (state, _action) => {
    const index = state.data.findIndex(
      proveedor => proveedor.id === state.elementoSeleccionado?.id,
    );
    state.data.splice(index, 1);

    state.crudStatus = 'succeeded';
  });
  builder.addCase(deleteProveedor.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deleteProveedor.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
