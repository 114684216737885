import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { PartidaArancelaria } from 'models/PartidaArancelaria';
import httpService from 'services/httpService';
import { IPartidasArancelariasState } from '../partidaArancelariaStore';

export const updatePartidaArancelaria = createAsyncThunk(
  'PartidasArancelarias/Update',
  httpService,
);

export const updatePartidaArancelariaReducer = (
  builder: ActionReducerMapBuilder<IPartidasArancelariasState>,
) => {
  builder.addCase(updatePartidaArancelaria.fulfilled, (state, _action) => {
    const index = state.pagedData.items.findIndex(
      partidaArancelaria => partidaArancelaria.id === state.elementoSeleccionado?.id,
    );

    state.crudStatus = 'succeeded';
    state.pagedData.items.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as PartidaArancelaria);
  });
  builder.addCase(updatePartidaArancelaria.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updatePartidaArancelaria.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
