import { Button, Col, Row } from 'antd';
import SearchFilter from '../../../components/SearchFilter/SearchFilter';

interface IProps {
  setNombre: (value: string) => void;
  setToggleModal: () => void;
}

export const Toolbar = ({ setNombre, setToggleModal }: IProps) => {
  return (
    <Row gutter={[24, 24]} align='middle' justify='space-between' style={{ marginBottom: 24 }}>
      <Col>
        <SearchFilter setFilter={setNombre} />
      </Col>
      <Col>
        <Button type='primary' onClick={setToggleModal}>
          Crear Tratado
        </Button>
      </Col>
    </Row>
  );
};
