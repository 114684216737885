import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DevaDetails } from 'models/Deva';
import { LugarEmbarque } from 'models/LugarEmbarque';
import httpService from '../../../../services/httpService';
import { IDevaDetallesState } from '../store';

export const getDevaById = createAsyncThunk('DevaDetalles/getById', httpService);

export const getDevaByIdReducer = (builder: ActionReducerMapBuilder<IDevaDetallesState>) => {
  builder.addCase(getDevaById.fulfilled, (state, action: PayloadAction<DevaDetails>) => {
    state.devaDetalles = action.payload;
    state.lugaresEmbarque = [
      { id: action.payload.lugarEmbarqueId, nombre: action.payload.lugarEmbarque } as LugarEmbarque,
    ];
    state.status = 'succeeded';
  });
  builder.addCase(getDevaById.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getDevaById.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
