import { createSlice } from '@reduxjs/toolkit';
import { DucaDetalles } from 'models/Duca';
import { RootState } from '../../../store/store';
import { getDetallesDucaReducer } from './extraReducer/getDetallesDuca';
import { getItemsDucaReducer } from './extraReducer/getItemsDuca';

export interface IArchivoPreliquidacionState {
  data?: DucaDetalles;
  status: StatusApp.Status;
  statusItems: StatusApp.Status;
  apiURL: string;
}

const initialState: IArchivoPreliquidacionState = {
  status: 'idle',
  statusItems: 'idle',
  apiURL: '/api/OrdenesDeTrabajo',
};

export const archivoPreliquidacionSlice = createSlice({
  name: 'ArchivoPreliquidacion',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getDetallesDucaReducer(builder);
    getItemsDucaReducer(builder);
  },
});

// export const {} = archivoPreliquidacionSlice.actions;

export const ArchivoPreliquidacionState = (state: RootState) => state.archivoPreliquidacion;

export const ArchivoPreliquidacionReducer = archivoPreliquidacionSlice.reducer;
