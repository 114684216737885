import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const queryModalidadEspecial = createAsyncThunk(
  'DucaDetalles/ModalidadEspecial/Query',
  httpService,
);

export const queryModalidadEspecialReducer = (
  builder: ActionReducerMapBuilder<IDucaDetallesState>,
) => {
  builder.addCase(
    queryModalidadEspecial.fulfilled,
    (state, action: PayloadAction<PagedList<ConfiguracionesPetch>>) => {
      state.ModalidadesEspeciales = action.payload.items;
      state.ModalidadesEspecialesStatus = 'succeeded';
    },
  );

  builder.addCase(queryModalidadEspecial.pending, state => {
    state.ModalidadesEspecialesStatus = 'pending';
  });

  builder.addCase(queryModalidadEspecial.rejected, state => {
    state.ModalidadesEspecialesStatus = 'failed';
  });
};
