export const validationRulesForInput = [
  {
    required: true,
    validator: (_rule: any, value: string) => {
      if (value === '' || value === null) {
        return Promise.reject(`Campo requerido`);
      }

      if (value.length < 3) {
        return Promise.reject(`Se esperaban al menos 3 caracteres`);
      }

      return Promise.resolve();
    },
  },
];
