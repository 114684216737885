import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DireccionProveedor } from '../../../../models/Proveedor';
import { IProveedoresState } from '../proveedoresStore';
import httpService from '../../../../services/httpService';

export const updateDireccionProveedor = createAsyncThunk('Proveedors/UpdateDireccion', httpService);

export const updateDireccionProveedorReducer = (
  builder: ActionReducerMapBuilder<IProveedoresState>,
) => {
  builder.addCase(updateDireccionProveedor.fulfilled, (state, _action: PayloadAction<boolean>) => {
    const direccionTemporal = state.direccionTemporal as DireccionProveedor;

    const proveedorIndex = state.data.findIndex(_ => _.id === direccionTemporal.proveedorId);
    if (proveedorIndex !== -1) {
      const direccionIndexInProveedor = state.data[proveedorIndex].direcciones.findIndex(
        _ => _.id === direccionTemporal.id,
      );
      if (direccionIndexInProveedor !== -1) {
        const direccion = state.data[proveedorIndex].direcciones[direccionIndexInProveedor];
        state.data[proveedorIndex].direcciones.splice(direccionIndexInProveedor, 1, {
          ...direccion,
          ...direccionTemporal,
        });
      }
    }

    const direccionIndexInDirecciones = state.direcciones.findIndex(
      _ => _.id === direccionTemporal.id,
    );
    if (direccionIndexInDirecciones !== -1) {
      const direccion = state.direcciones[direccionIndexInDirecciones];
      state.direcciones.splice(direccionIndexInDirecciones, 1, {
        ...direccion,
        ...direccionTemporal,
      });
    }

    state.direccionTemporal = undefined;
    state.crudDireccionesStatus = 'succeeded';
  });
  builder.addCase(updateDireccionProveedor.pending, (state, _action) => {
    state.crudDireccionesStatus = 'pending';
  });
  builder.addCase(updateDireccionProveedor.rejected, (state, _action) => {
    state.crudDireccionesStatus = 'failed';
  });
};
