import { InboxOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, List, Modal, Result, Row, Spin, Typography } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadFile } from 'antd/lib/upload/interface';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { dummyRequest } from 'utils/dummyRequest';
import excelModelo from '../../../assets/BulkImport_Productos.xlsx';
import { setBulkImportStatusIdle } from '../store/productosStore';
import styles from './BulkImportModal.module.less';

const { Item } = Form;
const { Link, Paragraph, Text } = Typography;

interface IProps {
  visible: boolean;
  form: FormInstance;
  status: StatusApp.Status;
  archivoExcel: UploadFile<any>[] | undefined;
  onImportSave: (values: any) => void;
  setCerrarModal: () => void;
  onChangeArchivoExcel: (values: UploadFile<any>[] | undefined) => void;
  onTryAgain?: () => void;
  createdProducts: number | undefined;
  feedbackMessages: string[] | undefined;
}

export const BulkImportModal: FC<IProps> = ({
  visible,
  form,
  status,
  archivoExcel,
  onImportSave,
  setCerrarModal,
  onChangeArchivoExcel,
  onTryAgain,
  createdProducts,
  feedbackMessages,
}) => {
  const dispatch = useDispatch();

  const fileInputForm = (
    <Form form={form} name='bulkImportForm' layout='vertical' defaultValue={{} as any}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Item name='productsList' rules={[{ required: true }]}>
            <Dragger
              multiple={false}
              fileList={archivoExcel}
              customRequest={f => dummyRequest(f)}
              accept='.xlsx, .xls'
              onChange={e => onChangeArchivoExcel(e.fileList)}
              className={styles.dragger}
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>
                Haga clic o arrastre el archivo a esta área para cargarlo
              </p>
            </Dragger>
          </Item>
          <Link href={excelModelo} download='BulkImport_Productos.xlsx'>
            Descargar archivo de modelo para importar los datos
          </Link>
        </Col>
      </Row>
    </Form>
  );

  const successResult = (
    <>
      <Result
        title='Importación Exitosa'
        subTitle='La importación concluyó exitosamente'
        status='success'
        style={{
          marginLeft: 0,
          marginRight: 0,
        }}
        extra={[
          <Button type='primary' key='cerrarModal' onClick={setCerrarModal}>
            Cerrar
          </Button>,
        ]}
      />
      <Paragraph>
        <Text strong style={{ fontSize: 16 }}>
          Se creó un total de: {createdProducts} producto(s).
        </Text>
      </Paragraph>
      {feedbackMessages && feedbackMessages.length > 0 && (
        <>
          Los siguientes problemas fueron encontrados:
          <List
            bordered
            size='small'
            dataSource={feedbackMessages}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
        </>
      )}
    </>
  );
  const failureResult = (
    <Result
      title='Importación Fallida'
      subTitle='Ocurrió un error durante la importación. Por favor verifique el archivo e intente más tarde'
      status='error'
      extra={[
        <Button onClick={setCerrarModal} type='default' key='closeModal'>
          Cerrar
        </Button>,
        <Button
          type='primary'
          key='tryAgain'
          onClick={() => {
            if (onTryAgain) {
              onTryAgain();
            } else {
              dispatch(setBulkImportStatusIdle());
            }
          }}
        >
          Intentar nuevamente
        </Button>,
      ]}
    />
  );

  const shouldShowForm = status !== 'failed' && status !== 'succeeded';
  const shouldShowSuccessResult = status === 'succeeded';
  const shouldShowFailureResult = status === 'failed';

  return (
    <Modal
      title='Importar Productos'
      visible={visible}
      onOk={() => {
        form
          .validateFields()
          .then(values => onImportSave(values))
          .catch(error => console.log(error));
      }}
      onCancel={() => {
        form.resetFields();
        setCerrarModal();
      }}
      footer={!shouldShowForm ? <></> : undefined}
    >
      <Spin spinning={status === 'pending' ? true : false}>
        {shouldShowForm && fileInputForm}
        {shouldShowSuccessResult && successResult}
        {shouldShowFailureResult && failureResult}
      </Spin>
    </Modal>
  );
};
