import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Button, Form, Space, Tooltip, Typography } from 'antd';
import { EstadoEntidad } from 'enum/EstadoEntidad';
import { addProveedor } from 'features/Proveedores/store/proveedoresStore';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { FC, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { BaseTable } from '../../../components/BaseTable/BaseTable';
import { Proveedor } from '../../../models/Proveedor';
import { InitialDataState } from '../../InitialData/store/initialDataStore';
import { PaisesState } from '../../Paises/store/paisesStore';
import { ModalUpsertProveedor } from '../../Proveedores/ModalUpsertProveedor/ModalUpsertProveedor';
import { ModalDireccionesProveedorPendiente } from '../ModalDireccionesProveedorPendiente/ModalDireccionesProveedorPendiente';
import { deleteProveedorPendiente } from '../store/extraReducers/proveedor/delete';
import { updateProveedorPendiente } from '../store/extraReducers/proveedor/update';
import { updateProveedoresPendientes } from '../store/extraReducers/proveedor/updateAll';
import {
  resetProveedoresPendientes,
  setDireccionesProveedorPendiente,
  setEditProveedorPendiente,
  setProveedorPendienteSeleccionado,
  toggleModalDireccionesProveedorPendienteVisible,
} from '../store/inicioStore';
import { Columns } from './Columns';
import styles from './ProveedoresPendientes.module.less';

const { Text } = Typography;

export const ProveedoresPendientes: FC = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { data: Paises } = useSelector(PaisesState);
  const { CondicionesComerciales } = useSelector(InitialDataState);
  const { proveedoresPendientes, proveedorPendienteSeleccionado, getProveedoresPendientesStatus } =
    useAppSelector(state => state.inicio);

  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();

  const showModal = (proveedor: Proveedor) => {
    onProveedorSeleccionado(proveedor);
    setModalVisible(true);
    form.setFieldsValue({
      ...proveedor,
      nombre: proveedor.nombre,
    } as Proveedor);
  };

  const cancelModal = () => {
    setModalVisible(false);
    onProveedorSeleccionado(undefined);
    form.resetFields();
  };

  const editProveedorSeleccionado = (proveedor: Proveedor) => {
    proveedor = {
      ...proveedor,
      nombre: proveedor.nombre,
    } as Proveedor;
    onEditProveedorPendiente(proveedor);
  };

  const deleteProveedorPendienteCols = (proveedor: Proveedor) => {
    onProveedorSeleccionado(proveedor);
    onDeleteProveedorPendiente(proveedor.id);
  };
  /* Proveedores Pendientes */
  const onProveedorSeleccionado = (proveedor: Proveedor | undefined) => {
    dispatch(setProveedorPendienteSeleccionado(proveedor));
  };

  const onEditProveedorPendiente = (proveedor: Proveedor) => {
    proveedor = {
      ...proveedor,
      estado: EstadoEntidad.Confirmado,
      pais: Paises.filter(x => x.id === proveedor.paisId)[0]?.nombre,
      condicionComercial: CondicionesComerciales.filter(
        x => x.id === proveedor.condicionComercialId,
      )[0]?.descripcion,
    };
    dispatch(setEditProveedorPendiente(proveedor));
  };

  const onUpdateProveedorPendiente = (proveedor: Proveedor) => {
    proveedor = {
      ...proveedor,
      estado: EstadoEntidad.Confirmado,
    };
    onProveedorSeleccionado(proveedor);
    customDispatch({
      asyncThuckFuction: updateProveedorPendiente,
      endPoint: `/api/Proveedores/Update/${proveedor.id}`,
      body: proveedor,
      method: 'PUT',
    });
  };

  const onDeleteProveedorPendiente = (id: string) => {
    customDispatch({
      asyncThuckFuction: deleteProveedorPendiente,
      endPoint: `/api/Proveedores/Delete/${id}`,
      method: 'DELETE',
    });
  };

  const onUpdateProveedoresPendientes = () => {
    const proveedores = proveedoresPendientes;
    if (proveedores.length) {
      customDispatch({
        asyncThuckFuction: updateProveedoresPendientes,
        endPoint: '/api/Proveedores/UpdateProveedoresPendientes',
        body: proveedores,
        method: 'PUT',
        onFinish: () => {
          proveedores.map(p => dispatch(addProveedor(p)));
          dispatch(resetProveedoresPendientes());
        },
      });
    }
  };

  const onShowDirecciones = (values: Proveedor) => {
    dispatch(setProveedorPendienteSeleccionado(values));
    dispatch(toggleModalDireccionesProveedorPendienteVisible());
    dispatch(setDireccionesProveedorPendiente(values.direcciones));
  };

  const cols = Columns({
    onSave: onUpdateProveedorPendiente,
    onDetails: showModal,
    onDelete: deleteProveedorPendienteCols,
    onShowDirecciones,
  });

  const handleFullScreen = useFullScreenHandle();

  const shouldRenderContent = proveedoresPendientes && proveedoresPendientes.length > 0;
  const innerMarkup = (
    <div className={styles.containerTable}>
      <BaseTable
        cols={cols}
        data={proveedoresPendientes}
        status={getProveedoresPendientesStatus}
        size='small'
        style={{ height: '100%' }}
        id='ProveedoresPendientesTabla'
        pagination={{ style: { marginBottom: 6 } }}
      />
      <ModalUpsertProveedor
        validarProveedor
        visible={modalVisible}
        form={form}
        status={'succeeded'}
        elementoSeleccionado={proveedorPendienteSeleccionado}
        paises={Paises}
        condicionesComerciales={CondicionesComerciales}
        onCreate={(_proveedor: Proveedor) => {}}
        onUpdate={(proveedor: Proveedor) => {
          editProveedorSeleccionado(proveedor);
          cancelModal();
        }}
        setCerrarDrawer={() => cancelModal()}
      />
      <ModalDireccionesProveedorPendiente />
    </div>
  );

  return (
    <FullScreen
      handle={handleFullScreen}
      className={`${styles.container} ${!shouldRenderContent ? styles.containerEmpty : ''}`}
    >
      <div className={styles.toolbar}>
        <Text strong>Proveedores</Text>
        <Space size={'middle'}>
          {shouldRenderContent && (
            <Tooltip
              title='Esta acción guardara todos los proveedores que esta viendo.'
              placement='left'
            >
              <Button type='primary' onClick={onUpdateProveedoresPendientes}>
                Guardar
              </Button>
            </Tooltip>
          )}
          {handleFullScreen.active ? (
            <Tooltip title='Salir de pantalla completa' placement='left'>
              <FullscreenExitOutlined style={{ fontSize: 24 }} onClick={handleFullScreen.exit} />
            </Tooltip>
          ) : (
            <Tooltip title='Ver tabla en pantalla completa' placement='left'>
              <FullscreenOutlined style={{ fontSize: 24 }} onClick={handleFullScreen.enter} />
            </Tooltip>
          )}
        </Space>
      </div>
      {innerMarkup}
    </FullScreen>
  );
};
