import { Col } from 'antd';
import { DropDownGrid } from 'components/FormControls/CustomDropDown';
import { CustomInput } from 'components/FormControls/CustomInput';
import { InputDisabled } from 'components/FormControls/InputDisabled';
import { useAppSelector } from 'store/hooks';

export const TabPaneDetalles = () => {
  const { TiposItems, CuotasArancelarias, ducaDetalles } = useAppSelector(
    state => state.ducaDetalles,
  );
  const Paises = useAppSelector(state => state.paises.data);
  const UnidadesMedidasNetas = useAppSelector(state => state.unidadesMedidasNetas.data);
  const EstadosMercancias = useAppSelector(state => state.estadosMercancias.data);

  const layout = { span: 24 };
  const rules = [{ required: true, message: 'Este campo es requerido' }];
  const propsInputNumber = { rules, layout, precision: 2, step: 'any', type: 'number' };
  const propsInputNumberNonRequired = { layout, precision: 2, step: 'any', type: 'number' };
  const propsDropdown = {
    layout,
    propSelectName: 'descripcion',
    renderText: (item: any) => `${item.codigo} - ${item.descripcion}`,
  };
  const propsDropdownPaises = {
    layout,
    propSelectName: 'combinado',
    renderText: (item: any) => item.combinado,
  };

  return (
    <>
      <CustomInputDisabled label='Número Item' name='numeroItem' />
      <DropDownGrid
        label='Tipo Item'
        name='tipoItemId'
        data={TiposItems}
        rules={[
          { required: true, message: 'Este campo es requerido' },
          ({ setFieldsValue }) => ({
            validator(_, value) {
              setFieldsValue({
                tipoItem: TiposItems.find(x => x.id === value)?.descripcion,
              });

              return Promise.resolve();
            },
          }),
        ]}
        disabled={ducaDetalles?.ordenCerrada}
        {...propsDropdown}
      />
      <CustomInputDisabled label='Posición Arancelaria' name='posicionArancelaria' />
      <CustomInput
        label='Titulo de Manifiesto Curries'
        name='tituloManifiestoCurrier'
        layout={layout}
        disabled={ducaDetalles?.ordenCerrada}
      />
      <CustomInput
        label='ID Matriz Insumos'
        name='idMatrizInsumos'
        layout={layout}
        disabled={ducaDetalles?.ordenCerrada}
      />
      <CustomInput
        label='Nro. Item Asociado'
        name='numeroItemAsociado'
        disabled={ducaDetalles?.ordenCerrada}
        {...propsInputNumberNonRequired}
      />
      <CustomInput
        label='Declaración a Cancelar'
        name='declaracionCancelar'
        layout={layout}
        disabled={ducaDetalles?.ordenCerrada}
      />
      <CustomInput
        label='Nro. Item a Cancelar'
        name='numeroItemCancelar'
        disabled={ducaDetalles?.ordenCerrada}
        {...propsInputNumber}
      />
      <CustomInput label='Peso Neto' name='pesoNeto' disabled {...propsInputNumber} />
      <CustomInput label='Peso Bruto' name='pesoBruto' disabled {...propsInputNumber} />
      <CustomInput
        label='Cantidad de Bultos'
        name='cantidadBultos'
        disabled
        {...propsInputNumber}
      />
      <DropDownGrid
        label='Estado Mercancía'
        name='estadoMercanciaId'
        data={EstadosMercancias}
        rules={[
          { required: true, message: 'Este campo es requerido' },
          ({ setFieldsValue }) => ({
            validator(_, value) {
              setFieldsValue({
                estadoMercancia: EstadosMercancias.find(x => x.id === value)?.nombre,
              });

              return Promise.resolve();
            },
          }),
        ]}
        layout={layout}
        propSelectName='nombre'
        renderText={(item: any) => item.nombre}
        disabled={ducaDetalles?.ordenCerrada}
      />
      <DropDownGrid
        label='País de Origen'
        name='paisOrigenId'
        data={Paises}
        rules={[
          { required: true, message: 'Este campo es requerido' },
          ({ setFieldsValue }) => ({
            validator(_, value) {
              setFieldsValue({
                paisOrigen: Paises.find(x => x.id === value)?.nombre,
              });

              return Promise.resolve();
            },
          }),
        ]}
        disabled={ducaDetalles?.ordenCerrada}
        {...propsDropdownPaises}
      />
      <DropDownGrid
        label='País Procedencia Destino'
        name='paisProcedenciaDestinoId'
        data={Paises}
        rules={[
          { required: true, message: 'Este campo es requerido' },
          ({ setFieldsValue }) => ({
            validator(_, value) {
              setFieldsValue({
                paisProcedenciaDestino: Paises.find(x => x.id === value)?.nombre,
              });

              return Promise.resolve();
            },
          }),
        ]}
        disabled={ducaDetalles?.ordenCerrada}
        {...propsDropdownPaises}
      />
      <DropDownGrid
        label='País Adquisición'
        name='paisAdquisicionId'
        data={Paises}
        rules={[
          { required: true, message: 'Este campo es requerido' },
          ({ setFieldsValue }) => ({
            validator(_, value) {
              setFieldsValue({
                paisAdquisicion: Paises.find(x => x.id === value)?.nombre,
              });

              return Promise.resolve();
            },
          }),
        ]}
        disabled={ducaDetalles?.ordenCerrada}
        {...propsDropdownPaises}
      />
      <DropDownGrid
        label='Cuota Arancelaria'
        name='cuotaArancelariaId'
        data={CuotasArancelarias}
        rules={[
          { required: false },
          ({ setFieldsValue }) => ({
            validator(_, value) {
              setFieldsValue({
                cuotaArancelaria: CuotasArancelarias.find(x => x.id === value)?.descripcion,
              });

              return Promise.resolve();
            },
          }),
        ]}
        disabled={ducaDetalles?.ordenCerrada}
        {...propsDropdown}
      />
      <DropDownGrid
        label='Unidad Comercial'
        name='unidadComercialId'
        data={UnidadesMedidasNetas}
        rules={[
          { required: true, message: 'Este campo es requerido' },
          ({ setFieldsValue }) => ({
            validator(_, value) {
              setFieldsValue({
                unidadComercial: UnidadesMedidasNetas.find(x => x.id === value)?.descripcion,
              });

              return Promise.resolve();
            },
          }),
        ]}
        disabled={ducaDetalles?.ordenCerrada}
        {...propsDropdown}
      />
      <CustomInput
        label='Cantidad Comercial'
        name='cantidadComercial'
        {...propsInputNumber}
        disabled
      />
      <DropDownGrid
        label='Unidad Estadística'
        name='unidadEstadisticaId'
        data={UnidadesMedidasNetas}
        rules={[
          { required: true, message: 'Este campo es requerido' },
          ({ setFieldsValue }) => ({
            validator(_, value) {
              setFieldsValue({
                unidadEstadistica: UnidadesMedidasNetas.find(x => x.id === value)?.descripcion,
              });

              return Promise.resolve();
            },
          }),
        ]}
        disabled={ducaDetalles?.ordenCerrada}
        {...propsDropdown}
      />
      <CustomInput
        label='Cantidad Estadística'
        name='cantidadEstadistica'
        disabled
        {...propsInputNumber}
      />
      <CustomInput label='Importe Factura' name='importeFOB' disabled {...propsInputNumber} />
      <CustomInput
        label='Importe Otros Gastos'
        name='importeOtrosGastos'
        disabled
        {...propsInputNumber}
      />
      <CustomInput label='Importe Seguro' name='importeSeguro' disabled {...propsInputNumber} />
      <CustomInput label='Importe Flete' name='importeFlete' disabled {...propsInputNumber} />
      <CustomInput
        label='Ajuste a Incluir'
        name='ajusteAIncluir'
        disabled={ducaDetalles?.ordenCerrada}
        {...propsInputNumberNonRequired}
      />
      <CustomInput
        label='Nro. Certificado Importación'
        name='numeroResolucion'
        layout={layout}
        rules={[{ required: false }]}
        disabled={ducaDetalles?.ordenCerrada}
      />
      <CustomInput
        label='Convenio Perfeccionamiento'
        name='convenioPerfectoActivo'
        layout={layout}
        disabled={ducaDetalles?.ordenCerrada}
      />
      <CustomInput
        label='Exoneración Aduanero'
        name='numeroExoneracionAduana'
        disabled={ducaDetalles?.ordenCerrada}
        layout={layout}
      />
      <CustomInput
        label='Descripción'
        name='descripcion'
        layout={layout}
        disabled={ducaDetalles?.ordenCerrada}
        rules={rules}
      />
      <CustomInput
        label='Comentario'
        name='comentario'
        disabled={ducaDetalles?.ordenCerrada}
        layout={layout}
      />
    </>
  );
};

interface ICustomInputDisabled {
  label: string;
  name: string;
}

const CustomInputDisabled = ({ label, name }: ICustomInputDisabled) => {
  return (
    <Col span={24}>
      <InputDisabled
        label={label}
        name={name}
        rules={[{ required: true, message: 'Este campo es requerido' }]}
      />
    </Col>
  );
};
