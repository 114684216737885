import { Col, Form, FormItemProps, Switch } from 'antd';
import { Rule } from 'antd/lib/form';
const { Item } = Form;

interface ICustomSwitch extends FormItemProps {
  layout?: { lg?: number; md?: number; xs?: number; span?: number };
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: boolean) => void;
  initialValue?: boolean;
  rules?: Rule[] | undefined;
}

export const CustomSwitch = ({
  label,
  name,
  disabled,
  layout,
  required = false,
  onChange,
  initialValue,
  rules,
  ...rest
}: ICustomSwitch) => {
  return (
    <Col span={6} {...layout}>
      <Item
        label={label}
        name={name}
        initialValue={initialValue}
        rules={
          required
            ? [
                {
                  required,
                  message: `Ingrese un valor para ${label}`,
                },
              ]
            : rules
        }
        {...rest}
      >
        <Switch checked={initialValue} disabled={disabled} onChange={e => onChange?.(e)} />
      </Item>
    </Col>
  );
};
