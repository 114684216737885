import { FC } from 'react';
import { Grid } from 'antd';
import styles from './Logo.module.less';

const { useBreakpoint } = Grid;

interface IProps {
  isAuthenticated: boolean;
  isCollapsed: boolean;
}

export const Logo: FC<IProps> = ({ isAuthenticated, isCollapsed }) => {
  const breakPoint = useBreakpoint();

  return isAuthenticated ? (
    !breakPoint.md ? (
      <div className={styles.logoContainer}>
        {/* <img
          className={styles.imgLogoCollapse}
          src={imgLogoCollapse}
          alt='logo Collapse'
        /> */}
      </div>
    ) : isCollapsed ? (
      <div className={styles.logoContainer}>
        {/* <img
          className={styles.imgLogoCollapse}
          src={imgLogoCollapse}
          alt='logo Collapse'
        /> */}
      </div>
    ) : (
      <div className={`${styles.logoContainer} ${styles.logoContainerExpand}`}>
        {/* <img className={styles.imgLogo} src={imgLogo} alt='logo' /> */}
      </div>
    )
  ) : (
    <div className={styles.logoContainerHome}>
      {/* <img className={styles.imgLogo} src={imgLogo} alt='logo' /> */}
    </div>
  );
};
