import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { ConfiguracionesPetch } from 'models/configuracionesPetch';
import { ConversionUnidadDeMedida } from 'models/ConversionUnidadDeMedida';
import {
  DucaBulto,
  DucaCaratula,
  DucaDetalles,
  IDucaBultoLineaRequest,
  IDucaDatoComplementarioRequest,
  IDucaDocumentoRequest,
  IDucaItemRequest,
  IDucaVentajaRequest,
} from 'models/Duca';
import { RootState } from '../../../store/store';
import { deleteRecord } from '../utils/deleteRecord';
import { GenerateKey } from '../utils/generateKey';
import { saveRecord } from '../utils/saveRecord';
import { getConversionOnzasLitrosReducer } from './extraReducer/getConversionOnzasLitros';
import { getDucaDetallesReducer } from './extraReducer/getDucaDetalles';
import { getDucaItemsReducer } from './extraReducer/getDucaItems';
import { getImpuestosReducer } from './extraReducer/getImpuestos';
import { importarItemsReducer } from './extraReducer/importarItems';
import { queryCuotaArancelariaReducer } from './extraReducer/queryCuotaArancelaria';
import { queryDepositoAduanaTransitoReducer } from './extraReducer/queryDepositoAduanaTransito';
import { queryDepositoAduaneroReducer } from './extraReducer/queryDepositoAduanero';
import { queryModalidadEspecialReducer } from './extraReducer/queryModalidadEspecial';
import { queryMotivoOperacionSuspensivaReducer } from './extraReducer/queryMotivoOperacionSuspensiva';
import { queryRegimenAduaneroReducer } from './extraReducer/queryRegimenAduanero';
import { queryRutaTransitoReducer } from './extraReducer/queryRutaTransito';
import { queryTipoDatoComplementarioReducer } from './extraReducer/queryTipoDatoComplementario';
import { queryTipoDocumentoReducer } from './extraReducer/queryTipoDocumento';
import { queryTipoItemReducer } from './extraReducer/queryTipoItem';
import { queryVentajaReducer } from './extraReducer/queryVentaja';
import { updateDucaDetallesReducer } from './extraReducer/updateDucaDetalles';

export interface IDucaDetallesState {
  status: StatusApp.Status;
  statusItems: StatusApp.Status;
  ducaDetalles?: DucaDetalles;
  RegimenesAduaneros: ConfiguracionesPetch[];
  RegimenesAduanerosStatus: StatusApp.Status;
  ModalidadesEspeciales: ConfiguracionesPetch[];
  ModalidadesEspecialesStatus: StatusApp.Status;
  RutasTransito: ConfiguracionesPetch[];
  RutasTransitoStatus: StatusApp.Status;
  MotivoOperacionSuspensiva: ConfiguracionesPetch[];
  MotivoOperacionSuspensivaStatus: StatusApp.Status;
  DepositosAduaneros: ConfiguracionesPetch[];
  DepositoAduaneroStatus: StatusApp.Status;
  DepositosAduanasTransito: ConfiguracionesPetch[];
  DepositoAduanaTransitoStatus: StatusApp.Status;
  TiposDocumentos: ConfiguracionesPetch[];
  TipoDocumentoStatus: StatusApp.Status;
  TipoDatosComplementarios: ConfiguracionesPetch[];
  TipoDatoComplementarioStatus: StatusApp.Status;
  TiposItems: ConfiguracionesPetch[];
  TipoItemStatus: StatusApp.Status;
  CuotasArancelarias: ConfiguracionesPetch[];
  CuotaArancelariaStatus: StatusApp.Status;
  Ventajas: ConfiguracionesPetch[];
  VentajaStatus: StatusApp.Status;
  ConversionOnzasLitros?: ConversionUnidadDeMedida;
  ConversionOnzasLitrosStatus: StatusApp.Status;
  bultosLineas: IDucaBultoLineaRequest[];
  documentos: IDucaDocumentoRequest[];
  datosComplementarios: IDucaDatoComplementarioRequest[];
  items: IDucaItemRequest[];
  itemStatus: StatusApp.Status;
  caratula?: DucaCaratula;
  bultos?: DucaBulto;
  bultosLineasEditingKey: string;
  documentosEditingKey: string;
  datosComplementariosEditingKey: string;
  itemEditingKey: string;
  drawerItemVisible: boolean;
  condicionEntregaId: string;
  modalVisibleAsignarVentaja: boolean;
}

const initialState: IDucaDetallesState = {
  status: 'idle',
  statusItems: 'idle',
  RegimenesAduaneros: [],
  RegimenesAduanerosStatus: 'idle',
  ModalidadesEspeciales: [],
  ModalidadesEspecialesStatus: 'idle',
  RutasTransito: [],
  RutasTransitoStatus: 'idle',
  MotivoOperacionSuspensiva: [],
  MotivoOperacionSuspensivaStatus: 'idle',
  DepositosAduaneros: [],
  DepositoAduaneroStatus: 'idle',
  DepositosAduanasTransito: [],
  DepositoAduanaTransitoStatus: 'idle',
  TiposDocumentos: [],
  TipoDocumentoStatus: 'idle',
  TipoDatosComplementarios: [],
  TipoDatoComplementarioStatus: 'idle',
  TiposItems: [],
  TipoItemStatus: 'idle',
  CuotasArancelarias: [],
  CuotaArancelariaStatus: 'idle',
  Ventajas: [],
  VentajaStatus: 'idle',
  ConversionOnzasLitrosStatus: 'idle',
  bultosLineas: [],
  documentos: [],
  datosComplementarios: [],
  items: [],
  itemStatus: 'idle',
  bultosLineasEditingKey: '',
  documentosEditingKey: '',
  datosComplementariosEditingKey: '',
  itemEditingKey: '',
  drawerItemVisible: false,
  condicionEntregaId: '',
  modalVisibleAsignarVentaja: false,
};

export const ducaDetallesSlice = createSlice({
  name: 'DucaDetalles',
  initialState,
  reducers: {
    ducaDetallesHardReset: state => {
      state.status = 'idle';
      state.statusItems = 'idle';
      state.ducaDetalles = undefined;
      state.bultosLineas = [];
      state.documentos = [];
      state.datosComplementarios = [];
      state.items = [];
    },
    setSaveDucaBultoLinea: (
      state,
      action: PayloadAction<{ record: IDucaBultoLineaRequest; key: string }>,
    ) => {
      state.bultosLineas = saveRecord(
        action.payload.key,
        action.payload.record,
        state.bultosLineas,
      );
    },
    setDeleteDucaBultoLinea: (state, action: PayloadAction<string>) => {
      state.bultosLineas = deleteRecord(action.payload, state.bultosLineas);
    },
    setBultoLineaEditingKey: (state, action: PayloadAction<string>) => {
      state.bultosLineasEditingKey = action.payload;
    },
    setSaveDucaDocumento: (
      state,
      action: PayloadAction<{ record: IDucaDocumentoRequest; key: string }>,
    ) => {
      state.documentos = saveRecord(action.payload.key, action.payload.record, state.documentos);
    },
    setDeleteDucaDocumento: (state, action: PayloadAction<string>) => {
      state.documentos = deleteRecord(action.payload, state.documentos);
    },
    setDocumentosEditingKey: (state, action: PayloadAction<string>) => {
      state.documentosEditingKey = action.payload;
    },
    setSaveDucaDatoComplementario: (
      state,
      action: PayloadAction<{ record: IDucaDatoComplementarioRequest; key: string }>,
    ) => {
      state.datosComplementarios = saveRecord(
        action.payload.key,
        action.payload.record,
        state.datosComplementarios,
      );
    },
    setDeleteDucaDatoComplementario: (state, action: PayloadAction<string>) => {
      state.datosComplementarios = deleteRecord(action.payload, state.datosComplementarios);
    },
    setDatosComplementariosEditingKey: (state, action: PayloadAction<string>) => {
      state.datosComplementariosEditingKey = action.payload;
    },
    setSaveDucaItem: (state, action: PayloadAction<{ record: IDucaItemRequest; key: string }>) => {
      const record = action.payload.record;
      const TasaCambioDolar = state.ducaDetalles?.tasaCambioDolar ?? 0;
      const isvPorcentaje = (record.isvPorcentaje ?? 0) / 100;
      const Pvd = ((record?.importeFOB ?? 0) / record.cantidadEstadistica) * TasaCambioDolar;
      let Isv = 0;
      record.valorCIF =
        record.importeFOB + record.importeFlete + record.importeSeguro + record.importeOtrosGastos;

      const existVentajaGeneral = record.ventajas.some(v => v.codigo === '000');

      if (existVentajaGeneral) {
        record.tarifaTLCAplicada = record.dai;
        record.gravadoPorcentajeCIF = record.dai;

        const TotalDerechos = record.cifImponible * (record.dai / 100);
        const Isc = record.cifImponible * (record.iscPorcentaje / 100);
        const Ipc = record.cantidadEstadistica * record.ipcFactor;

        if (
          record?.posicionArancelaria.startsWith('2202') ||
          record?.posicionArancelaria.startsWith('2203')
        ) {
          Isv = (Pvd / (1 + isvPorcentaje)) * isvPorcentaje * record.cantidadEstadistica;
        } else {
          Isv =
            ((record.cifImponible + TotalDerechos + Isc) * TasaCambioDolar + Ipc) * isvPorcentaje;
        }

        record.totalDerechos = TotalDerechos * TasaCambioDolar;
        record.isc = Isc * TasaCambioDolar;
        record.ipc = Ipc;
        record.isv = Isv;
      } else {
        record.tarifaTLCAplicada = record.tarifaTLC;
        record.gravadoPorcentajeCIF = record.tarifaTLC;

        const TotalDerechos = record.cifImponible * (record.tarifaTLC / 100);
        const Isc = record.cifImponible * (record.iscPorcentaje / 100);
        const Ipc = record.cantidadEstadistica * record.ipcFactor;

        if (
          record?.posicionArancelaria.startsWith('2202') ||
          record?.posicionArancelaria.startsWith('2203')
        ) {
          Isv = (Pvd / (1 + isvPorcentaje)) * isvPorcentaje * record.cantidadEstadistica;
        } else {
          Isv =
            ((record.cifImponible + TotalDerechos + Isc) * TasaCambioDolar + Ipc) * isvPorcentaje;
        }

        record.totalDerechos = TotalDerechos * TasaCambioDolar;
        record.isc = Isc * TasaCambioDolar;
        record.ipc = Ipc;
        record.isv = Isv;
      }

      state.items = saveRecord(action.payload.key, record, state.items);
    },
    setItemCalculoRapido: (
      state,
      action: PayloadAction<{ record: IDucaItemRequest; key: string }>,
    ) => {
      const record = action.payload.record;
      if (record.ipcFactor > 0) {
        // Verificar si tiene el datoComplementario PVD y DESCVENTAS
        const datosComplementarios = [...record.datosComplementarios];
        const indexPVD = datosComplementarios.findIndex(x => x.codigo === 'PVD');
        if (indexPVD > -1) {
          const datoComplementario = datosComplementarios[indexPVD];

          datosComplementarios.splice(indexPVD, 1, {
            ...datoComplementario,
            valor: String(record.precioVentaDistribuidor),
            estado:
              datoComplementario.id?.length > 0 ? EstadoDucaItem.Update : EstadoDucaItem.Create,
          });
        } else {
          const datoComplementario = state.TipoDatosComplementarios.find(x => x.codigo === 'PVD');
          const datoComplementarioPVD = {
            key: GenerateKey(),
            datoComplementarioId: datoComplementario?.id,
            codigo: datoComplementario?.codigo,
            descripcion: datoComplementario?.descripcion,
            estado: EstadoDucaItem.Create,
            valor: String(record.precioVentaDistribuidor),
          } as IDucaDatoComplementarioRequest;

          datosComplementarios.push(datoComplementarioPVD);
        }

        const indexDESCVENTAS = datosComplementarios.findIndex(x => x.codigo === 'DESCVENTAS');
        if (indexDESCVENTAS > -1) {
          const datoComplementario = datosComplementarios[indexDESCVENTAS];

          datosComplementarios.splice(indexDESCVENTAS, 1, {
            ...datoComplementario,
            valor: String(record.descuentoVentas),
            estado:
              datoComplementario.id?.length > 0 ? EstadoDucaItem.Update : EstadoDucaItem.Create,
          });
        } else {
          const datoComplementarioDescVenta = state.TipoDatosComplementarios.find(
            x => x.codigo === 'DESCVENTAS',
          );
          if (datoComplementarioDescVenta) {
            datosComplementarios.push({
              key: GenerateKey(),
              datoComplementarioId: datoComplementarioDescVenta.id,
              codigo: datoComplementarioDescVenta.codigo,
              descripcion: datoComplementarioDescVenta.descripcion,
              estado: EstadoDucaItem.Create,
              valor: String(record.descuentoVentas),
            } as IDucaDatoComplementarioRequest);
          }
        }

        record.datosComplementarios = datosComplementarios;
      }

      state.items = saveRecord(action.payload.key, record, state.items);
    },
    setSaveDucaItems: (state, action: PayloadAction<IDucaItemRequest[]>) => {
      state.items = action.payload;
    },
    setDeleteDucaItem: (state, action: PayloadAction<string>) => {
      state.items = deleteRecord(action.payload, state.items);
    },
    setItemEditingKey: (state, action: PayloadAction<string>) => {
      state.itemEditingKey = action.payload;
    },
    setDrawerItemVisible: (state, action: PayloadAction<boolean>) => {
      state.drawerItemVisible = action.payload;
    },
    addDocumentoToItem: (
      state,
      action: PayloadAction<{ itemsKey: string[]; documento: IDucaDocumentoRequest }>,
    ) => {
      action.payload.itemsKey.forEach(key => {
        const item = state.items.find(i => i.key === key);
        if (item) {
          if (item.estado !== EstadoDucaItem.Create) item.estado = EstadoDucaItem.Update;
          item.documentos?.push(action.payload.documento);
        }
      });
    },
    addVentajaToItem: (
      state,
      action: PayloadAction<{ itemsKey: string[]; ventaja: IDucaVentajaRequest }>,
    ) => {
      action.payload.itemsKey.forEach(key => {
        const item = state.items.find(i => i.key === key);
        const exist = item?.ventajas?.find(v => v.ventajaId === action.payload.ventaja.ventajaId);

        if (item && !exist) {
          const index = item.ventajas?.findIndex(v => v.codigo === '000');
          if (index !== -1) {
            const ventajaGeneral = item.ventajas[index];

            if (ventajaGeneral.estado === EstadoDucaItem.Create) {
              item.ventajas?.splice(index, 1);
            } else {
              item.ventajas?.splice(index, 1, {
                ...ventajaGeneral,
                estado: EstadoDucaItem.Delete,
              });
            }
          }

          if (item.estado !== EstadoDucaItem.Create) item.estado = EstadoDucaItem.Update;

          item.ventajas?.push(action.payload.ventaja);
          item.tarifaTLCAplicada = item.tarifaTLC;
          item.gravadoPorcentajeCIF = item.tarifaTLC;

          let Isv = 0;
          const TasaCambioDolar = Number(state.ducaDetalles?.tasaCambioDolar ?? 0);
          const isvPorcentaje = Number(item.isvPorcentaje ?? 0) / 100;
          const TotalDerechos = item.cifImponible * (item.tarifaTLC / 100);
          const Pvd = ((item?.importeFOB ?? 0) / item.cantidadEstadistica) * TasaCambioDolar;

          if (
            item?.posicionArancelaria.startsWith('2202') ||
            item?.posicionArancelaria.startsWith('2203')
          ) {
            Isv = (Pvd / (1 + isvPorcentaje)) * isvPorcentaje * item.cantidadEstadistica;
          } else {
            Isv =
              ((item.cifImponible + TotalDerechos + item.isc) * TasaCambioDolar + item.ipc) *
              isvPorcentaje;
          }

          item.isv = Isv;
          item.totalDerechos = TotalDerechos * TasaCambioDolar;
        }
      });
    },
    addDatoComplementarioToItem: (
      state,
      action: PayloadAction<{
        itemsKey: string[];
        datoComplementario: IDucaDatoComplementarioRequest;
      }>,
    ) => {
      action.payload.itemsKey.forEach(key => {
        const item = state.items.find(i => i.key === key);
        const exist = item?.datosComplementarios?.find(
          d => d.datoComplementarioId === action.payload.datoComplementario.datoComplementarioId,
        );
        if (item && !exist) {
          item.datosComplementarios?.push(action.payload.datoComplementario);
        }
      });
    },
    setCondicionEntregaId: (state, action: PayloadAction<string>) => {
      state.condicionEntregaId = action.payload;
    },
    setCaratula: (state, action: PayloadAction<DucaCaratula>) => {
      state.caratula = action.payload;
    },
    setDucaBultos: (state, action: PayloadAction<DucaBulto>) => {
      state.bultos = action.payload;
    },
    setVisibleModalAsignarVentaja: (state, action: PayloadAction<boolean>) => {
      state.modalVisibleAsignarVentaja = action.payload;
    },
  },
  extraReducers: builder => {
    getDucaDetallesReducer(builder);
    getDucaItemsReducer(builder);
    queryRegimenAduaneroReducer(builder);
    queryModalidadEspecialReducer(builder);
    queryRutaTransitoReducer(builder);
    queryMotivoOperacionSuspensivaReducer(builder);
    queryDepositoAduaneroReducer(builder);
    queryDepositoAduanaTransitoReducer(builder);
    queryTipoDocumentoReducer(builder);
    queryTipoDatoComplementarioReducer(builder);
    queryTipoItemReducer(builder);
    queryCuotaArancelariaReducer(builder);
    queryVentajaReducer(builder);
    getConversionOnzasLitrosReducer(builder);
    importarItemsReducer(builder);
    getImpuestosReducer(builder);
    updateDucaDetallesReducer(builder);
  },
});

export const {
  ducaDetallesHardReset,
  setSaveDucaBultoLinea,
  setDeleteDucaBultoLinea,
  setBultoLineaEditingKey,
  setSaveDucaDocumento,
  setDeleteDucaDocumento,
  setDocumentosEditingKey,
  setSaveDucaDatoComplementario,
  setDeleteDucaDatoComplementario,
  setDatosComplementariosEditingKey,
  setSaveDucaItem,
  setSaveDucaItems,
  setDeleteDucaItem,
  setItemEditingKey,
  setDrawerItemVisible,
  addDocumentoToItem,
  addVentajaToItem,
  addDatoComplementarioToItem,
  setCondicionEntregaId,
  setCaratula,
  setDucaBultos,
  setItemCalculoRapido,
  setVisibleModalAsignarVentaja,
} = ducaDetallesSlice.actions;

export const DucaDetallesState = (state: RootState) => state.ducaDetalles;

export const DucaDetallesReducer = ducaDetallesSlice.reducer;
