import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IMarcasComercialesState } from '../marcasComercialesStore';

export const getMarcasComerciales = createAsyncThunk('MarcasComerciales/GetAll', httpService);

export const getMarcasComercialesReducer = (
  builder: ActionReducerMapBuilder<IMarcasComercialesState>,
) => {
  // Get
  builder.addCase(getMarcasComerciales.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.data = action.payload;
  });
  builder.addCase(getMarcasComerciales.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getMarcasComerciales.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
