import { Button, Form, Input, Row, Select, Table, Typography } from 'antd';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { setError } from 'features/InitialData/store/initialDataStore';
import { IDucaDocumentoRequest } from 'models/Duca';
import { PapierError } from 'models/PapierException';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { Columns, IDocumentoColumnType } from './Columns';

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

interface IListadoDocuerosProps {
  data: IDucaDocumentoRequest[];
}

export const ListadoDocumentos = ({ data }: IListadoDocuerosProps) => {
  const dispatch = useDispatch();
  const [documentos, setDocumentos] = useState<IDucaDocumentoRequest[]>(data);
  const [editingKey, setEditingKey] = useState('');
  const { TiposDocumentos, ducaDetalles } = useAppSelector(state => state.ducaDetalles);
  const [formDocumentos] = Form.useForm<IDucaDocumentoRequest>();

  const Presencia = [
    { id: true, descripcion: 'Sí' },
    { id: false, descripcion: 'No' },
  ];

  const isEditing = (values: IDucaDocumentoRequest) => values.key === editingKey;

  const onSave = async (key: string) => {
    try {
      const row = await formDocumentos.validateFields();
      row.documentoId = TiposDocumentos.find(b => b.codigo === row.codigo)?.id ?? '';

      const newData = [...documentos];
      const index = newData.findIndex(item => key === item.key);
      if (key === 'new') {
        newData.splice(index, 1, {
          ...row,
          key: GenerateKey(),
          estado: EstadoDucaItem.Create,
        });
        setDocumentos(newData);
      } else {
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
            estado: item.id?.length > 0 ? EstadoDucaItem.Update : EstadoDucaItem.Create,
          });
          setDocumentos(newData);
        } else {
          newData.push(row);
          setDocumentos(newData);
        }
      }

      setEditingKey('');
    } catch (error) {
      if (error instanceof PapierError) {
        dispatch(
          setError({
            error: true,
            message: error.errorMessage,
            duration: 5,
          }),
        );
      } else {
        console.log('Validate Failed:', error);
      }
    }
  };

  const onEdit = (value: Partial<IDucaDocumentoRequest> & { key: React.Key }) => {
    formDocumentos.setFieldsValue({
      documentoId: '',
      codigo: '',
      descripcion: '',
      referencia: '',
      presencia: true,
      ...value,
    });
    setEditingKey(value.key);
  };

  const onDelete = (key: string) => {
    const index = documentos.findIndex(_ => _.key === key);

    if (index > -1) {
      const documento = documentos[index];
      const documentosTemp = documentos.map(_ => _);

      if (documento.estado === EstadoDucaItem.Create) {
        documentosTemp.splice(index, 0);
      } else {
        documentosTemp.splice(index, 1, {
          ...documento,
          estado: EstadoDucaItem.Delete,
        });
      }
      setDocumentos(documentosTemp);
    }
  };

  const onCancel = () => {
    if (documentos.some(d => d.key === 'new'))
      setDocumentos(documentos.filter(d => d.key !== 'new'));
    setEditingKey('');
  };

  const cols = Columns({
    isEditing,
    onSave,
    onEdit,
    onDelete,
    onCancel,
    ordenCerrada: ducaDetalles?.ordenCerrada ?? false,
  });
  const mergedColumns = cols.map((col: IDocumentoColumnType) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: IDucaDocumentoRequest) => ({
        width: col.width,
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType:
          col.dataIndex === 'descripcion' || col.dataIndex === 'referencia' ? 'input' : 'dropdown',
        options:
          col.dataIndex === 'codigo'
            ? TiposDocumentos
            : col.dataIndex === 'presencia'
            ? Presencia
            : undefined,
        editing: isEditing(record),
      }),
    };
  });

  const onNewItem = () => {
    formDocumentos.setFieldsValue({
      codigo: '',
      documentoId: '',
      descripcion: '',
      referencia: '',
      presencia: true,
    });
    setDocumentos([
      ...documentos,
      {
        id: '',
        key: 'new',
        documentoId: '',
        codigo: '',
        descripcion: '',
        referencia: '',
        presencia: true,
        estado: EstadoDucaItem.Create,
      },
    ]);
    setEditingKey('new');
  };

  const TableDocumentos = () => (
    <section>
      <Row justify='space-between'>
        <Title level={5}>Listado de Documentos</Title>
        {!ducaDetalles?.ordenCerrada && (
          <Button
            type='primary'
            ghost
            onClick={onNewItem}
            disabled={documentos.some(b => b.key === 'new')}
          >
            Agregar Documento
          </Button>
        )}
      </Row>

      <Form form={formDocumentos} component={false}>
        <Table
          components={{ body: { cell: EditableCell } }}
          bordered
          dataSource={documentos.filter(x => x.estado !== EstadoDucaItem.Delete)}
          columns={mergedColumns as any}
          pagination={false}
          size='small'
          style={{ marginTop: 16, width: '100%' }}
        />
      </Form>
    </section>
  );

  return { TableDocumentos, documentos };
};

interface IEditableCell extends React.HTMLAttributes<HTMLDivElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'dropdown' | 'input';
  options?: any[];
  record: IDucaDocumentoRequest;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<IEditableCell> = ({
  editing,
  dataIndex,
  title,
  inputType,
  options,
  record,
  index,
  children,
  ...restProps
}) => {
  const InputNode =
    inputType === 'input' ? (
      <Input
        type='text'
        disabled={dataIndex === 'descripcion'}
        style={dataIndex === 'descripcion' ? { color: '#666' } : undefined}
      />
    ) : (
      <Select
        autoFocus={dataIndex === 'codigo'}
        showSearch
        optionFilterProp='children'
        filterOption
        style={{ width: '100%', maxWidth: dataIndex === 'codigo' ? 190 : 90 }}
      >
        {options?.map(item =>
          dataIndex === 'codigo' ? (
            <Option key={item.id} value={item.codigo}>
              {`${item.codigo} - ${item.descripcion}`}
            </Option>
          ) : (
            <Option key={item.id} value={item.id}>
              {item.descripcion}
            </Option>
          ),
        )}
      </Select>
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            { required: true, message: `Campo requerido` },
            ({ setFieldsValue }) => ({
              validator(_, value) {
                if (dataIndex === 'codigo') {
                  setFieldsValue({
                    descripcion: options?.find(x => x?.codigo === value)?.descripcion,
                  });
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          {InputNode}
        </Item>
      ) : (
        children
      )}
    </td>
  );
};
