import { IDevaProductoDetalleRequest } from 'models/Deva';
import { PapierError } from 'models/PapierException';
import { UnidadMedidaNeta } from 'models/UnidadMedidaNeta';
import XLSX from 'xlsx';
import { ValidarProductoImportado } from './validarProductoImportado';

export const importarProductosDeExcel = async (file: Blob, unidadesMedidas: UnidadMedidaNeta[]) => {
  const fileURL = URL.createObjectURL(file);
  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(file);

  return new Promise((resolve, reject) => {
    let result: IDevaProductoDetalleRequest[] | PapierError = [];
    fetch(fileURL, { method: 'GET' }).then(async response => {
      const archivo = await response.blob();
      fileReader.readAsBinaryString(archivo);
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        try {
          result = fileReaderOnLoad(event, unidadesMedidas);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      };
    });
  });
};

const fileReaderOnLoad = (
  event: ProgressEvent<FileReader>,
  unidadesMedidas: UnidadMedidaNeta[],
): IDevaProductoDetalleRequest[] | PapierError => {
  let data = [] as IDevaProductoDetalleRequest[];
  const result = event.target;
  const workbook = XLSX.read(result?.result, { type: 'binary' });

  for (const sheet in workbook.Sheets) {
    if (workbook.Sheets.hasOwnProperty(sheet)) {
      data = data.concat(
        XLSX.utils
          .sheet_to_json(workbook.Sheets[sheet], {
            raw: false,
          })
          .map((excelRow: any, index) => {
            ValidarProductoImportado(excelRow, index, unidadesMedidas);

            const unidadMedidaId = unidadesMedidas?.find(
              um => um.nombre.toLowerCase() === String(excelRow['Unidad Medida']).toLowerCase(),
            )?.id;

            return {
              numeroFactura: String(excelRow['Numero de Factura']),
              fechaFactura: String(excelRow['Fecha de Factura']),
              modelo: String(excelRow.Modelo),
              cantidad: Number(excelRow['Cantidad Comercial']),
              valorUnitario: Number(excelRow['Valor Unitario']),
              valorTotal: Number(excelRow['Valor Total']),
              unidadMedida: String(excelRow['Unidad Medida']),
              unidadMedidaId,
            } as IDevaProductoDetalleRequest;
          }),
      );
    }
  }
  return data;
};
