import { Form } from 'antd';
import { debounce } from 'hooks/useDebounce';
import { QuerySpec } from 'models/QuerySpec';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { PartidaArancelaria } from '../../models/PartidaArancelaria';
import { Columns } from './Columns';
import { ModalUpsert } from './ModalUpsert/ModalUpsert';
import styles from './PartidasArancelarias.module.less';
import { createPartidaArancelaria } from './store/extraReducers/createPartidaArancelaria';
import { deletePartidaArancelaria } from './store/extraReducers/deletePartidaArancelaria';
import { queryPartidasArancelarias } from './store/extraReducers/queryPartidasArancelarias';
import { updatePartidaArancelaria } from './store/extraReducers/updatePartidaArancelaria';
import {
  PartidasArancelariasState,
  setCrudStatusIdle,
  setPartidaArancelariaTemporal,
  toggleModalVisible,
  toggleSeleccionarPartidaArancelaria,
} from './store/partidaArancelariaStore';
import { Toolbar } from './Toolbar/Toolbar';

export const PartidasArancelarias: FC = () => {
  const [querySpec, setQuery] = useState<QuerySpec>({
    pageNumber: 1,
    pageSize: 50,
    simpleTextFilter: undefined,
  });

  const { pagedData, status, crudStatus, elementoSeleccionado, apiURL } =
    useSelector(PartidasArancelariasState);
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callQuery = useCallback(
    debounce((q: QuerySpec) => {
      customDispatch({
        asyncThuckFuction: queryPartidasArancelarias,
        endPoint: '/api/PartidasArancelarias/Query',
        body: q,
      });
    }),
    [],
  );

  useEffect(() => {
    callQuery(querySpec);
  }, [callQuery, querySpec]);

  useEffect(() => {
    !window.document.title.includes('Partidas Arancelarias') &&
      dispatch(setTitleName('Partidas Arancelarias'));

    if (crudStatus === 'succeeded') {
      dispatch(setCrudStatusIdle());
      form.resetFields();
    }
  }, [crudStatus, dispatch, form]);

  const setUpdateSelection = (values: PartidaArancelaria) => {
    dispatch(toggleSeleccionarPartidaArancelaria(values));
    setToggleModalVisible();
    form.setFieldsValue({ ...values });
  };

  const setDeleteSelection = (values: PartidaArancelaria) => {
    dispatch(toggleSeleccionarPartidaArancelaria(values));
    onDelete(values.id);
  };

  const setToggleModalVisible = () => {
    dispatch(toggleModalVisible());
  };

  const cols = Columns({
    onUpdate: setUpdateSelection,
    onDelete: setDeleteSelection,
  });

  const onCreate = (values: PartidaArancelaria) => {
    dispatch(setPartidaArancelariaTemporal(values));
    customDispatch({
      asyncThuckFuction: createPartidaArancelaria,
      endPoint: `${apiURL}/Create`,
      body: values,
    });
  };

  const onUpdate = (values: PartidaArancelaria) => {
    dispatch(setPartidaArancelariaTemporal(values));
    customDispatch({
      asyncThuckFuction: updatePartidaArancelaria,
      endPoint: `${apiURL}/Update/${elementoSeleccionado?.id}`,
      body: values,
      method: 'PUT',
    });
  };

  const onDelete = (id: string) => {
    customDispatch({
      asyncThuckFuction: deletePartidaArancelaria,
      endPoint: `${apiURL}/Delete/${id}`,
      method: 'DELETE',
    });
  };

  return (
    <section className={styles.container}>
      <Toolbar
        setNombre={e => {
          setQuery({ ...querySpec, pageNumber: 1, simpleTextFilter: e });
        }}
        setToggleModal={setToggleModalVisible}
      />
      <BaseTable
        cols={cols}
        data={pagedData.items}
        pagination={{
          defaultPageSize: 50,
          pageSize: querySpec.pageSize,
          total: pagedData.totalCount,
          onChange: (page: number, size?: number) => {
            setQuery({
              ...querySpec,
              pageNumber: page,
              pageSize: size || 50,
            });
          },
        }}
        status={status}
        size='small'
        style={{ height: 'calc(100% - 40px)' }}
      />
      <ModalUpsert form={form} onCreate={onCreate} onUpdate={onUpdate} />
    </section>
  );
};
