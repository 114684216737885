import { InboxOutlined } from '@ant-design/icons';
import { Button, Col, Form, List, Modal, Result, Row, Spin, Typography } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { getMarcasComerciales } from 'features/MarcasComerciales/store/extraReducers/getMarcasComerciales';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { dummyRequest } from 'utils/dummyRequest';
import excelModelo from '../../../assets/BulkUpdate_Productos.xlsx';
import { bulkUpdateProductos } from '../store/extraReducer/bulkUpdateProductos';
import { setBulkUpdateStatusIdle, toggleBulkUpdateVisible } from '../store/productosStore';
import styles from './BulkUpdateModal.module.less';

const { Item } = Form;
const { Link, Paragraph, Text } = Typography;

export const BulkUpdateModal = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const {
    apiURL,
    bulkUpdateStatus,
    bulkUpdateVisible,
    bulkImportUpdateProducts,
    bulkUpdateFeedback,
  } = useAppSelector(state => state.productos);

  const onBulkUpdate = (formValues: any) => {
    const formData = new FormData();
    const file = formValues.productsList ? formValues.productsList.fileList[0].originFileObj : null;

    formData.append('productsList', file);

    customDispatch({
      asyncThuckFuction: bulkUpdateProductos,
      endPoint: `${apiURL}/BulkUpdateProductos`,
      body: formData,
      method: 'PUT',
      onFinish: () => {
        customDispatch({
          asyncThuckFuction: getMarcasComerciales,
          endPoint: '/api/MarcasComerciales/GetAll',
        });
      },
    });
  };

  const setCerrarModal = () => {
    dispatch(toggleBulkUpdateVisible());
    dispatch(setBulkUpdateStatusIdle());
    form.resetFields();
  };

  const fileInputForm = (
    <Form form={form} name='bulkImportForm' layout='vertical' defaultValue={{} as any}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Item name='productsList' rules={[{ required: true }]}>
            <Dragger
              multiple={false}
              fileList={undefined}
              customRequest={f => dummyRequest(f)}
              accept='.xlsx, .xls'
              className={styles.dragger}
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>
                Haga clic o arrastre el archivo a esta área para cargarlo
              </p>
            </Dragger>
          </Item>
          <Link href={excelModelo} download='BulkUpdate_Productos.xlsx'>
            Descargar archivo de modelo para actualizar los datos
          </Link>
        </Col>
      </Row>
    </Form>
  );

  const successResult = (
    <>
      <Result
        title='Actualización Exitosa'
        subTitle='La actualización concluyó exitosamente'
        status='success'
        style={{
          marginLeft: 0,
          marginRight: 0,
        }}
        extra={[
          <Button type='primary' key='cerrarModal' onClick={setCerrarModal}>
            Cerrar
          </Button>,
        ]}
      />
      <Paragraph>
        <Text strong style={{ fontSize: 16 }}>
          Se actualizo un total de: {bulkImportUpdateProducts} producto(s).
        </Text>
      </Paragraph>
      {bulkUpdateFeedback && bulkUpdateFeedback.length > 0 && (
        <>
          Los siguientes problemas fueron encontrados:
          <List
            bordered
            size='small'
            dataSource={bulkUpdateFeedback}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
        </>
      )}
    </>
  );
  const failureResult = (
    <Result
      title='Actualización Fallida'
      subTitle='Ocurrió un error durante la actualización. Por favor verifique el archivo e intente más tarde'
      status='error'
      extra={[
        <Button onClick={setCerrarModal} type='default' key='closeModal'>
          Cerrar
        </Button>,
        <Button
          type='primary'
          key='tryAgain'
          onClick={() => {
            dispatch(setBulkUpdateStatusIdle());
          }}
        >
          Intentar nuevamente
        </Button>,
      ]}
    />
  );

  const shouldShowForm = bulkUpdateStatus !== 'failed' && bulkUpdateStatus !== 'succeeded';
  const shouldShowSuccessResult = bulkUpdateStatus === 'succeeded';
  const shouldShowFailureResult = bulkUpdateStatus === 'failed';

  return (
    <Modal
      title='Actualizar Productos'
      visible={bulkUpdateVisible}
      onOk={() => {
        form
          .validateFields()
          .then(values => onBulkUpdate(values))
          .catch(error => console.log(error));
      }}
      onCancel={() => {
        form.resetFields();
        setCerrarModal();
      }}
      footer={!shouldShowForm ? <></> : undefined}
    >
      <Spin spinning={bulkUpdateStatus === 'pending' ? true : false}>
        {shouldShowForm && fileInputForm}
        {shouldShowSuccessResult && successResult}
        {shouldShowFailureResult && failureResult}
      </Spin>
    </Modal>
  );
};
