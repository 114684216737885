import { Select } from 'antd';
import { MarcaComercial } from 'models/MarcaComercial';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useAppSelector } from 'store/hooks';

const { Option } = Select;

export const AgMarcasComercialesCellEditor = forwardRef((props: any, ref) => {
  const { data: MarcasComerciales } = useAppSelector(state => state.marcasComerciales);
  const [value, setValue] = useState<MarcaComercial | undefined>(
    MarcasComerciales.find(p => p.nombre === props.value),
  );

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (props.colDef.field === 'marca') {
          props.data.marcaId = value?.id;
        }

        return value?.nombre;
      },
    };
  });

  const onChange = (e: string) => {
    setValue(MarcasComerciales.find(p => p.id === e));
  };

  return (
    <div>
      <Select
        showSearch
        optionFilterProp='children'
        defaultValue={value?.id}
        onChange={e => onChange(e)}
        style={{ width: '100%' }}
      >
        {MarcasComerciales.map(marcaComercial => (
          <Option key={marcaComercial.id} value={marcaComercial.id}>
            {marcaComercial.nombre}
          </Option>
        ))}
      </Select>
    </div>
  );
});
