import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Loader } from 'components/Loader/Loader';
import { setTitleName } from 'components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { IDucaItemRequest } from 'models/Duca';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { ContinuacionPartidasAdicionales } from './ContinuacionPartidasAdicionales';
import { FormatoPrincipal } from './FormatoPrincipal';
import './printLiquidacion.css';
import { getDetallesDuca } from './store/extraReducer/getDetallesDuca';
import { getItemsDuca } from './store/extraReducer/getItemsDuca';

export type TParams = {
  ordenId: string;
};

const ArchivoPreliquidacion: FC = () => {
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const { goBack } = useHistory();
  const { ordenId } = useParams<TParams>();
  const { data, status, statusItems } = useAppSelector(state => state.archivoPreliquidacion);
  const [countPagesAdicionales, setCountPagesAdicionales] = useState<number[]>([]);

  useEffect(() => {
    if (data && data.caratula) {
      !window.document.title.includes(`Preliquidacion - ${data.caratula.numeroPreImpreso}`) &&
        dispatch(setTitleName(`Preliquidacion - ${data.caratula.numeroPreImpreso}`));

      if (data.items) {
        if (data.items.length < 4) {
          setCountPagesAdicionales([]);
        } else {
          const countPages = Math.ceil((data.items.length - 4) / 16);
          setCountPagesAdicionales(Array(countPages).fill(0));
        }
      } else {
        setCountPagesAdicionales([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    customDispatch({
      asyncThuckFuction: getDetallesDuca,
      endPoint: `/api/Ducas/GetById/${ordenId}`,
    });

    customDispatch({
      asyncThuckFuction: getItemsDuca,
      endPoint: `/api/Ducas/GetDucaItems/${ordenId}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return status === 'pending' || statusItems === 'pending' ? (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Loader />
    </div>
  ) : (
    <div className='container'>
      <div className='toolbar'>
        <Button
          icon={<ArrowLeftOutlined />}
          style={{ position: 'absolute', left: 10 }}
          onClick={() => goBack()}
        >
          Regresar
        </Button>
        <Button type='primary' icon={<PrinterOutlined />} onClick={() => window.print()}>
          Imprimir
        </Button>
      </div>

      <div className='page'>
        <div className='title'>
          <span className='h1'>DECLARACION UNICA ADUANERA</span>
          <span className='h3'>(FORMATO PRINCIPAL)</span>
        </div>
        <FormatoPrincipal />
      </div>

      {countPagesAdicionales.map((_, index) => {
        const desde = index === 0 ? 4 : 16 * index + 4;
        const hasta = desde + 16;
        const products: IDucaItemRequest[] = data?.items?.slice(desde, hasta) ?? [];

        return (
          <div className='page' key={index}>
            <div className='title'>
              <span className='h1'>DECLARACION UNICA ADUANERA</span>
              <span className='h3'>(CONTINUACION DE PARTIDAS ADICIONALES)</span>
            </div>
            <ContinuacionPartidasAdicionales ultimaPDA={desde + 1} productos={products} />
          </div>
        );
      })}
    </div>
  );
};
export default ArchivoPreliquidacion;
