import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClaseBulto } from 'models/ClaseBulto';
import { Configuraciones } from 'models/Configuraciones';
import { Incoterm } from 'models/Incoterm';
import { OrganizacionPais } from 'models/OrganizacionPais';
import { Aduana } from '../../../models/Aduana';
import { ConfiguracionesPetch } from '../../../models/configuracionesPetch';
import { RootState } from '../../../store/store';
import { getAduanasReducer } from './extraReducers/getAduanas';
import { getClasesBultoReducer } from './extraReducers/getClasesBulto';
import { getCondicionesComercialesReducer } from './extraReducers/getCondicionesComerciales';
import { getConfiguracionesReducer } from './extraReducers/getConfiguraciones';
import { getFormasDePagoReducer } from './extraReducers/getFormaDePago';
import { getFormasDeEnvioReducer } from './extraReducers/getFormasDeEnvio';
import { getIncotermsReducer } from './extraReducers/getIncoterms';
import { getMonedasDePagoReducer } from './extraReducers/getMonedasDePago';
import { getNivelesComercialesReducer } from './extraReducers/getNivelesComerciales';
import { getOrganizacionesPaisesReducer } from './extraReducers/getOrganizacionesPaises';
import { getTiposIntermediarioReducer } from './extraReducers/getTiposIntermediario';

export interface IInitialDataState {
  initialDataCompleted: boolean;
  AduanasStatus: StatusApp.Status;
  Aduanas: Array<Aduana>;
  ClasesBultosStatus: StatusApp.Status;
  ClasesBulto: Array<ClaseBulto>;
  NivelesComercialesStatus: StatusApp.Status;
  NivelesComerciales: ConfiguracionesPetch[];
  CondicionesComerciales: ConfiguracionesPetch[];
  CondicionesComercialesStatus: StatusApp.Status;
  TiposIntermediario: ConfiguracionesPetch[];
  TiposIntermediarioStatus: StatusApp.Status;
  Incoterm: Incoterm[];
  IncotermStatus: StatusApp.Status;
  FormasDeEnvio: ConfiguracionesPetch[];
  FormasDeEnvioStatus: StatusApp.Status;
  MonedasDePago: ConfiguracionesPetch[];
  MonedasDePagoStatus: StatusApp.Status;
  FormasDePago: ConfiguracionesPetch[];
  FormasDePagoStatus: StatusApp.Status;
  Configuraciones?: Configuraciones;
  ConfiguracionesStatus: StatusApp.Status;
  OrganizacionesPaises: OrganizacionPais[];
  OrganizacionesPaisesStatus: StatusApp.Status;
  error: { error?: boolean; message?: string; duration?: number };
}

const initialState: IInitialDataState = {
  initialDataCompleted: false,
  AduanasStatus: 'idle',
  Aduanas: [],
  ClasesBultosStatus: 'idle',
  ClasesBulto: [],
  NivelesComercialesStatus: 'idle',
  NivelesComerciales: [],
  CondicionesComerciales: [],
  CondicionesComercialesStatus: 'idle',
  TiposIntermediario: [],
  TiposIntermediarioStatus: 'idle',
  Incoterm: [],
  IncotermStatus: 'idle',
  FormasDeEnvio: [],
  FormasDeEnvioStatus: 'idle',
  MonedasDePago: [],
  MonedasDePagoStatus: 'idle',
  FormasDePago: [],
  FormasDePagoStatus: 'idle',
  ConfiguracionesStatus: 'idle',
  OrganizacionesPaises: [],
  OrganizacionesPaisesStatus: 'idle',
  error: { error: false, message: '' },
};

export const initialDataSlice = createSlice({
  name: 'InitialData',
  initialState,
  reducers: {
    setInitialData: (state, action: PayloadAction<boolean>) => {
      state.initialDataCompleted = action.payload;
    },
    setError: (
      state,
      action: PayloadAction<{ error: boolean; message?: string; duration?: number }>,
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    getAduanasReducer(builder);
    getClasesBultoReducer(builder);
    getNivelesComercialesReducer(builder);
    getCondicionesComercialesReducer(builder);
    getTiposIntermediarioReducer(builder);
    getIncotermsReducer(builder);
    getFormasDeEnvioReducer(builder);
    getMonedasDePagoReducer(builder);
    getFormasDePagoReducer(builder);
    getConfiguracionesReducer(builder);
    getOrganizacionesPaisesReducer(builder);
  },
});

export const { setInitialData, setError } = initialDataSlice.actions;

export const InitialDataState = (state: RootState) => state.initialData;

export default initialDataSlice.reducer;
