import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Incoterm } from 'models/Incoterm';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';
import { IInitialDataState } from '../initialDataStore';

export const getIncoterms = createAsyncThunk(
  'getIncoterms/GetAll',
  async (fetchOp: IUseFetch, thunkApi: ThunckApi) => {
    const res = httpService(fetchOp, thunkApi);
    return res;
  },
);

export const getIncotermsReducer = (builder: ActionReducerMapBuilder<IInitialDataState>) => {
  builder.addCase(getIncoterms.fulfilled, (state, action: PayloadAction<Incoterm[]>) => {
    state.Incoterm = action.payload;
    state.IncotermStatus = 'succeeded';
  });

  builder.addCase(getIncoterms.pending, state => {
    state.IncotermStatus = 'pending';
  });

  builder.addCase(getIncoterms.rejected, state => {
    state.IncotermStatus = 'failed';
  });
};
