import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IDevasState, ProductoValidado } from '../store';

export const crearProductosPendientes = createAsyncThunk(
  'Devas/CrearProductosPendientes',
  httpService,
);

export const crearProductosPendientesReducer = (builder: ActionReducerMapBuilder<IDevasState>) => {
  builder.addCase(
    crearProductosPendientes.fulfilled,
    (state, action: PayloadAction<ProductoValidado[]>) => {
      const newProductosValidados = state.productosValidados.map(p => {
        const tempProducto = action.payload.find(pp => pp.product.sku === p.product.sku);

        if (tempProducto) return tempProducto;

        return p;
      });

      state.productosValidados = newProductosValidados;
      state.productosPendientesCreateStatus = 'succeeded';
    },
  );
  builder.addCase(crearProductosPendientes.pending, (state, _action) => {
    state.productosPendientesCreateStatus = 'pending';
  });
  builder.addCase(crearProductosPendientes.rejected, (state, _action) => {
    state.productosPendientesCreateStatus = 'failed';
  });
};
