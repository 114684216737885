import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setDevas, setValoresTotales } from 'components/Devas/store/store';
import { Deva } from 'models/Deva';
import { OrdenDeTrabajo } from 'models/OrdenTrabajo';
import httpService, { IUseFetch, ThunckApi } from 'services/httpService';
import { IOrdenTrabajoDetallesState } from '../ordenTrabajoDetallesStore';

export const getOrdenTrabajoDetalles = createAsyncThunk(
  'OrdenTrabajoDetalles/GetDetails',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      const devas = res.devas
        .sort((a: Deva, b: Deva) => {
          if (a.fechaCreacion && b.fechaCreacion) {
            if (a.fechaCreacion < b.fechaCreacion) return -1;
            if (a.fechaCreacion > b.fechaCreacion) return 1;
          }
          return 0;
        })
        .map(
          (deva: Deva) =>
            ({
              id: deva.id,
              proveedorId: deva.proveedorId,
              proveedor: deva.proveedor,
              numeroFactura: deva.numeroFactura,
              valorFOB: deva.valorFOB,
              flete: deva.flete,
              seguro: deva.seguro,
              otrosGastos: deva.otrosGastos,
              valorCIF: deva.valorCIF,
              incotermId: deva.incotermId,
            } as Deva),
        );
      thunkApi.dispatch(setDevas(devas));
      thunkApi.dispatch(
        setValoresTotales({
          flete: res.valorTotalFlete,
          seguro: res.valorTotalSeguro,
          otrosGastos: res.valorTotalOtrosGastos,
        }),
      );
    }

    return res;
  },
);

export const getOrdenTrabajoDetallesReducer = (
  builder: ActionReducerMapBuilder<IOrdenTrabajoDetallesState>,
) => {
  // Get
  builder.addCase(
    getOrdenTrabajoDetalles.fulfilled,
    (state, action: PayloadAction<OrdenDeTrabajo>) => {
      state.data = action.payload;
      state.status = 'succeeded';
    },
  );
  builder.addCase(getOrdenTrabajoDetalles.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getOrdenTrabajoDetalles.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
