import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const queryDepositoAduanaTransito = createAsyncThunk(
  'DucaDetalles/DepositoAduanaTransito/Query',
  httpService,
);

export const queryDepositoAduanaTransitoReducer = (
  builder: ActionReducerMapBuilder<IDucaDetallesState>,
) => {
  builder.addCase(
    queryDepositoAduanaTransito.fulfilled,
    (state, action: PayloadAction<PagedList<ConfiguracionesPetch>>) => {
      state.DepositosAduanasTransito = action.payload.items;
      state.DepositoAduanaTransitoStatus = 'succeeded';
    },
  );

  builder.addCase(queryDepositoAduanaTransito.pending, state => {
    state.DepositoAduanaTransitoStatus = 'pending';
  });

  builder.addCase(queryDepositoAduanaTransito.rejected, state => {
    state.DepositoAduanaTransitoStatus = 'failed';
  });
};
