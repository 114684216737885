import { RowValueChangedEvent } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Button, Empty, Row, Space, Spin, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AgCuotaArancelariaCellEditor } from 'components/AgGridCellEditor/AgCuotaArancelariaCellEditor';
import { AgEstadoMercanciaCellEditor } from 'components/AgGridCellEditor/AgEstadoMercanciaCellEditor';
import { AgInputCellEditor } from 'components/AgGridCellEditor/AgInputCellEditor';
import { AgInputNumberCellEditor } from 'components/AgGridCellEditor/AgInputNumberCellEditor';
import { AgPaisesCellEditor } from 'components/AgGridCellEditor/AgPaisesCellEditor';
import { AgPartidasArancelariasCellEditor } from 'components/AgGridCellEditor/AgPartidasArancelariasCellEditor';
import { AgTipoItemCellEditor } from 'components/AgGridCellEditor/AgTipoItemCellEditor';
import { AgUnidadesMedidaCellEditor } from 'components/AgGridCellEditor/AgUnidadesMedidaCellEditor';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { useFormatDataDucaItem } from 'features/DucaDetalles/utils/useFormatDataDucaItem';
import { IDucaItemRequest } from 'models/Duca';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { formatCurrency } from 'utils/formatCurrency';
import { tableColumnNumberFilter, tableColumnTextFilter } from 'utils/tableColumnFilter';
import {
  setDeleteDucaItem,
  setItemEditingKey,
  setSaveDucaItems,
  setVisibleModalAsignarVentaja,
} from '../store/store';
import { generarExcelItems } from '../utils/generarExcelItems';
import { Columns } from './Columns';
import { DetallesItem } from './DetallesItem/DetallesItem';
import { DropdownCellRenderer } from './dropdownCellRenderer';
import { ModalAgrupar } from './ModalAgrupar/ModalAgrupar';
import { ModalAsignarDatosComplementarios } from './ModalAsignarDatosComplementarios/ModalAsignarDatosComplementarios';
import { ModalAsignarDocumentos } from './ModalAsignarDocumentos/ModalAsignarDocumentos';
import { ModalAsignarVentajas } from './ModalAsignarVentajas/ModalAsignarVentajas';
import { ModalCalculoRapido } from './ModalCalculoRapido/ModalCalculoRapido';
import { ModalCalculoRapidoLitrosDeclarados } from './ModalCalculoRapidoLitrosDeclarados/ModalCalculoRapidoLitrosDeclarados';

const { Text } = Typography;

export interface DataType {
  key: React.Key;
  numeroItem: number;
  posicionArancelaria: string;
  descripcion: string;
  paisOrigen: string;
  existencia: boolean;
  dai?: number;
  tarifaTLC?: number;
  tarifaTLCAplicada?: number;
}

export const ColumnasTablaAsignar: ColumnsType<DataType> = [
  {
    title: 'Numero Item',
    dataIndex: 'numeroItem',
    width: 130,
    render: (value: number, record: DataType) =>
      !record.existencia ? <Text>{value}</Text> : <Text strong>{value}*</Text>,
    ...tableColumnNumberFilter<DataType>('numeroItem'),
  },
  {
    title: 'Posición Arancelaria',
    dataIndex: 'posicionArancelaria',
    width: 170,
    ...tableColumnTextFilter<DataType>('posicionArancelaria'),
  },
  {
    title: 'Descripción',
    dataIndex: 'descripcion',
    ellipsis: true,
    ...tableColumnTextFilter<DataType>('descripcion'),
  },
  {
    title: 'Pais Origen',
    dataIndex: 'paisOrigen',
    width: 130,
    ...tableColumnTextFilter<DataType>('paisOrigen'),
  },
];

type TParams = {
  ordenId: string;
};

interface ITabPaneItems {
  filtrarPorIPC: boolean;
  setFiltrarPorIPC: (value: boolean) => void;
  filtrarPorLitrosDeclarados: boolean;
  setFiltrarPorLitrosDeclarados: (value: boolean) => void;
}

export const TabPaneItems = ({
  filtrarPorIPC,
  setFiltrarPorIPC,
  filtrarPorLitrosDeclarados,
  setFiltrarPorLitrosDeclarados,
}: ITabPaneItems) => {
  const dispatch = useDispatch();
  const [modalAgrupar, setModalAgrupar] = useState<boolean>(false);
  const [modalAsignarDocumento, setModalAsignarDocumento] = useState<boolean>(false);
  const [modalAsignarDatoComplementario, setModalAsignarDatoComplementario] =
    useState<boolean>(false);
  const [heightTable, setHeightTable] = useState<number>(0);
  const [rowBottomPinned, setRowBottomPinned] = useState<object | undefined>(undefined);
  const [modalCalculoRapido, setModalCalculoRapido] = useState<boolean>(false);
  const [modalCalculoRapidoLitrosDeclarados, setModalCalculoRapidoLitrosDeclarados] =
    useState<boolean>(false);
  const [itemCalculoRapido, setItemCalculoRapido] = useState<IDucaItemRequest | undefined>(
    undefined,
  );
  const [itemCalculoRapidoLitrosDeclarados, setItemCalculoRapidoLitrosDeclarados] = useState<
    IDucaItemRequest | undefined
  >(undefined);
  const [itemsFormat, setItemsFormat] = useState<any[]>([]);
  const { itemStatus, items, ducaDetalles, modalVisibleAsignarVentaja } = useAppSelector(
    state => state.ducaDetalles,
  );
  const Configuraciones = useAppSelector(state => state.initialData.Configuraciones);
  const { ordenId } = useParams<TParams>();
  const formatData = useFormatDataDucaItem();

  const setModalAsignarVentaja = (value: boolean) => {
    dispatch(setVisibleModalAsignarVentaja(value));
  };

  // Calculos al cargar la información
  useEffect(() => {
    // Calcular el alto de la tabla
    const itemsResult = items.filter(x => x.estado !== EstadoDucaItem.Delete);
    setHeightTable((itemsResult.length + 3) * 41);

    // Calcular totales
    const totales = itemsResult.reduce(
      (acc, item) => {
        acc.cantidadComercial += item.cantidadComercial;
        acc.cantidadEstadistica += item.cantidadEstadistica;
        acc.importeFOB += item.importeFOB;
        acc.importeFlete += item.importeFlete;
        acc.importeSeguro += item.importeSeguro;
        acc.importeOtrosGastos += item.importeOtrosGastos;
        acc.valorCIF += item.valorCIF;
        acc.totalDerechos += item.totalDerechos;
        acc.isv += item.isv;
        acc.ipc += item.ipc;
        acc.isc += item.isc;
        acc.pesoBruto += item.pesoBruto;
        acc.pesoNeto += item.pesoNeto;
        acc.cantidadBultos += item.cantidadBultos;
        return acc;
      },
      {
        cantidadComercial: 0,
        cantidadEstadistica: 0,
        importeFOB: 0,
        importeFlete: 0,
        importeSeguro: 0,
        importeOtrosGastos: 0,
        valorCIF: 0,
        totalDerechos: 0,
        isv: 0,
        ipc: 0,
        isc: 0,
        pesoBruto: 0,
        pesoNeto: 0,
        cantidadBultos: 0,
      },
    );

    // Data para fila fijata en la tabla
    setRowBottomPinned({
      cantidadComercial: formatCurrency(
        totales.cantidadComercial.toFixed(Configuraciones?.precisionDecimal),
      ),
      cantidadEstadistica: formatCurrency(
        totales.cantidadEstadistica.toFixed(Configuraciones?.precisionDecimal),
      ),
      importeFOB: formatCurrency(totales.importeFOB.toFixed(Configuraciones?.precisionDecimal)),
      importeFlete: formatCurrency(totales.importeFlete.toFixed(Configuraciones?.precisionDecimal)),
      importeSeguro: formatCurrency(
        totales.importeSeguro.toFixed(Configuraciones?.precisionDecimal),
      ),
      importeOtrosGastos: formatCurrency(
        totales.importeOtrosGastos.toFixed(Configuraciones?.precisionDecimal),
      ),
      valorCIF: formatCurrency(totales.valorCIF.toFixed(Configuraciones?.precisionDecimal)),
      totalDerechos: formatCurrency(
        totales.totalDerechos.toFixed(Configuraciones?.precisionDecimal),
      ),
      isv: formatCurrency(totales.isv.toFixed(Configuraciones?.precisionDecimal)),
      ipc: formatCurrency(totales.ipc.toFixed(Configuraciones?.precisionDecimal)),
      isc: formatCurrency(totales.isc.toFixed(Configuraciones?.precisionDecimal)),
      pesoBruto: formatCurrency(totales.pesoBruto.toFixed(Configuraciones?.precisionDecimal)),
      pesoNeto: formatCurrency(totales.pesoNeto.toFixed(Configuraciones?.precisionDecimal)),
      cantidadBultos: formatCurrency(
        totales.cantidadBultos.toFixed(Configuraciones?.precisionDecimal),
      ),
      pinnedBottom: true,
    });

    // Data con formato en los numeros
    setItemsFormat(
      items.map(x => ({
        ...x,
        cantidadComercial: formatCurrency(
          x.cantidadComercial?.toFixed(Configuraciones?.precisionDecimal),
        ),
        cantidadEstadistica: formatCurrency(
          x.cantidadEstadistica?.toFixed(Configuraciones?.precisionDecimal),
        ),
        fobOtraMoneda: formatCurrency(x.fobOtraMoneda?.toFixed(Configuraciones?.precisionDecimal)),
        importeFOB: `$ ${formatCurrency(x.importeFOB?.toFixed(Configuraciones?.precisionDecimal))}`,
        importeFlete: `$ ${formatCurrency(
          x.importeFlete?.toFixed(Configuraciones?.precisionDecimal),
        )}`,
        importeSeguro: `$ ${formatCurrency(
          x.importeSeguro?.toFixed(Configuraciones?.precisionDecimal),
        )}`,
        importeOtrosGastos: `$ ${formatCurrency(
          x.importeOtrosGastos?.toFixed(Configuraciones?.precisionDecimal),
        )}`,
        valorCIF: `$ ${formatCurrency(x.valorCIF?.toFixed(Configuraciones?.precisionDecimal))}`,
        gravadoPorcentajeCIF: formatCurrency(
          x.gravadoPorcentajeCIF?.toFixed(Configuraciones?.precisionDecimal),
        ),
        totalDerechos: `L ${formatCurrency(
          x.totalDerechos?.toFixed(Configuraciones?.precisionDecimal),
        )}`,
        isv: `L ${formatCurrency(x.isv?.toFixed(Configuraciones?.precisionDecimal))}`,
        ipc: `L ${formatCurrency(x.ipc?.toFixed(Configuraciones?.precisionDecimal))}`,
        isc: `L ${formatCurrency(x.isc?.toFixed(Configuraciones?.precisionDecimal))}`,
        formaLiquidacion: formatCurrency(
          x.formaLiquidacion?.toFixed(Configuraciones?.precisionDecimal),
        ),
        pesoBruto: x.pesoBruto?.toFixed(Configuraciones?.precisionDecimal),
        pesoNeto: x.pesoNeto?.toFixed(Configuraciones?.precisionDecimal),
        cantidadBultos: x.cantidadBultos?.toFixed(Configuraciones?.precisionDecimal),
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const { DraweDetallesItem, onShow } = DetallesItem();

  const onDetails = (values: IDucaItemRequest) => {
    const item = items.find(x => x.key === values.key);
    dispatch(setItemEditingKey(values.key));
    onShow(item as IDucaItemRequest);
  };

  const onDelete = (key: string) => {
    dispatch(setDeleteDucaItem(key));
  };

  const onCalculoRapido = (key: string) => {
    setItemCalculoRapido(items.find(x => x.key === key));
    setModalCalculoRapido(true);
  };

  const onCalculoRapidoLitrosDeclarados = (key: string) => {
    setItemCalculoRapidoLitrosDeclarados(items.find(x => x.key === key));
    setModalCalculoRapidoLitrosDeclarados(true);
  };

  const onFiltrarCalculoRapido = (value: boolean) => {
    setFiltrarPorIPC(value);
    if (value) {
      setFiltrarPorLitrosDeclarados(false);
    }
  };

  const onFiltrarCalculoRapidoLitrosDeclarados = (value: boolean) => {
    setFiltrarPorLitrosDeclarados(value);

    if (value) {
      setFiltrarPorIPC(false);
    }
  };

  const onCloseModalCalculoRapido = () => {
    setModalCalculoRapido(false);
    setItemCalculoRapido(undefined);
  };

  const onCloseModalCalculoRapidoLitrosDeclarados = () => {
    setModalCalculoRapidoLitrosDeclarados(false);
    setItemCalculoRapidoLitrosDeclarados(undefined);
  };

  const onGenerarExcel = () => {
    generarExcelItems(items.filter(x => x.estado !== EstadoDucaItem.Delete));
  };

  const onRowDragEnd = (e: any) => {
    const { nodes } = e;
    const { rowIndex, data } = nodes[0];
    const { key } = data;
    const itemIndex = items.findIndex(x => x.key === key);
    const newItems = [...items.filter(x => x.estado !== EstadoDucaItem.Delete)];
    newItems.splice(itemIndex, 1);
    newItems.splice(rowIndex, 0, {
      ...items[itemIndex],
    });

    dispatch(
      setSaveDucaItems(
        newItems.map((x, i) => ({
          ...x,
          numeroItem: i + 1,
          estado:
            x.estado === EstadoDucaItem.Create ? EstadoDucaItem.Create : EstadoDucaItem.Update,
        })),
      ),
    );
  };

  const onRowValueChanged = useCallback(
    (event: RowValueChangedEvent) => {
      const data = formatData(event.data);
      dispatch(setSaveDucaItems(data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatData],
  );

  const columns = Columns({
    onDetails,
    onDelete,
    onCalculoRapido,
    onCalculoRapidoLitrosDeclarados,
    ordenCerrada: ducaDetalles?.ordenCerrada ?? false,
  });

  const dataLeaked = filtrarPorIPC
    ? itemsFormat.filter(x => x.estado !== EstadoDucaItem.Delete && x.ipcFactor > 0)
    : filtrarPorLitrosDeclarados
    ? itemsFormat.filter(x => x.estado !== EstadoDucaItem.Delete && x.litrosDeclarados === true)
    : itemsFormat.filter(x => x.estado !== EstadoDucaItem.Delete);

  const ItemTable = (
    <Row style={{ marginTop: ducaDetalles?.ordenCerrada ? 0 : 16 }}>
      <div
        className='ag-theme-alpine'
        style={{
          height:
            heightTable > 700
              ? ducaDetalles?.ordenCerrada
                ? 'calc(100vh - 225px)'
                : 'calc(100vh - 275px)'
              : heightTable - 3,
          width: '100%',
        }}
      >
        <AgGridReact
          defaultColDef={{
            resizable: true,
            width: 150,
            filter: true,
            sortable: true,
            editable: true,
          }}
          editType='fullRow'
          pagination={false}
          rowData={dataLeaked}
          columnDefs={columns}
          frameworkComponents={{
            dropdownCellRenderer: DropdownCellRenderer,
            AgPartidasArancelariasCellEditor,
            AgInputCellEditor,
            AgInputNumberCellEditor,
            AgUnidadesMedidaCellEditor,
            AgEstadoMercanciaCellEditor,
            AgPaisesCellEditor,
            AgTipoItemCellEditor,
            AgCuotaArancelariaCellEditor,
          }}
          pinnedBottomRowData={[rowBottomPinned]}
          onRowValueChanged={onRowValueChanged}
          getRowStyle={(params: any) => {
            if (params?.data?.ipcFactor > 0 && params?.data?.ipc === 'L 0') {
              return { backgroundColor: '#ffc0cb' };
            }
          }}
          rowDragManaged
          animateRows
          onRowDragEnd={(e: any) => onRowDragEnd(e)}
          onGridReady={params => {
            params.columnApi.autoSizeColumns(Object.keys(columns));
          }}
        />
      </div>
      <Text type='secondary' style={{ display: 'flex', justifyContent: 'start', marginTop: 16 }}>
        Los cambios no se verán reflejados hasta dar click en el botón "Guardar Cambios" en la parte
        superior de la pantalla
      </Text>
    </Row>
  );

  return (
    <section>
      <Spin spinning={itemStatus === 'pending'}>
        {!ducaDetalles?.ordenCerrada && (
          <Row justify='space-between'>
            <Space>
              {items.length > 0 && (
                <>
                  <Button onClick={() => setModalAsignarDocumento(true)}>Asignar Documentos</Button>
                  <Button onClick={() => setModalAsignarVentaja(true)}>Asignar Ventajas</Button>
                  <Button onClick={() => setModalAsignarDatoComplementario(true)}>
                    Asignar Datos Complementarios
                  </Button>
                  <Button onClick={() => onGenerarExcel()}>Generar Excel</Button>
                  <Button
                    onClick={() => onFiltrarCalculoRapido(!filtrarPorIPC)}
                    type='primary'
                    ghost
                    danger={filtrarPorIPC}
                  >
                    {items.some(x => x.ipcFactor > 0) && !filtrarPorIPC
                      ? 'Filtrar productos con IPC'
                      : 'Quitar filtro de IPC'}
                  </Button>
                  <Button
                    onClick={() =>
                      onFiltrarCalculoRapidoLitrosDeclarados(!filtrarPorLitrosDeclarados)
                    }
                    type='primary'
                    ghost
                    danger={filtrarPorLitrosDeclarados}
                  >
                    {items.some(x => x.litrosDeclarados) && !filtrarPorLitrosDeclarados
                      ? 'Filtrar productos por L/D'
                      : 'Quitar filtro de L/D'}
                  </Button>
                </>
              )}
            </Space>
            <Tooltip
              title='Si ya existen productos importados, se eliminaran por los nuevos'
              placement='left'
            >
              <Button type='primary' ghost onClick={() => setModalAgrupar(true)}>
                Importar items
              </Button>
            </Tooltip>
          </Row>
        )}
        {items.length > 0 ? (
          ItemTable
        ) : (
          <Row justify='center'>
            <Empty />
          </Row>
        )}
      </Spin>
      <DraweDetallesItem />
      <ModalAgrupar ordenId={ordenId} visible={modalAgrupar} setVisible={setModalAgrupar} />
      <ModalAsignarDocumentos
        visible={modalAsignarDocumento}
        setVisible={setModalAsignarDocumento}
      />
      <ModalAsignarVentajas
        visible={modalVisibleAsignarVentaja}
        setVisible={setModalAsignarVentaja}
      />
      <ModalAsignarDatosComplementarios
        visible={modalAsignarDatoComplementario}
        setVisible={setModalAsignarDatoComplementario}
      />
      <ModalCalculoRapido
        visible={modalCalculoRapido}
        item={itemCalculoRapido}
        onClose={onCloseModalCalculoRapido}
      />
      <ModalCalculoRapidoLitrosDeclarados
        visible={modalCalculoRapidoLitrosDeclarados}
        item={itemCalculoRapidoLitrosDeclarados}
        onClose={onCloseModalCalculoRapidoLitrosDeclarados}
      />
    </section>
  );
};
