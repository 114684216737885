import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { ITratadosState } from '../tratadosStore';

export const deleteTratado = createAsyncThunk('Tratados/Delete', httpService);

export const deleteTratadoReducer = (builder: ActionReducerMapBuilder<ITratadosState>) => {
  builder.addCase(deleteTratado.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.data.findIndex(tratado => tratado.id === state.elementoSeleccionado?.id);
    state.data.splice(index, 1);
  });
  builder.addCase(deleteTratado.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deleteTratado.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
