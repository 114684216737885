import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { MarcaComercial } from 'models/MarcaComercial';
import httpService from 'services/httpService';
import { IMarcasComercialesState } from '../marcasComercialesStore';

export const createMarcaComercial = createAsyncThunk('MarcasComerciales/Create', httpService);

export const createMarcaComercialReducer = (
  builder: ActionReducerMapBuilder<IMarcasComercialesState>,
) => {
  // Create
  builder.addCase(createMarcaComercial.fulfilled, (state, action) => {
    state.crudStatus = 'succeeded';
    state.data.unshift({
      ...state.elementoTemporal,
      id: action.payload,
    } as MarcaComercial);
  });
  builder.addCase(createMarcaComercial.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createMarcaComercial.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
