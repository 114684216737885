import { Col, Form } from 'antd';
import { DevasSecction } from 'components/Devas/DevasSecction/DevasSecction';
import { setOrdenDeTrabajoInCreation } from 'components/Devas/store/store';
import { DucaSecction } from 'components/Duca/DucaSecction/DucaSecction';
import { getDesignacionesProductos } from 'features/DesignacionesProductos/store/extraReducer/get';
import { resetOrdenDetalles } from 'features/OrdenTrabajoDetalles/store/ordenTrabajoDetallesStore';
import { UseCustomDispatch } from 'hooks/useCustomDispatch';
import { useInitialData } from 'hooks/useInitialData';
import { Deva } from 'models/Deva';
import { IOrdenDeTrabajoRequest } from 'models/OrdenTrabajo';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { FormDatosGeneralesFlete } from './FormDatosGeneralesFlete/FormDatosGeneraleFlete';
import styles from './OrdenDeTrabajo.module.less';

const initData: UseCustomDispatch[] = [
  {
    asyncThuckFuction: getDesignacionesProductos,
    endPoint: '/api/DesignacionesProductos/GetAll',
  },
];

export const OrdenDeTrabajo: FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<IOrdenDeTrabajoRequest>();

  const { getPartidasArancelariasStatus, getDesignacionesProductosStatus } = useAppSelector(
    state => ({
      getPartidasArancelariasStatus: state.partidasArancelarias.status,
      getDesignacionesProductosStatus: state.designacionesProductos.status,
    }),
  );

  const { idOrdenTrabajoCreada, ordenTrabajo, crearOrdenTrabajoStatus } = useAppSelector(
    state => state.ordenDeTrabajo,
  );

  const devas = useAppSelector(state => state.devas.devasOfOrdenDeTrabajo);

  const condition: boolean =
    getPartidasArancelariasStatus === 'idle' && getDesignacionesProductosStatus === 'idle';
  useInitialData(initData, condition);

  useEffect(() => {
    !window.document.title.includes('Orden De Trabajo') &&
      dispatch(setTitleName('Orden De Trabajo'));

    dispatch(setOrdenDeTrabajoInCreation(true));
    dispatch(resetOrdenDetalles('idle'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ordenTrabajo) {
      form.setFieldsValue({ ...ordenTrabajo });
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordenTrabajo]);

  // Calcular totales
  useEffect(() => {
    const totales = devas.reduce(
      (acc, current) => {
        acc.valorFOB += Number(current.valorFOB);
        acc.flete += Number(current.flete);
        acc.seguro += Number(current.seguro);
        acc.otrosGastos += Number(current.otrosGastos);
        acc.valorCIF += Number(current.valorCIF);

        return acc;
      },
      { valorFOB: 0, flete: 0, seguro: 0, otrosGastos: 0, valorCIF: 0 } as Deva,
    );

    form.setFieldsValue({
      fob: Number(totales.valorFOB.toFixed(2)),
      flete: Number(totales.flete.toFixed(2)),
      seguro: Number(totales.seguro.toFixed(2)),
      og: Number(totales.otrosGastos.toFixed(2)),
      cif: Number(totales.valorCIF.toFixed(2)),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devas]);

  return (
    <div className={styles.container}>
      <Col className={`${styles.secction} ${styles.secctionFormOrdenDeTrabajo}`}>
        <FormDatosGeneralesFlete form={form} />
      </Col>

      {crearOrdenTrabajoStatus === 'succeeded' && (
        <>
          <Col className={styles.secction}>
            <DevasSecction ordenTrabajoId={idOrdenTrabajoCreada} creando />
          </Col>
          <Col className={styles.secction}>
            <DucaSecction
              ordenTrabajoId={idOrdenTrabajoCreada}
              ducaId={ordenTrabajo?.ducaId}
              creando
            />
          </Col>
        </>
      )}
    </div>
  );
};
