import { Form } from 'antd';
const { Item } = Form;

interface IInvisibleItemProps {
  name: string;
}

export const InvisibleItem = ({ name }: IInvisibleItemProps) => (
  <Item name={name} style={{ opacity: 0, height: 0, margin: 0 }} noStyle>
    <span />
  </Item>
);
