import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IDucaDatoComplementarioRequest } from 'models/Duca';

export const TablaDatosComplementarios = ({ data }: { data: IDucaDatoComplementarioRequest[] }) => {
  const Columns: ColumnsType<IDucaDatoComplementarioRequest> = [
    {
      title: 'Código Dato',
      dataIndex: 'codigo',
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
    },
  ];

  return <Table columns={Columns} dataSource={data} pagination={false} bordered size='small' />;
};
