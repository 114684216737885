import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/InitialData/store/initialDataStore';
import { addProveedor } from 'features/Proveedores/store/proveedoresStore';
import { Proveedor } from 'models/Proveedor';
import httpService, { IUseFetch, ThunckApi } from '../../../../../services/httpService';
import { IInicioState } from '../../inicioStore';

export const updateProveedoresPendientes = createAsyncThunk(
  'Inicio/UpdateProveedoresPendientes',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      (fetch.body as Proveedor[]).map(p => thunkApi.dispatch(addProveedor(p)));
      if (document.fullscreenElement === null) {
        thunkApi.dispatch(setError({ message: 'Proveedores aprobados', error: false }));
      }
    }
    return res;
  },
);

export const updateProveedoresPendienteReducer = (
  builder: ActionReducerMapBuilder<IInicioState>,
) => {
  // Update Proveedores Pendientes
  builder.addCase(updateProveedoresPendientes.fulfilled, (state, _action) => {
    state.proveedoresPendientesStatus = 'succeeded';
  });
  builder.addCase(updateProveedoresPendientes.pending, (state, _action) => {
    state.proveedoresPendientesStatus = 'pending';
  });
  builder.addCase(updateProveedoresPendientes.rejected, (state, _action) => {
    state.proveedoresPendientesStatus = 'failed';
  });
};
