import { EstadoDucaItem } from 'enum/EstadoDucaItem';

interface Props {
  key: string;
}

export const deleteRecord = <T extends Props>(key: string, data: T[]) => {
  const newData = [...data];
  const index = newData.findIndex(item => key === item.key);
  if (index > -1) {
    const record = newData[index];
    if (key === 'new') newData.splice(index, 1);
    else {
      const id = (record as any).id;
      if (id !== null && id !== undefined) {
        newData.splice(index, 1, { ...record, estado: EstadoDucaItem.Delete });
      } else {
        newData.splice(index, 1);
      }
    }
  }

  return newData;
};
