import { Form, FormInstance, Input, Modal, Spin } from 'antd';
import { CustomInput } from 'components/FormControls/CustomInput';
import { FC } from 'react';
import { UnidadMedidaNeta } from '../../../models/UnidadMedidaNeta';

const { Item } = Form;

interface IProps {
  visible: boolean;
  form: FormInstance;
  status: StatusApp.Status;
  elementoSeleccionado: UnidadMedidaNeta | undefined;
  onCreate: (values: UnidadMedidaNeta) => void;
  onUpdate: (values: UnidadMedidaNeta) => void;
  setCerrarModal: () => void;
}

export const ModalUpsert: FC<IProps> = ({
  visible,
  form,
  status,
  elementoSeleccionado,
  onCreate,
  onUpdate,
  setCerrarModal,
}) => {
  return (
    <Modal
      title={elementoSeleccionado ? 'Editar Unidad de Medida Neta' : 'Crear Unidad de Medida Neta'}
      visible={visible}
      onOk={
        elementoSeleccionado
          ? () => {
              form
                .validateFields()
                .then(values => onUpdate(values as UnidadMedidaNeta))
                .catch(error => console.log(error));
            }
          : () => {
              form
                .validateFields()
                .then(values => onCreate(values as UnidadMedidaNeta))
                .catch(error => console.log(error));
            }
      }
      onCancel={() => {
        form.resetFields();
        setCerrarModal();
      }}
    >
      <Spin spinning={status === 'pending' ? true : false}>
        <Form
          form={form}
          name='UnidadMedidaNeta'
          layout='vertical'
          defaultValue={elementoSeleccionado as any}
        >
          <CustomInput
            name='codigo'
            label='Código'
            type='normal'
            rules={[{ required: true }]}
            layout={{ span: 24 }}
          />
          <Item
            name='descripcion'
            label='Descripción'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name='nombre'
            label='Unidad'
            rules={[
              {
                required: true,
                message: 'Por favor ingresa un nombre para la unidad de medida neta.',
              },
            ]}
          >
            <Input />
          </Item>
        </Form>
      </Spin>
    </Modal>
  );
};
