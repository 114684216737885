import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { setError } from 'features/InitialData/store/initialDataStore';
import { setOrdenTrabajoDucaId } from 'features/OrdenDeTrabajo/store/store';
import { setOrdenTrabajoDetallesDucaId } from 'features/OrdenTrabajoDetalles/store/ordenTrabajoDetallesStore';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

interface ItemResponse {
  id: string;
  key: string;
}

interface ItemsResponse extends ItemResponse {
  documentos: ItemResponse[];
  ventajas: ItemResponse[];
  datosComplementarios: ItemResponse[];
}

export const updateDucaDetalles = createAsyncThunk(
  'DucaDetalles/updateDucaDetalles',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      if ((fetch.extra as any)?.isCreating) thunkApi.dispatch(setOrdenTrabajoDucaId(res.ducaId));
      else thunkApi.dispatch(setOrdenTrabajoDetallesDucaId(res.ducaId));

      thunkApi.dispatch(setError({ message: 'Detalles guardados correctamente.', error: false }));
    }

    return res;
  },
);

export const updateDucaDetallesReducer = (builder: ActionReducerMapBuilder<IDucaDetallesState>) => {
  builder.addCase(
    updateDucaDetalles.fulfilled,
    (
      state,
      action: PayloadAction<{
        ducaId: string;
        bultosLineas: ItemResponse[];
        documentos: ItemResponse[];
        datosComplementarios: ItemResponse[];
        items: ItemsResponse[];
      }>,
    ) => {
      const duca = state.ducaDetalles;
      const ducaId = action.payload.ducaId;
      const bultosLineas = action.payload.bultosLineas;
      const documentos = action.payload.documentos;
      const datosComplementarios = action.payload.datosComplementarios;
      const items = action.payload.items;

      if (!duca?.id && duca?.caratula) {
        state.ducaDetalles = { ...duca, id: ducaId };
      }

      /* Asignar  Id */
      bultosLineas.forEach(item => {
        const index = state.bultosLineas.findIndex(b => b.key === item.key);

        if (index > -1)
          state.bultosLineas.splice(index, 1, {
            ...state.bultosLineas[index],
            id: item.id,
            estado: EstadoDucaItem.Completed,
          });
      });

      documentos.forEach(item => {
        const index = state.documentos.findIndex(b => b.key === item.key);

        if (index > -1)
          state.documentos.splice(index, 1, {
            ...state.documentos[index],
            id: item.id,
            estado: EstadoDucaItem.Completed,
          });
      });

      datosComplementarios.forEach(item => {
        const index = state.datosComplementarios.findIndex(b => b.key === item.key);

        if (index > -1)
          state.datosComplementarios.splice(index, 1, {
            ...state.datosComplementarios[index],
            id: item.id,
            estado: EstadoDucaItem.Completed,
          });
      });

      items.forEach(item => {
        const index = state.items.findIndex(b => b.key === item.key);

        if (index > -1) {
          const itemDocumentos = state.items[index].documentos?.map(d => ({
            ...d,
            id: item.documentos?.find(i => i.key === d.key)?.id ?? d.id,
          }));

          const itemVentajas = state.items[index].ventajas?.map(d => ({
            ...d,
            id: item.ventajas?.find(i => i.key === d.key)?.id ?? d.id,
          }));

          const itemDatosComplementarios = state.items[index].datosComplementarios?.map(d => ({
            ...d,
            id: item.datosComplementarios?.find(i => i.key === d.key)?.id ?? d.id,
          }));

          state.items.splice(index, 1, {
            ...state.items[index],
            id: item.id,
            documentos: itemDocumentos,
            ventajas: itemVentajas,
            datosComplementarios: itemDatosComplementarios,
            estado: EstadoDucaItem.Completed,
          });
        }
      });

      /* Quitar Lineas eliminadas */
      state.bultosLineas = state.bultosLineas.filter(b => b.estado !== EstadoDucaItem.Delete);
      state.documentos = state.documentos.filter(b => b.estado !== EstadoDucaItem.Delete);
      state.datosComplementarios = state.datosComplementarios.filter(
        b => b.estado !== EstadoDucaItem.Delete,
      );
      state.items = state.items
        .filter(b => b.estado !== EstadoDucaItem.Delete)
        .map(i => ({
          ...i,
          documentos: i.documentos
            ?.filter(d => d.estado !== EstadoDucaItem.Delete)
            ?.map(d => ({ ...d, estado: EstadoDucaItem.Completed })),
          ventajas: i.ventajas
            ?.filter(v => v.estado !== EstadoDucaItem.Delete)
            ?.map(v => ({ ...v, estado: EstadoDucaItem.Completed })),
          datosComplementarios: i.datosComplementarios
            ?.filter(d => d.estado !== EstadoDucaItem.Delete)
            ?.map(d => ({ ...d, estado: EstadoDucaItem.Completed })),
        }));

      if (state.ducaDetalles) {
        state.ducaDetalles = {
          ...state.ducaDetalles,
          caratula: {
            ...state.ducaDetalles.caratula,
            ...state.caratula,
          },
          bultos: {
            ...state.ducaDetalles.bultos,
            ...state.bultos,
            bultosLineas: state.bultosLineas.filter(b => b.estado !== EstadoDucaItem.Delete),
          },
          documentos: state.documentos.filter(b => b.estado !== EstadoDucaItem.Delete),
          datosComplementarios: state.datosComplementarios.filter(
            b => b.estado !== EstadoDucaItem.Delete,
          ),
          items: state.items.filter(b => b.estado !== EstadoDucaItem.Delete),
        };
      }

      state.status = 'succeeded';
    },
  );
  builder.addCase(updateDucaDetalles.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(updateDucaDetalles.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
