import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Producto } from '../../../../../models/Producto';
import httpService from '../../../../../services/httpService';
import { IInicioState } from '../../inicioStore';

export const getProductosPendientes = createAsyncThunk('Inicio/GetProductoPendiente', httpService);

export const getProductosReducer = (builder: ActionReducerMapBuilder<IInicioState>) => {
  // Get Productos Pendientes
  builder.addCase(
    getProductosPendientes.fulfilled,
    (state, action: PayloadAction<Array<Producto>>) => {
      state.productosPendientes =
        action.payload.length > 0
          ? action.payload.map(p => ({
              ...p,
              codigoDeBarras: p.codigoDeBarras?.toLowerCase() === 'null' ? '' : p.codigoDeBarras,
              codigoFitosanitario:
                p.codigoFitosanitario?.toLowerCase() === 'null' ? '' : p.codigoFitosanitario,
            }))
          : action.payload;
      state.getProductosPendientesStatus = 'idle';
    },
  );

  builder.addCase(getProductosPendientes.pending, (state, _action) => {
    state.getProductosPendientesStatus = 'pending';
  });

  builder.addCase(getProductosPendientes.rejected, (state, _action) => {
    state.getProductosPendientesStatus = 'failed';
  });
};
