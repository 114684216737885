import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Pais } from 'models/Pais';
import httpService from 'services/httpService';
import { IPaisesState } from '../paisesStore';

export const getPaises = createAsyncThunk('Paises/GetAll', httpService);

export const getPaisesReducer = (builder: ActionReducerMapBuilder<IPaisesState>) => {
  builder.addCase(getPaises.fulfilled, (state, action: PayloadAction<Pais[]>) => {
    state.status = 'succeeded';
    state.data = action.payload;
  });
  builder.addCase(getPaises.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getPaises.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
