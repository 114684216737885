import { Button, Form } from 'antd';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { CustomInputNumber } from '../../FormControls/CustomInputNumber';
import { prorratearValoresTotales } from '../store/extraReducer/prorratearValoresTotales';

interface IFormValoresTotales {
  disabled?: boolean;
  ordenTrabajoId: string;
  creando: boolean;
}

export const FormValoresTotales = ({
  disabled = true,
  ordenTrabajoId,
  creando,
}: IFormValoresTotales) => {
  const customDispath = useCustomDispatch();
  const [form] = Form.useForm<{ flete: number; seguro: number; otrosGastos: number }>();
  const [disabledForm, setDisabledForm] = useState<boolean>(false);
  const {
    ordenDeTrabajoInCreation,
    valoresTotalesDetalles,
    valoresTotalesOrdenDeTrabajo,
    devasDetalles,
    devasOfOrdenDeTrabajo,
    prorratearValoresTotalesStatus,
  } = useAppSelector(state => state.devas);

  useEffect(() => {
    if (ordenDeTrabajoInCreation) {
      form.setFieldsValue({
        flete: valoresTotalesOrdenDeTrabajo?.flete,
        seguro: valoresTotalesOrdenDeTrabajo?.seguro,
        otrosGastos: valoresTotalesOrdenDeTrabajo?.otrosGastos,
      });
      setDisabledForm(!(devasOfOrdenDeTrabajo.length > 0));
    } else {
      form.setFieldsValue({
        flete: valoresTotalesDetalles?.flete,
        seguro: valoresTotalesDetalles?.seguro,
        otrosGastos: valoresTotalesDetalles?.otrosGastos,
      });
      setDisabledForm(!(devasDetalles.length > 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ordenDeTrabajoInCreation,
    valoresTotalesDetalles,
    valoresTotalesOrdenDeTrabajo,
    devasDetalles,
    devasOfOrdenDeTrabajo,
  ]);

  const onFinish = (values: { flete: number; seguro: number; otrosGastos: number }) => {
    customDispath({
      asyncThuckFuction: prorratearValoresTotales,
      endPoint: `/api/Devas/ProrratearValoresTotales/${ordenTrabajoId}`,
      method: 'POST',
      body: values,
      extra: { creando },
    });
  };

  return (
    <Form form={form} layout='inline' onFinish={onFinish}>
      <CustomInputNumber
        name='flete'
        type='normal'
        addonBefore={'flete'}
        disabled={disabledForm ?? disabled}
      />
      <CustomInputNumber
        name='seguro'
        type='normal'
        addonBefore={'seguro'}
        disabled={disabledForm ?? disabled}
      />
      <CustomInputNumber
        name='otrosGastos'
        type='normal'
        addonBefore={'otros gastos'}
        disabled={disabledForm ?? disabled}
      />
      <Form.Item style={{ marginRight: 0 }}>
        <Button
          type='primary'
          htmlType='submit'
          disabled={prorratearValoresTotalesStatus === 'pending' ?? disabledForm ?? disabled}
          loading={prorratearValoresTotalesStatus === 'pending'}
        >
          Prorratear
        </Button>
      </Form.Item>
    </Form>
  );
};
