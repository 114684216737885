import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EstadoEntidad } from 'enum/EstadoEntidad';
import { IDevaProductoDetalleRequest } from 'models/Deva';
import { Producto } from 'models/Producto';
import httpService from 'services/httpService';
import { IDevasState, ProductoValidado } from '../store';

export const validarProductosSinArchivo = createAsyncThunk(
  'Devas/ValidarProductosSinArchivo',
  httpService,
);

interface IResponse {
  completo: boolean;
  estado: EstadoEntidad;
  product: IDevaProductoDetalleRequest;
  coincidencias: Array<Producto>;
}

export const validarProductosSinArchivoReducer = (
  builder: ActionReducerMapBuilder<IDevasState>,
) => {
  builder.addCase(
    validarProductosSinArchivo.fulfilled,
    (state, action: PayloadAction<IResponse[]>) => {
      const productos = action.payload;

      if (state.devaInCreation)
        state.devaInCreation.productos = productos.map(item => ({ ...item.product }));

      if (state.devaInUpdate)
        state.devaInUpdate.productos = productos.map(item => ({ ...item.product }));

      state.productosValidados = productos
        .map(i => ({ ...i }))
        .map(producto => {
          const payload = action.payload.find(p => p.product.modelo === producto.product.modelo);

          return {
            ...payload,
            product: {
              ...payload?.product,
              sku: payload?.product.modelo,
              partidaArancelariaId: '',
              partidaArancelaria: '',
              designacionProductoId: '',
              marcaComercialId: '',
              paisOrigenId: '',
              unidadMedidaId: '',
              estadoMercanciaId: '',
              mostrarDescripcionConPresentacion: false,
            } as Producto,
          } as ProductoValidado;
        });
      state.productosValidadosStatus = 'succeeded';
    },
  );
  builder.addCase(validarProductosSinArchivo.pending, (state, _action) => {
    state.productosValidadosStatus = 'pending';
  });
  builder.addCase(validarProductosSinArchivo.rejected, (state, _action) => {
    state.productosValidadosStatus = 'failed';
  });
};
