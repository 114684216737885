import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { GenerateKey } from './generateKey';

interface Props {
  id: string;
  key: string;
}

export const saveRecord = <T extends Props>(key: string, record: T, data: T[]) => {
  const newData = [...data];
  const index = newData.findIndex(item => key === item.key);

  if (key === 'new' && index > -1) {
    newData.splice(index, 1, { ...record, key: GenerateKey(), estado: EstadoDucaItem.Create });
  } else {
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...record,
        estado: item.id?.length > 0 ? EstadoDucaItem.Update : EstadoDucaItem.Create,
      });
    } else {
      newData.push({ ...record });
    }
  }

  return newData;
};
