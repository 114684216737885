import { Button, Form, FormInstance, Row, Space, Typography } from 'antd';
import { setDevas } from 'components/Devas/store/store';
import { CustomDatepicker } from 'components/FormControls/CustomDatePicker';
import { DropDownGrid } from 'components/FormControls/CustomDropDown';
import { CustomInput } from 'components/FormControls/CustomInput';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { resetData, setOrdenTrabajoForm } from 'features/OrdenDeTrabajo/store/store';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { Aduana } from 'models/Aduana';
import { ClaseBulto } from 'models/ClaseBulto';
import { IOrdenDeTrabajoRequest } from 'models/OrdenTrabajo';
import { Pais } from 'models/Pais';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { crearOrdenTrabajo } from '../store/extraReducer/crearOrdenTrabajo';
import { updateOrdenDeTrabajo } from '../store/extraReducer/updateOrdenDeTrabajo';
import styles from './FormDatosGeneralesFlete.module.less';

const { Title } = Typography;

interface IFormDatosGeneralesFlete {
  form: FormInstance<IOrdenDeTrabajoRequest>;
}

export const FormDatosGeneralesFlete = ({ form }: IFormDatosGeneralesFlete) => {
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const { replace } = useHistory();
  const { data: importadores, status: importadoresStatus } = useAppSelector(
    state => state.importadores,
  );
  const { Aduanas, AduanasStatus, ClasesBulto, ClasesBultosStatus } = useAppSelector(
    state => state.initialData,
  );
  const { data: Paises, status: PaisesStatus } = useAppSelector(state => state.paises);
  const { idOrdenTrabajoCreada, crearOrdenTrabajoStatus } = useAppSelector(
    state => state.ordenDeTrabajo,
  );

  const layout = { span: 8 };
  const dropdownTextRules = [{ required: true, message: 'Campo requerido' }];
  const inputNumberRules = { required: true, message: 'Campo requerido' };

  const onCrearOrdenTrabajo = async () => {
    const values = await form.validateFields();

    customDispatch({
      asyncThuckFuction: crearOrdenTrabajo,
      endPoint: '/api/OrdenesDeTrabajo/CreateOrdenDeTrabajo',
      body: values,
    });
  };

  const onDetalles = () => {
    dispatch(resetData());
    dispatch(setOrdenTrabajoForm(undefined));
    dispatch(setDevas([]));
    replace(`/ordenDeTrabajo/${idOrdenTrabajoCreada}`);
  };

  const onFinish = async () => {
    const values = await form.validateFields();

    customDispatch({
      asyncThuckFuction: updateOrdenDeTrabajo,
      endPoint: `/api/OrdenesDeTrabajo/UpdateOrdenDeTrabajo/${idOrdenTrabajoCreada}`,
      body: values,
    });
  };

  return (
    <div className={styles.container}>
      <Row justify='space-between'>
        <Title level={4}>Datos Generales</Title>
        {crearOrdenTrabajoStatus === 'succeeded' ? (
          <Space>
            <Button onClick={onDetalles}>Ver Detalles</Button>
            <Button onClick={onFinish} type='primary'>
              Guardar y Limpiar
            </Button>
          </Space>
        ) : (
          <Button type='primary' onClick={onCrearOrdenTrabajo}>
            Guardar
          </Button>
        )}
      </Row>
      <Form
        form={form}
        layout='vertical'
        scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
      >
        <Row gutter={[24, 0]}>
          <CustomInput
            label='Folio Preimpreso'
            name='folioPreimpreso'
            layout={layout}
            rules={dropdownTextRules}
            required
          />
          <DropDownGrid
            data={importadores}
            label='Importador'
            name='importadorId'
            loading={importadoresStatus === 'pending'}
            rules={dropdownTextRules}
            layout={layout}
          />
          <DropDownGrid
            data={Aduanas}
            label='Aduana de Ingreso'
            name='aduanaDeIngresoId'
            renderText={(item: Aduana) => item.combinado}
            loading={AduanasStatus === 'pending'}
            rules={dropdownTextRules}
            layout={layout}
          />
          <DropDownGrid
            data={Aduanas}
            label='Aduana de Despacho'
            name='aduanaDeDespachoId'
            renderText={(item: Aduana) => item.combinado}
            loading={AduanasStatus === 'pending'}
            layout={layout}
            allowClear
          />
          <CustomInput label='Contenedor' name='contenedor' layout={layout} />
          <DropDownGrid
            data={ClasesBulto}
            label='Clase de Bulto'
            name='claseBultoId'
            loading={ClasesBultosStatus === 'pending'}
            renderText={(item: ClaseBulto) => `${item.codigo} - ${item.descripcion}`}
            layout={layout}
            allowClear
          />
        </Row>
        <Title level={4}>Información del Flete</Title>
        <Row gutter={[24, 0]}>
          <CustomInputNumber
            name='pesoBl'
            label='Peso BL (KG)'
            layout={layout}
            ruler={inputNumberRules}
            min={0}
          />
          <CustomInputNumber
            name='numBultos'
            label='No. de Bultos'
            layout={layout}
            ruler={inputNumberRules}
            min={0}
          />
          <CustomInputNumber
            name='totalPiezas'
            label='Total de Piezas'
            layout={layout}
            ruler={inputNumberRules}
            min={0}
            presicion={0}
          />
          {crearOrdenTrabajoStatus === 'succeeded' && (
            <>
              <CustomInputDisabled label='FOB' name='fob' />
              <CustomInputDisabled label='Flete' name='flete' />
              <CustomInputDisabled label='Seguro' name='seguro' />
              <CustomInputDisabled label='O.G' name='og' />
              <CustomInputDisabled label='CIF' name='cif' />
            </>
          )}
        </Row>
        <Title level={4}>Embarque y Exportación</Title>
        <Row gutter={[24, 0]}>
          <DropDownGrid
            data={Paises}
            label='País de Embarque'
            name='paisEmbarqueId'
            loading={PaisesStatus === 'pending'}
            renderText={(item: Pais) => item.combinado}
            layout={layout}
            allowClear
          />
          <DropDownGrid
            data={Paises}
            label='Pais Exportación'
            name='paisExportacionId'
            loading={PaisesStatus === 'pending'}
            renderText={(item: Pais) => item.combinado}
            layout={layout}
            allowClear
          />
          <CustomDatepicker
            label='Fecha de Exportación'
            name='fechaExportacion'
            required={false}
            layout={layout}
          />
        </Row>
      </Form>
    </div>
  );
};

const CustomInputDisabled = ({ name, label }: { name: string; label: string }) => (
  <CustomInputNumber name={name} label={label} ruler={{ required: true }} layout={{ span: 8 }} />
);
