import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { DucaDetalles, IDucaItemRequest } from 'models/Duca';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const getDucaItems = createAsyncThunk('DucaDetalles/GetDucaItems', httpService);

export const getDucaItemsReducer = (builder: ActionReducerMapBuilder<IDucaDetallesState>) => {
  builder.addCase(getDucaItems.fulfilled, (state, action: PayloadAction<IDucaItemRequest[]>) => {
    const itemsPayload = action.payload;

    const items =
      itemsPayload?.map(item => ({
        ...item,
        key: GenerateKey(),
        documentos: item?.documentos?.map(x => ({ ...x, key: GenerateKey() })) ?? [],
        ventajas: item?.ventajas?.map(x => ({ ...x, key: GenerateKey() })) ?? [],
        datosComplementarios:
          item?.datosComplementarios?.map(x => ({ ...x, key: GenerateKey() })) ?? [],
      })) ?? [];

    if (state.ducaDetalles) {
      state.ducaDetalles.items = items;
    } else {
      state.ducaDetalles = { items } as DucaDetalles;
    }

    state.items = items;
    state.statusItems = 'succeeded';
  });
  builder.addCase(getDucaItems.pending, (state, _action) => {
    state.statusItems = 'pending';
  });
  builder.addCase(getDucaItems.rejected, (state, _action) => {
    state.statusItems = 'failed';
  });
};
