import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { DesignacionProducto } from '../../../../models/DesignacionProducto';
import httpService from '../../../../services/httpService';
import { IDesignacionesProductosState } from '../designacionesProductosStore';

export const updateDesignacionProducto = createAsyncThunk(
  'DesignacionesProductos/Update',
  httpService,
);

export const updateDesignacionProductoReducer = (
  builder: ActionReducerMapBuilder<IDesignacionesProductosState>,
) => {
  builder.addCase(updateDesignacionProducto.fulfilled, (state, _action) => {
    const index = state.data.findIndex(
      designacionProducto => designacionProducto.id === state.elementoSeleccionado?.id,
    );

    state.crudStatus = 'succeeded';
    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as DesignacionProducto);
  });
  builder.addCase(updateDesignacionProducto.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updateDesignacionProducto.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
