import './globals/reportViewerDependencies';
import { Loader } from 'components/Loader/Loader';
import { CustomNotification } from 'components/Notification/Notification';
import ArchivoPreliquidacion from 'features/ArchivoPreliquidacion/ArchivoPreliquidacion';
import { DevaDetails } from 'features/DevaDetalles/DevaDetails';
import { DucaDetalles } from 'features/DucaDetalles/DucaDetalles';
import { InitialDataState, setError } from 'features/InitialData/store/initialDataStore';
import { FC, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import ProtectedRoute from './auth/protected-route';
import Layout from './components/Layout/Layout';
import { ClasificacionesFenag } from './features/ClasificacionFenag/ClasificacionesFenag';
import { ConversionesUnidadesDeMedidas } from './features/ConversionesUnidadesDeMedidas/ConversionesUnidadesDeMedidas';
import { DesignacionesProductos } from './features/DesignacionesProductos/DesignacionesProductos';
import { EstadosMercancias } from './features/EstadosMecaderias/EstadosMercancias';
import { Importadores } from './features/Importadores/Importadores';
import { Inicio } from './features/Inicio/Inicio';
import { Logout } from './features/Logout/Logout';
import { MarcasComerciales } from './features/MarcasComerciales/MarcasComerciales';
import { OrdenDeTrabajo } from './features/OrdenDeTrabajo/OrdenDeTrabajo';
import { OrdenTrabajoDetalles } from './features/OrdenTrabajoDetalles/OrdenTrabajoDetalles';
import { Paises } from './features/Paises/Paises';
import { PartidasArancelarias } from './features/PartidasArancelarias/PartidasArancelarias';
import { Productos } from './features/Productos/Productos';
import { Proveedores } from './features/Proveedores/Proveedores';
import { Tratados } from './features/Tratados/Tratados';
import { UnidadesMedidasNetas } from './features/UnidadesMedidasNetas/UnidadesMedidasNetas';
import { Usuarios } from './features/Usuarios/Usuarios';
import { DucaReportViewer } from 'features/Reportes/ReportViewer/DucaReportViewer';

const Notification = () => {
  const { error } = useSelector(InitialDataState);
  const dispatch = useDispatch();

  useEffect(() => {
    CustomNotification({
      error: Boolean(error.error),
      message: String(error.message),
      duration: error.duration ?? 5,
      onClose: () => {
        dispatch(setError({ error: false, message: '', duration: 0 }));
      },
    });
  }, [dispatch, error.error, error.message, error.duration]);
  return <></>;
};

const ShowLoader = () => {
  const { initialDataCompleted: loading } = useSelector(InitialDataState);

  return loading ? <Loader /> : <ProtectedRoute path='/' exact component={Inicio} />;
};

export const App: FC = () => {
  return (
    <Switch>
      <ProtectedRoute path='/logout' exact component={Logout} />
      <ProtectedRoute
        path='/ordenDeTrabajo/:ordenId/preliquidacion'
        exact
        component={ArchivoPreliquidacion}
      />
      <Fragment>
        <Layout>
          <Notification />
          <ShowLoader />
          <ProtectedRoute path='/ordenDeTrabajo' exact component={OrdenDeTrabajo} />
          <ProtectedRoute path='/ordenDeTrabajo/:ordenId' exact component={OrdenTrabajoDetalles} />
          <ProtectedRoute
            path='/ordenDeTrabajo/:ordenId/detallesdeva/:devaId/:nombreProveedor'
            exact
            component={DevaDetails}
          />
          <ProtectedRoute
            path='/ordenDeTrabajo/:ordenId/detallesduca'
            exact
            component={DucaDetalles}
          />
          <ProtectedRoute
            path='/ordenDeTrabajo/:ordenId/detallesduca/:ducaId'
            exact
            component={DucaDetalles}
          />
          <ProtectedRoute
            path='/ordenDeTrabajo/:ordenId/detallesduca/:ducaId/preliquidacion/imprimir'
            exact
            component={DucaReportViewer}
          />

          {/* Configuraciones */}
          <ProtectedRoute path='/productos' exact component={Productos} />
          <ProtectedRoute path='/importadores' exact component={Importadores} />
          <ProtectedRoute path='/proveedores' exact component={Proveedores} />
          <ProtectedRoute path='/partidasarancelarias' exact component={PartidasArancelarias} />
          <ProtectedRoute path='/marcascomerciales' exact component={MarcasComerciales} />
          <ProtectedRoute path='/paises' exact component={Paises} />
          <ProtectedRoute path='/tratados' exact component={Tratados} />
          <ProtectedRoute path='/unidadesmedidasnetas' exact component={UnidadesMedidasNetas} />
          <ProtectedRoute path='/estadosmercancias' exact component={EstadosMercancias} />
          <ProtectedRoute path='/clasificacionesfenag' exact component={ClasificacionesFenag} />
          <ProtectedRoute path='/designacionesproductos' exact component={DesignacionesProductos} />
          <ProtectedRoute path='/usuarios' exact component={Usuarios} />
          <ProtectedRoute
            path='/conversionesunidades'
            exact
            component={ConversionesUnidadesDeMedidas}
          />
        </Layout>
      </Fragment>
    </Switch>
  );
};
