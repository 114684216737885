import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IDucaDocumentoRequest } from 'models/Duca';

export const TablaDocumentos = ({ data }: { data: IDucaDocumentoRequest[] }) => {
  const Columns: ColumnsType<IDucaDocumentoRequest> = [
    {
      title: 'Código Documento',
      dataIndex: 'codigo',
    },
    { title: 'Descripción', dataIndex: 'descripcion', ellipsis: true },
    {
      title: 'Referencia',
      dataIndex: 'referencia',
      ellipsis: true,
    },
    {
      title: 'Presencia',
      dataIndex: 'presencia',
      render: (_text: string, record: IDucaDocumentoRequest) => {
        return record.presencia ? 'Sí' : 'No';
      },
    },
  ];

  return <Table columns={Columns} dataSource={data} pagination={false} bordered size='small' />;
};
