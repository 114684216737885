import { Button, Col, Drawer, Form, Row, Spin } from 'antd';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { IOrdenDeTrabajoRequest } from 'models/OrdenTrabajo';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { updateOrdenTrabajoDetalle } from '../store/extraReducers/updateOrdenTrabajo';
import { FormDatosGeneralesFlete } from './FormDatosGeneralesFlete/FormDatosGeneralesFlete';

interface IProps {
  visible: boolean;
  setCerrarModal: () => void;
}

export const DrawerOrdenTrabajoInfo = ({ visible, setCerrarModal }: IProps) => {
  const customDispatch = useCustomDispatch();
  const [form] = Form.useForm<IOrdenDeTrabajoRequest>();
  const { ordenId } = useParams<{ ordenId: string }>();
  const { data, status } = useAppSelector(state => state.ordenTrabajoDetalles);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...data,
        pesoBl: data?.pesoBl,
        numBultos: data?.numBultos,
        totalPiezas: data?.totalPiezas,
        fechaExportacion: data?.fechaExportacion ? (moment(data?.fechaExportacion) as any) : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onSave = async () => {
    const values = await form.validateFields();
    customDispatch({
      asyncThuckFuction: updateOrdenTrabajoDetalle,
      endPoint: `/api/OrdenesDeTrabajo/UpdateOrdenDeTrabajo/${ordenId}`,
      body: values,
    });
  };

  return (
    <Drawer
      title={'Editar Orden de Trabajo'}
      width={600}
      onClose={setCerrarModal}
      visible={visible}
      bodyStyle={{ paddingBottom: 0 }}
      maskClosable={false}
      footer={
        <Row justify='end'>
          <Button type='primary' onClick={onSave} style={{ marginRight: 8 }}>
            Guardar
          </Button>
        </Row>
      }
    >
      <Spin spinning={status === 'pending'}>
        <Col>
          <FormDatosGeneralesFlete form={form} />
        </Col>
      </Spin>
    </Drawer>
  );
};
