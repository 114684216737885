import { Form, FormInstance, Modal, Select, Spin } from 'antd';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { FC } from 'react';
import { ConversionUnidadDeMedida } from '../../../models/ConversionUnidadDeMedida';
import { UnidadMedidaNeta } from '../../../models/UnidadMedidaNeta';

const { Item } = Form;
const { Option } = Select;

interface IProps {
  visible: boolean;
  form: FormInstance;
  status: StatusApp.Status;
  elementoSeleccionado: ConversionUnidadDeMedida | undefined;
  unidadesMedidasNetas: Array<UnidadMedidaNeta>;
  onCreate: (values: ConversionUnidadDeMedida) => void;
  onUpdate: (values: ConversionUnidadDeMedida) => void;
  setCerrarModal: () => void;
}

export const ModalUpsert: FC<IProps> = ({
  visible,
  form,
  status,
  elementoSeleccionado,
  unidadesMedidasNetas,
  onCreate,
  onUpdate,
  setCerrarModal,
}) => {
  return (
    <Modal
      title={
        elementoSeleccionado ? 'Editar Designación de Producto' : 'Crear Designación de Producto'
      }
      visible={visible}
      onOk={
        elementoSeleccionado
          ? () => {
              form
                .validateFields()
                .then(values => onUpdate(values as ConversionUnidadDeMedida))
                .catch(error => console.log(error));
            }
          : () => {
              form
                .validateFields()
                .then(values => onCreate(values as ConversionUnidadDeMedida))
                .catch(error => console.log(error));
            }
      }
      onCancel={() => {
        form.resetFields();
        setCerrarModal();
      }}
    >
      <Spin spinning={status === 'pending' ? true : false}>
        <Form
          form={form}
          name='ConversionUnidadDeMedida'
          layout='vertical'
          defaultValue={elementoSeleccionado as any}
        >
          <Item
            name='unidadMedidaOrigenId'
            label='Unidad Medida Origen'
            rules={[
              {
                required: true,
                message: 'Por favor seleccione una unidad de medida.',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Selecciona una unidad de medida'
              optionFilterProp='children'
              disabled={elementoSeleccionado ? true : false}
            >
              {unidadesMedidasNetas.map(unidad => (
                <Option key={unidad.id} value={unidad.id}>
                  {`${unidad.nombre} - ${unidad.descripcion}`}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name='unidadMedidaDestinoId'
            label='Unidad Medida Destino'
            rules={[
              {
                required: true,
                message: 'Por favor seleccione una unidad de medida.',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Selecciona una unidad de medida'
              optionFilterProp='children'
              disabled={elementoSeleccionado ? true : false}
            >
              {unidadesMedidasNetas.map(unidad => (
                <Option key={unidad.id} value={unidad.id}>
                  {`${unidad.nombre} - ${unidad.descripcion}`}
                </Option>
              ))}
            </Select>
          </Item>
          <CustomInputNumber
            name='factor'
            label='Factor'
            ruler={{ required: true }}
            type='normal'
          />
        </Form>
      </Spin>
    </Modal>
  );
};
