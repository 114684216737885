import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ConversionUnidadDeMedida } from '../../../../models/ConversionUnidadDeMedida';
import httpService from '../../../../services/httpService';
import { IConversionUnidadDeMedidaState } from '../conversionesUnidadesDeMedidasStore';

export const updateConversionUnidadDeMedida = createAsyncThunk(
  'ConversionUnidadesDeMedidas/Update',
  httpService,
);

export const updateConversionUnidadDeMedidaReducer = (
  builder: ActionReducerMapBuilder<IConversionUnidadDeMedidaState>,
) => {
  builder.addCase(updateConversionUnidadDeMedida.fulfilled, (state, _action) => {
    const index = state.data.findIndex(
      designacionProducto => designacionProducto.id === state.elementoSeleccionado?.id,
    );

    state.crudStatus = 'succeeded';
    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as ConversionUnidadDeMedida);
  });
  builder.addCase(updateConversionUnidadDeMedida.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updateConversionUnidadDeMedida.rejected, (state, action) => {
    state.crudStatus = 'failed';
    state.error = action.error.message;
  });
};
