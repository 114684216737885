import { Button } from 'antd';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

interface Iprops {
  text: string;
}

export const LogoutButton: FC<Iprops> = ({ text }) => {
  const history = useHistory();
  return (
    <Button block type='primary' onClick={() => history.push('/logout')}>
      {text}
    </Button>
  );
};
