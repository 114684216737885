import { Col, Divider, Form, FormInstance, Row, Modal } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { DropDownGrid } from 'components/FormControls/CustomDropDown';
import { CustomInput } from 'components/FormControls/CustomInput';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { CustomSwitch } from 'components/FormControls/CustomSwitch';
import { CustomTextarea } from 'components/FormControls/CustomTextarea';
import { InputDisabled } from 'components/FormControls/InputDisabled';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { setCondicionEntregaId, setSaveDucaItems } from 'features/DucaDetalles/store/store';
import { useDebounce } from 'hooks/useDebounce';
import { Incoterm } from 'models/Incoterm';
import { DireccionProveedor } from 'models/Proveedor';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';

interface ICaratulaProps {
  form: FormInstance;
  onChangeModalidadEspecial: (value: SelectValue) => void;
}

export const Caratula = ({ form, onChangeModalidadEspecial }: ICaratulaProps) => {
  const [prorratear, setProrratear] = useState<boolean>(false);
  const [incotermSeleccionado, setIncotermSeleccionado] = useState<Incoterm | undefined>(undefined);
  const [disabledTipoCambioDivisaFact, setDisabledTipoCambioDivisaFact] = useState<boolean>(true);
  const [direccionesProveedor, setDireccionesProveedor] = useState<DireccionProveedor[]>([]);
  const Aduanas = useAppSelector(state => state.initialData.Aduanas);
  const Paises = useAppSelector(state => state.paises.data);
  const FormaPago = useAppSelector(state => state.initialData.FormasDePago);
  const Incoterms = useAppSelector(state => state.initialData.Incoterm);
  const Monedas = useAppSelector(state => state.initialData.MonedasDePago);
  const {
    RegimenesAduaneros,
    RegimenesAduanerosStatus,
    DepositosAduaneros,
    DepositoAduaneroStatus,
    ModalidadesEspeciales,
    ModalidadesEspecialesStatus,
    RutasTransito,
    RutasTransitoStatus,
    MotivoOperacionSuspensiva,
    MotivoOperacionSuspensivaStatus,
    DepositosAduanasTransito,
    DepositoAduanaTransitoStatus,
    ducaDetalles,
    status,
    items,
  } = useAppSelector(state => state.ducaDetalles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (ducaDetalles?.caratula) {
      setProrratear(ducaDetalles.caratula.prorratear);
      setIncotermSeleccionado(
        Incoterms.find(item => item.id === ducaDetalles.caratula.condicionEntregaId),
      );
      onChangeDivisaFactura(ducaDetalles.caratula.divisaFacturaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ducaDetalles]);

  // filtrar direcciones proveedores
  useEffect(() => {
    if (ducaDetalles?.caratula) {
      setDireccionesProveedor(
        ducaDetalles.direccionesProveedores.filter(
          _ => _.proveedorId === ducaDetalles.caratula.proveedorId,
        ),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ducaDetalles]);

  const dropdownRules = [{ required: true, message: 'Este campo es requerido' }];

  const ModenedasFiltradas = Monedas.filter(
    x => x.codigo === 'USD' || x.codigo === 'EUR' || x.codigo === 'HNL',
  );

  const onProveedorChange = (id: SelectValue) => {
    form.setFieldsValue({
      domicilioProveedor: String(ducaDetalles?.proveedores.find(x => x.id === id)?.direccion ?? ''),
      observaciones: `Contenedor : ${
        ducaDetalles?.contenedor
      }, Proveedores : ${ducaDetalles?.proveedores
        .filter(x => x.id !== id)
        .map(x => x.nombre)
        .join(', ')}`,
    });

    // filtar direcciones por proveedor
    if (ducaDetalles) {
      form.setFieldsValue({ direccionProveedorId: '' });
      setDireccionesProveedor(
        ducaDetalles.direccionesProveedores.filter(_ => _.proveedorId === id),
      );
    }
  };

  const onChangeIncoterm = (id: string) => {
    const incoterm = Incoterms.find(i => i.id === id);
    if (incoterm) {
      if (!incoterm.fleteDeclarado) form.setFieldsValue({ totalFlete: 0 });
      else form.setFieldsValue({ totalFlete: ducaDetalles?.caratula?.totalFlete });

      if (!incoterm.seguroDeclarado) form.setFieldsValue({ totalSeguro: 0 });
      else form.setFieldsValue({ totalSeguro: ducaDetalles?.caratula?.totalSeguro });
    }

    setIncotermSeleccionado(incoterm);
    dispatch(setCondicionEntregaId(id));
  };

  const onChangeDivisaFactura = (value: any) => {
    const moneda = ModenedasFiltradas.find(x => x.id === value);
    if (moneda && moneda.codigo !== 'USD') {
      setDisabledTipoCambioDivisaFact(false);
    } else {
      setDisabledTipoCambioDivisaFact(true);
    }
  };

  const onChangeTipoCambioDivisaFactura = useDebounce((value: string) => {
    const tipoCambioDivisaFactura = Number(value);
    const divisaFactura = Monedas.find(m => m.id === form.getFieldValue('divisaFacturaId'));
    if (items.length > 0) {
      Modal.confirm({
        title: 'Recalcular FOB Otra Moneda en los Items?',
        content: '',
        okText: 'Ok',
        onOk() {
          const newItems = items
            .filter(item => item.estado !== EstadoDucaItem.Delete)
            .map(item => {
              return {
                ...item,
                fobOtraMoneda:
                  divisaFactura?.codigo === 'USD' && tipoCambioDivisaFactura === 0
                    ? item.importeFOB
                    : item.importeFOB * tipoCambioDivisaFactura,
              };
            });

          dispatch(setSaveDucaItems(newItems));
        },
      });
    }
  });

  return (
    <Form form={form} layout='vertical'>
      <Divider style={{ marginTop: 0 }}>Caratula</Divider>
      <Row gutter={[24, 24]}>
        <CustomInputDisabled name='declarante' label='Declarante' />
        <CustomInputDisabled name='aduanaDespacho' label='Aduana de Despacho' />
        <DropDownGrid
          name='regimenAduaneroId'
          label='Régimen Aduanero'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={RegimenesAduaneros}
          loading={RegimenesAduanerosStatus === 'pending'}
          rules={dropdownRules}
          layout={{ span: 12 }}
          disabled={ducaDetalles?.ordenCerrada}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInputDisabled name='rtnImportador' label='RTN Importador/Exportador' />
        <CustomInputDisabled name='importador' label='Importador - Exportador' />
        <CustomInputDisabled name='rtnAgenciaAduanera' label='RTN Agencia Aduanera' />
        <CustomInputDisabled name='agenciaAduanera' label='Agencia Aduanera' />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInput name='idManifiestoEntregaRapida' label='ID Manifiesto de Entrega Rápida' />
        <DropDownGrid
          name='proveedorId'
          label='Nombre Proveedor/Destinatario'
          data={ducaDetalles?.proveedores ?? []}
          loading={status === 'pending'}
          rules={dropdownRules}
          onChange={value => onProveedorChange(value)}
          disabled={ducaDetalles?.ordenCerrada}
        />
        <CustomInput name='contratoProveedorDestinatario' label='Contrato Proveedor/Destinatario' />
        <DropDownGrid
          index='direccion'
          name='direccionProveedorId'
          label='Domicilio del Proveedor'
          data={direccionesProveedor}
          loading={status === 'pending'}
          rules={dropdownRules}
          disabled={ducaDetalles?.ordenCerrada}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInputDisabled name='numeroPreImpreso' label='Número PreImpreso' />
        <CustomInput name='entidadMediacion' label='Entidad Mediación' />
        <DropDownGrid
          name='depositoAlmacenamientoId'
          label='Depósito Almacenamiento'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={DepositosAduaneros}
          loading={DepositoAduaneroStatus === 'pending'}
          disabled={ducaDetalles?.ordenCerrada}
        />
        <CustomInputDisabled name='aduanaIngresoSalida' label='Aduana Ingreso/Salida' />
      </Row>
      <Row gutter={[24, 24]}>
        <DropDownGrid
          name='paisOrigenId'
          label='País de Origen'
          propSelectName='combinado'
          data={Paises}
          rules={dropdownRules}
          disabled={ducaDetalles?.ordenCerrada}
        />
        <DropDownGrid
          name='paisProcedenciaDestinoId'
          label='País Procedencia/Destino'
          propSelectName='combinado'
          data={Paises}
          rules={dropdownRules}
          disabled={ducaDetalles?.ordenCerrada}
        />
        <DropDownGrid
          name='formaPagoId'
          label='Forma de Pago'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={FormaPago}
          rules={dropdownRules}
          disabled={ducaDetalles?.ordenCerrada}
        />
        <DropDownGrid
          name='condicionEntregaId'
          label='Condición de Entrega'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={Incoterms}
          rules={dropdownRules}
          onChange={value => onChangeIncoterm(value?.toString() ?? '')}
          disabled={ducaDetalles?.ordenCerrada}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <DropDownGrid
          name='aduanaTransitoDestinoId'
          label='Aduana Tránsito de Destino'
          renderText={item => `${item.combinado}`}
          data={Aduanas}
          disabled={ducaDetalles?.ordenCerrada}
        />
        <DropDownGrid
          name='modalidadEspecialId'
          label='Modalidad Especial'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={ModalidadesEspeciales}
          loading={ModalidadesEspecialesStatus === 'pending'}
          rules={dropdownRules}
          onChange={onChangeModalidadEspecial}
          disabled={ducaDetalles?.ordenCerrada}
        />
        <DropDownGrid
          name='depositoAduanaTransitoId'
          label='Depósito	Aduana Tránsito'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={DepositosAduanasTransito}
          loading={DepositoAduanaTransitoStatus === 'pending'}
          disabled={ducaDetalles?.ordenCerrada}
        />
        <CustomInputNumber
          name='plazoDiasMeses'
          label='Plazo Días - Meses'
          type='full'
          initialValue={0}
          min={0}
          disabled={ducaDetalles?.ordenCerrada}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <DropDownGrid
          name='rutaTransitoId'
          label='Ruta del Tránsito'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={RutasTransito}
          loading={RutasTransitoStatus === 'pending'}
          layout={{ span: 12 }}
          disabled={ducaDetalles?.ordenCerrada}
        />
        <DropDownGrid
          name='motivoOperacionSuspensivaId'
          label='Motivo Oper. Suspensiva'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={MotivoOperacionSuspensiva}
          loading={MotivoOperacionSuspensivaStatus === 'pending'}
          layout={{ span: 12 }}
          disabled={ducaDetalles?.ordenCerrada}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomTextarea
          name='observaciones'
          label='Observaciones'
          layout={{ span: 24 }}
          disabled={ducaDetalles?.ordenCerrada}
        />
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <Row gutter={[24, 24]}>
        <CustomInputDisabled name='claseBultos' label='Clase de Bultos' />
        <CustomInputNumber
          name='cantidadBultos'
          label='Cantidad de Bultos'
          ruler={{ required: true }}
          disabled
        />
        <CustomInputNumber
          name='pesoBrutoBultos'
          label='Peso Bruto de los Bultos'
          ruler={{ required: true }}
          disabled
        />
        <CustomSwitch
          name='prorratear'
          label='¿Prorratear?'
          layout={{ span: 3 }}
          initialValue={prorratear}
          onChange={setProrratear}
          disabled={ducaDetalles?.ordenCerrada}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInputNumber
          name='totalFactura'
          label='Total Factura'
          ruler={{ required: true }}
          disabled
        />
        <CustomInputNumber
          name='totalOtrosGastos'
          label='Total Otros Gastos'
          ruler={{ required: true }}
          disabled
        />
        <CustomInputNumber
          name='totalSeguro'
          label='Total Seguro'
          ruler={{ required: true }}
          disabled
        />
        <CustomInputNumber
          name='totalFlete'
          label='Total Flete'
          ruler={{ required: true }}
          disabled
        />
      </Row>
      <Row gutter={[24, 24]}>
        <DropDownGrid
          name='divisaFacturaId'
          label='Divisa Factura'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={ModenedasFiltradas}
          rules={dropdownRules}
          onChange={onChangeDivisaFactura}
          disabled={ducaDetalles?.ordenCerrada}
        />
        <CustomInputNumber
          name='tipoCambioDivisaFact'
          label='Tipo Cambio Divisa Fact'
          initialValue={0}
          min={0}
          disabled={ducaDetalles?.ordenCerrada || disabledTipoCambioDivisaFact}
          onChange={(value: number) => onChangeTipoCambioDivisaFactura(value.toString())}
        />
        <DropDownGrid
          disabled={
            ducaDetalles?.ordenCerrada
              ? ducaDetalles.ordenCerrada
              : !incotermSeleccionado?.seguroDeclarado
          }
          name='divisaSeguroId'
          label='Divisa Seguro'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={ModenedasFiltradas}
        />
        <DropDownGrid
          disabled={
            ducaDetalles?.ordenCerrada
              ? ducaDetalles.ordenCerrada
              : !incotermSeleccionado?.fleteDeclarado
          }
          name='divisaFleteId'
          label='Divisa Flete'
          propSelectName='descripcion'
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          data={ModenedasFiltradas}
        />
      </Row>
    </Form>
  );
};

const CustomInputDisabled = ({
  name,
  label,
  layout,
}: {
  name: string;
  label: string;
  layout?: object;
}) => (
  <Col span={6} {...layout}>
    <InputDisabled name={name} label={label} required />
  </Col>
);
