import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ClasificacionFenag } from '../../../../models/ClasificacionFenag';
import httpService from '../../../../services/httpService';
import { IClasificacionesFenagState } from '../clasificacionesFenagStore';

export const createClasificacionFenag = createAsyncThunk(
  'ClasificacionesFenag/Create',
  httpService,
);

export const createClasificacionFenagReducer = (
  builder: ActionReducerMapBuilder<IClasificacionesFenagState>,
) => {
  builder.addCase(createClasificacionFenag.fulfilled, (state, action) => {
    state.crudStatus = 'succeeded';
    state.data.unshift({
      ...state.elementoTemporal,
      id: action.payload,
    } as ClasificacionFenag);
  });
  builder.addCase(createClasificacionFenag.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createClasificacionFenag.rejected, (state, action) => {
    state.crudStatus = 'failed';
    state.error = action.error.message;
  });
};
