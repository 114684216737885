import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Usuario } from '../../../models/Usuario';
import { RootState } from '../../../store/store';
import { createUsuarioReducer } from './extraReducer/createUsuario';
import { deleteUsuarioReducer } from './extraReducer/deleteUser';
import { getUsuariosReducer } from './extraReducer/getUsuarios';
import { updateUsuarioReducer } from './extraReducer/updateUsuario';

export interface IUsuariosState {
  data: Array<Usuario>;
  status: StatusApp.Status;
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado: Usuario | undefined;
  elementoTemporal: Usuario | undefined;
  apiURL: string;
}

const initialState: IUsuariosState = {
  data: [],
  status: 'idle',
  crudStatus: 'idle',
  modalVisible: false,
  elementoSeleccionado: undefined,
  elementoTemporal: undefined,
  apiURL: '/api/Usuarios',
};

export const usuariosSlice = createSlice({
  name: 'Usuarios',
  initialState,
  reducers: {
    toggleSeleccionarUsuario: (state, action: PayloadAction<Usuario | undefined>) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setUsuarioTemporal: (state, action: PayloadAction<Usuario>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
  },
  extraReducers: builder => {
    createUsuarioReducer(builder);
    getUsuariosReducer(builder);
    updateUsuarioReducer(builder);
    deleteUsuarioReducer(builder);
  },
});

export const {
  toggleSeleccionarUsuario,
  toggleModalVisible,
  setUsuarioTemporal,
  setCrudStatusIdle,
} = usuariosSlice.actions;

export const UsuariosState = (state: RootState) => state.usuarios;

export default usuariosSlice.reducer;
