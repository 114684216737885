import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/InitialData/store/initialDataStore';
import httpService, { IUseFetch, ThunckApi } from '../../../../../services/httpService';
import { IInicioState } from '../../inicioStore';

export const deleteProveedorPendiente = createAsyncThunk(
  'Inicio/DeleteProveedorPendiente',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      if (document.fullscreenElement === null) {
        thunkApi.dispatch(setError({ message: 'Proveedor eliminado', error: false }));
      }
    }
    return res;
  },
);

export const deleteProveedorPendienteReducer = (builder: ActionReducerMapBuilder<IInicioState>) => {
  // Delete Proveedor Pendiente
  builder.addCase(deleteProveedorPendiente.fulfilled, (state, _action) => {
    const proveedor = state.proveedorPendienteSeleccionado;
    const index = state.proveedoresPendientes.findIndex(p => p.id === proveedor?.id);
    state.proveedoresPendientes.splice(index, 1);
    state.proveedorPendienteSeleccionado = undefined;
    state.proveedoresPendientesStatus = 'succeeded';
  });
  builder.addCase(deleteProveedorPendiente.pending, (state, _action) => {
    state.proveedoresPendientesStatus = 'pending';
  });
  builder.addCase(deleteProveedorPendiente.rejected, (state, _action) => {
    state.proveedoresPendientesStatus = 'failed';
  });
};
