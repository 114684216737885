import { Row } from 'antd';
import { DropDownGrid } from 'components/FormControls/CustomDropDown';
import { CustomInput } from 'components/FormControls/CustomInput';
import { GreenTitle } from 'components/FormControls/GreenTitle';
import { EstadoOrdenTrabajo } from 'enum/EstadoOrdenTrabajo';
import { useAppSelector } from 'store/hooks';

export const InformacionGeneralIntermediario = () => {
  const paises = useAppSelector(state => state.paises.data);
  const tipoIntermediario = useAppSelector(state => state.initialData.TiposIntermediario).map(
    item => ({ id: item.id, nombre: `${item.codigo} - ${item.descripcion}` }),
  );

  const disabled = useAppSelector(
    state => state.devaDetalles.devaDetalles?.ordenDeTrabajo?.estado === EstadoOrdenTrabajo.Cerrada,
  );

  return (
    <section>
      <GreenTitle text='D. Información General del Intermediario' />
      <Row gutter={[24, 24]}>
        <CustomInput
          layout={{ span: 24 }}
          label='12. Nombre o Razón Social'
          name='nombre'
          required={false}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInput
          layout={{ span: 12 }}
          label='13. Dirección'
          name='direccion'
          required={false}
        />
        <CustomInput label='Ciudad' name='ciudad' required={false} />
        <DropDownGrid
          data={paises}
          propSelectName='combinado'
          label='País'
          name='paisId'
          required={false}
          disabled={disabled}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInput
          layout={{ span: 12 }}
          label='Correo Electrónico'
          name='correo'
          required={false}
          type='email'
        />
        <CustomInput label='Teléfono' name='telefono' required={false} />
        <CustomInput label='Fax' name='fax' required={false} />
      </Row>
      <Row gutter={[24, 24]}>
        <DropDownGrid
          data={tipoIntermediario}
          propSelectName='nombre'
          label='14. Tipo Intermediario'
          name='tipoIntermediarioId'
          required={false}
          disabled={disabled}
        />
        <CustomInput
          label='Otro Tipo Intermediario'
          name='otroTipoIntermediario'
          required={false}
        />
      </Row>
    </section>
  );
};
