import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IConversionUnidadDeMedidaState } from '../conversionesUnidadesDeMedidasStore';

export const deleteConversionUnidadDeMedida = createAsyncThunk(
  'ConversionUnidadesDeMedidas/Delete',
  httpService,
);

export const deleteConversionUnidadDeMedidaReducer = (
  builder: ActionReducerMapBuilder<IConversionUnidadDeMedidaState>,
) => {
  builder.addCase(deleteConversionUnidadDeMedida.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.data.findIndex(
      designacionProducto => designacionProducto.id === state.elementoSeleccionado?.id,
    );
    state.data.splice(index, 1);
  });
  builder.addCase(deleteConversionUnidadDeMedida.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deleteConversionUnidadDeMedida.rejected, (state, action) => {
    state.crudStatus = 'failed';
    state.error = action.error.message;
  });
};
