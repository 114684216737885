import { Form, Input, Modal, Select, Table } from 'antd';
import { CustomNotification } from 'components/Notification/Notification';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import {
  setDatosComplementariosEditingKey,
  setDeleteDucaDatoComplementario,
  setSaveDucaDatoComplementario,
  setSaveDucaItems,
} from 'features/DucaDetalles/store/store';
import { IDucaDatoComplementarioRequest } from 'models/Duca';
import { PapierError } from 'models/PapierException';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { Columns, IDatoComplementarioColumnType } from './Columns';

const { Item } = Form;
const { Option } = Select;

interface IListadoDatosComplementariosProps {
  onCancelEditMode?: () => void;
}

export const ListadoDatosComplementarios = ({
  onCancelEditMode,
}: IListadoDatosComplementariosProps) => {
  const dispatch = useDispatch();
  const {
    datosComplementarios,
    TipoDatosComplementarios,
    datosComplementariosEditingKey,
    ducaDetalles,
    items,
    condicionEntregaId,
    itemStatus,
  } = useAppSelector(state => state.ducaDetalles);
  const Incoterms = useAppSelector(state => state.initialData.Incoterm);
  const [formComplementarios] = Form.useForm<IDucaDatoComplementarioRequest>();

  const isEditing = (values: IDucaDatoComplementarioRequest) =>
    values.key === datosComplementariosEditingKey;

  const onSave = async (key: string) => {
    try {
      const row = await formComplementarios.validateFields();
      row.datoComplementarioId =
        TipoDatosComplementarios.find(b => b.codigo === row.codigo)?.id ?? '';

      if (row.codigo === 'C300' && isNaN(Number(row.valor)))
        throw new PapierError('El valor debe ser un número');

      if (
        datosComplementarios.some(
          b => b.codigo === row.codigo && b.key !== key && b.estado !== EstadoDucaItem.Delete,
        )
      )
        throw new PapierError('Ya existe un dato complementario con ese código');

      dispatch(setSaveDucaDatoComplementario({ record: row, key }));
      setEditingKey('');
      comprobarRecalculoTributos(row.codigo, row.valor, 'Save');
    } catch (error) {
      if (error instanceof PapierError) {
        CustomNotification({
          error: true,
          message: error.message,
          duration: 5,
          onClose: () => {},
        });
      } else {
        console.log('Validate Failed:', error);
      }
    }
  };

  const onEdit = (value: Partial<IDucaDatoComplementarioRequest> & { key: React.Key }) => {
    formComplementarios.setFieldsValue({
      datoComplementarioId: '',
      codigo: '',
      descripcion: '',
      valor: '',
      ...value,
    });
    setEditingKey(value.key);
    onCancelEditMode?.();
  };

  const onDelete = (key: string) => {
    const datoComplementario = datosComplementarios.find(x => x.key === key);
    dispatch(setDeleteDucaDatoComplementario(key));
    onCancelEditMode?.();
    comprobarRecalculoTributos(datoComplementario?.codigo ?? '', '', 'Delete');
  };

  const onCancel = () => {
    if (datosComplementarios.some(d => d.key === 'new'))
      dispatch(setDeleteDucaDatoComplementario('new'));

    setEditingKey('');
  };

  const setEditingKey = (key: string) => {
    dispatch(setDatosComplementariosEditingKey(key));
  };

  const comprobarRecalculoTributos = (codigo: string, valor: string, type: 'Save' | 'Delete') => {
    if (items.length > 0) {
      const incotermId =
        condicionEntregaId.length > 0
          ? condicionEntregaId
          : ducaDetalles?.caratula.condicionEntregaId;
      const incoterm = Incoterms.find(i => i.id === incotermId);
      const validationDatoComplementario = codigo === 'C300' || codigo === 'C305';
      const tasaCambioDolar = ducaDetalles?.tasaCambioDolar ?? 0;
      let declararCIFImponible = false;

      if (incoterm && validationDatoComplementario) {
        Modal.confirm({
          title: 'Desea recalcular los tributos?',
          content: '',
          okText: 'Recalcular',
          onOk() {
            if (type === 'Save') {
              const datoComplementarioC300Valor =
                codigo === 'C300'
                  ? Number(valor)
                  : datosComplementarios.find(x => x.codigo === 'C300')?.valor;
              const datoComplementarioC305Valor =
                codigo === 'C305'
                  ? valor
                  : datosComplementarios.find(x => x.codigo === 'C305')?.valor;

              if (incoterm && (datoComplementarioC300Valor || datoComplementarioC305Valor)) {
                declararCIFImponible = true;
              }

              const totalFlete = ducaDetalles?.caratula.totalFlete ?? 0;

              if (declararCIFImponible) {
                const newItems = items
                  .filter(i => i.estado !== EstadoDucaItem.Delete)
                  .map(item => {
                    const factor = item.importeFOB / Number(ducaDetalles?.totalFOB);
                    const isvPorcentaje = item.isvPorcentaje / 100;
                    let newFlete = 0;
                    let Isv = 0;

                    if (incoterm.fleteDeclarado && datoComplementarioC305Valor !== undefined) {
                      newFlete = factor * (totalFlete * 0.25);
                    }

                    if (
                      !incoterm.fleteDeclarado &&
                      datoComplementarioC305Valor !== undefined &&
                      datoComplementarioC300Valor !== undefined
                    ) {
                      newFlete = factor * (Number(datoComplementarioC300Valor) * 0.25);
                    }

                    const CIFImponible =
                      item.importeFOB + newFlete + item.importeSeguro + item.importeOtrosGastos;
                    const TotalDerechos = CIFImponible * (item.gravadoPorcentajeCIF / 100);
                    const Isc = CIFImponible * (item.iscPorcentaje / 100);
                    const Ipc = item.cantidadEstadistica * item.ipcFactor;
                    const Pvd =
                      ((item?.importeFOB ?? 0) / item.cantidadEstadistica) * tasaCambioDolar;

                    // Calcular ISV
                    if (
                      item?.posicionArancelaria.startsWith('2202') ||
                      item?.posicionArancelaria.startsWith('2203')
                    ) {
                      Isv = (Pvd / (1 + isvPorcentaje)) * isvPorcentaje * item.cantidadEstadistica;
                    } else {
                      Isv =
                        ((CIFImponible + TotalDerechos + Isc) * tasaCambioDolar + Ipc) *
                        isvPorcentaje;
                    }

                    return {
                      ...item,
                      cifImponible: CIFImponible,
                      totalDerechos: TotalDerechos * tasaCambioDolar,
                      isc: Isc * tasaCambioDolar,
                      ipc: Ipc,
                      isv: Isv,
                      estado:
                        item.estado === EstadoDucaItem.Create
                          ? EstadoDucaItem.Create
                          : EstadoDucaItem.Update,
                    };
                  });
                dispatch(setSaveDucaItems(newItems));
              }
            }

            if (type === 'Delete') {
              const newItems = items.map(item => {
                const isvPorcentaje = item.isvPorcentaje / 100;
                let Isv = 0;

                const CIFImponible = item.valorCIF;
                const TotalDerechos = CIFImponible * (item.gravadoPorcentajeCIF / 100);
                const Isc = CIFImponible * (item.iscPorcentaje / 100);
                const Ipc = item.cantidadEstadistica * item.ipcFactor;
                const Pvd = ((item?.importeFOB ?? 0) / item.cantidadEstadistica) * tasaCambioDolar;

                // Calcular ISV
                if (
                  item?.posicionArancelaria.startsWith('2202') ||
                  item?.posicionArancelaria.startsWith('2203')
                ) {
                  Isv = (Pvd / (1 + isvPorcentaje)) * isvPorcentaje * item.cantidadEstadistica;
                } else {
                  Isv =
                    ((CIFImponible + TotalDerechos + Isc) * tasaCambioDolar + Ipc) * isvPorcentaje;
                }

                return {
                  ...item,
                  cifImponible: CIFImponible,
                  totalDerechos: TotalDerechos * tasaCambioDolar,
                  isc: Isc * tasaCambioDolar,
                  ipc: Ipc,
                  isv: Isv,
                  estado:
                    item.estado === EstadoDucaItem.Create
                      ? EstadoDucaItem.Create
                      : EstadoDucaItem.Update,
                };
              });
              dispatch(setSaveDucaItems(newItems));
            }
          },
        });
      }
    }
  };

  const cols = Columns({
    isEditing,
    onSave,
    onEdit,
    onDelete,
    onCancel,
    ordenCerrada: ducaDetalles?.ordenCerrada ?? false,
  });

  const mergedColumns = cols.map((col: IDatoComplementarioColumnType) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: IDucaDatoComplementarioRequest) => ({
        width: col.width,
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType:
          col.dataIndex === 'descripcion' || col.dataIndex === 'valor' ? 'input' : 'dropdown',
        options: col.dataIndex === 'codigo' ? TipoDatosComplementarios : undefined,
        editing: isEditing(record),
      }),
    };
  });

  const TableDatosComplementariosTemp = (
    <Form form={formComplementarios} component={false}>
      <Table
        components={{ body: { cell: EditableCell } }}
        bordered
        dataSource={datosComplementarios.filter(x => x.estado !== EstadoDucaItem.Delete)}
        columns={mergedColumns as any}
        pagination={false}
        size='small'
        style={{ marginTop: 16 }}
        loading={itemStatus === 'pending'}
      />
    </Form>
  );

  const TableDatosComplementarios = () => TableDatosComplementariosTemp;

  return { TableDatosComplementarios, formComplementarios, onCancel, setEditingKey };
};

interface IEditableCell extends React.HTMLAttributes<HTMLDivElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'dropdown' | 'input';
  options?: any[];
  record: IDucaDatoComplementarioRequest;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<IEditableCell> = ({
  editing,
  dataIndex,
  title,
  inputType,
  options,
  record,
  index,
  children,
  ...restProps
}) => {
  const InputNode =
    inputType === 'input' ? (
      <Input
        type='text'
        disabled={dataIndex === 'descripcion'}
        style={dataIndex === 'descripcion' ? { color: '#666' } : { width: '100%' }}
      />
    ) : (
      <Select
        autoFocus={dataIndex === 'codigo'}
        showSearch
        optionFilterProp='children'
        style={{ width: 250 }}
        filterOption
      >
        {options?.map(item => (
          <Option key={item.id} value={item.codigo}>
            {`${item.codigo} - ${item.descripcion}`}
          </Option>
        ))}
      </Select>
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            { required: true, message: `Campo requerido` },
            ({ setFieldsValue }) => ({
              validator(_, value) {
                if (dataIndex === 'codigo') {
                  setFieldsValue({
                    descripcion: options?.find(x => x?.codigo === value)?.descripcion,
                  });
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          {InputNode}
        </Item>
      ) : (
        children
      )}
    </td>
  );
};
