import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IEstadosMercanciasState } from '../estadosMercanciasStore';

export const deleteEstadoMercancia = createAsyncThunk('EstadosMercancias/Delete', httpService);

export const deleteEstadoMercanciaReducer = (
  builder: ActionReducerMapBuilder<IEstadosMercanciasState>,
) => {
  builder.addCase(deleteEstadoMercancia.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.data.findIndex(
      estadoMercancia => estadoMercancia.id === state.elementoSeleccionado?.id,
    );
    state.data.splice(index, 1);
  });
  builder.addCase(deleteEstadoMercancia.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deleteEstadoMercancia.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
