import { Button, Form, FormInstance, Input, Select } from 'antd';
import { IDucaDatoComplementarioRequest } from 'models/Duca';
import { useAppSelector } from 'store/hooks';

const { Item } = Form;
const { Option } = Select;

interface IFormDatoComplementario {
  form: FormInstance<IDucaDatoComplementarioRequest>;
  onFinish: (values: IDucaDatoComplementarioRequest) => void;
  onChange: (value: string, option: any) => void;
  disabled: boolean;
}

export const FormDatoComplementario = ({
  form,
  onFinish,
  onChange,
  disabled,
}: IFormDatoComplementario) => {
  const TipoDatosComplementarios = useAppSelector(
    state => state.ducaDetalles.TipoDatosComplementarios,
  );
  const rules = [{ required: true, message: 'Campo requerido' }];

  return (
    <Form
      form={form}
      layout='vertical'
      style={{ display: 'flex', alignItems: 'end', gap: 16 }}
      onFinish={disabled ? undefined : onFinish}
    >
      <Item
        label='Dato Complementario'
        name='datoComplementarioId'
        rules={rules}
        style={{ width: '50%', maxWidth: '50%' }}
      >
        <Select showSearch optionFilterProp='children' onChange={onChange} allowClear>
          {TipoDatosComplementarios.map(tipo => (
            <Option key={tipo.id} value={tipo.id}>
              {`${tipo.codigo} - ${tipo.descripcion}`}
            </Option>
          ))}
        </Select>
      </Item>
      <Item label='Valor' name='valor' rules={rules} style={{ width: '50%', maxWidth: '50%' }}>
        <Input />
      </Item>
      <Item>
        <Button type='primary' htmlType='submit' disabled={disabled}>
          Asignar
        </Button>
      </Item>
    </Form>
  );
};
