import { Button, Form, Input, Row, Select, Table, Typography } from 'antd';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { setError } from 'features/InitialData/store/initialDataStore';
import { IDucaDatoComplementarioRequest } from 'models/Duca';
import { PapierError } from 'models/PapierException';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { Columns } from './Columns';

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

interface IListadoDatosComplementariosProps {
  data: IDucaDatoComplementarioRequest[];
}

export const ListadoDatosComplementarios = ({ data }: IListadoDatosComplementariosProps) => {
  const dispatch = useDispatch();
  const [datosComplementarios, setDatosComplementarios] =
    useState<IDucaDatoComplementarioRequest[]>(data);
  const [editingKey, setEditingKey] = useState('');
  const { TipoDatosComplementarios, ducaDetalles } = useAppSelector(state => state.ducaDetalles);
  const [formComplementarios] = Form.useForm<IDucaDatoComplementarioRequest>();

  const isEditing = (values: IDucaDatoComplementarioRequest) => values.key === editingKey;

  const onSave = async (key: string) => {
    try {
      const row = await formComplementarios.validateFields();
      row.datoComplementarioId =
        TipoDatosComplementarios.find(b => b.codigo === row.codigo)?.id ?? '';

      if (
        datosComplementarios.some(
          b => b.codigo === row.codigo && b.key !== key && b.estado !== EstadoDucaItem.Delete,
        )
      )
        throw new PapierError('Ya existe un dato complementario con ese código');

      const newData = [...datosComplementarios];
      const index = newData.findIndex(item => key === item.key);
      if (key === 'new') {
        newData.splice(index, 1, {
          ...row,
          key: GenerateKey(),
          estado: EstadoDucaItem.Create,
        });
        setDatosComplementarios(newData);
      } else {
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
            estado: item.id?.length > 0 ? EstadoDucaItem.Update : EstadoDucaItem.Create,
          });
          setDatosComplementarios(newData);
        } else {
          newData.push(row);
          setDatosComplementarios(newData);
        }
      }

      setEditingKey('');
    } catch (error) {
      if (error instanceof PapierError) {
        dispatch(
          setError({
            error: true,
            message: error.errorMessage,
            duration: 5,
          }),
        );
      } else {
        console.log('Validate Failed:', error);
      }
    }
  };

  const onEdit = (value: Partial<IDucaDatoComplementarioRequest> & { key: React.Key }) => {
    formComplementarios.setFieldsValue({
      datoComplementarioId: '',
      codigo: '',
      descripcion: '',
      valor: '',
      ...value,
    });
    setEditingKey(value.key);
  };

  const onDelete = (key: string) => {
    const index = datosComplementarios.findIndex(_ => _.key === key);

    if (index > -1) {
      const datoComplementario = datosComplementarios[index];
      const datosComplementariosTemp = datosComplementarios.map(_ => _);

      if (datoComplementario.estado === EstadoDucaItem.Create) {
        datosComplementariosTemp.splice(index, 0);
      } else {
        datosComplementariosTemp.splice(index, 1, {
          ...datoComplementario,
          estado: EstadoDucaItem.Delete,
        });
      }

      setDatosComplementarios(datosComplementariosTemp);
    }
  };

  const onCancel = () => {
    if (datosComplementarios.some(d => d.key === 'new'))
      setDatosComplementarios(datosComplementarios.filter(d => d.key !== 'new'));
    setEditingKey('');
  };

  const cols = Columns({
    isEditing,
    onSave,
    onEdit,
    onDelete,
    onCancel,
    ordenCerrada: ducaDetalles?.ordenCerrada ?? false,
  });
  const mergedColumns = cols.map((col: any) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: IDucaDatoComplementarioRequest) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType:
          col.dataIndex === 'descripcion' || col.dataIndex === 'valor' ? 'input' : 'dropdown',
        options: col.dataIndex === 'codigo' ? TipoDatosComplementarios : undefined,
        editing: isEditing(record),
      }),
    };
  });

  const onNewItem = () => {
    formComplementarios.setFieldsValue({
      datoComplementarioId: '',
      codigo: '',
      descripcion: '',
      valor: '',
    });
    setDatosComplementarios([
      ...datosComplementarios,
      {
        id: '',
        key: 'new',
        datoComplementarioId: '',
        codigo: '',
        descripcion: '',
        valor: '',
        estado: EstadoDucaItem.Create,
      },
    ]);
    setEditingKey('new');
  };

  const TablaDatosComplementarios = () => (
    <section>
      <Row justify='space-between'>
        <Title level={5}>Listado de Datos Complementarios</Title>
        {!ducaDetalles?.ordenCerrada && (
          <Button
            type='primary'
            ghost
            onClick={onNewItem}
            disabled={datosComplementarios.some(b => b.key === 'new')}
          >
            Agregar Dato Complementario
          </Button>
        )}
      </Row>
      <Form form={formComplementarios} component={false}>
        <Table
          components={{ body: { cell: EditableCell } }}
          bordered
          dataSource={datosComplementarios.filter(x => x.estado !== EstadoDucaItem.Delete)}
          columns={mergedColumns as any}
          pagination={false}
          size='small'
          style={{ marginTop: 16, width: '100%' }}
        />
      </Form>
    </section>
  );

  return { TablaDatosComplementarios, datosComplementarios };
};

interface IEditableCell extends React.HTMLAttributes<HTMLDivElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'dropdown' | 'input';
  options?: any[];
  record: IDucaDatoComplementarioRequest;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<IEditableCell> = ({
  editing,
  dataIndex,
  title,
  inputType,
  options,
  record,
  index,
  children,
  ...restProps
}) => {
  const InputNode =
    inputType === 'input' ? (
      <Input
        type='text'
        disabled={dataIndex === 'descripcion'}
        style={dataIndex === 'descripcion' ? { color: '#666' } : undefined}
      />
    ) : (
      <Select
        autoFocus={dataIndex === 'codigo'}
        showSearch
        optionFilterProp='children'
        filterOption
        style={{ width: dataIndex === 'codigo' ? 200 : 90 }}
      >
        {options?.map(item => (
          <Option key={item.id} value={item.codigo}>
            {`${item.codigo} - ${item.descripcion}`}
          </Option>
        ))}
      </Select>
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            { required: true, message: `Campo requerido` },
            ({ setFieldsValue }) => ({
              validator(_, value) {
                if (dataIndex === 'codigo') {
                  setFieldsValue({
                    descripcion: options?.find(x => x?.codigo === value)?.descripcion,
                  });
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          {InputNode}
        </Item>
      ) : (
        children
      )}
    </td>
  );
};
