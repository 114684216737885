import { Form } from 'antd';
import { EstadoEntidad } from 'enum/EstadoEntidad';
import { useFilter } from 'hooks/useFilter';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { Proveedor } from '../../models/Proveedor';
import { InitialDataState } from '../InitialData/store/initialDataStore';
import { PaisesState } from '../Paises/store/paisesStore';
import { Columns } from './Columns';
import { ModalDireccionesProveedor } from './ModalDireccionesProveedor/ModalDireccionesProveedor';
import { ModalUpsertProveedor } from './ModalUpsertProveedor/ModalUpsertProveedor';
import styles from './Proveedores.module.less';
import { createProveedor } from './store/extraReducers/createProveedorReducer';
import { deleteProveedor } from './store/extraReducers/deleteProveedorReducer';
import { updateProveedor } from './store/extraReducers/updateProveedorReducer';
import {
  ProveedoresState,
  setCrudStatusIdle,
  setDirecciones,
  setProveedorTemporal,
  toggleModalDireccionesVisible,
  toggleModalVisible,
  toggleSeleccionarProveedor,
} from './store/proveedoresStore';
import { Toolbar } from './Toolbar/Toolbar';

export const Proveedores: FC = () => {
  const { data, status, crudStatus, modalVisible, elementoSeleccionado, apiURL } =
    useSelector(ProveedoresState);
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { data: Paises } = useSelector(PaisesState);
  const { CondicionesComerciales } = useSelector(InitialDataState);

  useEffect(() => {
    !window.document.title.includes('Proveedores') && dispatch(setTitleName('Proveedores'));

    if (crudStatus === 'succeeded') {
      dispatch(setCrudStatusIdle());
      form.resetFields();
    }
  }, [crudStatus, dispatch, form]);

  const setUpdateSelection = (values: Proveedor) => {
    dispatch(toggleSeleccionarProveedor(values));
    setToggleModalVisible();
    form.setFieldsValue({
      ...values,
    });
  };

  const setDeleteSelection = (values: Proveedor) => {
    dispatch(toggleSeleccionarProveedor(values));
    onDelete(values.id);
  };

  const setToggleModalVisible = () => {
    dispatch(toggleModalVisible());
    form.resetFields();
  };

  const onShowDirecciones = (values: Proveedor) => {
    dispatch(toggleSeleccionarProveedor(values));
    dispatch(toggleModalDireccionesVisible());
    dispatch(setDirecciones(values.direcciones));
  };

  const cols = Columns({
    onUpdate: setUpdateSelection,
    onDelete: setDeleteSelection,
    onShowDirecciones,
  });

  const onCreate = (values: Proveedor) => {
    const newData = {
      ...values,
      pais: Paises.filter(x => x.id === values.paisId)[0]?.nombre,
      condicionComercial: CondicionesComerciales.filter(
        x => x.id === values.condicionComercialId,
      )[0]?.descripcion,
      estado: EstadoEntidad.Confirmado,
    } as Proveedor;

    dispatch(setProveedorTemporal(newData));
    customDispatch({
      asyncThuckFuction: createProveedor,
      endPoint: `${apiURL}/Create`,
      body: newData,
    });
  };

  const onUpdate = (values: Proveedor) => {
    const newData = {
      ...values,
      pais: Paises.filter(x => x.id === values.paisId)[0]?.nombre,
      condicionComercial: CondicionesComerciales.filter(
        x => x.id === values.condicionComercialId,
      )[0]?.descripcion,
    } as Proveedor;

    dispatch(setProveedorTemporal(newData));
    customDispatch({
      asyncThuckFuction: updateProveedor,
      endPoint: `${apiURL}/Update/${elementoSeleccionado?.id}`,
      body: values,
      method: 'PUT',
    });
  };

  const onDelete = (id: string) => {
    customDispatch({
      asyncThuckFuction: deleteProveedor,
      endPoint: `${apiURL}/Delete/${id}`,
      method: 'DELETE',
    });
  };

  const [onfilter, filteredData] = useFilter<Proveedor>({
    data,
    filterPredicate: (item, filter) => item.nombre.toLowerCase().includes(filter.toLowerCase()),
  });

  return (
    <section className={styles.container}>
      <Toolbar setNombre={onfilter} setToggleModal={setToggleModalVisible} />
      <BaseTable
        cols={cols}
        data={filteredData.filter(x => x.estado !== EstadoEntidad.Pendiente)}
        status={status}
        size='small'
        style={{ height: 'calc(100% - 40px)' }}
      />
      <ModalUpsertProveedor
        validarProveedor={false}
        visible={modalVisible}
        form={form}
        status={crudStatus}
        elementoSeleccionado={elementoSeleccionado}
        paises={Paises}
        condicionesComerciales={CondicionesComerciales}
        onCreate={onCreate}
        onUpdate={onUpdate}
        setCerrarDrawer={setToggleModalVisible}
      />
      <ModalDireccionesProveedor />
    </section>
  );
};
