import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setError } from 'features/InitialData/store/initialDataStore';
import { Deva } from 'models/Deva';
import httpService, { IUseFetch, ThunckApi } from 'services/httpService';
import { IDevasState } from '../store';

export const agregarProductos = createAsyncThunk(
  'Devas/AgregarProductos',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      thunkApi.dispatch(setError({ message: 'Productos agregados', error: false }));
    }
    return res;
  },
);

export const agregarProductosReducer = (builder: ActionReducerMapBuilder<IDevasState>) => {
  builder.addCase(
    agregarProductos.fulfilled,
    (
      state,
      action: PayloadAction<{ devaId: string; importeFactura: number; valorAduana: number }>,
    ) => {
      const devaInUpdate = state.devaInUpdate;

      if (state.ordenDeTrabajoInCreation) {
        const devaIndex = state.devasOfOrdenDeTrabajo.findIndex(
          d => d.id === action.payload.devaId,
        );
        const deva = state.devasOfOrdenDeTrabajo.find(d => d.id === action.payload.devaId);
        const numerosFactudas =
          deva?.numeroFactura.concat(
            Array.from(new Set(devaInUpdate?.productos.map(producto => producto.numeroFactura))),
          ) ?? [];

        state.devasOfOrdenDeTrabajo.splice(devaIndex, 1, {
          ...deva,
          valorFOB: action.payload.importeFactura,
          valorCIF: action.payload.valorAduana,
          numeroFactura: numerosFactudas,
        } as Deva);
      } else {
        const devaIndex = state.devasDetalles.findIndex(d => d.id === action.payload.devaId);
        const deva = state.devasDetalles.find(d => d.id === action.payload.devaId);
        const numerosFactudas =
          deva?.numeroFactura.concat(
            Array.from(new Set(devaInUpdate?.productos.map(producto => producto.numeroFactura))),
          ) ?? [];

        state.devasDetalles.splice(devaIndex, 1, {
          ...deva,
          valorFOB: action.payload.importeFactura,
          valorCIF: action.payload.valorAduana,
          numeroFactura: numerosFactudas,
        } as Deva);
      }
      state.devaInUpdate = undefined;
      state.createDevaStatus = 'succeeded';
    },
  );
  builder.addCase(agregarProductos.pending, (state, _action) => {
    state.createDevaStatus = 'pending';
    state.productosPendientesCreateStatus = 'idle';
  });
  builder.addCase(agregarProductos.rejected, (state, _action) => {
    state.createDevaStatus = 'failed';
  });
};
