import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import { EventValue } from 'rc-picker/lib/interface';

export function tableColumnTextFilter<T>(dataIndex: string): ColumnType<T> {
  let searchInputHolder: any | null = null;

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => (searchInputHolder = node)}
          placeholder='Search'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          size='small'
          type='link'
          style={{ width: 90, marginRight: 8 }}
          onClick={() => {
            clearFilters?.();
            confirm();
          }}
        >
          Reiniciar
        </Button>
        <Button
          type='primary'
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90 }}
        >
          Buscar
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputHolder.current?.select());
      }
    },
  };
}

export function tableColumnDateFilter<T>(dataIndex: string, containerId?: string): ColumnType<T> {
  let dates: EventValue<Moment>[] = [];
  return {
    filterDropdown: ({ confirm, clearFilters, setSelectedKeys }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          getPopupContainer={
            containerId?.length
              ? () => document.getElementById(containerId) as HTMLElement
              : undefined
          }
          onChange={e => {
            setSelectedKeys(e as any);
            dates = e as any;
          }}
          allowClear={false}
          style={{ marginBottom: 8, width: '100%' }}
          value={[dates[0], dates[1]]}
        />
        <br />

        <Button
          size='small'
          type='link'
          style={{ width: 'calc(50% - 4px)', marginRight: 8 }}
          onClick={() => {
            dates = [];
            clearFilters?.();
            confirm();
          }}
        >
          Reiniciar
        </Button>
        <Button
          type='primary'
          size='small'
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          style={{ width: 'calc(50% - 4px)' }}
        >
          Buscar
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (_value: any, record: any) => {
      const fecha = String(record[dataIndex]).split(' ')[0].split('/');

      if (dates[0] instanceof moment && dates[1] instanceof moment) {
        const date = moment(`${fecha[2]}-${fecha[1]}-${fecha[0]}`, 'YY-MM-DD');
        return date.isBetween(dates[0], dates[1], 'days', '[]');
      } else {
        return false;
      }
    },
  };
}

export function tableColumnNumberFilter<T>(dataIndex: string): ColumnType<T> {
  let searchInputHolder: any | null = null;

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => (searchInputHolder = node)}
          placeholder='Search'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          type={'number'}
        />
        <Button
          size='small'
          type='link'
          style={{ width: 90, marginRight: 8 }}
          onClick={() => {
            clearFilters?.();
            confirm();
          }}
        >
          Reiniciar
        </Button>
        <Button
          type='primary'
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90 }}
        >
          Buscar
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString() ? record[dataIndex].toString().includes(value) : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputHolder.current?.select());
      }
    },
  };
}
