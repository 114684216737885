import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IUnidadesMedidasNetasState } from '../unidadesMedidasNetasStore';

export const getUnidadesMedidasNetas = createAsyncThunk('UnidadesMedidasNetas/GetAll', httpService);

export const getUnidadesMedidasNetasReducer = (
  builder: ActionReducerMapBuilder<IUnidadesMedidasNetasState>,
) => {
  builder.addCase(getUnidadesMedidasNetas.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.data = action.payload;
  });
  builder.addCase(getUnidadesMedidasNetas.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getUnidadesMedidasNetas.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
