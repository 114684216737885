import { Button, Col, Form, FormInstance, Row, Typography } from 'antd';
import { EstadoOrdenTrabajo } from 'enum/EstadoOrdenTrabajo';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { IDevaDetallesRequest } from 'models/Deva';
import { Incoterm } from 'models/Incoterm';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { TParams } from '../DevaDetails';
import { updateDevaDetalles } from '../store/extraReducers/updateDevaDetalles';
import { normalizeFormNames } from '../utils/normalizeFormNames';
import { CondicionesTransaccion } from './CondicionesTransaccion/CondicionesTransaccion';
import { DeterminacionValor } from './DeterminacionValor/DeterminacionValor';
import { InformacionGeneral } from './InformacionGeneral/InformacionGeneral';

const { Title } = Typography;

interface IRegistroDeclaracionValores {
  form: FormInstance<IDevaDetallesRequest>;
}

export const RegistroDeclaracionValores = ({ form }: IRegistroDeclaracionValores) => {
  const customDispatch = useCustomDispatch();
  const [incotermSeleccionado, setIncotermSeleccionado] = useState<Incoterm | undefined>(undefined);
  const devaDetalles = useAppSelector(state => state.devaDetalles.devaDetalles);
  const Incoterms = useAppSelector(state => state.initialData.Incoterm);
  const { devaId, ordenId } = useParams<TParams>();

  useEffect(() => {
    if (devaDetalles) {
      const direccion = devaDetalles.direccionesProveedores.find(
        _ => _.id === devaDetalles.direccionProveedorId,
      );
      const prefixedImportador = normalizeFormNames('importador', devaDetalles?.importador);
      const prefixedProveedor = normalizeFormNames('proveedor', devaDetalles?.proveedor);
      const prefixedDireccionProveedor = normalizeFormNames('proveedor', direccion);
      const incoterm = Incoterms.find(i => i.id === devaDetalles?.incotermId);
      let importeTransporte = devaDetalles?.importeTransporte;
      let importeSeguro = devaDetalles?.importeSeguro;

      if (incoterm) {
        if (!incoterm.fleteDeclarado) importeTransporte = 0;
        if (!incoterm.seguroDeclarado) importeSeguro = 0;
      }

      const fields = {
        ...devaDetalles,
        aduanaIngreso: devaDetalles?.aduanaIngreso?.nombre,
        aduanaDespacho: devaDetalles?.aduanaDespacho?.nombre,
        fechaAceptacion: devaDetalles?.fechaAceptacion && moment(devaDetalles.fechaAceptacion),
        fechaContrato: devaDetalles?.fechaContrato && moment(devaDetalles.fechaContrato),
        fechaExportacion: devaDetalles?.fechaExportacion && moment(devaDetalles.fechaExportacion),
        importeTransporte,
        importeSeguro,
        ...prefixedImportador,
        ...prefixedProveedor,
        ...prefixedDireccionProveedor,
        ...devaDetalles.intermediario,
      };

      form.setFieldsValue(fields);
      setIncotermSeleccionado(incoterm);

      if (devaDetalles?.ordenDeTrabajo?.estado === EstadoOrdenTrabajo.Cerrada) {
        Object.keys(fields).forEach(key => {
          if (form.getFieldInstance(key)) {
            const fieldKeys = Object.keys(form.getFieldInstance(key));
            if (fieldKeys.includes('input')) {
              form.getFieldInstance(key).input.disabled = true;
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devaDetalles]);

  const onChangeIncoterm = (id: string) => {
    const incoterm = Incoterms.find(i => i.id === id);
    if (incoterm) {
      if (!incoterm.fleteDeclarado) form.setFieldsValue({ importeTransporte: 0 });
      else form.setFieldsValue({ importeTransporte: devaDetalles?.importeTransporte });

      if (!incoterm.seguroDeclarado) form.setFieldsValue({ importeSeguro: 0 });
      else form.setFieldsValue({ importeSeguro: devaDetalles?.importeSeguro });
    }

    setIncotermSeleccionado(incoterm);
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(values => onUpdateDevaDetalles(values))
      .catch(error => {
        console.log(error);
        form.scrollToField(error.errorFields[0].name, { behavior: 'smooth', block: 'center' });
      });
  };

  const onUpdateDevaDetalles = (values: IDevaDetallesRequest) => {
    customDispatch({
      asyncThuckFuction: updateDevaDetalles,
      endPoint: `/api/Devas/UpsertDetalles/${devaId}`,
      body: { ...values, id: devaId, ordenDeTrabajoId: ordenId },
      method: 'PUT',
    });
  };

  return (
    <section style={{ marginBottom: 16 }}>
      <Row justify='space-between'>
        <Col>
          <Title style={{ color: '#2F80ED' }} level={4}>
            1. Información General
          </Title>
        </Col>
        <Col>
          {devaDetalles?.ordenDeTrabajo?.estado !== EstadoOrdenTrabajo.Cerrada && (
            <Button onClick={onFinish}>Guardar</Button>
          )}
        </Col>
      </Row>
      <Form form={form} layout='vertical'>
        <InformacionGeneral form={form} onChangeIncoterm={onChangeIncoterm} />
        <CondicionesTransaccion />
        <DeterminacionValor form={form} incotermSeleccionado={incotermSeleccionado} />
      </Form>
    </section>
  );
};
