import { CloseOutlined, DeleteOutlined, FormOutlined, SaveOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { IDucaDocumentoRequest } from 'models/Duca';
import { tableColumnTextFilter } from 'utils/tableColumnFilter';

interface IColumnsProps {
  isEditing: (values: IDucaDocumentoRequest) => boolean;
  onSave: (key: string) => void;
  onEdit: (values: IDucaDocumentoRequest) => void;
  onDelete: (key: string) => void;
  onCancel: () => void;
  ordenCerrada: boolean;
}

export interface IDocumentoColumnType extends ColumnType<IDucaDocumentoRequest> {
  editable?: boolean;
}

export const Columns = ({
  isEditing,
  onSave,
  onEdit,
  onDelete,
  onCancel,
  ordenCerrada,
}: IColumnsProps): IDocumentoColumnType[] =>
  ordenCerrada
    ? [
        {
          title: 'Código Documento',
          dataIndex: 'codigo',
          width: 200,
          align: 'left',
          editable: true,
          sorter: (a: any, b: any) => a.codigo.length - b.codigo.length,
          ...tableColumnTextFilter<IDucaDocumentoRequest>('codigo'),
        },
        {
          title: 'Descripción',
          dataIndex: 'descripcion',
          width: '50%',
          align: 'left',
          editable: true,
          sorter: (a: any, b: any) => a.descripcion.length - b.descripcion.length,
          ...tableColumnTextFilter<IDucaDocumentoRequest>('descripcion'),
        },
        {
          title: 'Referencia',
          dataIndex: 'referencia',
          width: '50%',
          align: 'left',
          editable: true,
          sorter: (a: any, b: any) => a.referencia.length - b.referencia.length,
          ...tableColumnTextFilter<IDucaDocumentoRequest>('referencia'),
        },
        {
          title: 'Presencia',
          dataIndex: 'presencia',
          width: 90,
          align: 'left',
          editable: true,
          filters: [
            { text: 'Sí', value: true },
            { text: 'No', value: false },
          ],
          onFilter: (value: any, record: IDucaDocumentoRequest) => record.presencia === value,
          render: (_text: string, record: IDucaDocumentoRequest) => {
            return record.presencia ? 'Sí' : 'No';
          },
        },
      ]
    : [
        {
          title: 'Código Documento',
          dataIndex: 'codigo',
          width: 200,
          align: 'left',
          editable: true,
          sorter: (a: any, b: any) => a.codigo.length - b.codigo.length,
          ...tableColumnTextFilter<IDucaDocumentoRequest>('codigo'),
        },
        {
          title: 'Descripción',
          dataIndex: 'descripcion',
          width: '50%',
          align: 'left',
          editable: true,
          sorter: (a: any, b: any) => a.descripcion.length - b.descripcion.length,
          ...tableColumnTextFilter<IDucaDocumentoRequest>('descripcion'),
        },
        {
          title: 'Referencia',
          dataIndex: 'referencia',
          width: '50%',
          align: 'left',
          editable: true,
          sorter: (a: any, b: any) => a.referencia.length - b.referencia.length,
          ...tableColumnTextFilter<IDucaDocumentoRequest>('referencia'),
        },
        {
          title: 'Presencia',
          dataIndex: 'presencia',
          width: 90,
          align: 'left',
          editable: true,
          filters: [
            { text: 'Sí', value: true },
            { text: 'No', value: false },
          ],
          onFilter: (value: any, record: IDucaDocumentoRequest) => record.presencia === value,
          render: (_text: string, record: IDucaDocumentoRequest) => {
            return record.presencia ? 'Sí' : 'No';
          },
        },
        {
          title: 'Editar',
          dataIndex: 'editar',
          align: 'center',
          width: 50,
          render: (_: any, record: IDucaDocumentoRequest) => {
            const editable = isEditing(record);
            return (
              <div style={{ textAlign: 'center' }}>
                {editable ? (
                  <SaveOutlined
                    onClick={() => onSave(record.key)}
                    style={{ color: '#1890FF', fontSize: 16 }}
                  />
                ) : (
                  <FormOutlined
                    onClick={() => onEdit(record)}
                    style={{ color: '#FAAD14', fontSize: 16 }}
                  />
                )}
              </div>
            );
          },
        },
        {
          title: 'Borrar',
          dataIndex: 'borrar',
          align: 'center',
          width: 50,
          render: (_: any, record: IDucaDocumentoRequest) => {
            const editable = isEditing(record);
            return (
              <div style={{ textAlign: 'center' }}>
                {editable ? (
                  <CloseOutlined
                    onClick={() => onCancel()}
                    style={{ color: '#FF4D4F', fontSize: 16 }}
                  />
                ) : (
                  <Popconfirm title='Suguro de eliminar?' onConfirm={() => onDelete(record.key)}>
                    <DeleteOutlined style={{ color: '#FF4D4F', fontSize: 16 }} />
                  </Popconfirm>
                )}
              </div>
            );
          },
        },
      ];
