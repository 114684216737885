import { Form, FormInstance, Input, Modal, Spin, Select } from 'antd';
import { useAppSelector } from 'store/hooks';
import { Pais } from '../../../models/Pais';

const { Item } = Form;
const { Option } = Select;

interface IProps {
  visible: boolean;
  form: FormInstance;
  status: StatusApp.Status;
  elementoSeleccionado: Pais | undefined;
  onCreate: (values: Pais) => void;
  onUpdate: (values: Pais) => void;
  setCerrarModal: () => void;
}

export const ModalUpsert = ({
  visible,
  form,
  status,
  elementoSeleccionado,
  onCreate,
  onUpdate,
  setCerrarModal,
}: IProps) => {
  const OrganizacionesPaises = useAppSelector(state => state.initialData.OrganizacionesPaises);
  return (
    <Modal
      title={elementoSeleccionado ? 'Editar País' : 'Crear País'}
      visible={visible}
      onOk={
        elementoSeleccionado
          ? () => {
              form
                .validateFields()
                .then(values => onUpdate(values as Pais))
                .catch(error => console.log(error));
            }
          : () => {
              form
                .validateFields()
                .then(values => onCreate(values as Pais))
                .catch(error => console.log(error));
            }
      }
      onCancel={() => {
        form.resetFields();
        setCerrarModal();
      }}
    >
      <Spin spinning={status === 'pending' ? true : false}>
        <Form form={form} name='Pais' layout='vertical' defaultValue={elementoSeleccionado as any}>
          <Item
            name='nombre'
            label='Nombre'
            rules={[
              {
                required: true,
                message: 'Por favor ingresa un nombre para el país.',
              },
            ]}
          >
            <Input />
          </Item>
          <Item name='abreviatura' label='Abreviatura' rules={[{ required: true }]}>
            <Input />
          </Item>

          {elementoSeleccionado ? (
            <Item name='combinado' label='Combinado' rules={[{ required: true }]}>
              <Input disabled />
            </Item>
          ) : null}

          <Item name='organizacionId' label='Organización'>
            <Select allowClear>
              {OrganizacionesPaises.map(organizacion => (
                <Option value={organizacion.id} key={organizacion.id}>
                  {`${organizacion.abreviatura} - ${organizacion.nombre}`}
                </Option>
              ))}
            </Select>
          </Item>
        </Form>
      </Spin>
    </Modal>
  );
};
