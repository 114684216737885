import { ArrowLeftOutlined } from '@ant-design/icons';
// Report Viewer Source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.min.css';
// Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
// Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';
import '@boldreports/global/i18n/ej.culture.es-ES.min.js';
import '@boldreports/global/l10n/ej.localetexts.es-ES.min.js';
import { Button, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

declare let BoldReportViewerComponent: any;

const viewerStyle = {
  height: 'calc(100% - 45px)',
  width: '100%',
};

const ReportServer = process.env.REACT_APP_API_URL;
const BoldReportPath = process.env.REACT_APP_BOLD_REPORT_PATH;

export const DucaReportViewer = () => {
  const { goBack } = useHistory();
  const { ordenId, ducaId } = useParams<{ ordenId: string; ducaId: string }>();
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        background: '#fff',
        padding: 12,
        borderRadius: 6,
      }}
    >
      <Row style={{ marginBottom: 12 }}>
        <Button icon={<ArrowLeftOutlined />} onClick={goBack}>
          Regresar
        </Button>
      </Row>
      <div style={viewerStyle}>
        <BoldReportViewerComponent
          id='reportviewer-container'
          reportServiceUrl={`${ReportServer}/api/ReportViewer`}
          reportPath={`${BoldReportPath}//Duca.rdl`}
          serviceAuthorizationToken={'bearer kNkABvQWDkTaZrJ35lFaMfkYGUjVHVp6f8L+feGTvXY='}
          locale='es-ES'
          parameters={[
            {
              name: 'DucaId',
              labels: ['DucaId'],
              values: [ducaId],
              nullable: false,
            },
            {
              name: 'OrdenDeTrabajoId',
              labels: ['OrdenDeTrabajoId'],
              values: [ordenId],
              nullable: false,
            },
          ]}
        />
      </div>
    </div>
  );
};
