import { Select } from 'antd';
import { EstadoMercancia } from 'models/EstadoMercancia';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useAppSelector } from 'store/hooks';

const { Option } = Select;

export const AgEstadoMercanciaCellEditor = forwardRef((props: any, ref) => {
  const { data: EstadosMercancias } = useAppSelector(state => state.estadosMercancias);
  const [value, setValue] = useState<EstadoMercancia | undefined>(
    EstadosMercancias.find(p => p.nombre === props.value),
  );

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (props.colDef.field === 'estadoMercancia') {
          props.data.estadoMercanciaId = value?.id;
        }

        return value?.nombre;
      },
    };
  });

  const onChange = (e: string) => {
    setValue(EstadosMercancias.find(p => p.id === e));
  };

  return (
    <div>
      <Select
        showSearch
        optionFilterProp='children'
        defaultValue={value?.id}
        onChange={e => onChange(e)}
        style={{ width: '100%' }}
      >
        {EstadosMercancias.map(estadoMercancia => (
          <Option key={estadoMercancia.id} value={estadoMercancia.id}>
            {estadoMercancia.nombre}
          </Option>
        ))}
      </Select>
    </div>
  );
});
