import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const queryTipoDocumento = createAsyncThunk('DucaDetalles/TipoDocumento/Query', httpService);

export const queryTipoDocumentoReducer = (builder: ActionReducerMapBuilder<IDucaDetallesState>) => {
  builder.addCase(
    queryTipoDocumento.fulfilled,
    (state, action: PayloadAction<PagedList<ConfiguracionesPetch>>) => {
      state.TiposDocumentos = action.payload.items;
      state.TipoDocumentoStatus = 'succeeded';
    },
  );

  builder.addCase(queryTipoDocumento.pending, state => {
    state.TipoDocumentoStatus = 'pending';
  });

  builder.addCase(queryTipoDocumento.rejected, state => {
    state.TipoDocumentoStatus = 'failed';
  });
};
