import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IDesignacionesProductosState } from '../designacionesProductosStore';

export const getDesignacionesProductos = createAsyncThunk(
  'DesignacionesProductos/GetAll',
  httpService,
);

export const getDesignacionesProductosReducer = (
  builder: ActionReducerMapBuilder<IDesignacionesProductosState>,
) => {
  builder.addCase(getDesignacionesProductos.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.data = action.payload;
  });
  builder.addCase(getDesignacionesProductos.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getDesignacionesProductos.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
