import { Form, Input } from 'antd';

const { Item } = Form;
const { TextArea } = Input;

interface ITextAreaDisabled {
  name: string;
  label: string;
}

export const TextAreaDisabled = ({ name, label }: ITextAreaDisabled) => (
  <Item name={name} label={label}>
    <TextArea disabled rows={3} style={{ color: '#666' }} />
  </Item>
);
