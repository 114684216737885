import { LoadingOutlined } from '@ant-design/icons';
import styles from './App.module.less';

export const Loader = () => {
  return (
    <div className={styles.loadingContainer}>
      <LoadingOutlined className={styles.loadingIcon} />
      <span className={styles.loadingText}> Cargando...</span>
    </div>
  );
};
