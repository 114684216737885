import { Select } from 'antd';
import { ConfiguracionesPetch } from 'models/configuracionesPetch';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useAppSelector } from 'store/hooks';

const { Option } = Select;

export const AgTipoItemCellEditor = forwardRef((props: any, ref) => {
  const { TiposItems } = useAppSelector(state => state.ducaDetalles);
  const [value, setValue] = useState<ConfiguracionesPetch | undefined>(
    TiposItems.find(t => t.descripcion === props.value),
  );

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value?.descripcion;
      },
    };
  });

  const onChange = (e: string) => {
    setValue(TiposItems.find(t => t.id === e));
  };

  return (
    <div>
      <Select
        showSearch
        optionFilterProp='children'
        defaultValue={value?.id}
        onChange={e => onChange(e)}
        style={{ width: '100%' }}
      >
        {TiposItems.map(tipoItem => (
          <Option key={tipoItem.id} value={tipoItem.id}>
            {tipoItem.descripcion}
          </Option>
        ))}
      </Select>
    </div>
  );
});
