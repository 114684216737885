import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IUsuariosState } from '../usuariosStore';

export const getUsuarios = createAsyncThunk('Usuarios/GetAll', httpService);

export const getUsuariosReducer = (builder: ActionReducerMapBuilder<IUsuariosState>) => {
  builder.addCase(getUsuarios.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.data = action.payload;
  });
  builder.addCase(getUsuarios.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getUsuarios.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
