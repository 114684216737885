import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ConversionUnidadDeMedida } from '../../../../models/ConversionUnidadDeMedida';
import httpService from '../../../../services/httpService';
import { IConversionUnidadDeMedidaState } from '../conversionesUnidadesDeMedidasStore';

export const createConversionUnidadDeMedida = createAsyncThunk(
  'ConversionUnidadesDeMedidas/Create',
  httpService,
);

export const createConversionUnidadDeMedidaReducer = (
  builder: ActionReducerMapBuilder<IConversionUnidadDeMedidaState>,
) => {
  builder.addCase(createConversionUnidadDeMedida.fulfilled, (state, action) => {
    state.crudStatus = 'succeeded';
    state.data.unshift({
      ...state.elementoTemporal,
      id: action.payload,
    } as ConversionUnidadDeMedida);
  });
  builder.addCase(createConversionUnidadDeMedida.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createConversionUnidadDeMedida.rejected, (state, action) => {
    state.crudStatus = 'failed';
    state.error = action.error.message;
  });
};
