import { Form } from 'antd';
import { useFilter } from 'hooks/useFilter';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { UnidadMedidaNeta } from '../../models/UnidadMedidaNeta';
import { Columns } from './Columns';
import { ModalUpsert } from './ModalUpsert/ModalUpsert';
import { createUnidadMedidaNeta } from './store/extraReducer/createUnidades';
import { deleteUnidadMedidaNeta } from './store/extraReducer/deleteUnidades';
import { updateUnidadMedidaNeta } from './store/extraReducer/updateUnidades';
import {
  setCrudStatusIdle,
  setUnidadMedidaNetaTemporal,
  toggleModalVisible,
  toggleSeleccionarUnidadMedidaNeta,
  UnidadesMedidasNetasState,
} from './store/unidadesMedidasNetasStore';
import { Toolbar } from './Toolbar/Toolbar';
import styles from './UnidadesMedidasNetas.module.less';

export const UnidadesMedidasNetas: FC = () => {
  const { data, status, crudStatus, modalVisible, elementoSeleccionado, apiURL } =
    useSelector(UnidadesMedidasNetasState);
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    !window.document.title.includes('Unidades de Medidas Netas') &&
      dispatch(setTitleName('Unidades de Medidas Netas'));

    if (crudStatus === 'succeeded') {
      dispatch(setCrudStatusIdle());
      form.resetFields();
    }
  }, [crudStatus, dispatch, form]);

  const setUpdateSelection = (values: UnidadMedidaNeta) => {
    dispatch(toggleSeleccionarUnidadMedidaNeta(values));
    setToggleModalVisible();
    form.setFieldsValue({
      codigo: values.codigo,
      nombre: values.nombre,
      descripcion: values.descripcion,
    });
  };

  const setDeleteSelection = (values: UnidadMedidaNeta) => {
    dispatch(toggleSeleccionarUnidadMedidaNeta(values));
    onDelete(values.id);
  };

  const setToggleModalVisible = () => {
    dispatch(toggleModalVisible());
  };

  const cols = Columns({
    onUpdate: setUpdateSelection,
    onDelete: setDeleteSelection,
  });

  const onCreate = (values: UnidadMedidaNeta) => {
    dispatch(setUnidadMedidaNetaTemporal(values));
    customDispatch({
      asyncThuckFuction: createUnidadMedidaNeta,
      endPoint: `${apiURL}/Create`,
      body: values,
    });
  };

  const onUpdate = (values: UnidadMedidaNeta) => {
    dispatch(setUnidadMedidaNetaTemporal(values));
    customDispatch({
      asyncThuckFuction: updateUnidadMedidaNeta,
      endPoint: `${apiURL}/Update/${elementoSeleccionado?.id}`,
      body: values,
      method: 'PUT',
    });
  };

  const onDelete = (id: string) => {
    customDispatch({
      asyncThuckFuction: deleteUnidadMedidaNeta,
      endPoint: `${apiURL}/Delete/${id}`,
      method: 'DELETE',
    });
  };

  const [onfilter, filteredData] = useFilter<UnidadMedidaNeta>({
    data,
    filterPredicate: (item, filter) => item.nombre.toLowerCase().includes(filter.toLowerCase()),
  });

  return (
    <section className={styles.container}>
      <Toolbar setNombre={onfilter} setToggleModal={setToggleModalVisible} />
      <BaseTable
        cols={cols}
        data={filteredData}
        status={status}
        size='small'
        style={{ height: 'calc(100% - 40px)' }}
      />
      <ModalUpsert
        visible={modalVisible}
        form={form}
        status={crudStatus}
        elementoSeleccionado={elementoSeleccionado}
        onCreate={onCreate}
        onUpdate={onUpdate}
        setCerrarModal={setToggleModalVisible}
      />
    </section>
  );
};
