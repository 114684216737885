import { IDucaItemRequest } from 'models/Duca';
import { Fragment, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { DuaCell, DuaCol, DuaData, DuaRow } from './DuaTable';
import './printLiquidacion.css';
import { FormatoPartidaArancelaria } from './utils/FormatoPartidaArancelaria';
import { GetDecimals } from './utils/GetDecimals';
import { NumberFormat } from './utils/NumberFormat';

const LineBreak = () => <p style={{ height: 0, lineHeight: '0px', marginBottom: 0 }} />;

export const FormatoPrincipal = () => {
  const { data } = useAppSelector(state => state.archivoPreliquidacion);
  const Configuraciones = useAppSelector(state => state.initialData.Configuraciones);
  const [productos, setProductos] = useState<IDucaItemRequest[]>([]);
  const [cantidadProductosVacios, setCantidadProductosVacios] = useState<number>(0);
  const [cantidadComercialTotal, setCantidadComercialTotal] = useState<number>(0);
  const [totalFOB, setTotalFOB] = useState<number>(0);
  const [totalCIF, setTotalCIF] = useState<number>(0);
  const [totalDerechos, setTotalDerechos] = useState<number>(0);
  const [totalISC, setTotalISC] = useState<number>(0);
  const [otrosGrastos, setOtrosGastos] = useState<number>(0);
  const [totalIPC, setTotalIPC] = useState<number>(0);
  const [totalISV, setTotalISV] = useState<number>(0);
  const [totalLiquidacion, setTotalLiquidacion] = useState<number>(0);
  const border = '1px solid #000';
  const itemsLength = data?.items?.length ?? 0;

  const precisionDecimal = (value: number) =>
    Number(value.toFixed(Configuraciones?.precisionDecimal));

  useEffect(() => {
    if (data) {
      const tempItems = data.items.slice(0, itemsLength < 4 ? itemsLength : 4);
      const tempTotalCantidadComercial = data.items.reduce(
        (acc, item) => acc + item.cantidadComercial,
        0,
      );
      const tempTotalDerechos = data.items.reduce((acc, item) => acc + item.totalDerechos, 0);
      const tempTotalISC = data.items.reduce((acc, item) => acc + item.isc, 0);
      const tempTotalIPC = data.items.reduce((acc, item) => acc + item.ipc, 0);
      const tempTotalISV = data.items.reduce((acc, item) => acc + item.isv, 0);
      const tempOtrosGastos = data.tasaCambioDolar * 5;
      const tempTotalLiquidacion =
        tempTotalDerechos + tempTotalISC + tempOtrosGastos + tempTotalIPC + tempTotalISV;

      setCantidadProductosVacios(4 - tempItems.length);
      setProductos(tempItems);
      setCantidadComercialTotal(precisionDecimal(tempTotalCantidadComercial));
      setTotalFOB(precisionDecimal(data.totalFOB));
      setTotalCIF(precisionDecimal(data.totalCIF));
      setTotalDerechos(precisionDecimal(tempTotalDerechos));
      setTotalISC(precisionDecimal(tempTotalISC));
      setOtrosGastos(precisionDecimal(tempOtrosGastos));
      setTotalIPC(precisionDecimal(tempTotalIPC));
      setTotalISV(precisionDecimal(tempTotalISV));
      setTotalLiquidacion(precisionDecimal(tempTotalLiquidacion));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <DuaCol noBorder style={{ height: 'auto' }}>
      {/* I. REGISTRO DEL TRAMITEADUANERO */}
      <DuaRow>
        <DuaCol width={'40%'}>
          <DuaCell className='duaSection'>I. REGISTRO DEL TRAMITE ADUANERO</DuaCell>
        </DuaCol>
        <DuaCol width={'20%'} style={{ alignItems: 'center' }}>
          <DuaCell className='duaSection' style={{ fontWeight: 'initial', whiteSpace: 'nowrap' }}>
            NUMERO UNICO CORRELATIVO (1)
          </DuaCell>
        </DuaCol>
        <DuaCol width={'40%'}>
          <DuaCell
            className='duaSection'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 'initial',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ whiteSpace: 'nowrap' }}>IMPORTACION (2)</span>
              <span className='rectangle' />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}>
              <span style={{ whiteSpace: 'nowrap' }}>EXPORTACION (3)</span>
              <span className='rectangle' />
            </div>
          </DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow>
        <DuaCol width={'40%'}>
          <DuaRow noBorder>
            <DuaCol width={'12%'} style={{ textAlign: 'center' }}>
              <DuaCell>COD.</DuaCell>
            </DuaCol>
            <DuaCol width={'38%'}>
              <DuaCell>NOMBRE DEL REGIMEN (4)</DuaCell>
            </DuaCol>
            <DuaCol width={'12%'} style={{ textAlign: 'center' }}>
              <DuaCell>COD.</DuaCell>
            </DuaCol>
            <DuaCol width={'35%'}>
              <DuaCell>NOMBRE DE ADUANA (5)</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'20%'}>
          <DuaRow noBorder style={{ textAlign: 'center' }}>
            <DuaCol width={'30%'}>
              <DuaCell>AÑO</DuaCell>
            </DuaCol>
            <DuaCol width={'70%'}>
              <DuaCell>No. CORRELATIVO</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'40%'}>
          <DuaRow noBorder>
            <DuaCol width={'30%'} style={{ textAlign: 'center' }}>
              <DuaCell className='cellDoubleLine'>
                <DuaCol style={{ minHeight: 10 }}>
                  <span>FECHA DE ACEPTACION</span>
                  <DuaRow noBorder>
                    <DuaCol width={'33%'}>
                      <DuaCell className='cellDoubleLine'>DD</DuaCell>
                    </DuaCol>
                    <DuaCol width={'33%'}>
                      <DuaCell className='cellDoubleLine'>MM</DuaCell>
                    </DuaCol>
                    <DuaCol width={'34%'}>
                      <DuaCell className='cellDoubleLine'>AAAA</DuaCell>
                    </DuaCol>
                  </DuaRow>
                </DuaCol>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'16%'} style={{ textAlign: 'center' }}>
              <DuaCell>COD.</DuaCell>
            </DuaCol>
            <DuaCol width={'54%'} style={{ textAlign: 'center' }}>
              <DuaCell>AGENTE ADUANERO (6)</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>
      <DuaRow>
        <DuaCol width={'40%'}>
          <DuaRow noBorder>
            <DuaCol width={'12%'} style={{ textAlign: 'center' }}>
              <DuaCell>
                {/* Codigo Regimen */}
                <DuaData>{data?.caratula?.regimenAduaneroCodigo}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'38%'}>
              <DuaCell>
                {/* Regimen */}
                <DuaData>{data?.caratula?.regimenAduanero}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'12%'} style={{ textAlign: 'center' }}>
              <DuaCell>
                {/* Codigo Aduana */}
                <DuaData>{data?.caratula?.aduanaDespachoCodigo}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'35%'}>
              <DuaCell>
                {/* Aduana */}
                <DuaData>{data?.caratula?.aduanaDespacho}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'20%'}>
          <DuaRow noBorder>
            <DuaCol width={'30%'} style={{ textAlign: 'center' }}>
              <DuaCell>
                {/* Año */}
                <DuaData>{new Date().getFullYear()}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'70%'}>
              <DuaCell>
                {/* No. Correlativo */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'40%'}>
          <DuaRow noBorder>
            <DuaCol width={'30%'} style={{ textAlign: 'center' }}>
              <DuaCell>
                <DuaRow noBorder>
                  <DuaCol width={'33%'}>
                    <DuaCell>
                      {/* Fecha de Aceptacion Dia */}
                      <DuaData>{new Date().getDate()}</DuaData>
                    </DuaCell>
                  </DuaCol>
                  <DuaCol width={'33%'}>
                    <DuaCell style={{ padding: 0 }}>
                      {/* Fecha de Aceptacion Mes */}
                      <DuaData>{new Date().getMonth() + 1}</DuaData>
                    </DuaCell>
                  </DuaCol>
                  <DuaCol width={'34%'}>
                    <DuaCell style={{ padding: 0 }}>
                      {/* Fecha de Aceptacion Año */}
                      <DuaData>{new Date().getFullYear()}</DuaData>
                    </DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'16%'} style={{ textAlign: 'center' }}>
              <DuaCell>
                {/* Codigo Agente Aduanero */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'54%'} style={{ textAlign: 'center' }}>
              <DuaCell>
                {/* Agente Aduanero */}
                <DuaData>{Configuraciones?.nombreLegal}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>

      {/* II. NOMBRE O RAZON SOCIAL DEL: */}
      <DuaRow style={{ alignItems: 'flex-start' }}>
        <DuaCol width={'50%'}>
          <DuaRow noBorder style={{ borderBottom: border }}>
            <DuaCell noBorder className='duaSection' style={{ whiteSpace: 'nowrap' }}>
              II. NOMBRE O RAZON SOCIAL DEL:
            </DuaCell>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 5px',
                fontSize: 7,
              }}
            >
              <span>IMPORTADOR (7)</span>
              <span>EXPORTADOR (8)</span>
              <span>CONSIGNATARIO (9)</span>
              <span>PROVEEDOR (10)</span>
            </div>
          </DuaRow>
          <DuaRow noBorder style={{ borderBottom: border }}>
            <DuaCol width={'35px'} />
            <DuaCol noBorder width={'70%'}>
              <DuaRow noBorder style={{ justifyContent: 'space-between' }}>
                <DuaCell noBorder style={{ whiteSpace: 'nowrap' }}>
                  1er. APELLIDO
                </DuaCell>
                <DuaCell noBorder style={{ whiteSpace: 'nowrap' }}>
                  2do. APELLIDO
                </DuaCell>
                <DuaCell noBorder style={{ whiteSpace: 'nowrap' }}>
                  1er. NOMBRE
                </DuaCell>
                <DuaCell noBorder style={{ whiteSpace: 'nowrap' }}>
                  2do. NOMBRE
                </DuaCell>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'22%'} style={{ textAlign: 'center' }}>
              <DuaCell>R.T.N.</DuaCell>
            </DuaCol>
          </DuaRow>
          <DuaRow noBorder>
            <DuaCol width={'78%'}>
              <DuaRow noBorder style={{ borderBottom: border }}>
                <DuaCol width={'35px'} style={{ textAlign: 'center' }}>
                  <DuaCell>
                    <DuaData>7</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'calc(100% - 35px)'}>
                  <DuaCell>
                    {/* Nombre del Cliente */}
                    <DuaData>{data?.caratula.importador}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
              <DuaRow noBorder>
                <DuaCol width={'35px'} style={{ textAlign: 'center' }}>
                  <DuaCell>
                    <DuaData>10</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'calc(100% - 35px)'}>
                  <DuaCell>
                    {/* Nombre del Proveedor */}
                    <DuaData>{data?.caratula.proveedor}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'22%'}>
              <DuaCell>
                {/* R.T.N. */}
                <DuaData>{data?.caratula.rtnImportador}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'50%'} style={{ minHeight: '100%' }}>
          <DuaCell noBorder>DIRECCION (11)</DuaCell>
          <DuaCell>
            {/* Direccion del Cliente */}
            <DuaData>{data?.caratula.importadorDireccion}</DuaData>
          </DuaCell>
        </DuaCol>
      </DuaRow>

      {/* III. DATOS DOCUMENTALES DE LA MERCANCIA | IV. DATOS MEDIODE TRANSPORTE */}
      <DuaRow>
        <DuaCol width={'50%'}>
          <DuaCell className='duaSection'>III. DATOS DOCUMENTALES DE LA MERCANCIA</DuaCell>
        </DuaCol>
        <DuaCol width={'50%'}>
          <DuaRow noBorder>
            <DuaCell noBorder className='duaSection'>
              IV. DATOS MEDIO DE TRANSPORTE
            </DuaCell>
            <DuaCell style={{ marginLeft: 12 }}>CONTROL / TRANSITO</DuaCell>
          </DuaRow>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ borderBottom: 'none' }}>
        <DuaCol width={'4%'}>
          <DuaCell noBorder>&nbsp;</DuaCell>
        </DuaCol>
        <DuaCol width={'21%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'50%'}>
              <DuaCell style={{ whiteSpace: 'nowrap' }}>PAIS DE ORIGEN (12)</DuaCell>
            </DuaCol>
            <DuaCol width={'50%'} style={{ textAlign: 'end' }}>
              <DuaCell style={{ whiteSpace: 'nowrap' }}>PROCEDENCIA (13)</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaCell className='cellDoubleLine'>
            No. DE CONOCIMIENTO DE EMBARQUE BL (14) <LineBreak />O DOCUMENTO EQUIVALENTE
          </DuaCell>
        </DuaCol>
        <DuaCol width={'19%'}>
          <DuaCell>MEDIO TRANSPORTE (15)</DuaCell>
        </DuaCol>
        <DuaCol width={'6%'} style={{ textAlign: 'center' }}>
          <DuaCell>COD</DuaCell>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaCell>NOMBRE DEL TRANSPORTISTA (16)</DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow>
        <DuaCol width={'4%'}>
          <DuaCell>&nbsp;</DuaCell>
        </DuaCol>
        <DuaCol width={'21%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'50%'}>
              <DuaCell>
                {/* Pais de Origen */}
                <DuaData>{data?.caratula.paisOrigenAbreviatura}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'50%'} style={{ textAlign: 'end' }}>
              <DuaCell>
                {/* Pais Procedencia Destino */}
                <DuaData>{data?.caratula.paisProcedenciaDestinoAbreviatura}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaCell>
            {/* No. De conocimiento de embarque bl  o documento equivalente */}
            <DuaData>{data?.bultos.tituloTransporte}</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'19%'}>
          <DuaCell>
            {/* Medio de Transporte */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'6%'} style={{ textAlign: 'center' }}>
          <DuaCell>
            {/* Codigo Medio de Transporte */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaCell>
            {/* Nombre del Transportista */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ borderBottom: 'none' }}>
        <DuaCol width={'4%'}>
          <DuaCell>&nbsp;</DuaCell>
        </DuaCol>
        <DuaCol width={'21%'}>
          <DuaCell>DESTINO (17)</DuaCell>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'75%'}>
              <DuaCell>NOS. FACTURAS (18)</DuaCell>
            </DuaCol>
            <DuaCol width={'25%'}>
              <DuaCell>CANTIDAD</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'19%'}>
          <DuaCell>No. PLACA CABEZAL / CAMION (19)</DuaCell>
        </DuaCol>
        <DuaCol width={'6%'} style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
          <DuaCell>COD NAC</DuaCell>
        </DuaCol>
        <DuaCol width={'19%'} style={{ whiteSpace: 'nowrap' }}>
          <DuaCell>N° FURGON O N° CONTENEDOR (20)</DuaCell>
        </DuaCol>
        <DuaCol width={'6%'} style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
          <DuaCell>COD NAC</DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow>
        <DuaCol width={'4%'}>
          <DuaCell>&nbsp;</DuaCell>
        </DuaCol>
        <DuaCol width={'21%'}>
          <DuaCell>
            {/* Pais Destino */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'75%'}>
              <DuaCell>
                {/* Facturas */}
                <DuaData>{data?.facturas[0]}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'25%'} style={{ textAlign: 'end' }}>
              {/* Cantidad de Facturas */}
              <DuaCell>
                <DuaData>{data?.facturas?.length}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'19%'}>
          <DuaCell>
            {/* No. Placa Cabezal / Camion */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'6%'} style={{ textAlign: 'center' }}>
          <DuaCell>
            {/* Codigo Nac */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'19%'}>
          <DuaCell>
            {/* N° Furgon o N° Contenedor */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'6%'} style={{ textAlign: 'center' }}>
          <DuaCell>
            {/* Cod Nac */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ borderBottom: 'none' }}>
        <DuaCol width={'4%'}>
          <DuaCell>&nbsp;</DuaCell>
        </DuaCol>
        <DuaCol width={'21%'}>
          <DuaCell>No. MANIFIESTO (21)</DuaCell>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaCell>No. DECLARACION EXPORTACION / BANCO (22)</DuaCell>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaCell>ADUANA ENTRADA / PARTIDA (23)</DuaCell>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaCell>ADUANA SALIDA / DESTINO (24)</DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ overflow: 'initial' }}>
        <DuaCol width={'4%'}>
          <DuaCell>&nbsp;</DuaCell>
        </DuaCol>
        <DuaCol width={'21%'}>
          <DuaCell>
            {/* No. Manifiesto */}
            <DuaData>{data?.bultos.manifiesto}</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaCell>
            {/* No. Declaracion Exportacion / Banco */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol className='cellAduanas' width={'25%'}>
          <DuaCell noBorder>
            {/* Aduana Entrada / Partida */}
            <DuaData>{data?.caratula.aduanaIngresoSalida}</DuaData>
          </DuaCell>
          <DuaRow noBorder>
            <DuaCol noBorder width={'50%'}>
              <DuaCell>FECHA:</DuaCell>
            </DuaCol>
            <DuaCol width={'50%'}>
              <DuaCell>HORA:</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol className='cellAduanas' width={'25%'}>
          <DuaCell noBorder>
            {/* Aduana Salida / Destino */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
          <DuaRow noBorder>
            <DuaCol noBorder width={'50%'}>
              <DuaCell>FECHA:</DuaCell>
            </DuaCol>
            <DuaCol width={'50%'}>
              <DuaCell>HORA:</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ alignItems: 'flex-start' }}>
        <DuaCol width={'50%'} style={{ textAlign: 'center' }}>
          <DuaRow noBorder style={{ alignItems: 'flex-start' }}>
            <DuaCol width={'32%'} style={{ whiteSpace: 'nowrap' }}>
              <DuaCell>FECHA DE CANCELACION (25)</DuaCell>
              <DuaRow noBorder>
                <DuaCol width={'33%'}>
                  <DuaCell noBorder>DD</DuaCell>
                  <DuaCell>
                    {/* Fecha de Cancelacion Dia */}
                    <DuaData>{new Date().getDate()}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'33%'}>
                  <DuaCell noBorder>MM</DuaCell>
                  <DuaCell>
                    {/* Fecha de Cancelacion Mes */}
                    <DuaData>{new Date().getMonth()}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'33%'}>
                  <DuaCell noBorder>AAAA</DuaCell>
                  <DuaCell>
                    {/* Fecha de Cancelacion Año */}
                    <DuaData>{new Date().getFullYear()}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol
              width={'calc(18% + 1px)'}
              style={{ justifyContent: 'flex-start', position: 'absolute', left: '32%' }}
            >
              <DuaCell>UBICACION (26)</DuaCell>
            </DuaCol>
            <DuaCol
              width={'50%'}
              style={{ justifyContent: 'flex-start', position: 'absolute', left: '50%' }}
            >
              <DuaCell>MARCAS Y CONTRAMARCAS (27)</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol width={'50%'}>
          <DuaRow noBorder style={{ borderBottom: border }}>
            <DuaCol width={'50%'}>
              <DuaCell noBorder>No. MARCHAMO ORIGEN (28)</DuaCell>
              <DuaCell>
                {/* No. Marchamo Origen */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'50%'}>
              <DuaCell noBorder>No. MARCHAMO ADUANA (29)</DuaCell>
              <DuaCell>
                {/* No. Marchamo Aduana */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>

      {/* V. DATOS DEL VALOR (C30) */}
      <DuaRow>
        <DuaCol
          width={'7%'}
          style={{ textAlign: 'center', verticalAlign: 'center', position: 'absolute' }}
        >
          <DuaCell className='duaSection'>
            V. <LineBreak />
            DATOS DEL <LineBreak />
            VALOR <LineBreak />
            (C30)
          </DuaCell>
        </DuaCol>
        <DuaCol width={'93%'} style={{ textAlign: 'center', position: 'relative', left: '7%' }}>
          <DuaRow noBorder style={{ borderBottom: border }}>
            <DuaCol width={'12.5%'}>
              <DuaCell>TASA CAMBIARIA</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>VALOR FOB $</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>FLETE $</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>SEGURO $</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>OTROS GASTOS $</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>TOTAL CIF $</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>AJUSTES</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>CIF IMPONIBLE $</DuaCell>
            </DuaCol>
          </DuaRow>
          <DuaRow noBorder style={{ borderBottom: border }}>
            <DuaCol width={'12.5%'}>
              <DuaCell>
                {/* Tasa Cambiaria */}
                <DuaData>{data?.tasaCambioDolar}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>
                {/* Valor Fob */}
                <DuaData>{NumberFormat(data?.caratula?.totalFactura)}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>
                {/* Flete */}
                <DuaData>{NumberFormat(data?.caratula?.totalFlete)}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>
                {/* Seguro */}
                <DuaData>{NumberFormat(data?.caratula?.totalSeguro)}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>
                {/* Otros Gastos */}
                <DuaData>{NumberFormat(data?.caratula?.totalOtrosGastos)}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>
                {/* Total Cif */}
                <DuaData>{NumberFormat(data?.totalCIF)}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>
                {/* Ajustes */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>
                {/* Cif Imponible */}
                <DuaData>{NumberFormat(data?.totalCIF)}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
          <DuaRow noBorder>
            <DuaCol width={'12.5%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'12.5%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>

      {/* VI. DECLARACION DE LA MERCANCIA */}
      <DuaRow>
        <DuaCol>
          <DuaCell className='duaSection'>VI. DECLARACION DE MERCANCIA (31)</DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ textAlign: 'center' }}>
        <DuaCol width={'20%'}>
          <DuaCell>BULTOS</DuaCell>
          <DuaRow noBorder>
            <DuaCol width={'13%'}>
              <DuaCell style={{ padding: 0 }}>PDA</DuaCell>
            </DuaCol>
            <DuaCol width={'28%'}>
              <DuaCell style={{ padding: 0 }}>CANTIDAD</DuaCell>
            </DuaCol>
            <DuaCol width={'21%'}>
              <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                CODIGO <LineBreak />
                UNIDAD
              </DuaCell>
            </DuaCol>
            <DuaCol width={'38%'}>
              <DuaCell style={{ padding: 0 }}>KILOS BRUTOS</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'10%'} style={{ position: 'absolute', left: '20%' }}>
          <DuaCell>
            POSICION <LineBreak />
            ARANCELARIA
          </DuaCell>
        </DuaCol>
        <DuaCol
          width={'36%'}
          style={{
            position: 'absolute',
            left: '30%',
            justifyContent: 'flex-start',
            paddingTop: 3,
          }}
        >
          <DuaCell className='cellDoubleLine'>
            DESCRIPCION DE LA MERCANCIA <LineBreak />
            CARACTERISTICAS FISICAS Y TECNICAS <LineBreak />
            (NO ARANCELARIA)
          </DuaCell>
        </DuaCol>
        <DuaCol width={'10%'} style={{ position: 'absolute', left: '66%' }}>
          <DuaCell>
            VALOR <LineBreak />
            FOB $
          </DuaCell>
        </DuaCol>
        <DuaCol width={'10%'} style={{ position: 'absolute', left: '76%' }}>
          <DuaCell>
            VALOR <LineBreak />
            CIF $
          </DuaCell>
        </DuaCol>
        <DuaCol
          width={'4%'}
          style={{
            position: 'absolute',
            left: '86%',
            justifyContent: 'flex-start',
            paddingTop: 3,
          }}
        >
          <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
            GRAV. <LineBreak />
            ADV <LineBreak /> % S/CIF
          </DuaCell>
        </DuaCol>
        <DuaCol width={'10%'} style={{ position: 'absolute', left: '90%' }}>
          <DuaCell>
            TOTAL <LineBreak />
            DERECHOS
          </DuaCell>
        </DuaCol>
      </DuaRow>

      {/* Productos */}
      {productos.map((producto, index) => (
        <Fragment key={index}>
          <DuaRow style={{ textAlign: 'center' }}>
            <DuaCol width={'20%'}>
              <DuaRow noBorder>
                <DuaCol width={'13%'}>
                  <DuaCell style={{ padding: 0 }}>
                    {/* PDA */}
                    <DuaData>{index + 1}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'28%'}>
                  <DuaCell style={{ padding: 0 }}>
                    {/* Cantidad */}
                    <DuaData>{NumberFormat(producto?.cantidadComercial)}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'21%'}>
                  <DuaCell style={{ padding: 0 }}>
                    {/* Codigo Unidad */}
                    <DuaData>{producto?.unidadComercialCodigo}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'38%'}>
                  <DuaCell>
                    {/* Kilos Brutos */}
                    <DuaData>{NumberFormat(producto?.pesoBruto)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaCell>
                {/* Posicion Arancelaria */}
                <DuaData className='posicionArancelaria'>
                  {FormatoPartidaArancelaria(producto?.posicionArancelaria)}
                </DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'36%'} style={{ textAlign: 'start' }}>
              <DuaCell>
                {/* Descripcion */}
                <DuaData>{producto?.descripcion}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* Valor Fob Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(producto.importeFOB))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* Valor Fob Decimales */}
                    <DuaData>.{GetDecimals(producto.importeFOB)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* Valor Cif Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(producto.valorCIF))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* Valor Cif Decimales */}
                    <DuaData>.{GetDecimals(producto.valorCIF)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'4%'}>
              <DuaCell>
                {/* Dai */}
                <DuaData>{producto.gravadoPorcentajeCIF}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* Total Derechos Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(producto.totalDerechos))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* Total Derechos Decimales */}
                    <DuaData>.{GetDecimals(producto.totalDerechos)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow style={{ textAlign: 'center', borderBottom: 'none' }}>
            <DuaCol width={'20%'}>
              <DuaRow noBorder>
                <DuaCol width={'13%'}>
                  <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'28%'}>
                  <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'21%'}>
                  <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'38%'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'36%'}>
              <DuaCell>
                {/* Descripcion */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'4%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          {index < 3 && <DuaRow style={{ backgroundColor: '#c0c0c0', borderBottom: 'none' }} />}
        </Fragment>
      ))}

      {/* Productos Vacios */}
      {/* TODO: Provar con menos de 4 productos, hacerlo desde el backend */}
      {Array(cantidadProductosVacios)
        .fill(0)
        .map((_, index) => (
          <Fragment key={index}>
            <DuaRow>
              <DuaCol width={'20%'}>
                <DuaRow noBorder>
                  <DuaCol width={'13%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'28%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'21%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'38%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'36%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'4%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
            </DuaRow>
            <DuaRow style={{ textAlign: 'center', borderBottom: 'none' }}>
              <DuaCol width={'20%'}>
                <DuaRow noBorder>
                  <DuaCol width={'13%'}>
                    <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'28%'}>
                    <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'21%'}>
                    <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'38%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'36%'}>
                <DuaCell>
                  {/* Descripcion */}
                  <DuaData>&nbsp;</DuaData>
                </DuaCell>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'4%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
            </DuaRow>
            {index === cantidadProductosVacios - 1 && (
              <DuaRow style={{ backgroundColor: '#c0c0c0', borderBottom: 'none' }} />
            )}
          </Fragment>
        ))}

      {/* Totales */}
      <DuaRow style={{ backgroundColor: '#c0c0c0', borderBottom: 'none' }}>
        <DuaCol noBorder width={'20%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'13%'} style={{ textAlign: 'center' }}>
              <DuaCell style={{ padding: 0 }}>
                {/* Ultimo PDA */}
                <DuaData>{productos.length}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol noBorder width={'28%'} style={{ textAlign: 'center' }}>
              <DuaCell style={{ padding: 0 }}>
                {/* Total Cantidad */}
                <DuaData>{NumberFormat(cantidadComercialTotal)}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol noBorder width={'21%'}>
              <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol noBorder width={'38%'} style={{ textAlign: 'center' }}>
              <DuaCell>
                {/* Total Kilos Brutos */}
                <DuaData>{NumberFormat(data?.totalPesoBruto)}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol noBorder width={'10%'}>
          <DuaCell>&nbsp;</DuaCell>
        </DuaCol>
        <DuaCol noBorder width={'36%'} style={{ textAlign: 'center' }}>
          <DuaCell>TOTALES</DuaCell>
        </DuaCol>
        <DuaCol noBorder width={'10%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
              <DuaCell>
                {/* Total Valor Fob Enteros */}
                <DuaData>{NumberFormat(Math.trunc(totalFOB))}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
              <DuaCell>
                {/* Total Valor Fob Decimales */}
                <DuaData>.{GetDecimals(totalFOB)}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol noBorder width={'10%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
              <DuaCell>
                {/* Total Valor Cif Enteros */}
                <DuaData>{NumberFormat(Math.trunc(totalCIF))}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
              <DuaCell>
                {/* Total Valor Cif Decimales */}
                <DuaData>.{GetDecimals(totalCIF)}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol noBorder width={'4%'}>
          <DuaCell>&nbsp;</DuaCell>
        </DuaCol>
        <DuaCol width={'10%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
              <DuaCell>
                {/* Total Derechos Enteros */}
                <DuaData>{NumberFormat(Math.trunc(totalDerechos))}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
              <DuaCell>
                {/* Total Derechos Decimales */}
                <DuaData>.{GetDecimals(totalDerechos)}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>

      {/* VII.  GARANTIA (32) | VIII. LIQUIDACION (33) | N° DE CONTRAESCRITURA (34) */}
      <DuaRow>
        <DuaCol width={'46%'}>
          <DuaCell className='duaSection'>VII. GARANTIA (32)</DuaCell>
        </DuaCol>
        <DuaCol width={'54%'}>
          <DuaCell className='duaSection'>
            <span style={{ marginRight: 20 }}>VII. LIQUIDACION (33)</span>
            <span>N° DE CONTRAESCRITURA (34)</span>
          </DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow>
        <DuaCol width={'16%'} style={{ position: 'absolute' }}>
          <DuaRow noBorder style={{ borderBottom: border }}>
            <DuaCol noBorder width={'auto'}>
              <DuaCell noBorder>TIPO</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>
                {/* Tipo Garantia */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
          <DuaRow noBorder>
            <DuaCol noBorder width={'auto'}>
              <DuaCell noBorder>N°</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>
                {/* Numero Garantia */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol width={'84%'} style={{ position: 'relative', left: '16%' }}>
          <DuaRow
            noBorder
            style={{
              textAlign: 'center',
              borderBottom: border,
              display: 'grid',
              gridTemplateColumns: '18% calc(18% - 2px) 4% 3% 13% 14.7%  14.9%  14.7%',
            }}
          >
            <DuaCol>
              <DuaCell>MONTO</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>FECHA DE VENCIMIENTO</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell className='cellDoubleLine'>COD (B)</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell className='cellDoubleLine'>COD (D)</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>CTAS. Y SUB CTAS.</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>MONTO EXONERADO</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>AUTODETERMINACION</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell style={{ whiteSpace: 'nowrap' }}>LIQUIDADO / ADUANA</DuaCell>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '18% calc(18% - 2px) 4% 3% 13% 14.7%  14.9% 14.7%',
            }}
          >
            <DuaCol>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'33%'}>
                  <DuaCell>DD</DuaCell>
                </DuaCol>
                <DuaCol width={'33%'}>
                  <DuaCell>MM</DuaCell>
                </DuaCol>
                <DuaCol width={'33%'}>
                  <DuaCell>AAAA</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol style={{ borderBottom: border }}>
              <DuaCell>115-</DuaCell>
            </DuaCol>
            <DuaCol style={{ borderBottom: border }}>
              <DuaCell>01</DuaCell>
            </DuaCol>
            <DuaCol style={{ borderBottom: border }}>
              <DuaCell className='cellDoubleLine' style={{ whiteSpace: 'pre-line' }}>
                IMPORTACION <LineBreak />
                (01)(02)(03)(04)(05)
              </DuaCell>
            </DuaCol>
            <DuaCol style={{ borderBottom: border }}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol style={{ borderBottom: border }}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(totalDerechos))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>.{GetDecimals(totalDerechos)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol style={{ borderBottom: border }}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '18% calc(18% - 2px) 4% 3% 13% 14.7% 14.9% 14.7%',
            }}
          >
            <DuaCol>
              <DuaCell>
                {/* Monto */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'33%'}>
                  <DuaCell>
                    {/* Fecha de Vencimiento Dia */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'33%'}>
                  <DuaCell>
                    {/* Fecha de Vencimiento Mes */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'33%'}>
                  <DuaCell>
                    {/* Fecha de Vencimiento Año */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaCell>116-</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>28</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                EXPORTACION
                <LineBreak />
                (01)(02)
              </DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>

      {/* IX. OBSERVACIONES */}
      <DuaRow>
        <DuaCol width={'46%'} style={{ position: 'absolute', justifyContent: 'flex-start' }}>
          <DuaCell noBorder className='duaSection'>
            IX. OBSERVACIONES
          </DuaCell>
          <DuaCell>
            {/* Observaciones */}
            <DuaData style={{ whiteSpace: 'pre-wrap' }}>{data?.caratula.observaciones}</DuaData>
          </DuaCell>
        </DuaCol>

        <DuaCol width={'54%'} style={{ position: 'relative', left: '46%' }}>
          <DuaRow
            noBorder
            style={{
              borderBottom: border,
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '6.3% 4.7% 20.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCell>113-</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                52
                <LineBreak />
                53
              </DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell className='cellDoubleLine'>
                SELECTIVO AL <LineBreak />
                CONSUMO (10)(11)
              </DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(totalISC))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>.{GetDecimals(totalISC)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              borderBottom: border,
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '6.3% 4.7% 20.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCell style={{ padding: 0 }}>12106</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>11</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                TASA ADMINISTRATIVA
                <LineBreak />
                ADUANERA (0.5%)
              </DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              borderBottom: border,
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '6.3% 4.7% 20.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCell style={{ padding: 0 }}>125-</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>08</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                MULTAS
                <LineBreak />
                (01)(02)
              </DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              borderBottom: border,
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '6.3% 4.7% 20.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCell style={{ padding: 0 }}>14201</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>61</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>AEROPUESTO</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              borderBottom: border,
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '6.3% 4.7% 20.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCell style={{ padding: 0 }}>14202</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>62</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>ALMACENAJE</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              borderBottom: border,
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '6.3% 4.7% 20.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCell style={{ padding: 0 }}>14203</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>63</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>ACARREO Y ESTIBA</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              borderBottom: border,
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '6.3% 4.7% 20.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCol>
                <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                  128-
                  <LineBreak />
                  121-
                </DuaCell>
              </DuaCol>
            </DuaCol>
            <DuaCol>
              <DuaCell>87</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCol>
                <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                  OTROS
                  <LineBreak />
                  (01)(02)
                </DuaCell>
              </DuaCol>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(otrosGrastos))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>.{GetDecimals(otrosGrastos)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              borderBottom: border,
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '6.3% 4.7% 20.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCol>
                <DuaCell>113-</DuaCell>
              </DuaCol>
            </DuaCol>
            <DuaCol>
              <DuaCell>4-</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCol>
                <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                  PROD. Y CONSUMO
                  <LineBreak />
                  (01)(02)(03)(06)(09)
                </DuaCell>
              </DuaCol>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(totalIPC))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>.{GetDecimals(totalIPC)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              borderBottom: border,
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '6.3% 4.7% 20.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCol>
                <DuaCell>113-</DuaCell>
              </DuaCol>
            </DuaCol>
            <DuaCol>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCol>
                <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                  IMPUESTO/VENTA
                  <LineBreak />
                  (07)(08)
                </DuaCell>
              </DuaCol>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(totalISV))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>.{GetDecimals(totalISV)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow
            noBorder
            style={{
              textAlign: 'center',
              display: 'grid',
              gridTemplateColumns: '6.3% 4.7% 20.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCol>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
            </DuaCol>
            <DuaCol>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCol>
                <DuaCell>CREDITOS</DuaCell>
              </DuaCol>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>

      {/* X. DILIGENCIAS (36) */}
      <DuaRow>
        <DuaCol width={'46%'}>
          <DuaCell className='duaSection'>X. DILIGENCIAS (36)</DuaCell>
        </DuaCol>

        <DuaCol width={'54%'}>
          <DuaRow
            noBorder
            style={{
              display: 'grid',
              gridTemplateColumns: '31.1% 22.97% 23.1% 22.97%',
            }}
          >
            <DuaCol>
              <DuaCell>TOTAL LIQUIDACION</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Monto Exonerado Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Monto Exonerado Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(totalLiquidacion))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* AutoDeterminacion Decimales */}
                    <DuaData>.{GetDecimals(totalLiquidacion)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Enteros */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>
                    {/* Liquidado / Aduana Decimales */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ borderBottom: 'none' }}>
        <DuaCol width={'46%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'66%'}>
              <DuaCell>1. DECLARANTE</DuaCell>
            </DuaCol>
            <DuaCol width={'34%'} style={{ textAlign: 'center' }}>
              <DuaRow noBorder>
                <DuaCol noBorder width={'30%'} />
                <DuaCol width={'70%'}>
                  <DuaCell>FECHA</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol width={'54%'}>
          <DuaRow
            noBorder
            style={{
              borderBottom: border,
              display: 'grid',
              gridTemplateColumns: '31.1% 68.9%',
            }}
          >
            <DuaCol>
              <DuaCell>TOTAL EN LETRAS</DuaCell>
            </DuaCol>
            <DuaCol>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ borderBottom: 'none' }}>
        <DuaCol width={'46%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'66%'}>
              <DuaCell style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <b>F</b>
                <span style={{ display: 'block', borderBottom: border, width: '100%' }} />
              </DuaCell>
            </DuaCol>
            <DuaCol width={'34%'} style={{ textAlign: 'center' }}>
              <DuaRow noBorder>
                <DuaCol width={'30%'} />
                <DuaCol width={'70%'}>
                  <DuaRow noBorder style={{ borderTop: border }}>
                    <DuaCol width={'33%'}>
                      <DuaCell>DD</DuaCell>
                    </DuaCol>
                    <DuaCol width={'33%'}>
                      <DuaCell>MM</DuaCell>
                    </DuaCol>
                    <DuaCol width={'34%'}>
                      <DuaCell>AAAA</DuaCell>
                    </DuaCol>
                  </DuaRow>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol width={'54%'}>
          <DuaRow noBorder style={{ borderBottom: border }} />
        </DuaCol>
      </DuaRow>

      {/* XI. DATOS PARA EL BANCO (37) */}
      <DuaRow>
        <DuaCol width={'46%'} style={{ textAlign: 'center' }}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'66%'}>
              <DuaCell>AGENTE ADUANERO</DuaCell>
            </DuaCol>
            <DuaCol width={'34%'}>
              <DuaRow noBorder>
                <DuaCol width={'30%'} />
                <DuaCol width={'70%'}>
                  <DuaRow noBorder>
                    <DuaCol width={'33%'}>
                      <DuaCell>
                        {/* Agente Aduanero Fecha DD */}
                        <DuaData>&nbsp;</DuaData>
                      </DuaCell>
                    </DuaCol>
                    <DuaCol width={'33%'}>
                      <DuaCell>
                        {/* Agente Aduanero Fecha MM */}
                        <DuaData>&nbsp;</DuaData>
                      </DuaCell>
                    </DuaCol>
                    <DuaCol width={'34%'}>
                      <DuaCell>
                        {/* Agente Aduanero Fecha Año */}
                        <DuaData>&nbsp;</DuaData>
                      </DuaCell>
                    </DuaCol>
                  </DuaRow>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol width={'54%'}>
          <DuaRow noBorder>
            <DuaCol>
              <DuaCell className='duaSection'>XI. DATOS PARA EL BANCO (37)</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ borderBottom: 'none' }}>
        <DuaCol width={'46%'}>
          <DuaRow noBorder>
            <DuaCol width={'66%'}>
              <DuaCell>2. AFORO</DuaCell>
            </DuaCol>
            <DuaCol width={'34%'} style={{ textAlign: 'center' }}>
              <DuaRow noBorder>
                <DuaCol width={'30%'}>
                  <DuaCell style={{ padding: 0 }}>N° COD</DuaCell>
                </DuaCol>
                <DuaCol width={'70%'}>
                  <DuaCell>FECHA</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol width={'54%'}>
          <DuaCell>MONTO</DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ borderBottom: 'none' }}>
        <DuaCol width={'46%'}>
          <DuaRow noBorder>
            <DuaCol width={'66%'}>
              <DuaCell style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <b>F</b>
                <span style={{ display: 'block', borderBottom: border, width: '100%' }} />
              </DuaCell>
            </DuaCol>
            <DuaCol width={'34%'} style={{ textAlign: 'center' }}>
              <DuaRow noBorder style={{ borderTop: border }}>
                <DuaCol width={'30%'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'70%'}>
                  <DuaRow noBorder>
                    <DuaCol width={'33%'}>
                      <DuaCell>DD</DuaCell>
                    </DuaCol>
                    <DuaCol width={'33%'}>
                      <DuaCell>MM</DuaCell>
                    </DuaCol>
                    <DuaCol width={'34%'}>
                      <DuaCell>AAAA</DuaCell>
                    </DuaCol>
                  </DuaRow>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow noBorder style={{ borderBottom: border }}>
            <DuaCol width={'66%'} style={{ textAlign: 'center' }}>
              <DuaCell>AFORADOR O CONTADOR VISTA</DuaCell>
            </DuaCol>
            <DuaCol width={'34%'}>
              <DuaRow noBorder>
                <DuaCol width={'30%'}>
                  <DuaCell>
                    {/* Aforador o contrador Nomero Codigo */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'70%'}>
                  <DuaRow noBorder>
                    <DuaCol width={'33%'}>
                      <DuaCell>
                        {/* Aforador o Contador Fecha DD */}
                        <DuaData>&nbsp;</DuaData>
                      </DuaCell>
                    </DuaCol>
                    <DuaCol width={'33%'}>
                      <DuaCell>
                        {/* Aforador o Contador Fecha MM */}
                        <DuaData>&nbsp;</DuaData>
                      </DuaCell>
                    </DuaCol>
                    <DuaCol width={'34%'}>
                      <DuaCell>
                        {/* Aforador o Contador Fecha Año */}
                        <DuaData>&nbsp;</DuaData>
                      </DuaCell>
                    </DuaCol>
                  </DuaRow>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol width={'54%'}>
          <DuaRow className='rowDoubleLine' noBorder>
            <DuaCol>
              <DuaCell>FECHA DE PAGO</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ borderBottom: 'none' }}>
        <DuaCol width={'46%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'66%'}>
              <DuaCell>3. AUTORIZACION</DuaCell>
            </DuaCol>
            <DuaCol width={'34%'} style={{ textAlign: 'center' }}>
              <DuaRow noBorder>
                <DuaCol noBorder width={'30%'} />
                <DuaCol width={'70%'}>
                  <DuaCell>FECHA</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol width={'54%'}>
          <DuaCell>FIRMA Y SELLO</DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ borderBottom: 'none' }}>
        <DuaCol width={'46%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'66%'}>
              <DuaCell style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <b>F</b>
                <span style={{ display: 'block', borderBottom: border, width: '100%' }} />
              </DuaCell>
            </DuaCol>
            <DuaCol width={'34%'} style={{ textAlign: 'center' }}>
              <DuaRow noBorder>
                <DuaCol width={'30%'} />
                <DuaCol width={'70%'}>
                  <DuaRow noBorder style={{ borderTop: border }}>
                    <DuaCol width={'33%'}>
                      <DuaCell>DD</DuaCell>
                    </DuaCol>
                    <DuaCol width={'33%'}>
                      <DuaCell>MM</DuaCell>
                    </DuaCol>
                    <DuaCol width={'34%'}>
                      <DuaCell>AAAA</DuaCell>
                    </DuaCol>
                  </DuaRow>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol width={'54%'}>
          <DuaCell className='cellDoubleLine' style={{ fontSize: 6 }}>
            NO ES VALIDO SIN LA FIRMA Y<LineBreak />
            EL SELLO DEL RECEPTOR
          </DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow>
        <DuaCol width={'46%'}>
          <DuaRow noBorder>
            <DuaCol noBorder width={'66%'} style={{ textAlign: 'center' }}>
              <DuaCell>ADMINISTRADOR O DESIGNADO</DuaCell>
            </DuaCol>
            <DuaCol width={'34%'} style={{ textAlign: 'center' }}>
              <DuaRow noBorder>
                <DuaCol width={'30%'} />
                <DuaCol width={'70%'}>
                  <DuaRow noBorder>
                    <DuaCol width={'33%'}>
                      <DuaCell>
                        {/* Administrador o Designado Fecha Dia */}
                        <DuaData>&nbsp;</DuaData>
                      </DuaCell>
                    </DuaCol>
                    <DuaCol width={'33%'}>
                      <DuaCell>
                        {/* Administrador o Designado Fecha Mes */}
                        <DuaData>&nbsp;</DuaData>
                      </DuaCell>
                    </DuaCol>
                    <DuaCol width={'34%'}>
                      <DuaCell>
                        {/* Administrador o Designado Fecha Año */}
                        <DuaData>&nbsp;</DuaData>
                      </DuaCell>
                    </DuaCol>
                  </DuaRow>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol width={'54%'}>
          <DuaRow noBorder style={{ justifyContent: 'flex-end', paddingRight: 50 }}>
            <DuaCol noBorder width={'auto'}>
              <DuaCell style={{ fontSize: 15 }}>SERIE No.</DuaCell>
            </DuaCol>
            <DuaCol width={'auto'}>
              <DuaCell style={{ color: 'red' }}>
                {/* Folio Preimpreso */}
                <DuaData style={{ fontSize: 15 }}>{data?.caratula.numeroPreImpreso}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>
      <DuaRow noBorder>
        <DuaCol noBorder width={'15%'} />
        <DuaCol noBorder width={'40%'} style={{ borderBottom: border }} />
        <DuaCol width={'45%'} />
      </DuaRow>
    </DuaCol>
  );
};
