import { CloseOutlined, DeleteOutlined, FormOutlined, SaveOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { IDucaBultoLineaRequest } from 'models/Duca';
import { tableColumnTextFilter } from 'utils/tableColumnFilter';

interface IColumnsProps {
  isEditing: (values: IDucaBultoLineaRequest) => boolean;
  onSave: (key: string) => void;
  onEdit: (values: IDucaBultoLineaRequest) => void;
  onDelete: (key: string) => void;
  onCancel: () => void;
  ordenCerrada: boolean;
}

export const Columns = ({
  isEditing,
  onSave,
  onEdit,
  onDelete,
  onCancel,
  ordenCerrada,
}: IColumnsProps) => {
  if (ordenCerrada) {
    return [
      {
        title: 'Linea',
        dataIndex: 'numeroLinea',
        align: 'left',
        width: 150,
        editable: true,
        sorter: (a: any, b: any) => a.numeroLinea - b.numeroLinea,
        ...tableColumnTextFilter<IDucaBultoLineaRequest>('numeroLinea'),
      },
      {
        title: 'Unidades Afectadas',
        dataIndex: 'cantidadUnidadesAfectadas',
        align: 'left',
        width: 250,
        editable: true,
        sorter: (a: any, b: any) => a.cantidadUnidadesAfectadas - b.cantidadUnidadesAfectadas,
        ...tableColumnTextFilter<IDucaBultoLineaRequest>('cantidadUnidadesAfectadas'),
      },
      {
        title: 'Peso Afectado',
        dataIndex: 'pesoAfectado',
        align: 'left',
        width: 250,
        editable: true,
        sorter: (a: any, b: any) => a.pesoAfectado - b.pesoAfectado,
        ...tableColumnTextFilter<IDucaBultoLineaRequest>('pesoAfectado'),
      },
    ];
  } else {
    return [
      {
        title: 'Linea',
        dataIndex: 'numeroLinea',
        align: 'left',
        width: 150,
        editable: true,
        sorter: (a: any, b: any) => a.numeroLinea - b.numeroLinea,
        ...tableColumnTextFilter<IDucaBultoLineaRequest>('numeroLinea'),
      },
      {
        title: 'Unidades Afectadas',
        dataIndex: 'cantidadUnidadesAfectadas',
        align: 'left',
        width: 250,
        editable: true,
        sorter: (a: any, b: any) => a.cantidadUnidadesAfectadas - b.cantidadUnidadesAfectadas,
        ...tableColumnTextFilter<IDucaBultoLineaRequest>('cantidadUnidadesAfectadas'),
      },
      {
        title: 'Peso Afectado',
        dataIndex: 'pesoAfectado',
        align: 'left',
        width: 250,
        editable: true,
        sorter: (a: any, b: any) => a.pesoAfectado - b.pesoAfectado,
        ...tableColumnTextFilter<IDucaBultoLineaRequest>('pesoAfectado'),
      },
      {
        title: 'Editar',
        dataIndex: 'editar',
        align: 'center',
        width: 50,
        render: (_: any, record: IDucaBultoLineaRequest) => {
          const editable = isEditing(record);
          return (
            <div style={{ textAlign: 'center' }}>
              {editable ? (
                <SaveOutlined
                  onClick={() => onSave(record.key)}
                  style={{ color: '#1890FF', fontSize: 16 }}
                />
              ) : (
                <FormOutlined
                  onClick={() => onEdit(record)}
                  style={{ color: '#FAAD14', fontSize: 16 }}
                />
              )}
            </div>
          );
        },
      },
      {
        title: 'Borrar',
        dataIndex: 'borrar',
        align: 'center',
        width: 50,
        render: (_: any, record: IDucaBultoLineaRequest) => {
          const editable = isEditing(record);
          return (
            <div style={{ textAlign: 'center' }}>
              {editable ? (
                <CloseOutlined
                  onClick={() => onCancel()}
                  style={{ color: '#FF4D4F', fontSize: 16 }}
                />
              ) : (
                <Popconfirm title='Suguro de eliminar?' onConfirm={() => onDelete(record.key)}>
                  <DeleteOutlined style={{ color: '#FF4D4F', fontSize: 16 }} />
                </Popconfirm>
              )}
            </div>
          );
        },
      },
    ];
  }
};
