import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { httpServiceFormData } from 'services/httpService';
import { IDevasState } from '../store';

export const bulkImportProductos = createAsyncThunk('Devas/BulkImport', httpServiceFormData);

export const bulkImportProductosReducer = (builder: ActionReducerMapBuilder<IDevasState>) => {
  builder.addCase(bulkImportProductos.fulfilled, (state, action) => {
    state.bulkImportStatus = 'succeeded';
    state.bulkImportFeedback = action.payload.feedbackMensajes;
    state.archivoBulkImport = undefined;
    state.bulkImportCreatedProducts = action.payload.productosCreados?.length;
  });
  builder.addCase(bulkImportProductos.pending, (state, _action) => {
    state.bulkImportStatus = 'pending';
  });
  builder.addCase(bulkImportProductos.rejected, (state, _action) => {
    state.bulkImportStatus = 'failed';
  });
};
