import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const queryVentaja = createAsyncThunk('DucaDetalles/Ventaja/Query', httpService);

export const queryVentajaReducer = (builder: ActionReducerMapBuilder<IDucaDetallesState>) => {
  builder.addCase(
    queryVentaja.fulfilled,
    (state, action: PayloadAction<PagedList<ConfiguracionesPetch>>) => {
      state.Ventajas = action.payload.items;
      state.VentajaStatus = 'succeeded';
    },
  );

  builder.addCase(queryVentaja.pending, state => {
    state.VentajaStatus = 'pending';
  });

  builder.addCase(queryVentaja.rejected, state => {
    state.VentajaStatus = 'failed';
  });
};
