import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DucaDetalles } from 'models/Duca';
import httpService from '../../../../services/httpService';
import { IArchivoPreliquidacionState } from '../store';

export const getDetallesDuca = createAsyncThunk(
  'ArchivoPreliquidacion/GetDetallesDuca',
  httpService,
);

export const getDetallesDucaReducer = (
  builder: ActionReducerMapBuilder<IArchivoPreliquidacionState>,
) => {
  builder.addCase(getDetallesDuca.fulfilled, (state, action: PayloadAction<DucaDetalles>) => {
    if (state.data) {
      state.data = {
        ...action.payload,
        ...state.data,
      };
    } else {
      state.data = { ...action.payload };
    }

    state.status = 'succeeded';
  });
  builder.addCase(getDetallesDuca.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getDetallesDuca.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
