import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import esEs from 'antd/lib/locale/es_ES';
import { InitialData } from 'features/InitialData/InitialData';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { App } from './App';
import { Auth0ProviderWithHistory } from './auth/Auth0ProviderWithHistory';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import 'moment/locale/es-mx';

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Auth0ProviderWithHistory>
        <Provider store={store}>
          <ConfigProvider locale={esEs}>
            <App />
            <InitialData />
          </ConfigProvider>
        </Provider>
      </Auth0ProviderWithHistory>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
