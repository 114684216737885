import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setError } from 'features/InitialData/store/initialDataStore';
import httpService, { IUseFetch, ThunckApi } from 'services/httpService';
import { IInitialState } from '../store';

export const deleteOrdenTrabajo = createAsyncThunk(
  'OrdenesTrabajo/Delete',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      if (document.fullscreenElement === null) {
        thunkApi.dispatch(setError({ message: 'Orden de Trabajo eliminada', error: false }));
      }
    }
    return res;
  },
);

export const deleteOrdenTrabajoReducer = (builder: ActionReducerMapBuilder<IInitialState>) => {
  // Delete Orden de Trabajo
  builder.addCase(deleteOrdenTrabajo.fulfilled, (state, action: PayloadAction<string>) => {
    const index = state.data.findIndex(orden => orden.id === action.payload);
    state.data.splice(index, 1);

    state.status = 'succeeded';
  });
  builder.addCase(deleteOrdenTrabajo.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(deleteOrdenTrabajo.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
