import { FormInstance } from 'antd';
import { ListadoDocumentos } from 'components/Duca/TablasDrawerItem/ListadoDocumentos/ListadoDocumentos';
import { IDucaItemRequest } from 'models/Duca';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';

interface ITabPaneDocumentos {
  form: FormInstance<IDucaItemRequest>;
}

export const TabPaneDocumentos = ({ form }: ITabPaneDocumentos) => {
  const { items, itemEditingKey } = useAppSelector(state => state.ducaDetalles);

  const { TableDocumentos, documentos } = ListadoDocumentos({
    data: items.find(item => item.key === itemEditingKey)?.documentos ?? [],
  });

  useEffect(() => {
    form.setFieldsValue({
      documentos,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentos]);

  return <TableDocumentos />;
};
