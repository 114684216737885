import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DevaDetails } from 'models/Deva';
import { LugarEmbarque } from 'models/LugarEmbarque';
import { RootState } from '../../../store/store';
import { deleteDevaProductoReducer } from './extraReducers/deleteDevaProducto';
import { getDevaByIdReducer } from './extraReducers/getDetalleDeva';
import { queryLugaresEmbarqueReducer } from './extraReducers/queryLugaresEmbarque';
import { createDevaProductoReducer } from './extraReducers/createDevaProducto';
import { updateDevaProductoReducer } from './extraReducers/updateProductoDeva';
import { DireccionProveedor } from 'models/Proveedor';

export interface IDevaDetallesState {
  status: StatusApp.Status;
  devaDetalles?: DevaDetails;
  lugaresEmbarqueStatus: StatusApp.Status;
  lugaresEmbarque: LugarEmbarque[];
  devaProductoIdEliminado?: string;
  devaProductoDrawerIsVisible: boolean;
  devaProductoCrud: StatusApp.Status;
}

const initialState: IDevaDetallesState = {
  status: 'idle',
  devaDetalles: undefined,
  lugaresEmbarqueStatus: 'idle',
  lugaresEmbarque: [],
  devaProductoDrawerIsVisible: false,
  devaProductoCrud: 'idle',
};

export const devaDetallesSlice = createSlice({
  name: 'DevaDetalles',
  initialState,
  reducers: {
    devaDetallesHardReset: state => {
      state.status = 'idle';
      state.devaDetalles = undefined;
      state.lugaresEmbarqueStatus = 'idle';
      state.lugaresEmbarque = [];
    },
    setDevaProductoIdEliminado: (state, action: PayloadAction<string>) => {
      state.devaProductoIdEliminado = action.payload;
    },
    toggleDevaProductoDrawerVisible: (state, action: PayloadAction<boolean>) => {
      state.devaProductoDrawerIsVisible = action.payload;
    },
    setDirecionProveedorToDevaDetalles: (state, action: PayloadAction<DireccionProveedor>) => {
      // Agregar dirección creada como valor por defecto en direccionProveedorId
      if (state.devaDetalles) {
        state.devaDetalles.direccionProveedorId = action.payload.id;
      }

      // Agregar dirección al array de direcciones del Proveedor
      if (state.devaDetalles?.direccionesProveedores) {
        state.devaDetalles.direccionesProveedores.push(action.payload);
      }
    },
  },
  extraReducers: builder => {
    getDevaByIdReducer(builder);
    queryLugaresEmbarqueReducer(builder);
    deleteDevaProductoReducer(builder);
    createDevaProductoReducer(builder);
    updateDevaProductoReducer(builder);
  },
});

export const {
  devaDetallesHardReset,
  setDevaProductoIdEliminado,
  toggleDevaProductoDrawerVisible,
  setDirecionProveedorToDevaDetalles,
} = devaDetallesSlice.actions;

export const DevaDetallesState = (state: RootState) => state.devaDetalles;

export const DevaDetallesReducer = devaDetallesSlice.reducer;
