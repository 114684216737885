import { Button, Row, Typography } from 'antd';
import { FC } from 'react';
import styles from './CondicionesTransaccion.module.less';

const { Text } = Typography;

interface ITr {
  number: string;
  condicion: string;
  extraActionDisable?: boolean;
  extraActionText?: string;
  extraAction?: () => void;
}

export const Trow: FC<ITr> = ({
  number,
  condicion,
  children,
  extraActionDisable,
  extraActionText,
  extraAction,
}) => {
  return (
    <tr className={styles.row}>
      <td>
        <Text strong>{number}</Text>
      </td>
      <td>
        <Row justify='space-between' align='middle' style={{ width: '100%' }}>
          <Text>{condicion}</Text>
          {extraActionText && extraAction && (
            <Button onClick={extraAction} disabled={extraActionDisable}>
              {extraActionText}
            </Button>
          )}
        </Row>
      </td>
      <td id='children'>{children}</td>
    </tr>
  );
};
export const TrowText = ({ number, condicion }: ITr) => {
  return (
    <tr className={styles.rowText}>
      <td>
        <Text strong>{number}</Text>
      </td>
      <td>
        <Text strong>{condicion}</Text>
      </td>
    </tr>
  );
};

interface ITable {
  columns: string[];
}

export const Table: FC<ITable> = ({ columns, children }) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {columns.map((column, i) => (
            <th key={column + i}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
