import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { Usuario } from '../../models/Usuario';
import { Columns } from './Columns';
import { ModalUpsert } from './ModalUpsert/ModalUpsert';
import { createUsuario } from './store/extraReducer/createUsuario';
import { deleteUsuario } from './store/extraReducer/deleteUser';
import { updateUsuario } from './store/extraReducer/updateUsuario';
import {
  setCrudStatusIdle,
  setUsuarioTemporal,
  toggleModalVisible,
  toggleSeleccionarUsuario,
  UsuariosState,
} from './store/usuariosStore';
import { Toolbar } from './Toolbar/Toolbar';
import styles from './Usuarios.module.less';

export const Usuarios: FC = () => {
  const [nombre, setNombre] = useState('');
  const { data, status, crudStatus, modalVisible, elementoSeleccionado, apiURL } =
    useSelector(UsuariosState);
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    !window.document.title.includes('Usuarios') && dispatch(setTitleName('Usuarios'));

    if (crudStatus === 'succeeded') {
      dispatch(setCrudStatusIdle());
      form.resetFields();
    }
  }, [crudStatus, dispatch, form]);

  const setUpdateSelection = (values: Usuario) => {
    dispatch(toggleSeleccionarUsuario(values));
    setToggleModalVisible();
    form.setFieldsValue({
      nombreDisplay: values.nombreDisplay,
      email: values.email,
      oAuthId: values.oAuthId,
    });
  };

  const setDeleteSelection = (values: Usuario) => {
    dispatch(toggleSeleccionarUsuario(values));
    onDelete(values.id);
  };

  const setToggleModalVisible = () => {
    dispatch(toggleModalVisible());
  };

  const cols = Columns({
    nombre,
    onUpdate: setUpdateSelection,
    onDelete: setDeleteSelection,
  });

  const onCreate = (values: Usuario) => {
    dispatch(setUsuarioTemporal(values));
    customDispatch({
      asyncThuckFuction: createUsuario,
      endPoint: `${apiURL}/Create`,
      body: values,
    });
  };

  const onUpdate = (values: Usuario) => {
    dispatch(setUsuarioTemporal(values));
    customDispatch({
      asyncThuckFuction: updateUsuario,
      endPoint: `${apiURL}/Update/${elementoSeleccionado?.id}`,
      body: values,
      method: 'PUT',
    });
  };

  const onDelete = (id: string) => {
    customDispatch({
      asyncThuckFuction: deleteUsuario,
      endPoint: `${apiURL}/Delete/${id}`,
      method: 'DELETE',
    });
  };

  return (
    <section className={styles.container}>
      <Toolbar setNombre={setNombre} setToggleModal={setToggleModalVisible} />
      <BaseTable
        cols={cols}
        data={data}
        status={status}
        size='small'
        style={{ height: 'calc(100% - 40px)' }}
      />
      <ModalUpsert
        visible={modalVisible}
        form={form}
        status={crudStatus}
        elementoSeleccionado={elementoSeleccionado}
        onCreate={onCreate}
        onUpdate={onUpdate}
        setCerrarModal={setToggleModalVisible}
      />
    </section>
  );
};
