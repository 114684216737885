import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService, { IUseFetch } from '../../../../services/httpService';
import { RootState } from '../../../../store/store';
import { IImportadoresState } from '../store';

export const updateImportador = createAsyncThunk(
  'update/Importador',
  async (props: IUseFetch, thuckApi) => {
    await httpService(props, thuckApi);
    const {
      paises,
      initialData: { NivelesComerciales },
    } = thuckApi.getState() as RootState;
    return { paises, NivelesComerciales };
  },
);

export const updateImportadorReducer = (builder: ActionReducerMapBuilder<IImportadoresState>) => {
  builder.addCase(updateImportador.fulfilled, (state, action) => {
    if (!state.elementoTemporal) {
      state.status = 'failed';
      return;
    }

    const nivelComercialSelected = action.payload.NivelesComerciales.find(
      nc => nc.id === state.elementoTemporal?.nivelComercialId,
    );

    const paisSelected = action.payload.paises.data.find(
      p => p.id === state.elementoTemporal?.paisId,
    );

    const index = state.data.findIndex(imp => imp.id === state.elementoSeleccionado?.id);

    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
      nivelComercial: String(nivelComercialSelected?.descripcion),
      pais: String(paisSelected?.nombre),
    });

    state.modalVisible = false;
    state.status = 'succeeded';
  });

  builder.addCase(updateImportador.pending, state => {
    state.status = 'pending';
  });

  builder.addCase(updateImportador.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
  });
};
