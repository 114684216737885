import { CloudUploadOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, message, Row, Space, Tooltip } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { EstadoEntidad } from 'enum/EstadoEntidad';
import { getMarcasComerciales } from 'features/MarcasComerciales/store/extraReducers/getMarcasComerciales';
import { BulkImportModal } from 'features/Productos/BulkImportModal/BulkImportModal';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { IDevaRequest } from 'models/Deva';
import { Producto } from 'models/Producto';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { agregarProductos } from '../store/extraReducer/agregarProductos';
import { bulkImportProductos } from '../store/extraReducer/bulkImportProductos';
import { crearProductosPendientes } from '../store/extraReducer/crearProductosPendientes';
import { createDeva } from '../store/extraReducer/createDeva';
import {
  setArchivoBulkImport,
  setBulkImportStatusIdle,
  setDrawerAgregarProductoVisible,
  setProductosPendientesStatus,
  setValidationDevaMessage,
  toggleBulkImportVisible,
} from '../store/store';
import { generarExcelProductosNoCreados } from '../utils/generarExcelProductosNoCreados';
import { ValidarProductos } from '../ValidarProductos/ValidarProductos';
import { DrawerUpsertProductos } from './DrawerUpsertProductos';
import { DrawerAgregarProducto } from '../DrawerAgregarProducto/DrawerAgregarProducto';
import { validarProductosSinArchivo } from '../store/extraReducer/validarProductosSinArchivo';

interface IProps {
  visible: boolean;
  onClose: () => void;
}

export const DrawerValidarProductos = ({ visible, onClose }: IProps) => {
  const [form] = Form.useForm();
  const [bulkImportForm] = Form.useForm();
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const [drawerUpsertProductoVisible, setDrawerUpsertProductoVisible] = useState<boolean>(false);
  const [productoSelecionado, setProductoSeleccionado] = useState<Producto | undefined>(undefined);
  const {
    productosValidados,
    productosPendientesCreateStatus,
    proveedor,
    devaInCreation,
    devaInUpdate,
    createDevaStatus,
    bulkImportStatus,
    bulkImportVisible,
    archivoBulkImport,
    bulkImportCreatedProducts,
    bulkImportFeedback,
    createDevaWithModelFile,
  } = useAppSelector(state => state.devas);

  const validateDevaProductos = () => {
    let validDeva = [] as any[];

    if (devaInCreation) {
      validDeva = devaInCreation?.productos.map(producto => {
        const productoValidado = productosValidados.find(p => p.product.sku === producto.modelo);
        return {
          ...producto,
          productoId: productoValidado?.product.id,
          fechaFactura: new Date(producto.fechaFactura),
        };
      });
    }

    if (devaInUpdate) {
      validDeva = devaInUpdate?.productos.map(producto => {
        const productoValidado = productosValidados.find(p => p.product.sku === producto.modelo);
        return {
          ...producto,
          productoId: productoValidado?.product.id,
          fechaFactura: new Date(producto.fechaFactura),
        };
      });
    }

    return validDeva;
  };

  useEffect(() => {
    if (productosPendientesCreateStatus !== 'succeeded') return;
    const validatedProducts = validateDevaProductos();

    if (devaInCreation) {
      customDispatch({
        asyncThuckFuction: createDeva,
        endPoint: '/api/Devas/Create',
        body: { ...devaInCreation, productos: validatedProducts } as IDevaRequest,
      });
    }

    if (devaInUpdate) {
      customDispatch({
        asyncThuckFuction: agregarProductos,
        endPoint: `/api/Devas/AgregarProductos/${devaInUpdate.devaId}`,
        body: { productos: validatedProducts },
        method: 'PUT',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productosPendientesCreateStatus]);

  const onCrearProductosPendientes = () => {
    const productosIncompletos = productosValidados.filter(p => !p.completo);
    if (productosIncompletos.length > 0) {
      message.error('Algunos productos no tienen la informacion completa.');
      return;
    }
    const productosNoCreados = Array.from(
      new Map(
        productosValidados
          .filter(p => p.estado === EstadoEntidad.NoCreado)
          .map(p => [p.product.sku, { ...p.product, proveedor: proveedor.nombre } as Producto]),
      ).values(),
    );

    if (productosNoCreados.length > 0) {
      dispatch(setValidationDevaMessage('Enviando productos a la cola de creación'));
      customDispatch({
        asyncThuckFuction: crearProductosPendientes,
        endPoint: '/api/Productos/CreateProductosPendientes',
        body: productosNoCreados,
        onFinish: () => {
          customDispatch({
            asyncThuckFuction: getMarcasComerciales,
            endPoint: '/api/MarcasComerciales/GetAll',
          });
        },
      });
      return;
    }

    dispatch(setProductosPendientesStatus('succeeded'));
  };

  const setDrawerUpsertProducto = (producto: Producto) => {
    setProductoSeleccionado(producto);
    setDrawerUpsertProductoVisible(true);

    form.setFieldsValue({
      ...producto,
      mostrarDescripcionConPresentacion: String(producto.mostrarDescripcionConPresentacion),
      partidaArancelaria: producto.partidaArancelaria,
      partidaArancelariaId: producto.partidaArancelaria ?? producto.partidaArancelariaId,
    } as Producto);
  };

  const onGenerarExcel = () => {
    const skuUnicosNoCreados = Array.from(
      new Set(
        productosValidados.filter(p => p.estado === EstadoEntidad.NoCreado).map(p => p.product.sku),
      ),
    );

    const productosNoCreados = skuUnicosNoCreados.map(
      sku =>
        ({
          sku,
          proveedor: proveedor.nombre,
          partidaArancelaria: productosValidados.find(p => p.product.sku === sku)?.product
            .partidaArancelaria,
        } as Producto),
    );

    generarExcelProductosNoCreados(productosNoCreados);
  };

  const exitenProductosPendientesNoCreados: boolean = productosValidados.some(
    p => p.estado === EstadoEntidad.NoCreado,
  );

  // BulkImport
  const onChangeArchivoExcel = (values: UploadFile<any>[] | undefined) => {
    setArchivoBulkImport(values);
  };

  const setBulkImportVisible = () => {
    dispatch(toggleBulkImportVisible());
    dispatch(setBulkImportStatusIdle());
    bulkImportForm.resetFields();
  };

  const onBulkImport = (formValues: any) => {
    const formData = new FormData();
    const file = formValues.productsList ? formValues.productsList.fileList[0].originFileObj : null;

    formData.append('productsList', file);
    formData.append('estadosProductos', String(EstadoEntidad.Pendiente));

    customDispatch({
      asyncThuckFuction: bulkImportProductos,
      endPoint: `/api/Productos/BulkCreateProductos`,
      body: formData,
      onFinish: () => {
        customDispatch({
          asyncThuckFuction: getMarcasComerciales,
          endPoint: '/api/MarcasComerciales/GetAll',
        });
      },
    });
  };

  const onBulkImportTryAgain = () => {
    dispatch(setBulkImportStatusIdle());
  };

  useEffect(() => {
    if (bulkImportStatus === 'succeeded') {
      dispatch(setValidationDevaMessage('Validando productos'));
      const productos = devaInCreation ? devaInCreation?.productos : devaInUpdate?.productos;

      customDispatch({
        asyncThuckFuction: validarProductosSinArchivo,
        endPoint: `/api/Devas/ValidarProductosSinArchivo/${proveedor.id}`,
        body: productos,
        method: 'POST',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkImportStatus]);

  const onAgregarProducto = () => {
    dispatch(setDrawerAgregarProductoVisible(true));
  };

  return (
    <>
      <Drawer
        title='Validación de Productos'
        visible={visible}
        width='100vw'
        onClose={onClose}
        destroyOnClose
        footer={
          <Row justify='end'>
            <Space>
              <Button onClick={onClose} disabled={createDevaStatus === 'pending'}>
                Cancelar
              </Button>
              <Button
                type='primary'
                onClick={onCrearProductosPendientes}
                disabled={createDevaStatus === 'pending'}
              >
                Guardar
              </Button>
            </Space>
          </Row>
        }
      >
        <Row justify='end' style={{ marginBottom: 24 }}>
          <Space>
            {!createDevaWithModelFile && (
              <Button type='primary' onClick={onAgregarProducto}>
                Agregar Productos
              </Button>
            )}

            <Tooltip title='Generar excel con los productos no creados'>
              <Button
                icon={
                  <FileExcelOutlined
                    style={{ color: exitenProductosPendientesNoCreados ? 'green' : '' }}
                  />
                }
                onClick={onGenerarExcel}
                disabled={!exitenProductosPendientesNoCreados}
              >
                Generar Excel
              </Button>
            </Tooltip>
            <Tooltip title='Importar productos desde un archivo excel'>
              <Button
                icon={
                  <CloudUploadOutlined
                    style={{ color: exitenProductosPendientesNoCreados ? '#1890ff' : '' }}
                  />
                }
                onClick={setBulkImportVisible}
                disabled={!exitenProductosPendientesNoCreados}
              >
                Importar
              </Button>
            </Tooltip>
          </Space>
        </Row>
        <ValidarProductos
          productoSeleccioando={productoSelecionado}
          setProductoSeleccionado={setProductoSeleccionado}
          setDrawerUpsertProducto={setDrawerUpsertProducto}
        />
      </Drawer>

      <DrawerAgregarProducto />

      <DrawerUpsertProductos
        form={form}
        visible={drawerUpsertProductoVisible}
        productoSeleccionado={productoSelecionado}
        setProductoSeleccionado={setProductoSeleccionado}
        setVisible={setDrawerUpsertProductoVisible}
      />

      <BulkImportModal
        createdProducts={bulkImportCreatedProducts}
        feedbackMessages={bulkImportFeedback}
        form={bulkImportForm}
        visible={bulkImportVisible}
        status={bulkImportStatus}
        onImportSave={onBulkImport}
        setCerrarModal={setBulkImportVisible}
        archivoExcel={archivoBulkImport}
        onChangeArchivoExcel={onChangeArchivoExcel}
        onTryAgain={onBulkImportTryAgain}
      />
    </>
  );
};
