import { Col, DatePicker, Form } from 'antd';

interface ICustomDatePicker {
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  layout?: { lg?: number; md?: number; xs?: number; span?: number };
}

const { Item } = Form;

export const CustomDatepicker = ({
  label,
  name,
  required = true,
  disabled = false,
  layout,
}: ICustomDatePicker) => {
  return (
    <Col span={6} {...layout}>
      <Item label={label} name={name} rules={[{ required }]}>
        <DatePicker
          role='input'
          disabled={disabled}
          style={{ width: '100%' }}
          format='DD/MM/YYYY'
        />
      </Item>
    </Col>
  );
};
