import { Button, Form, Input, Row, Select, Table, Typography } from 'antd';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { setError } from 'features/InitialData/store/initialDataStore';
import { IDucaVentajaRequest } from 'models/Duca';
import { PapierError } from 'models/PapierException';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { Columns, IVentajaColumnType } from './Columns';

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

interface IListadoVentajasProps {
  data: IDucaVentajaRequest[];
}

export const ListadoVentajas = ({ data }: IListadoVentajasProps) => {
  const dispatch = useDispatch();
  const [ventajasTable, setVentajas] = useState<IDucaVentajaRequest[]>(data);
  const [editingKey, setEditingKey] = useState('');
  const [formVentaja] = Form.useForm<IDucaVentajaRequest>();
  const { Ventajas, ducaDetalles } = useAppSelector(state => state.ducaDetalles);

  const isEditing = (values: IDucaVentajaRequest) => values.key === editingKey;

  const onSave = async (key: string) => {
    try {
      const row = await formVentaja.validateFields();
      row.ventajaId = Ventajas.find(b => b.codigo === row.codigo)?.id ?? '';

      if (
        ventajasTable.some(
          b => b.codigo === row.codigo && b.key !== key && b.estado !== EstadoDucaItem.Delete,
        )
      )
        throw new PapierError('Ya existe una ventaja con ese código');

      const newData = [...ventajasTable];
      const index = newData.findIndex(item => key === item.key);
      if (key === 'new') {
        const indexVentajaGeneral = ventajasTable.findIndex(v => v.codigo === '000');
        newData.splice(index, 1, {
          ...row,
          key: GenerateKey(),
          estado: EstadoDucaItem.Create,
        });

        if (indexVentajaGeneral !== -1) {
          newData.splice(indexVentajaGeneral, 1);
        }

        setVentajas(newData);
      } else {
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
            estado: item.id?.length > 0 ? EstadoDucaItem.Update : EstadoDucaItem.Create,
          });
          setVentajas(newData);
        } else {
          newData.push(row);
          setVentajas(newData);
        }
      }

      setEditingKey('');
    } catch (error) {
      if (error instanceof PapierError) {
        dispatch(
          setError({
            error: true,
            message: error.errorMessage,
            duration: 5,
          }),
        );
      } else {
        console.log('Validate Failed:', error);
      }
    }
  };

  const onEdit = (value: Partial<IDucaVentajaRequest> & { key: React.Key }) => {
    formVentaja.setFieldsValue({
      ventajaId: '',
      codigo: '',
      descripcion: '',
      ...value,
    });
    setEditingKey(value.key);
  };

  const onDelete = (key: string) => {
    const index = ventajasTable.findIndex(_ => _.key === key);

    if (index > -1) {
      const ventaja = ventajasTable[index];
      const ventajasTemp = ventajasTable.map(_ => _);

      if (ventaja.estado === EstadoDucaItem.Create) {
        ventajasTemp.splice(index, 0);
      } else {
        ventajasTemp.splice(index, 1, {
          ...ventaja,
          estado: EstadoDucaItem.Delete,
        });
      }
      setVentajas(ventajasTemp);
    }
  };

  const onCancel = () => {
    if (ventajasTable.some(d => d.key === 'new'))
      setVentajas(ventajasTable.filter(d => d.key !== 'new'));
    setEditingKey('');
  };

  const cols = Columns({
    isEditing,
    onSave,
    onEdit,
    onDelete,
    onCancel,
    ordenCerrada: ducaDetalles?.ordenCerrada ?? false,
  });
  const mergedColumns = cols.map((col: IVentajaColumnType) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: IDucaVentajaRequest) => ({
        width: col.width,
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.dataIndex === 'descripcion' ? 'input' : 'dropdown',
        options: col.dataIndex === 'codigo' ? Ventajas : undefined,
        editing: isEditing(record),
      }),
    };
  });

  const onNewItem = () => {
    formVentaja.setFieldsValue({
      ventajaId: '',
      codigo: '',
      descripcion: '',
    });
    setVentajas([
      ...ventajasTable,
      {
        id: '',
        key: 'new',
        ventajaId: '',
        codigo: '',
        descripcion: '',
        estado: EstadoDucaItem.Create,
      },
    ]);
    setEditingKey('new');
  };

  const TablaVentajas = () => (
    <section>
      <Row justify='space-between'>
        <Title level={5}>Listado de Ventajas</Title>
        {!ducaDetalles?.ordenCerrada && (
          <Button
            type='primary'
            ghost
            onClick={onNewItem}
            disabled={ventajasTable.some(b => b.key === 'new')}
          >
            Agregar Ventaja
          </Button>
        )}
      </Row>
      <Form form={formVentaja} component={false}>
        <Table
          components={{ body: { cell: EditableCell } }}
          bordered
          dataSource={ventajasTable.filter(x => x.estado !== EstadoDucaItem.Delete)}
          columns={mergedColumns as any}
          pagination={false}
          size='small'
          style={{ marginTop: 16, width: '100%' }}
        />
      </Form>
    </section>
  );

  return { TablaVentajas, ventajas: ventajasTable };
};

interface IEditableCell extends React.HTMLAttributes<HTMLDivElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'dropdown' | 'input';
  options?: any[];
  record: IDucaVentajaRequest;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<IEditableCell> = ({
  editing,
  dataIndex,
  title,
  inputType,
  options,
  record,
  index,
  children,
  ...restProps
}) => {
  const InputNode =
    inputType === 'input' ? (
      <Input
        type='text'
        disabled={dataIndex === 'descripcion'}
        style={dataIndex === 'descripcion' ? { color: '#666' } : undefined}
      />
    ) : (
      <Select
        autoFocus={dataIndex === 'codigo'}
        showSearch
        optionFilterProp='children'
        filterOption
        style={{ width: '100%' }}
      >
        {options?.map(item => (
          <Option key={item.id} value={item.codigo}>
            {`${item.codigo} - ${item.descripcion}`}
          </Option>
        ))}
      </Select>
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            { required: true, message: `Campo requerido` },
            ({ setFieldsValue }) => ({
              validator(_, value) {
                if (dataIndex === 'codigo') {
                  setFieldsValue({
                    descripcion: options?.find(x => x?.codigo === value)?.descripcion,
                  });
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          {InputNode}
        </Item>
      ) : (
        children
      )}
    </td>
  );
};
