import { useCallback, useEffect, useState } from 'react';
import { debounce } from './useDebounce';

interface IUseFilter<T> {
  data: T[];
  filterPredicate: (item: T, valueToFilter: string) => boolean;
}

export const useFilter = <T>({
  data,
  filterPredicate,
}: IUseFilter<T>): [(v: string) => void, T[]] => {
  const [newData, setNewData] = useState<T[]>([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value.length === 0) {
      setNewData(data);
    }
  }, [data, value.length]);

  const filterDataMemo = (valueToFilter: string) => {
    const res = data.filter((item: T) => filterPredicate(item, valueToFilter));
    setNewData(res);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filter = useCallback(
    debounce((e: string) => {
      setValue(e);
      filterDataMemo(e);
    }),
    [debounce, data],
  );

  return [filter, newData];
};
