import { FormInstance, Row } from 'antd';
import { CustomDatepicker } from 'components/FormControls/CustomDatePicker';
import { DropDownGrid } from 'components/FormControls/CustomDropDown';
import { CustomInput } from 'components/FormControls/CustomInput';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { GreenTitle } from 'components/FormControls/GreenTitle';
import { EstadoOrdenTrabajo } from 'enum/EstadoOrdenTrabajo';
import { queryLugaresEmbarque } from 'features/DevaDetalles/store/extraReducers/queryLugaresEmbarque';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useDebounce } from 'hooks/useDebounce';
import { LugarEmbarque } from 'models/LugarEmbarque';
import { QuerySpec } from 'models/QuerySpec';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';

interface IProps {
  form: FormInstance;
  onChangeIncoterm: (id: string) => void;
}

export const CaracteristicasTransaccion = ({ form, onChangeIncoterm }: IProps) => {
  const [selectedPais, setSelectedPais] = useState<string>('');
  const paises = useAppSelector(state => state.paises.data);
  const icoterm = useAppSelector(state => state.initialData.Incoterm);
  const { devaDetalles, disabled } = useAppSelector(state => ({
    devaDetalles: state.devaDetalles.devaDetalles,
    disabled:
      state.devaDetalles.devaDetalles?.ordenDeTrabajo?.estado === EstadoOrdenTrabajo.Cerrada,
  }));

  useEffect(() => {
    if (!devaDetalles?.paisEmbarqueId) return;
    setSelectedPais(devaDetalles?.paisEmbarqueId);
    if (devaDetalles.lugarEmbarque || devaDetalles.lugarEmbarque !== '') {
      dispatchLugaresEmbarque(devaDetalles.lugarEmbarque, devaDetalles.paisEmbarqueId);
    } else {
      dispatchLugaresEmbarque('', devaDetalles.paisEmbarqueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devaDetalles?.paisEmbarqueId]);

  const {
    FormasDeEnvio: formasDeEnvio,
    FormasDePago: formasDePago,
    MonedasDePago: modedas,
  } = useAppSelector(state => state.initialData);
  const { lugaresEmbarqueStatus, lugaresEmbarque } = useAppSelector(state => state.devaDetalles);
  const customDispatch = useCustomDispatch();

  const onChangeLugarEmbarque = useDebounce((value: string) => {
    if (selectedPais.length) dispatchLugaresEmbarque(value, selectedPais);
  });

  const dispatchLugaresEmbarque = (filterValue: string, paisId: string) => {
    customDispatch({
      asyncThuckFuction: queryLugaresEmbarque,
      endPoint: '/api/LugaresDeEmbarque/Query',
      body: {
        simpleTextFilter: filterValue,
        pageNumber: 1,
        pageSize: 50,
        filterGroups: [
          {
            columnName: 'paisId',
            filters: [
              {
                filterValue: paisId,
              },
            ],
          },
        ],
      } as QuerySpec,
    });
  };

  return (
    <section>
      <GreenTitle text='E. Características de la Transacción' />
      <Row gutter={[24, 24]}>
        <CustomInput label='15. Lugar de la Entrega' name='lugarEntrega' />
        <DropDownGrid
          index='combinado'
          required
          data={paises}
          label='País de  Entrega'
          name='paisEntregaId'
          disabled={disabled}
        />
        <DropDownGrid
          required
          propSelectName='descripcion'
          data={icoterm}
          label='15.1 Incoterm '
          name='incotermId'
          disabled={disabled}
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          onChange={value => onChangeIncoterm(value?.toString() ?? '')}
        />
        <CustomInput label='Versión ' name='version' rules={[{ min: 4 }]} maxLength={10} />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInput label='17. Número de Contrato' name='numeroContrato' required={false} />
        <CustomDatepicker
          label='17.1 Fecha de Contrato'
          name='fechaContrato'
          disabled={disabled}
          required={false}
        />
        <DropDownGrid
          propSelectName='descripcion'
          data={formasDeEnvio}
          label='18. Forma de Envío'
          name='formaEnvioId'
          disabled={disabled}
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          required
        />
        <CustomInput
          label='Otra Forma de Envío (Especifique)'
          name='otraFormaEnvio'
          required={false}
          rules={[{ min: 3, message: 'Otra Forma de Envío debe tener al menos 3 caracteres' }]}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <DropDownGrid
          required
          layout={{ lg: 6 }}
          data={[
            { id: true, nombre: 'Si' },
            { id: false, nombre: 'No' },
          ]}
          label='Pago efectuado'
          name='pagoEfectuado'
          disabled={disabled}
          rules={[{ required: true, message: 'Campo requerido' }]}
        />

        <DropDownGrid
          propSelectName='descripcion'
          data={formasDePago}
          label='19. Forma de Pago'
          name='formaPagoId'
          disabled={disabled}
          renderText={item => `${item.codigo} - ${item.descripcion}`}
          required
        />
        <CustomInput
          layout={{ span: 12 }}
          label='Otra Forma de Pago (Especifique)'
          name='otraFormaPago'
          required={false}
          rules={[{ min: 3, message: 'Otra Forma de Pago debe tener al menos 3 caracteres' }]}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <DropDownGrid
          data={lugaresEmbarque}
          label='20. Lugar de Embarque'
          name='lugarEmbarqueId'
          loading={lugaresEmbarqueStatus === 'pending'}
          onSearch={onChangeLugarEmbarque}
          renderText={(item: LugarEmbarque) => item.combinado}
          disabled={disabled}
          allowClear
        />
        <CustomInput required={false} disabled label='21. País de Embarque' name='paisEmbarque' />
        <CustomInput required={false} disabled label='País de Exportación' name='paisExportacion' />
        <CustomDatepicker
          label='Fecha de Exportación'
          name='fechaExportacion'
          disabled
          required={false}
        />
      </Row>
      <Row gutter={[24, 24]}>
        <DropDownGrid
          required
          data={modedas}
          renderText={item => item.codigo + ' - ' + item.descripcion}
          label='22. Moneda en que se Realizó la Transacción'
          name='monedaPagoId'
          disabled={disabled}
        />
        <CustomInput
          disabled
          label='Otra Moneda (Especifique)'
          name='otraMoneda'
          required={false}
        />
        <CustomInputNumber
          name='tipoCambioMonedaExtranjeraDolar'
          label='23. Tipo de cambio de Modeda Extranjera a Dolares USD'
          layout={{ span: 12 }}
          type='full'
          disabled={disabled}
          ruler={{ required: true }}
        />
      </Row>
    </section>
  );
};
