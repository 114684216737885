export const GetDecimals = (num: number = 0): string => {
  if (num === 0) return '00';

  const decimals = String((num - Math.trunc(num)).toFixed(2));
  let result = '';

  if (decimals?.[2]) {
    result = decimals[2];
  } else {
    result = '0';
  }

  if (decimals?.[3]) {
    result += decimals[3];
  } else {
    result += '0';
  }

  return result;
};
