import { CloseOutlined, DeleteOutlined, FormOutlined, SaveOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { DireccionProveedor } from 'models/Proveedor';

interface IProps {
  isEditing: (values: DireccionProveedor) => boolean;
  onSave: (key: string) => void;
  onEdit: (values: DireccionProveedor) => void;
  onDelete: (key: string) => void;
  onCancel: () => void;
}

interface IDireccionesProveedorPendienteColumnType extends ColumnType<DireccionProveedor> {
  editable?: boolean;
}

export const DireccionesProveedorPendienteColumns = ({
  isEditing,
  onSave,
  onEdit,
  onDelete,
  onCancel,
}: IProps): IDireccionesProveedorPendienteColumnType[] => [
  {
    title: 'Dirección',
    dataIndex: 'direccion',
    width: 300,
    editable: true,
  },
  {
    title: 'Ciudad',
    dataIndex: 'ciudad',
    width: 200,
    editable: true,
  },
  {
    title: 'Telefono',
    dataIndex: 'telefono',
    width: 150,
    editable: true,
  },
  {
    title: 'Fax',
    dataIndex: 'fax',
    width: 150,
    editable: true,
  },
  {
    title: 'Correo',
    dataIndex: 'email',
    width: 300,
    editable: true,
  },
  {
    title: 'Editar',
    dataIndex: 'editar',
    align: 'center',
    width: 50,
    render: (_: any, record: DireccionProveedor) => {
      const editable = isEditing(record);
      return (
        <div style={{ textAlign: 'center' }}>
          {editable ? (
            <SaveOutlined
              onClick={() => onSave(record.key)}
              style={{ color: '#1890FF', fontSize: 16 }}
            />
          ) : (
            <FormOutlined
              onClick={() => onEdit(record)}
              style={{ color: '#FAAD14', fontSize: 16 }}
            />
          )}
        </div>
      );
    },
  },
  {
    title: 'Borrar',
    dataIndex: 'borrar',
    align: 'center',
    width: 50,
    render: (_: any, record: DireccionProveedor) => {
      const editable = isEditing(record);
      return (
        <div style={{ textAlign: 'center' }}>
          {editable ? (
            <CloseOutlined onClick={() => onCancel()} style={{ color: '#FF4D4F', fontSize: 16 }} />
          ) : (
            <Popconfirm title='Suguro de eliminar?' onConfirm={() => onDelete(record.key)}>
              <DeleteOutlined style={{ color: '#FF4D4F', fontSize: 16 }} />
            </Popconfirm>
          )}
        </div>
      );
    },
  },
];
