import { PagedList } from 'models/PagedList';

export default function buildDefaultPagedList<T>(): PagedList<T> {
  return {
    items: [],
    totalCount: 0,
    pageIndex: 0,
    pageSize: 0,
  };
}
