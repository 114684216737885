import { Button, Divider, FormInstance, Row, Typography, Form } from 'antd';
import { DropDownGrid } from 'components/FormControls/CustomDropDown';
import { CustomInput } from 'components/FormControls/CustomInput';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { useCancelEditMode } from 'features/DucaDetalles/utils/useCancelEditMode';
import { setSaveDucaBultoLinea } from 'features/DucaDetalles/store/store';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { ListadoLineas } from './ListadoLineas/ListadoLineas';

const { Title, Text } = Typography;

interface IBulto {
  form: FormInstance;
  requerido: boolean;
  onChangeTituloTransporte: (value: string) => void;
}

export const Bulto = ({ form, requerido, onChangeTituloTransporte }: IBulto) => {
  const dispatch = useDispatch();
  const { onCancelEditMode } = useCancelEditMode({ table: 'Linea' });
  const { bultosLineas, ducaDetalles } = useAppSelector(state => state.ducaDetalles);

  const indicadorCancelacionGlobal = [
    { id: true, descripcion: 'Sí' },
    { id: false, descripcion: 'No' },
  ];
  const layout = { span: 8 };
  const rulesInput = [{ required: requerido, message: 'Este campo es requerido' }];

  const { TableLinea, setEditingKey, formLinea } = ListadoLineas({ onCancelEditMode });

  const onNewItem = () => {
    formLinea.setFieldsValue({
      numeroLinea: 0,
      cantidadUnidadesAfectadas: 0,
      pesoAfectado: 0,
    });
    dispatch(
      setSaveDucaBultoLinea({
        record: {
          id: '',
          key: 'new',
          cantidadUnidadesAfectadas: 0,
          numeroLinea: 0,
          pesoAfectado: 0,
          estado: EstadoDucaItem.Create,
        },
        key: 'new',
      }),
    );
    setEditingKey('new');
    onCancelEditMode();
  };

  return (
    <section>
      <Divider style={{ marginTop: 0 }}>Bultos</Divider>
      <Form form={form} layout='vertical'>
        <Row gutter={[24, 24]}>
          <CustomInput
            name='manifiesto'
            label='Manifiesto'
            layout={layout}
            rules={rulesInput}
            required={false}
          />
          <CustomInput
            name='tituloTransporte'
            label='Título Transporte'
            layout={layout}
            rules={rulesInput}
            required={false}
            onChange={onChangeTituloTransporte}
          />
          <DropDownGrid
            name='indicadorCancelacionGlobal'
            label='Indicador de Cancelación Global'
            propSelectName='descripcion'
            data={indicadorCancelacionGlobal}
            layout={layout}
            initialValue
            disabled={ducaDetalles?.ordenCerrada}
            allowClear
          />
        </Row>
      </Form>
      <Row justify='space-between'>
        <Title level={5}>Listado de Lineas</Title>
        {!ducaDetalles?.ordenCerrada && (
          <Button
            type='primary'
            ghost
            onClick={onNewItem}
            disabled={bultosLineas.some(b => b.key === 'new')}
          >
            Agregar Linea
          </Button>
        )}
      </Row>

      <TableLinea />
      <Text type='secondary' style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
        Los cambios no se verán reflejados hasta dar click en el botón "Guardar Cambios" en la parte
        superior de la pantalla
      </Text>
    </section>
  );
};
