import { Button, Divider, Row, Typography } from 'antd';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { useCancelEditMode } from 'features/DucaDetalles/utils/useCancelEditMode';
import { setSaveDucaDatoComplementario } from 'features/DucaDetalles/store/store';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { ListadoDatosComplementarios } from './ListadoDatosComplementarios/ListadoDatosComplementarios';

const { Title, Text } = Typography;

export const DatosComplementarios = () => {
  const dispatch = useDispatch();
  const { onCancelEditMode } = useCancelEditMode({ table: 'DatoComplementario' });
  const { datosComplementarios, ducaDetalles, itemStatus } = useAppSelector(
    state => state.ducaDetalles,
  );

  const { TableDatosComplementarios, setEditingKey, formComplementarios } =
    ListadoDatosComplementarios({ onCancelEditMode });

  const onNewItem = () => {
    formComplementarios.setFieldsValue({
      datoComplementarioId: '',
      codigo: '',
      descripcion: '',
      valor: '',
    });
    dispatch(
      setSaveDucaDatoComplementario({
        record: {
          id: '',
          key: 'new',
          datoComplementarioId: '',
          codigo: '',
          descripcion: '',
          valor: '',
          estado: EstadoDucaItem.Create,
        },
        key: 'new',
      }),
    );
    setEditingKey('new');
    onCancelEditMode();
  };

  return (
    <section style={{ marginTop: 24 }}>
      <Divider style={{ marginTop: 0 }}>Datos Complementarios</Divider>

      <Row justify='space-between'>
        <Title level={5}>Listado de Datos Complementarios</Title>
        {!ducaDetalles?.ordenCerrada && (
          <Button
            type='primary'
            ghost
            onClick={onNewItem}
            disabled={datosComplementarios.some(b => b.key === 'new')}
            loading={itemStatus === 'pending'}
          >
            Agregar Dato Complementario
          </Button>
        )}
      </Row>

      <TableDatosComplementarios />
      <Text type='secondary' style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
        Los cambios no se verán reflejados hasta dar click en el botón "Guardar Cambios" en la parte
        superior de la pantalla
      </Text>
    </section>
  );
};
