import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EstadoOrdenTrabajo } from 'enum/EstadoOrdenTrabajo';
import httpService from 'services/httpService';
import { IOrdenTrabajoDetallesState } from '../ordenTrabajoDetallesStore';

export const closeOrdenTrabajo = createAsyncThunk('OrdenTrabajoDetalles/Close', httpService);

export const closeOrdenTrabajoDetallesReducer = (
  builder: ActionReducerMapBuilder<IOrdenTrabajoDetallesState>,
) => {
  // Get
  builder.addCase(closeOrdenTrabajo.fulfilled, state => {
    state.data!.estado = EstadoOrdenTrabajo.Cerrada;
    state.status = 'succeeded';
  });
  builder.addCase(closeOrdenTrabajo.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(closeOrdenTrabajo.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
