import { UserAddOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Row, Select } from 'antd';
import { EstadoEntidad } from 'enum/EstadoEntidad';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { Proveedor } from 'models/Proveedor';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';
import { ModalUpsertProveedor } from '../ModalUpsertProveedor/ModalUpsertProveedor';
import { createProveedor } from '../store/extraReducers/createProveedorReducer';
import { setCrudStatusIdle, setProveedorTemporal } from '../store/proveedoresStore';

const { Option } = Select;

interface IProps {
  formInstance: FormInstance;
}

export const CreateProveedorInline = ({ formInstance }: IProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [providerId, setProviderId] = useState<string | undefined>(undefined);
  const [providerName, setProviderName] = useState<string>('');
  const {
    data: Proveedores,
    crudStatus,
    apiURL,
  } = useSelector((state: RootState) => state.proveedores);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const { data: Paises } = useSelector((state: RootState) => state.paises);
  const { CondicionesComerciales } = useSelector((state: RootState) => state.initialData);
  const { proveedoresPendientes } = useAppSelector(state => state.inicio);

  useEffect(() => {
    if (crudStatus === 'succeeded') {
      const tempProviderId = Proveedores.find(provider => provider.nombre === providerName)?.id;
      dispatch(setCrudStatusIdle());
      setProviderId(String(tempProviderId));
      formInstance.setFields([{ name: 'proveedorId', value: tempProviderId }]);
      setCerrar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Proveedores]);

  const setCerrar = () => {
    form.resetFields();
    setVisible(false);
  };

  const onCreate = (values: Proveedor) => {
    const newData = {
      ...values,
      pais: Paises.filter(x => x.id === values.paisId)[0]?.nombre,
      condicionComercial: CondicionesComerciales.filter(
        x => x.id === values.condicionComercialId,
      )[0]?.descripcion,
      estado: EstadoEntidad.Pendiente,
    } as Proveedor;

    setProviderName(newData.nombre);

    dispatch(setProveedorTemporal(newData));
    customDispatch({
      asyncThuckFuction: createProveedor,
      endPoint: `${apiURL}/Create`,
      body: newData,
    });
  };

  return (
    <>
      <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Select
          value={providerId}
          filterOption
          showSearch
          placeholder='Selecciona un proveedor'
          optionFilterProp='children'
          style={{ width: 200 }}
          onChange={(value, option) => {
            setProviderId(value);
            setProviderName((option as any)?.children);
            formInstance.setFields([{ name: 'proveedorId', value }]);
          }}
        >
          {[...Proveedores, ...proveedoresPendientes]
            .sort((a, b) => {
              if (a.nombre < b.nombre) {
                return -1;
              }
              if (a.nombre > b.nombre) {
                return 1;
              }
              return 0;
            })
            .map(proveedor => (
              <Option key={proveedor.id} value={proveedor.id}>
                {proveedor.nombre}
              </Option>
            ))}
        </Select>
        <Button
          type='primary'
          style={{ marginLeft: 16 }}
          onClick={() => {
            setVisible(true);
          }}
        >
          <UserAddOutlined />
        </Button>
      </Row>
      <ModalUpsertProveedor
        validarProveedor={false}
        visible={visible}
        form={form}
        status={crudStatus}
        elementoSeleccionado={undefined}
        paises={Paises}
        condicionesComerciales={CondicionesComerciales}
        onCreate={onCreate}
        onUpdate={() => {}}
        setCerrarDrawer={setCerrar}
      />
    </>
  );
};
