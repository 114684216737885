import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Button, Form, Space, Tooltip, Typography } from 'antd';
import { EstadoEntidad } from 'enum/EstadoEntidad';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { FC, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { BaseTable } from '../../../components/BaseTable/BaseTable';
import { Producto } from '../../../models/Producto';
import { DrawerUpsert } from '../../Productos/DrawerUpsert/DrawerUpsert';
import { deleteProductoPendiente } from '../store/extraReducers/producto/delete';
import { updateProductoPendiente } from '../store/extraReducers/producto/update';
import { updateProductosPendientes } from '../store/extraReducers/producto/updateAll';
import { setEditProductoPendiente, setProductoPendienteSeleccionado } from '../store/inicioStore';
import { Columns } from './Columns';
import styles from './ProductosPendientes.module.less';

const { Text } = Typography;

export const ProductosPendientes: FC = () => {
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [form] = Form.useForm<Producto>();
  const {
    productosPendientes,
    productoPendienteSeleccionado: productoSeleccionado,
    getProductosPendientesStatus,
  } = useAppSelector(state => state.inicio);

  const {
    designacionesProductos,
    paises,
    unidadesMedidasNetas,
    estadosMercancias,
    marcasComerciales,
    clasificacionesFenag,
  } = useAppSelector(state => ({
    designacionesProductos: state.designacionesProductos.data,
    paises: state.paises.data,
    unidadesMedidasNetas: state.unidadesMedidasNetas.data,
    estadosMercancias: state.estadosMercancias.data,
    marcasComerciales: state.marcasComerciales.data,
    clasificacionesFenag: state.clasificacionesFenag.data,
  }));

  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();

  const showDrawer = (producto: Producto) => {
    setProductoSeleccionado(producto);
    setDrawerVisible(true);
    form.setFieldsValue({
      ...producto,
      mostrarDescripcionConPresentacion: String(producto.mostrarDescripcionConPresentacion),
      proveedorId: producto.proveedor,
    } as Producto);
  };

  const cancelDrawer = () => {
    setDrawerVisible(false);
    setProductoSeleccionado(undefined);
    form.resetFields();
  };

  const editProductoSeleccionado = (producto: Producto) => {
    const designacionProducto = designacionesProductos.filter(
      d => d.nombre === producto.designacionProducto,
    )[0];

    producto = {
      ...producto,
      proveedorId: productoSeleccionado?.proveedorId ?? '',
      designacionProductoId:
        designacionProducto !== undefined
          ? designacionProducto.id
          : '00000000-0000-0000-0000-000000000000',
      marcaComercial: marcasComerciales.filter(marca => marca.id === producto.marcaComercialId)[0]
        .nombre,
      paisOrigenId: producto.paisOrigenId?.toLowerCase() === 'null' ? '' : producto.paisOrigenId,
      paisOrigen: paises.filter(pais => pais.id === producto.paisOrigenId)[0].nombre,
      unidadMedida: unidadesMedidasNetas.filter(
        unidadMedidaNeta => unidadMedidaNeta.id === producto.unidadMedidaId,
      )[0].nombre,
      estadoMercancia: estadosMercancias.filter(
        estadoMercancia => estadoMercancia.id === producto.estadoMercanciaId,
      )[0].nombre,
      mostrarDescripcionConPresentacion:
        producto.mostrarDescripcionConPresentacion.toString() === 'true' ? true : false,
      clasificacionFenagId: producto.clasificacionFenagId ?? null,
      clasificacionFenag:
        producto.clasificacionFenagId &&
        clasificacionesFenag.filter(
          clasificacionFenag => clasificacionFenag.id === producto.clasificacionFenagId,
        )[0].nombre,
      codigoDeBarras: producto.codigoDeBarras ?? '',
      codigoFitoSanitario:
        producto.codigoFitosanitario?.toLowerCase() === 'null' ? '' : producto.codigoFitosanitario,
      estado: EstadoEntidad.Confirmado,
    } as Producto;

    onEditProductoPendiente(producto);
  };

  const ondeleteProductoPendiente = (producto: Producto) => {
    setProductoSeleccionado(producto);
    onDeleteProductoPendiente(producto.id);
  };

  /* Productos Pendientes */
  const setProductoSeleccionado = (producto: Producto | undefined) => {
    dispatch(setProductoPendienteSeleccionado(producto));
  };

  const onEditProductoPendiente = (producto: Producto) => {
    dispatch(setEditProductoPendiente(producto));
  };

  const onUpdateProductoPendiente = (producto: Producto) => {
    setProductoSeleccionado(producto);

    customDispatch({
      asyncThuckFuction: updateProductoPendiente,
      endPoint: `/api/Productos/Update/${producto.id}`,
      body: producto,
      method: 'PUT',
    });
  };

  const onDeleteProductoPendiente = (id: string) => {
    customDispatch({
      asyncThuckFuction: deleteProductoPendiente,
      endPoint: `/api/Productos/Delete/${id}`,
      method: 'DELETE',
    });
  };

  const onUpdateProductosPendientes = () => {
    const productos = productosPendientes;
    if (productos.length) {
      customDispatch({
        asyncThuckFuction: updateProductosPendientes,
        endPoint: '/api/Productos/UpdateProductosPendientes',
        body: productos,
        method: 'PUT',
      });
    }
  };

  const cols = Columns({
    onSave: onUpdateProductoPendiente,
    onDetails: showDrawer,
    onDelete: ondeleteProductoPendiente,
  });

  const handleFullScreen = useFullScreenHandle();

  const shouldRenderContent = productosPendientes && productosPendientes.length > 0;
  const innerMarkup = (
    <div className={styles.containerTable}>
      <BaseTable
        cols={cols}
        data={productosPendientes}
        status={getProductosPendientesStatus}
        size='small'
        style={{ height: '100%' }}
        id='ProductosPendientesTabla'
        pagination={{ style: { marginBottom: 6 } }}
      />
      <DrawerUpsert
        validarProducto
        visible={drawerVisible}
        form={form}
        status={'succeeded'}
        elementoSeleccionado={productoSeleccionado}
        onCreate={(_producto: Producto) => {}}
        onUpdate={(producto: Producto) => {
          editProductoSeleccionado(producto);
          cancelDrawer();
        }}
        setCerrarModal={() => cancelDrawer()}
        container={document.getElementById('ProductosPendientesTabla') as HTMLElement}
      />
    </div>
  );

  return (
    <FullScreen
      handle={handleFullScreen}
      className={`${styles.container} ${!shouldRenderContent ? styles.containerEmpty : ''}`}
    >
      <div className={styles.toolbar}>
        <Text strong>Productos</Text>
        <Space size={'middle'}>
          {shouldRenderContent && (
            <Tooltip
              title='Esta acción guardara todos los productos que esta viendo.'
              placement='left'
            >
              <Button type='primary' onClick={onUpdateProductosPendientes}>
                Guardar
              </Button>
            </Tooltip>
          )}
          {handleFullScreen.active ? (
            <Tooltip title='Salir de pantalla completa' placement='left'>
              <FullscreenExitOutlined style={{ fontSize: 24 }} onClick={handleFullScreen.exit} />
            </Tooltip>
          ) : (
            <Tooltip title='Ver tabla en pantalla completa' placement='left'>
              <FullscreenOutlined style={{ fontSize: 24 }} onClick={handleFullScreen.enter} />
            </Tooltip>
          )}
        </Space>
      </div>
      {innerMarkup}
    </FullScreen>
  );
};
