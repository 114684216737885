import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Usuario } from '../../models/Usuario';

interface IProps {
  nombre: string;
  onUpdate: (values: Usuario) => void;
  onDelete: (values: Usuario) => void;
}

export const Columns = ({ nombre, onUpdate, onDelete }: IProps): ColumnsType<any> => {
  return [
    {
      title: 'Nombre',
      dataIndex: 'nombreDisplay',
      filteredValue: [`${nombre}`],
      width: 250,
      onFilter: (value, record) => {
        const filter = value as string;
        const row = record.nombreDisplay as string;
        if (filter.length === 0) return true;
        return row.toLowerCase().includes(filter.toLowerCase());
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 200,
    },
    {
      title: 'Auth0 Id',
      dataIndex: 'oAuthId',
      width: 250,
    },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 50,
      render: (_: any, record: Usuario) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='editar' onClick={() => onUpdate(record)}>
                <Button type='primary' ghost block>
                  Editar
                </Button>
              </Menu.Item>
              <Popconfirm
                title='¿Estás seguro de eliminar este usuario?'
                okText='Eliminar'
                cancelText='Cancelar'
                onConfirm={() => onDelete(record)}
              >
                <Menu.Item key='eliminar'>
                  <Button danger block>
                    Eliminar
                  </Button>
                </Menu.Item>
              </Popconfirm>
            </Menu>
          }
          trigger={['click']}
          placement='bottomRight'
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Dropdown>
      ),
    },
  ];
};
