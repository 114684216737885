import { InputNumber } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';

export const AgInputNumberCellEditor = forwardRef((props: any, ref) => {
  const [value, setValue] = useState<number>(Number(String(props.value).replace(',', '')));

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  const onChange = (e: number) => {
    setValue(e);
  };

  return (
    <div>
      <InputNumber
        onChange={onChange}
        defaultValue={value}
        style={{ width: '100%' }}
        type='number'
      />
    </div>
  );
});
