import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IPaisesState } from '../paisesStore';

export const deletePais = createAsyncThunk('Paises/Delete', httpService);

export const deletePaisReducer = (builder: ActionReducerMapBuilder<IPaisesState>) => {
  // Delete
  builder.addCase(deletePais.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.data.findIndex(pais => pais.id === state.elementoSeleccionado?.id);
    state.data.splice(index, 1);
  });
  builder.addCase(deletePais.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deletePais.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
