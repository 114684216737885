import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';
import { IInitialDataState } from '../initialDataStore';

export const getCondicionesComerciales = createAsyncThunk(
  'CondicionesComerciales/GetAll',
  async (fetchOp: IUseFetch, thunkApi: ThunckApi) => {
    const res = httpService(fetchOp, thunkApi);
    return res;
  },
);

export const getCondicionesComercialesReducer = (
  builder: ActionReducerMapBuilder<IInitialDataState>,
) => {
  builder.addCase(
    getCondicionesComerciales.fulfilled,
    (state, action: PayloadAction<ConfiguracionesPetch[]>) => {
      state.CondicionesComerciales = action.payload;
      state.CondicionesComercialesStatus = 'succeeded';
    },
  );

  builder.addCase(getCondicionesComerciales.pending, state => {
    state.CondicionesComercialesStatus = 'pending';
  });

  builder.addCase(getCondicionesComerciales.rejected, state => {
    state.CondicionesComercialesStatus = 'failed';
  });
};
