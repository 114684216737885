import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import buildDefaultPagedList from 'utils/buildDefaultPagedList';
import { PartidaArancelaria } from '../../../models/PartidaArancelaria';
import { RootState } from '../../../store/store';
import { PagedList } from './../../../models/PagedList';
import { createPartidaArancelariaReducer } from './extraReducers/createPartidaArancelaria';
import { deletePartidaArancelariaReducer } from './extraReducers/deletePartidaArancelaria';
import { queryPartidasArancelariasReducer } from './extraReducers/queryPartidasArancelarias';
import { updatePartidaArancelariaReducer } from './extraReducers/updatePartidaArancelaria';

export interface IPartidasArancelariasState {
  pagedData: PagedList<PartidaArancelaria>;
  status: StatusApp.Status;
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado?: PartidaArancelaria;
  elementoTemporal?: PartidaArancelaria;
  apiURL: string;
}

const initialState: IPartidasArancelariasState = {
  pagedData: buildDefaultPagedList<PartidaArancelaria>(),
  status: 'idle',
  crudStatus: 'idle',
  modalVisible: false,
  apiURL: '/api/PartidasArancelarias',
};

export const partidasArancelariasSlice = createSlice({
  name: 'PartidasArancelarias',
  initialState,
  reducers: {
    toggleSeleccionarPartidaArancelaria: (
      state,
      action: PayloadAction<PartidaArancelaria | undefined>,
    ) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setPartidaArancelariaTemporal: (state, action: PayloadAction<PartidaArancelaria>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
  },
  extraReducers: builder => {
    createPartidaArancelariaReducer(builder);
    deletePartidaArancelariaReducer(builder);
    updatePartidaArancelariaReducer(builder);
    queryPartidasArancelariasReducer(builder);
  },
});

export const {
  toggleSeleccionarPartidaArancelaria,
  toggleModalVisible,
  setPartidaArancelariaTemporal,
  setCrudStatusIdle,
} = partidasArancelariasSlice.actions;

export const PartidasArancelariasState = (state: RootState) => state.partidasArancelarias;

export default partidasArancelariasSlice.reducer;
