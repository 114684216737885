import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Usuario } from 'models/Usuario';
import httpService from '../../../../services/httpService';
import { IUsuariosState } from '../usuariosStore';

export const updateUsuario = createAsyncThunk('Usuarios/Update', httpService);

export const updateUsuarioReducer = (builder: ActionReducerMapBuilder<IUsuariosState>) => {
  builder.addCase(updateUsuario.fulfilled, (state, _action) => {
    const index = state.data.findIndex(usuario => usuario.id === state.elementoSeleccionado?.id);

    state.crudStatus = 'succeeded';
    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as Usuario);
  });
  builder.addCase(updateUsuario.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updateUsuario.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
