import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { UnidadMedidaNeta } from '../../models/UnidadMedidaNeta';

interface IProps {
  onUpdate: (values: UnidadMedidaNeta) => void;
  onDelete: (values: UnidadMedidaNeta) => void;
}

export const Columns = ({ onUpdate, onDelete }: IProps): ColumnsType<any> => {
  return [
    {
      title: 'Código',
      dataIndex: 'codigo',
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
    },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 50,
      render: (_: any, record: UnidadMedidaNeta) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='editar' onClick={() => onUpdate(record)}>
                <Button type='primary' ghost block>
                  Editar
                </Button>
              </Menu.Item>
              <Popconfirm
                title='¿Estás seguro de eliminar esta unidad de medida neta?'
                okText='Eliminar'
                cancelText='Cancelar'
                onConfirm={() => onDelete(record)}
              >
                <Menu.Item key='eliminar'>
                  <Button danger block>
                    Eliminar
                  </Button>
                </Menu.Item>
              </Popconfirm>
            </Menu>
          }
          trigger={['click']}
          placement='bottomRight'
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Dropdown>
      ),
    },
  ];
};
