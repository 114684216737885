import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClasificacionFenag } from '../../../models/ClasificacionFenag';
import { RootState } from '../../../store/store';
import { createClasificacionFenagReducer } from './extraReducer/create';
import { deleteClasificacionFenagReducer } from './extraReducer/delete';
import { getClasificacionesFenagReducer } from './extraReducer/get';
import { updateClasificacionFenagReducer } from './extraReducer/update';

export interface IClasificacionesFenagState {
  data: Array<ClasificacionFenag>;
  status: StatusApp.Status;
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado: ClasificacionFenag | undefined;
  elementoTemporal: ClasificacionFenag | undefined;
  error?: string;
  apiURL: string;
}

const initialState: IClasificacionesFenagState = {
  data: [],
  status: 'idle',
  crudStatus: 'idle',
  modalVisible: false,
  elementoSeleccionado: undefined,
  elementoTemporal: undefined,
  apiURL: '/api/ClasificacionesFenag',
};

export const clasificacionesFenagSlice = createSlice({
  name: 'ClasificacionesFenag',
  initialState,
  reducers: {
    toggleSeleccionarClasificacionFenag: (
      state,
      action: PayloadAction<ClasificacionFenag | undefined>,
    ) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setClasificacionFenagTemporal: (state, action: PayloadAction<ClasificacionFenag>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
    resetError: state => {
      state.error = undefined;
    },
  },
  extraReducers: builder => {
    getClasificacionesFenagReducer(builder);
    createClasificacionFenagReducer(builder);
    updateClasificacionFenagReducer(builder);
    deleteClasificacionFenagReducer(builder);
  },
});

export const {
  toggleSeleccionarClasificacionFenag,
  toggleModalVisible,
  setClasificacionFenagTemporal,
  setCrudStatusIdle,
} = clasificacionesFenagSlice.actions;

export const ClasificacionesFenagState = (state: RootState) => state.clasificacionesFenag;

export default clasificacionesFenagSlice.reducer;
