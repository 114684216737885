import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  setBultoLineaEditingKey,
  setDatosComplementariosEditingKey,
  setDeleteDucaBultoLinea,
  setDeleteDucaDatoComplementario,
  setDeleteDucaDocumento,
  setDocumentosEditingKey,
} from '../store/store';

interface IProps {
  table: 'Linea' | 'Documento' | 'DatoComplementario';
}

export const useCancelEditMode = ({ table }: IProps) => {
  const dispatch = useDispatch();
  const { bultosLineas, documentos, datosComplementarios } = useAppSelector(
    state => state.ducaDetalles,
  );

  const onCancelLineas = () => {
    if (bultosLineas.some(d => d.key === 'new')) dispatch(setDeleteDucaBultoLinea('new'));
    dispatch(setBultoLineaEditingKey(''));
  };

  const onCancelDocumentos = () => {
    if (documentos.some(d => d.key === 'new')) dispatch(setDeleteDucaDocumento('new'));
    dispatch(setDocumentosEditingKey(''));
  };

  const onCancelDatosComplementarios = () => {
    if (datosComplementarios.some(d => d.key === 'new'))
      dispatch(setDeleteDucaDatoComplementario('new'));
    dispatch(setDatosComplementariosEditingKey(''));
  };

  const onCancelEditMode = () => {
    if (table === 'Linea') {
      onCancelDocumentos();
      onCancelDatosComplementarios();
    }

    if (table === 'Documento') {
      onCancelLineas();
      onCancelDatosComplementarios();
    }

    if (table === 'DatoComplementario') {
      onCancelLineas();
      onCancelDocumentos();
    }
  };

  return { onCancelEditMode };
};
