import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const queryTipoItem = createAsyncThunk('DucaDetalles/TipoItem/Query', httpService);

export const queryTipoItemReducer = (builder: ActionReducerMapBuilder<IDucaDetallesState>) => {
  builder.addCase(
    queryTipoItem.fulfilled,
    (state, action: PayloadAction<PagedList<ConfiguracionesPetch>>) => {
      state.TiposItems = action.payload.items;
      state.TipoItemStatus = 'succeeded';
    },
  );

  builder.addCase(queryTipoItem.pending, state => {
    state.TipoItemStatus = 'pending';
  });

  builder.addCase(queryTipoItem.rejected, state => {
    state.TipoItemStatus = 'failed';
  });
};
