import { DatePicker } from 'antd';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useState } from 'react';

export const AgDateCellEditor = forwardRef((props: any, ref) => {
  const dateSplit = String(props.value).split('/');
  const dateFormatCorrected = `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`;
  const [date, setDate] = useState(new Date(dateFormatCorrected));
  // const [editing, setEditing] = useState(true);

  /* useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
  }, [editing]); */

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return moment.utc(date).local().format('DD/MM/YY');
      },
    };
  });

  const onChange = (_date: any, dateString: string) => {
    const data = dateString.split('/');
    const dataFormatCorrect = `${data[1]}/${data[0]}/${data[2]}`;
    setDate(new Date(dataFormatCorrect));
    // setEditing(false);
  };

  return (
    <div>
      <DatePicker
        onChange={onChange}
        defaultValue={moment(date)}
        format='DD/MM/YY'
        style={{ width: '100%' }}
      />
    </div>
  );
});
