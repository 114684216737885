import { Button, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { toggleDevaProductoDrawerVisible } from '../../../features/DevaDetalles/store/store';

export const DevaProductosToolbar = () => {
  const dispatch = useDispatch();

  const onShowDevaProductoDrawer = () => {
    dispatch(toggleDevaProductoDrawerVisible(true));
  };

  return (
    <Row justify='end'>
      <Button ghost type='primary' onClick={onShowDevaProductoDrawer}>
        Agregar Producto
      </Button>
    </Row>
  );
};
