import { Button, Form, Modal, Typography } from 'antd';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { addDatoComplementarioToItem } from 'features/DucaDetalles/store/store';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { IDucaDatoComplementarioRequest } from 'models/Duca';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { DataType } from '../TabPaneItems';
import { FormDatoComplementario } from './FormDatoComplementarios';
import { TablaAsignarDatosComplementarios } from './TablaAsignarDatosComplementarios';

const { Text } = Typography;

interface IModalAsignarDatosComplementarios {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export const ModalAsignarDatosComplementarios = ({
  visible,
  setVisible,
}: IModalAsignarDatosComplementarios) => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [data, setData] = useState<DataType[]>([]);
  const { TipoDatosComplementarios, items } = useAppSelector(state => state.ducaDetalles);
  const [form] = Form.useForm<IDucaDatoComplementarioRequest>();

  useEffect(() => {
    const tempData = items
      .filter(item => item.estado !== EstadoDucaItem.Delete)
      .map(item => ({
        key: item.key,
        numeroItem: item.numeroItem,
        posicionArancelaria: item.posicionArancelaria,
        descripcion: item.descripcion,
        paisOrigen: item.paisOrigen,
        existencia: false,
      }));
    setData(tempData);
  }, [items]);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRow([]);
  };

  const onFinish = async (values: IDucaDatoComplementarioRequest) => {
    const datoComplementario = TipoDatosComplementarios.find(
      x => x.id === values.datoComplementarioId,
    );
    values.codigo = datoComplementario?.codigo ?? '';
    values.descripcion = datoComplementario?.descripcion ?? '';
    values.key = GenerateKey();

    dispatch(addDatoComplementarioToItem({ itemsKey: selectedRow, datoComplementario: values }));

    setSelectedRow([]);
    setData(data.map(x => ({ ...x, existencia: false })));
    form.resetFields();
  };

  const onChangeDatoComplementario = (value: string, _option: any) => {
    setData(data.map(x => ({ ...x, existencia: onExisteAsignacion(x.key, value) })));
  };

  const onExisteAsignacion = (key: React.Key, documentoId: string): boolean => {
    const item = items.find(x => x.key === key);
    return item?.datosComplementarios?.some(x => x.datoComplementarioId === documentoId) ?? false;
  };

  return (
    <Modal
      title='Asignar Datos Complementarios'
      visible={visible}
      onCancel={onClose}
      width={763}
      footer={<Button onClick={onClose}>Cerrar</Button>}
    >
      <FormDatoComplementario
        form={form}
        onFinish={onFinish}
        onChange={onChangeDatoComplementario}
        disabled={selectedRow.length === 0}
      />
      <TablaAsignarDatosComplementarios
        data={data}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
      <Text>
        Los productos con un asterisco (*) ya tiene el dato complementario que se trata de asignar
      </Text>
    </Modal>
  );
};
