import { Col, Form, FormInstance, FormItemProps, Popconfirm, Row, Select } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import { CustomInput } from 'components/FormControls/CustomInput';
import { GreenTitle } from 'components/FormControls/GreenTitle';
import { setDirecionProveedorToDevaDetalles } from 'features/DevaDetalles/store/store';
import { ModalDireccionesProveedor } from 'features/Proveedores/ModalDireccionesProveedor/ModalDireccionesProveedor';
import {
  setDireccionEdigingKey as setDireccionInProveedorEditingKey,
  setDirecciones as setDireccionInProveedor,
  toggleModalDireccionesVisible,
  toggleSeleccionarProveedor,
} from 'features/Proveedores/store/proveedoresStore';
import { DireccionProveedor } from 'models/Proveedor';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';

const { Item } = Form;

interface IInformacionGeneralProveedor {
  form: FormInstance;
}

export const InformacionGeneralProveedor = ({ form }: IInformacionGeneralProveedor) => {
  const { status, devaDetalles } = useAppSelector(state => state.devaDetalles);
  const { crudDireccionesStatus, data: Proveedores } = useAppSelector(state => state.proveedores);
  const dispatch = useDispatch();

  useEffect(() => {
    const direccionesInDevaDetalles = devaDetalles?.direccionesProveedores as DireccionProveedor[];
    if (crudDireccionesStatus !== 'succeeded' || direccionesInDevaDetalles.length > 0) return;

    const direccion = Proveedores.find(_ => _.id === devaDetalles?.proveedor?.id)?.direcciones[0];
    dispatch(setDirecionProveedorToDevaDetalles(direccion as DireccionProveedor));

    form.setFieldsValue({
      direccionProveedorId: direccion?.id,
      proveedorCiudad: direccion?.ciudad,
      proveedorEmail: direccion?.email,
      proveedorTelefono: direccion?.telefono,
      proveedorFax: direccion?.fax,
    });

    // Cerrar Modal de Direcciones del Proveedor
    dispatch(toggleModalDireccionesVisible());
    dispatch(toggleSeleccionarProveedor(undefined));
    dispatch(setDireccionInProveedorEditingKey(''));
    dispatch(setDireccionInProveedor([]));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crudDireccionesStatus]);

  const onChangeDirecion = (value: string) => {
    const direccion = devaDetalles?.direccionesProveedores.find(_ => _.id === value);

    form.setFieldsValue({
      direccionProveedorId: direccion?.id,
      proveedorCiudad: direccion?.ciudad,
      proveedorEmail: direccion?.email,
      proveedorTelefono: direccion?.telefono,
      proveedorFax: direccion?.fax,
    });
  };

  const onShowDireccionesProveedor = () => {
    dispatch(toggleSeleccionarProveedor(devaDetalles?.proveedor));
    dispatch(toggleModalDireccionesVisible());
    dispatch(setDireccionInProveedor([]));
  };

  return (
    <section>
      <GreenTitle text='C. Información General del Proveedor' />
      <Row gutter={[24, 24]}>
        <CustomInput
          required={false}
          disabled
          layout={{ span: 24 }}
          label='9. Nombre o Razón Social'
          name='proveedorNombre'
        />
      </Row>
      <Row gutter={[24, 24]}>
        {devaDetalles?.direccionesProveedores?.length &&
        devaDetalles?.direccionesProveedores?.length > 0 ? (
          <CustomSelect
            span={12}
            data={
              devaDetalles?.direccionesProveedores?.map(direccion => ({
                value: direccion.id,
                label: direccion.direccion,
              })) ?? []
            }
            name='direccionProveedorId'
            label='Dirección'
            loading={status === 'pending'}
            onChange={value => onChangeDirecion(value)}
            rules={[{ required: true }]}
          />
        ) : (
          <Popconfirm
            placement='right'
            title='Este proveedor no tiene direcciones. ¿Quieres agregarle una?'
            onConfirm={onShowDireccionesProveedor}
            okText='Si'
            cancelText='No'
          >
            <CustomSelect
              span={12}
              data={[]}
              name='direccionProveedorId'
              label='Dirección'
              loading={status === 'pending'}
              rules={[{ required: true }]}
            />
          </Popconfirm>
        )}

        <CustomInput required={false} disabled label='Ciudad' name='proveedorCiudad' />
        <CustomInput required={false} disabled label='País' name='proveedorPais' />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInput
          required={false}
          disabled
          layout={{ span: 12 }}
          label='Correo Electrónico'
          name='proveedorEmail'
        />
        <CustomInput required={false} disabled label='Teléfono' name='proveedorTelefono' />
        <CustomInput required={false} disabled label='Fax' name='proveedorFax' />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInput
          required={false}
          disabled
          label='11. Condición Comercial'
          name='proveedorCondicionComercial'
        />
        <CustomInput
          required={false}
          disabled
          label='Otra Condición Comercial'
          name='proveedorOtraCondicionComercial'
        />
      </Row>
      <ModalDireccionesProveedor />
    </section>
  );
};

interface ICustomSelect extends FormItemProps {
  span?: number;
  data: { value: string; label: string }[];
  loading: boolean;
  onChange?: (value: string) => void;
}

const CustomSelect = ({ span, data, loading, onChange, ...rest }: ICustomSelect) => {
  return (
    <Col span={span}>
      <Item {...rest}>
        <Select
          showSearch
          loading={loading}
          options={data.map(option => option as BaseOptionType)}
          onChange={value => onChange?.(value)}
        />
      </Item>
    </Col>
  );
};
