import { Button, Divider, Row, Typography } from 'antd';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { useCancelEditMode } from 'features/DucaDetalles/utils/useCancelEditMode';
import { setSaveDucaDocumento } from 'features/DucaDetalles/store/store';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { ListadoDocumentos } from './ListadoDocumentos/ListadoDocumentos';

const { Title, Text } = Typography;

export const Documentos = () => {
  const dispatch = useDispatch();
  const { onCancelEditMode } = useCancelEditMode({ table: 'Documento' });
  const { documentos, ducaDetalles } = useAppSelector(state => state.ducaDetalles);

  const { TableDocumentos, setEditingKey, formDocumentos } = ListadoDocumentos({
    onCancelEditMode,
  });

  const onNewItem = () => {
    formDocumentos.setFieldsValue({
      codigo: '',
      documentoId: '',
      descripcion: '',
      referencia: '',
      presencia: true,
    });
    dispatch(
      setSaveDucaDocumento({
        record: {
          id: '',
          key: 'new',
          documentoId: '',
          codigo: '',
          descripcion: '',
          referencia: '',
          presencia: true,
          estado: EstadoDucaItem.Create,
        },
        key: 'new',
      }),
    );
    setEditingKey('new');
    onCancelEditMode();
  };

  return (
    <section style={{ marginTop: 24 }}>
      <Divider style={{ marginTop: 0 }}>Documentos</Divider>

      <Row justify='space-between'>
        <Title level={5}>Listado de Documentos</Title>
        {!ducaDetalles?.ordenCerrada && (
          <Button
            type='primary'
            ghost
            onClick={onNewItem}
            disabled={documentos.some(b => b.key === 'new')}
          >
            Agregar Documento
          </Button>
        )}
      </Row>

      <TableDocumentos />
      <Text type='secondary' style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
        Los cambios no se verán reflejados hasta dar click en el botón "Guardar Cambios" en la parte
        superior de la pantalla
      </Text>
    </section>
  );
};
