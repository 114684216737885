import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { DucaDetalles } from 'models/Duca';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const getDucaDetalles = createAsyncThunk('DucaDetalles/GetById', httpService);

export const getDucaDetallesReducer = (builder: ActionReducerMapBuilder<IDucaDetallesState>) => {
  builder.addCase(getDucaDetalles.fulfilled, (state, action: PayloadAction<DucaDetalles>) => {
    const bultosPayload = action.payload.bultos;
    const documentosPayload = action.payload.documentos;
    const datosComplementariosPayload = action.payload.datosComplementarios;

    const bultosLineas =
      bultosPayload?.bultosLineas?.map(linea => ({ ...linea, key: GenerateKey() })) ?? [];
    const documentos =
      documentosPayload?.map(documento => ({ ...documento, key: GenerateKey() })) ?? [];
    const datosComplementarios =
      datosComplementariosPayload?.map(dato => ({ ...dato, key: GenerateKey() })) ?? [];

    if (state.ducaDetalles) {
      state.ducaDetalles = {
        ...action.payload,
        ...state.ducaDetalles,
        bultos: {
          ...bultosPayload,
          bultosLineas,
        },
        documentos,
        datosComplementarios,
      } as DucaDetalles;
    } else {
      state.ducaDetalles = {
        ...action.payload,
        bultos: {
          ...bultosPayload,
          bultosLineas,
        },
        documentos,
        datosComplementarios,
      } as DucaDetalles;
    }

    state.bultosLineas = bultosLineas;
    state.documentos = documentos;
    state.datosComplementarios = datosComplementarios;
    state.status = 'succeeded';
  });
  builder.addCase(getDucaDetalles.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getDucaDetalles.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
