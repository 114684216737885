import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdenDeTrabajo } from '../../../models/OrdenTrabajo';
import { RootState } from '../../../store/store';
import { closeOrdenTrabajoDetallesReducer } from './extraReducers/closeOrdenTrabajo';
import { getOrdenTrabajoDetallesReducer } from './extraReducers/getOrdenTrabajoDetalles';
import { updateOrdenTrabajoDetalleReducer } from './extraReducers/updateOrdenTrabajo';

export interface IOrdenTrabajoDetallesState {
  data: OrdenDeTrabajo | undefined;
  status: StatusApp.Status;
  apiURL: string;
  currentOrdenTrabajoId: string;
  drawerVisible: boolean;
}

const initialState: IOrdenTrabajoDetallesState = {
  data: undefined,
  status: 'idle',
  apiURL: '/api/OrdenesDeTrabajo',
  currentOrdenTrabajoId: '',
  drawerVisible: false,
};

export const ordenTrabajoDetallesSlice = createSlice({
  name: 'OrdenTrabajoDetalles',
  initialState,
  reducers: {
    resetOrdenDetalles: (state, action: PayloadAction<StatusApp.Status>) => {
      state.data = undefined;
      state.status = action.payload;
    },
    setOrdenTrabajoForm: (state, action: PayloadAction<OrdenDeTrabajo>) => {
      state.data = action.payload;
    },
    setCurrentOrdenTrabajoId: (state, action: PayloadAction<string>) => {
      state.currentOrdenTrabajoId = action.payload;
    },
    setTotales: (state, action: PayloadAction<OrdenDeTrabajo>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    setOrdenTrabajoDetallesDucaId: (state, action: PayloadAction<string>) => {
      if (state.data) state.data.ducaId = action.payload;
    },
    setDrawerVisible: (state, action: PayloadAction<boolean>) => {
      state.drawerVisible = action.payload;
    },
    setStatusOrdenTrabajoDetalles: (state, action: PayloadAction<StatusApp.Status>) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    getOrdenTrabajoDetallesReducer(builder);
    updateOrdenTrabajoDetalleReducer(builder);
    closeOrdenTrabajoDetallesReducer(builder);
  },
});

export const {
  resetOrdenDetalles,
  setOrdenTrabajoForm,
  setCurrentOrdenTrabajoId,
  setTotales,
  setOrdenTrabajoDetallesDucaId,
  setDrawerVisible,
  setStatusOrdenTrabajoDetalles,
} = ordenTrabajoDetallesSlice.actions;

export const OrdenTrabajoDetallesState = (state: RootState) => state.ordenTrabajoDetalles;

export default ordenTrabajoDetallesSlice.reducer;
