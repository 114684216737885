import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { setError } from 'features/InitialData/store/initialDataStore';
import { setValoresTotalesOrdenDeTrabajo } from 'features/OrdenDeTrabajo/store/store';
import { Deva } from 'models/Deva';
import httpService, { IUseFetch, ThunckApi } from 'services/httpService';
import { IDevasState } from '../store';

export const deleteDeva = createAsyncThunk(
  'Devas/DeleteDeva',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      thunkApi.dispatch(
        setValoresTotalesOrdenDeTrabajo({
          TotalFOB: res.totalFOB,
          TotalFlete: res.totalFlete,
          TotalSeguro: res.totalSeguro,
          TotalOtrosGastos: res.totalOtrosGastos,
          TotalCIF: res.totalCIF,
        }),
      );
      thunkApi.dispatch(setError({ message: 'Deva eliminada', error: false }));
    }
    return res;
  },
);

interface IResponse {
  totalFlete: number;
  totalSeguro: number;
  totalOtrosGastos: number;
  totalCIF: number;
  devas: Deva[];
}

export const deleteDevaReducer = (builder: ActionReducerMapBuilder<IDevasState>) => {
  builder.addCase(deleteDeva.fulfilled, (state, action: PayloadAction<IResponse>) => {
    if (state.ordenDeTrabajoInCreation) {
      state.devasOfOrdenDeTrabajo = action.payload.devas.map(x => ({ ...x, key: GenerateKey() }));
    } else {
      state.devasDetalles = action.payload.devas.map(x => ({ ...x, key: GenerateKey() }));
    }
    state.deleteDevaStatus = 'succeeded';
  });
  builder.addCase(deleteDeva.pending, (state, _action) => {
    state.deleteDevaStatus = 'pending';
  });
  builder.addCase(deleteDeva.rejected, (state, _action) => {
    state.deleteDevaStatus = 'failed';
  });
};
