import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { hardReset as hardReserDevas, setDevas } from 'components/Devas/store/store';
import { setError } from 'features/InitialData/store/initialDataStore';
import httpService, { IUseFetch, ThunckApi } from 'services/httpService';
import { IOrdenDeTrabajoState } from '../store';

export const updateOrdenDeTrabajo = createAsyncThunk(
  'OrdenDeTrabajo/updateOrdenTrabajo',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      thunkApi.dispatch(setDevas([]));
      thunkApi.dispatch(hardReserDevas());
      thunkApi.dispatch(setError({ message: 'Orden de trabajo actualizada.', error: false }));
    }

    return res;
  },
);

export const updateOrdenDeTrabajoReducer = (
  builder: ActionReducerMapBuilder<IOrdenDeTrabajoState>,
) => {
  builder.addCase(updateOrdenDeTrabajo.fulfilled, (state, _action) => {
    state.idOrdenTrabajoCreada = '';
    state.ordenTrabajo = undefined;
    state.updateOrdenTrabajoStatus = 'succeeded';
    state.crearOrdenTrabajoStatus = 'idle';
  });
  builder.addCase(updateOrdenDeTrabajo.pending, (state, _action) => {
    state.updateOrdenTrabajoStatus = 'pending';
  });
  builder.addCase(updateOrdenDeTrabajo.rejected, (state, _action) => {
    state.updateOrdenTrabajoStatus = 'failed';
  });
};
