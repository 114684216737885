import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ClaseBulto } from 'models/ClaseBulto';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';
import { IInitialDataState } from '../initialDataStore';

export const getClasesBulto = createAsyncThunk(
  'ClasesBulto/GetAll',
  async (fetchOp: IUseFetch, thunkApi: ThunckApi) => {
    const res = httpService(fetchOp, thunkApi);
    return res;
  },
);

export const getClasesBultoReducer = (builder: ActionReducerMapBuilder<IInitialDataState>) => {
  builder.addCase(getClasesBulto.fulfilled, (state, action: PayloadAction<ClaseBulto[]>) => {
    state.ClasesBulto = action.payload;
    state.ClasesBultosStatus = 'succeeded';
  });
  builder.addCase(getClasesBulto.pending, state => {
    state.ClasesBultosStatus = 'pending';
  });
  builder.addCase(getClasesBulto.rejected, state => {
    state.ClasesBultosStatus = 'failed';
  });
};
