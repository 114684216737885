import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IProductosState } from '../productosStore';

export const deleteProducto = createAsyncThunk('Productos/Delete', httpService);

export const deleteProductoReducer = (builder: ActionReducerMapBuilder<IProductosState>) => {
  // Delete
  builder.addCase(deleteProducto.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.pagedData.items.findIndex(
      producto => producto.id === state.elementoSeleccionado?.id,
    );
    state.pagedData.items.splice(index, 1);
  });
  builder.addCase(deleteProducto.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deleteProducto.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
