import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Usuario } from 'models/Usuario';
import httpService from 'services/httpService';
import { IUsuariosState } from '../usuariosStore';

export const createUsuario = createAsyncThunk('Usuarios/Create', httpService);

export const createUsuarioReducer = (builder: ActionReducerMapBuilder<IUsuariosState>) => {
  builder.addCase(createUsuario.fulfilled, (state, action) => {
    state.crudStatus = 'succeeded';
    state.data.unshift({
      ...state.elementoTemporal,
      id: action.payload,
    } as Usuario);
  });
  builder.addCase(createUsuario.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createUsuario.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
