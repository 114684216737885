import { Select } from 'antd';
import { UnidadMedidaNeta } from 'models/UnidadMedidaNeta';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useAppSelector } from 'store/hooks';

const { Option } = Select;

export const AgUnidadesMedidaCellEditor = forwardRef((props: any, ref) => {
  const { data: UnidadesMedida } = useAppSelector(state => state.unidadesMedidasNetas);
  const [value, setValue] = useState<UnidadMedidaNeta | undefined>(
    UnidadesMedida.find(p => p.nombre === props.value) ??
      UnidadesMedida.find(p => p.descripcion === props.value),
  );
  const forName = UnidadesMedida.find(p => p.nombre === props.value) ? true : false;

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (props.colDef.field === 'unidadMedida') {
          props.data.unidadMedidaId = value?.id;
        }

        if (props.colDef.field === 'unidadComercial') {
          props.data.unidadComercialCodigo = value?.codigo;
          props.data.unidadComercialId = value?.id;
        }

        if (props.colDef.field === 'unidadEstadistica') {
          props.data.unidadEstadisticaCodigo = value?.codigo;
          props.data.unidadEstadisticaId = value?.id;
        }

        return forName ? value?.nombre : value?.descripcion;
      },
    };
  });

  const onChange = (e: string) => {
    setValue(UnidadesMedida.find(p => p.id === e));
  };

  return (
    <div>
      <Select
        showSearch
        optionFilterProp='children'
        defaultValue={value?.id}
        onChange={e => onChange(e)}
        style={{ width: '100%' }}
      >
        {UnidadesMedida.map(unidadMedida => (
          <Option key={unidadMedida.id} value={unidadMedida.id}>
            {unidadMedida.nombre}
          </Option>
        ))}
      </Select>
    </div>
  );
});
