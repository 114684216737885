import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PartidaArancelaria } from '../../models/PartidaArancelaria';

interface IProps {
  onUpdate: (values: PartidaArancelaria) => void;
  onDelete: (values: PartidaArancelaria) => void;
}

export const Columns = ({ onUpdate, onDelete }: IProps): ColumnsType<any> => {
  return [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      width: 110,
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
    },
    {
      title: 'Nivel',
      dataIndex: 'nivel',
      width: 50,
    },
    { title: 'DAI', dataIndex: 'dai', width: 50 },
    { title: 'ISC', dataIndex: 'isc', width: 50 },
    { title: 'IPC', dataIndex: 'ipc', width: 50 },
    { title: 'ISV', dataIndex: 'isv', width: 50 },
    { title: 'Unidad Medida Estadistica', dataIndex: 'unidadMedidaEstadistica', width: 200 },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 50,
      render: (_: any, record: PartidaArancelaria) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='editar' onClick={() => onUpdate(record)}>
                <Button
                  type='primary'
                  ghost
                  block
                  disabled={record.nombre?.length === 12 ? false : true}
                >
                  Editar
                </Button>
              </Menu.Item>
              <Popconfirm
                title='¿Estás seguro de eliminar esta partida arancelaria?'
                okText='Eliminar'
                cancelText='Cancelar'
                onConfirm={() => onDelete(record)}
              >
                <Menu.Item key='eliminar'>
                  <Button danger block>
                    Eliminar
                  </Button>
                </Menu.Item>
              </Popconfirm>
            </Menu>
          }
          trigger={['click']}
          placement='bottomRight'
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Dropdown>
      ),
    },
  ];
};
