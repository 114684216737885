import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';
import { IInitialDataState } from '../initialDataStore';

export const getMonedasDePago = createAsyncThunk(
  'getMonedasDePago/GetAll',
  async (fetchOp: IUseFetch, thunkApi: ThunckApi) => {
    const res = httpService(fetchOp, thunkApi);
    return res;
  },
);

export const getMonedasDePagoReducer = (builder: ActionReducerMapBuilder<IInitialDataState>) => {
  builder.addCase(
    getMonedasDePago.fulfilled,
    (state, action: PayloadAction<ConfiguracionesPetch[]>) => {
      state.MonedasDePago = action.payload;
      state.MonedasDePagoStatus = 'succeeded';
    },
  );

  builder.addCase(getMonedasDePago.pending, state => {
    state.MonedasDePagoStatus = 'pending';
  });

  builder.addCase(getMonedasDePago.rejected, state => {
    state.MonedasDePagoStatus = 'failed';
  });
};
