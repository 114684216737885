import { Form } from 'antd';
import { useFilter } from 'hooks/useFilter';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { MarcaComercial } from '../../models/MarcaComercial';
import { Columns } from './Columns';
import styles from './MarcasComerciales.module.less';
import { ModalUpsert } from './ModalUpsert/ModalUpsert';
import { createMarcaComercial } from './store/extraReducers/createMarcaComercial';
import { deleteMarcaComercial } from './store/extraReducers/deleteMarcaComercial';
import { updateMarcaComercial } from './store/extraReducers/updateMarcaComercial';
import {
  MarcasComercialesState,
  setCrudStatusIdle,
  setMarcaComercialTemporal,
  toggleModalVisible,
  toggleSeleccionarMarcaComercial,
} from './store/marcasComercialesStore';
import { Toolbar } from './Toolbar/Toolbar';

export const MarcasComerciales: FC = () => {
  const { data, status, crudStatus, modalVisible, elementoSeleccionado, apiURL } =
    useSelector(MarcasComercialesState);
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    !window.document.title.includes('Marcas Comerciales') &&
      dispatch(setTitleName('Marcas Comerciales'));

    if (crudStatus === 'succeeded') {
      dispatch(setCrudStatusIdle());
      form.resetFields();
    }
  }, [crudStatus, dispatch, form]);

  const setUpdateSelection = (values: MarcaComercial) => {
    dispatch(toggleSeleccionarMarcaComercial(values));
    setToggleModalVisible();
    form.setFieldsValue({ nombre: values.nombre });
  };

  const setDeleteSelection = (values: MarcaComercial) => {
    dispatch(toggleSeleccionarMarcaComercial(values));
    onDelete(values.id);
  };

  const setToggleModalVisible = () => {
    dispatch(toggleModalVisible());
  };

  const cols = Columns({
    onUpdate: setUpdateSelection,
    onDelete: setDeleteSelection,
  });

  const onCreate = (values: MarcaComercial) => {
    dispatch(setMarcaComercialTemporal(values));
    customDispatch({
      asyncThuckFuction: createMarcaComercial,
      endPoint: `${apiURL}/Create`,
      body: values,
    });
  };

  const onUpdate = (values: MarcaComercial) => {
    dispatch(setMarcaComercialTemporal(values));
    customDispatch({
      asyncThuckFuction: updateMarcaComercial,
      endPoint: `${apiURL}/Update/${elementoSeleccionado?.id}`,
      body: values,
      method: 'PUT',
    });
  };

  const onDelete = (id: string) => {
    customDispatch({
      asyncThuckFuction: deleteMarcaComercial,
      endPoint: `${apiURL}/Delete/${id}`,
      method: 'DELETE',
    });
  };

  const [onfilter, filteredData] = useFilter<MarcaComercial>({
    data,
    filterPredicate: (item, filter) => item.nombre.toLowerCase().includes(filter.toLowerCase()),
  });

  return (
    <section className={styles.container}>
      <Toolbar setNombre={onfilter} setToggleModal={setToggleModalVisible} />
      <BaseTable
        cols={cols}
        data={filteredData}
        status={status}
        size='small'
        style={{ height: 'calc(100% - 40px)' }}
      />
      <ModalUpsert
        visible={modalVisible}
        form={form}
        status={crudStatus}
        elementoSeleccionado={elementoSeleccionado}
        onCreate={onCreate}
        onUpdate={onUpdate}
        setCerrarModal={setToggleModalVisible}
      />
    </section>
  );
};
