import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { IDucaItemRequest } from 'models/Duca';
import { useAppSelector } from 'store/hooks';

export const useFormatDataDucaItem = () => {
  const { DucaItems, UnidadesMedida, EstadosMercancias, Paises, TiposItems, CuotasArancelarias } =
    useAppSelector(state => ({
      UnidadesMedida: state.unidadesMedidasNetas.data,
      EstadosMercancias: state.estadosMercancias.data,
      Paises: state.paises.data,
      TiposItems: state.ducaDetalles.TiposItems,
      CuotasArancelarias: state.ducaDetalles.CuotasArancelarias,
      DucaItems: state.ducaDetalles.items,
    }));

  const parseData = (data: any) => {
    const items = [...DucaItems];
    const index = items.findIndex(i => i.key === data.key);
    if (index !== -1) {
      const item = items[index];
      const unidadComercial = UnidadesMedida.find(u => u.descripcion === data.unidadComercial);
      const unidadEstadistica = UnidadesMedida.find(u => u.descripcion === data.unidadEstadistica);
      const estadoMercancia = EstadosMercancias.find(e => e.nombre === data.estadoMercancia);
      const tipoItem = TiposItems.find(t => t.descripcion === data.tipoItem);
      const paisOrigen = Paises.find(p => p.nombre === data.paisOrigen);
      const paisProcedenciaDestino = Paises.find(p => p.nombre === data.paisProcedenciaDestino);
      const paisAdquisicion = Paises.find(p => p.nombre === data.paisAdquisicion);
      const cuotaArancelaria = CuotasArancelarias.find(
        c => c.descripcion === data.cuotaArancelaria,
      );

      items.splice(index, 1, {
        ...item,
        posicionArancelariaId: data.posicionArancelariaId,
        posicionArancelaria: data.posicionArancelaria,
        descripcion: data.descripcion,
        comentario: data.comentario,
        unidadComercialId: unidadComercial?.id,
        unidadComercial: unidadComercial?.descripcion,
        unidadComercialCodigo: unidadComercial?.nombre,
        unidadEstadisticaId: unidadEstadistica?.id,
        unidadEstadistica: unidadEstadistica?.descripcion,
        formaLiquidacion: Number(data.formaLiquidacion?.toString().replace(',', '') ?? 0),
        estadoMercanciaId: estadoMercancia?.id,
        estadoMercancia: estadoMercancia?.nombre,
        declaracionCancelar: data.declaracionCancelar,
        numeroItemCancelar: Number(data.numeroItemCancelar?.toString().replace(',', '') ?? 0),
        tipoItemId: tipoItem?.id,
        tipoItem: tipoItem?.descripcion,
        paisOrigenId: paisOrigen?.id,
        paisOrigen: paisOrigen?.nombre,
        paisProcedenciaDestinoId: paisProcedenciaDestino?.id,
        paisProcedenciaDestino: paisProcedenciaDestino?.nombre,
        paisAdquisicionId: paisAdquisicion?.id,
        paisAdquisicion: paisAdquisicion?.nombre,
        cuotaArancelariaId: cuotaArancelaria?.id,
        cuotaArancelaria: cuotaArancelaria?.descripcion,
        tituloManifiestoCurrier: data.tituloManifiestoCurrier,
        idMatrizInsumos: data.idMatrizInsumos,
        numeroItemAsociado: Number(data.numeroItemAsociado?.toString().replace(',', '') ?? 0),
        ajusteAIncluir: Number(data.ajusteAIncluir?.toString().replace(',', '') ?? 0),
        numeroResolucion: data.numeroResolucion,
        convenioPerfectoActivo: data.convenioPerfectoActivo,
        numeroExoneracionAduana: data.numeroExoneracionAduana,
        pesoBruto: Number(data.pesoBruto?.toString().replace(',', '') ?? 0),
        pesoNeto: Number(data.pesoNeto?.toString().replace(',', '') ?? 0),
        estado:
          item.estado === EstadoDucaItem.Create ? EstadoDucaItem.Create : EstadoDucaItem.Update,
      } as IDucaItemRequest);

      const pesoBruto = Number(data.pesoBruto);
      if (pesoBruto !== item.pesoBruto) {
        const TotalFob = items
          .filter(i => i.key !== item.key)
          .reduce((previus, current) => (previus += current.importeFOB), 0);
        let action: 'Restar' | 'Sumar' = 'Sumar';
        let cantidad = 0;
        if (pesoBruto > item.pesoBruto) {
          action = 'Restar';
          cantidad = pesoBruto - item.pesoBruto;
        } else {
          cantidad = item.pesoBruto - pesoBruto;
        }

        return items.map(i => {
          if (i.key !== item.key) {
            const factor = i.importeFOB / TotalFob;
            const asignarCantidad = factor * cantidad;
            const pesoBrutoFinal =
              action === 'Restar' ? i.pesoBruto - asignarCantidad : i.pesoBruto + asignarCantidad;
            const estadoFinal =
              i.estado === EstadoDucaItem.Create ? EstadoDucaItem.Create : EstadoDucaItem.Update;

            return { ...i, pesoBruto: pesoBrutoFinal, estado: estadoFinal };
          } else {
            return i;
          }
        });
      }
    }

    return items;
  };

  return parseData;
};
