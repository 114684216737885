import { Row, Typography, Space, Button, Tooltip } from 'antd';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { downloadDuca } from '../store/extraReducer/downloadDuca';
const { Title } = Typography;

interface IDucaSecction {
  ordenTrabajoId: string;
  ducaId?: string;
  creando: boolean;
}

export const DucaSecction = ({ ordenTrabajoId, ducaId, creando }: IDucaSecction) => {
  const { push } = useHistory();
  const customDispatch = useCustomDispatch();
  const { devasDetalles, devasOfOrdenDeTrabajo } = useAppSelector(state => state.devas);

  const onDetails = () => {
    push({
      pathname: `/ordenDeTrabajo/${ordenTrabajoId}/detallesduca/${ducaId ?? ''}`,
      state: { creando },
    });
  };

  const onDownload = () => {
    customDispatch({
      asyncThuckFuction: downloadDuca,
      endPoint: `/api/Ducas/ExportToJson/${ducaId}`,
    });
  };

  const onGenerarPreliquidacion = () => {
    push({
      pathname: `/ordenDeTrabajo/${ordenTrabajoId}/preliquidacion`,
      state: { creando },
    });
  };

  const onImprimirPreliquidacion = () => {
    push({
      pathname: `/ordenDeTrabajo/${ordenTrabajoId}/detallesduca/${ducaId}/preliquidacion/imprimir`,
      state: { ducaId },
    });
  };

  const desabledDetalles = creando
    ? devasOfOrdenDeTrabajo.length > 0 && true
    : devasDetalles.length > 0 && true;

  return (
    <Row justify='space-between' align='middle'>
      <Title level={4} style={{ marginBottom: 0 }}>
        Duca
      </Title>
      <Space>
        {ducaId ? (
          <Tooltip placement='left' title='Imprime la duca en el formato preimpreso'>
            <Button onClick={onImprimirPreliquidacion}>Imprimir Preliquidacion</Button>
          </Tooltip>
        ) : (
          <Tooltip placement='left' title='Faltan detalles para imprimir la preliquidacion'>
            <Button disabled>Imprimir Preliquidacion</Button>
          </Tooltip>
        )}
        {ducaId ? (
          <Button onClick={onGenerarPreliquidacion}>Generar Preliquidacion</Button>
        ) : (
          <Tooltip placement='left' title='Faltan detalles para generar la preliquidacion'>
            <Button disabled>Generar Preliquidacion</Button>
          </Tooltip>
        )}
        {ducaId ? (
          <Button onClick={onDownload}>Descargar Duca</Button>
        ) : (
          <Tooltip placement='left' title='Faltan detalles para descargar la duca'>
            <Button disabled>Descargar Duca</Button>
          </Tooltip>
        )}
        <Button type='primary' ghost onClick={onDetails} disabled={!desabledDetalles}>
          Ver Detalles
        </Button>
      </Space>
    </Row>
  );
};
