import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { PartidaArancelaria } from 'models/PartidaArancelaria';
import httpService from 'services/httpService';
import { IPartidasArancelariasState } from '../partidaArancelariaStore';

export const queryPartidasArancelarias = createAsyncThunk(
  'PartidasArancelarias/Query',
  httpService,
);

export const queryPartidasArancelariasReducer = (
  builder: ActionReducerMapBuilder<IPartidasArancelariasState>,
) => {
  builder.addCase(
    queryPartidasArancelarias.fulfilled,
    (state, action: PayloadAction<PagedList<PartidaArancelaria>>) => {
      state.status = 'succeeded';
      state.pagedData = action.payload;
    },
  );
  builder.addCase(queryPartidasArancelarias.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(queryPartidasArancelarias.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
