import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Row, Typography, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { getProveedoresPendientes } from 'features/Inicio/store/extraReducers/proveedor/get';
import { setError } from 'features/InitialData/store/initialDataStore';
import { CreateProveedorInline } from 'features/Proveedores/CreateProveedorInline/CreateProveedorInline';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useInitialData } from 'hooks/useInitialData';
import { PapierError } from 'models/PapierException';
import { Proveedor } from 'models/Proveedor';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { dummyRequest } from 'utils/dummyRequest';
import excelModelo from '../../../assets/Modelo.xlsx';
import { DrawerValidarProductos } from '../DrawerValidarProductos/DrawerValidarProductos';
import { validarProductos } from '../store/extraReducer/validarProductos';
import {
  reset,
  setDevaInCreation,
  setDrawerValidarProductosVisible,
  setProveedor,
  setValidationDevaMessage,
} from '../store/store';
import { importarProductosDeExcel } from '../utils/importarProdutosDeExcel';

const { Item } = Form;
const { Link } = Typography;

interface IProps {
  ordenDeTrabajoId: string;
}

export const CreateDeva = ({ ordenDeTrabajoId }: IProps) => {
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const [form] = Form.useForm();
  const [files, setFiles] = useState<UploadFile[] | undefined>(undefined);
  const Proveedores = useAppSelector(state => state.proveedores.data);
  const UnidadesMedidasNetas = useAppSelector(state => state.unidadesMedidasNetas.data);
  const { createDevaStatus, drawerValidarProductosVisible } = useAppSelector(state => state.devas);
  const { proveedoresPendientes } = useAppSelector(state => state.inicio);

  useInitialData([
    {
      asyncThuckFuction: getProveedoresPendientes,
      endPoint: '/api/Proveedores/GetPendientes',
    },
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClose = () => {
    form.resetFields();
    setFiles(undefined);
    dispatch(reset());
  };

  useEffect(() => {
    createDevaStatus === 'succeeded' && onClose();
  }, [createDevaStatus, onClose]);

  const onGuardarProductos = async (proveedorId: string, productos?: UploadFile) => {
    try {
      const tempProveedor =
        Proveedores.find(proveedor => proveedor.id === proveedorId) ??
        proveedoresPendientes.find(proveedor => proveedor.id === proveedorId);

      dispatch(setProveedor(tempProveedor as Proveedor));
      dispatch(
        setDevaInCreation({
          proveedorId,
          ordenDeTrabajoId,
          productos: [],
          proveedor: String(tempProveedor?.nombre),
        }),
      );

      dispatch(setValidationDevaMessage('Validando productos'));

      if (productos) {
        await importarProductosDeExcel(productos.originFileObj as any, UnidadesMedidasNetas);
        onValidarProductos(proveedorId, productos);
        dispatch(
          setDrawerValidarProductosVisible({
            drawerValidarProductosVisible: true,
            createDevaWithModelFile: true,
          }),
        );
      } else {
        dispatch(
          setDrawerValidarProductosVisible({
            drawerValidarProductosVisible: true,
            createDevaWithModelFile: false,
          }),
        );
      }
    } catch (error) {
      if (error instanceof PapierError) {
        dispatch(
          setError({
            error: true,
            message: error.errorMessage,
            duration: 5,
          }),
        );
      } else {
        console.error(error);
      }
    }
  };

  const onValidarProductos = (proveedorId: string, productos: UploadFile) => {
    const formData = new FormData();
    const file = productos.originFileObj ?? null;
    formData.append('productsList', file as any);

    customDispatch({
      asyncThuckFuction: validarProductos,
      endPoint: `/api/Devas/ValidarProductos/${proveedorId}`,
      body: formData,
    });
  };

  return (
    <div>
      <Row style={{ marginBottom: 8 }}>
        <Form form={form} name='createDeva' layout='inline'>
          <Item name='proveedorId' label='Proveedor' rules={[{ required: true }]}>
            <CreateProveedorInline formInstance={form} />
          </Item>
          <Item name='productos' label='Productos'>
            <Upload
              multiple={false}
              fileList={files}
              accept='.xlsx, .xls'
              customRequest={f => dummyRequest(f)}
              onChange={e => setFiles(e.fileList.length > 0 ? [e.file] : undefined)}
            >
              <Button icon={<UploadOutlined />} style={{ width: 200 }}>
                Subir Productos
              </Button>
            </Upload>
          </Item>
        </Form>
        <Button
          type='primary'
          ghost
          onClick={() =>
            form
              .validateFields()
              .then(values => onGuardarProductos(values.proveedorId, values?.productos?.file))
              .catch(error => console.log(error))
          }
        >
          Guardar Productos
        </Button>
      </Row>
      <Link href={excelModelo} download='Modelo.xlsx'>
        Descargar archivo de modelo para importar los datos
      </Link>

      <DrawerValidarProductos visible={drawerValidarProductosVisible} onClose={onClose} />
    </div>
  );
};
