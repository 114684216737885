import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { setValoresTotalesOrdenDeTrabajo } from 'features/OrdenDeTrabajo/store/store';
import { Deva } from 'models/Deva';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';
import { IDevasState, setValoresTotales } from '../store';

export const prorratearValoresTotales = createAsyncThunk(
  'Deva/ProrratearValoresTotales',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      thunkApi.dispatch(
        setValoresTotalesOrdenDeTrabajo({
          TotalFOB: res.totalFOB,
          TotalFlete: res.totalFlete,
          TotalSeguro: res.totalSeguro,
          TotalOtrosGastos: res.totalOtrosGastos,
          TotalCIF: res.totalCIF,
        }),
      );

      const body = fetch.body as { flete: number; seguro: number; otrosGastos: number };
      thunkApi.dispatch(
        setValoresTotales({
          flete: body.flete,
          seguro: body.seguro,
          otrosGastos: body.otrosGastos,
        }),
      );
    }
    return res;
  },
);

interface IResponse {
  totalFlete: number;
  totalSeguro: number;
  totalOtrosGastos: number;
  totalCIF: number;
  devas: Deva[];
}

export const prorratearValoresTotalesReducer = (builder: ActionReducerMapBuilder<IDevasState>) => {
  builder.addCase(prorratearValoresTotales.fulfilled, (state, action: PayloadAction<IResponse>) => {
    if (state.ordenDeTrabajoInCreation) {
      state.devasOfOrdenDeTrabajo = action.payload.devas.map(x => ({ ...x, key: GenerateKey() }));
    } else {
      state.devasDetalles = action.payload.devas.map(x => ({ ...x, key: GenerateKey() }));
    }

    state.prorratearValoresTotalesStatus = 'succeeded';
  });

  builder.addCase(prorratearValoresTotales.pending, state => {
    state.prorratearValoresTotalesStatus = 'pending';
  });

  builder.addCase(prorratearValoresTotales.rejected, state => {
    state.prorratearValoresTotalesStatus = 'failed';
  });
};
