import { Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { IDucaDocumentoRequest } from 'models/Duca';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { TablaDocumentos } from './TablaDocumentos';
import { ColumnsType } from 'antd/lib/table';
import Text from 'antd/es/typography/Text';
import {
  tableColumnNumberFilter,
  tableColumnTextFilter,
} from '../../../../utils/tableColumnFilter';

export interface DataTypeDucaDocumento {
  key: React.Key;
  numeroItem: number;
  posicionArancelaria: string;
  descripcion: string;
  paisOrigen: string;
  existencia: boolean;
  dai?: number;
  tarifaTLC?: number;
  tarifaTLCAplicada?: number;
}

interface ITablaAsignarDocumentos {
  data: DataTypeDucaDocumento[];
  selectedRow: string[];
  setSelectedRow: (value: string[]) => void;
}

export const ColumnasTablaAsignar: ColumnsType<DataTypeDucaDocumento> = [
  {
    title: 'Numero Item',
    dataIndex: 'numeroItem',
    width: 130,
    render: (value: number, record: DataTypeDucaDocumento) =>
      !record.existencia ? <Text>{value}</Text> : <Text strong>{value}*</Text>,
    ...tableColumnNumberFilter<DataTypeDucaDocumento>('numeroItem'),
  },
  {
    title: 'Posición Arancelaria',
    dataIndex: 'posicionArancelaria',
    width: 170,
    ...tableColumnTextFilter<DataTypeDucaDocumento>('posicionArancelaria'),
  },
  {
    title: 'Descripción',
    dataIndex: 'descripcion',
    ellipsis: true,
    ...tableColumnTextFilter<DataTypeDucaDocumento>('descripcion'),
  },
  {
    title: 'Pais Origen',
    dataIndex: 'paisOrigen',
    width: 130,
    ...tableColumnTextFilter<DataTypeDucaDocumento>('paisOrigen'),
  },
  {
    title: 'Proveedor',
    dataIndex: 'proveedor',
    width: 130,
    ...tableColumnTextFilter<DataTypeDucaDocumento>('proveedor'),
  },
];

export const TableAsignarDocumentos = ({
  data,
  selectedRow,
  setSelectedRow,
}: ITablaAsignarDocumentos) => {
  const [documentos, setDocumentos] = useState<IDucaDocumentoRequest[]>([]);
  const [keyExpanded, setKeyExpanded] = useState<React.Key[]>([]);
  const items = useAppSelector(state => state.ducaDetalles.items);

  useEffect(() => {
    if (selectedRow.length === 0) {
      setDocumentos([]);
      setKeyExpanded([]);
      return;
    }
  }, [selectedRow]);

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys: any, _selectedRows: DataTypeDucaDocumento[]) => {
      setSelectedRow(selectedRowKeys);
    },
  } as TableRowSelection<DataTypeDucaDocumento>;

  return (
    <Table
      columns={ColumnasTablaAsignar}
      dataSource={data}
      rowSelection={rowSelection}
      expandable={
        items.some(item => item.documentos.length > 0)
          ? {
              expandedRowKeys: keyExpanded,
              expandedRowRender: () => <TablaDocumentos data={documentos} />,
              onExpand: (expanded, record) => {
                if (expanded) {
                  const result =
                    items
                      .find(x => x.key === record.key)
                      ?.documentos?.filter(x => x.estado !== EstadoDucaItem.Delete) ?? [];
                  setDocumentos(result);
                  setKeyExpanded([record.key]);
                } else {
                  setKeyExpanded([]);
                }
              },
              rowExpandable: record =>
                Number(items.find(x => x.key === record.key)?.documentos?.length) > 0,
            }
          : undefined
      }
      pagination={false}
      size='small'
      bordered
      scroll={{ y: 300 }}
    />
  );
};
