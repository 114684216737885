import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Modal, Row, Typography } from 'antd';
import { importarItems } from 'features/DucaDetalles/store/extraReducer/importarItems';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useState } from 'react';

const { Text } = Typography;

interface IFormProps {
  designacion: boolean;
  caracteristica: boolean;
}

interface IModalAgrupar {
  ordenId: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export const ModalAgrupar = ({ ordenId, visible, setVisible }: IModalAgrupar) => {
  const customDispatch = useCustomDispatch();
  const [agrupar, setAgrupar] = useState<boolean>(false);
  const [errors, setError] = useState<string[]>([]);
  const [form] = Form.useForm<IFormProps>();

  const onCancelar = () => {
    setVisible(false);
    setAgrupar(false);
    setError([]);
    form.resetFields();
  };

  const onAgruparProductos = async () => {
    const values = await form.getFieldsValue();

    if (values.designacion === true || values.caracteristica === true) {
      customDispatch({
        asyncThuckFuction: importarItems,
        endPoint: `/api/Ducas/ImportarItems/${ordenId}`,
        method: 'POST',
        body: {
          agrupar: true,
          designacion: values.designacion,
          caracteristica: values.caracteristica,
        },
      });
      onCancelar();
    }
  };

  const onNoAgruparProductos = () => {
    customDispatch({
      asyncThuckFuction: importarItems,
      endPoint: `/api/Ducas/ImportarItems/${ordenId}`,
      method: 'POST',
      body: { agrupar: false },
    });
    onCancelar();
  };
  return (
    <Modal
      visible={visible}
      title={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <ExclamationCircleOutlined style={{ color: '#faad14', fontSize: 22, marginRight: 6 }} />
          ¿Deseas agrupar los productos?
        </span>
      }
      footer={
        <Row justify='space-between'>
          <Button type='link' onClick={onCancelar}>
            Cancelar
          </Button>
          <div>
            <Button onClick={onNoAgruparProductos} disabled={agrupar}>
              No Agrupar
            </Button>
            <Button type='primary' onClick={() => setAgrupar(true)} disabled={agrupar}>
              Sí Agrupar
            </Button>
          </div>
        </Row>
      }
      onCancel={onCancelar}
    >
      <Row justify='center' style={{ marginBottom: agrupar ? 16 : 0 }}>
        <Text type='warning'>
          Si ya existen productos importados, se eliminaran por los nuevos.
        </Text>
      </Row>
      <Form
        form={form}
        onChange={async () => {
          const values = await form.getFieldsValue();
          if (!values.designacion && !values.caracteristica) {
            setError(['Debe seleccionar al menos una opción']);
          } else {
            setError([]);
          }
        }}
      >
        {agrupar && (
          <>
            Opciones a agragar en la descripción del producto:
            <Form.Item
              name='designacion'
              initialValue
              valuePropName='checked'
              style={{ marginBottom: 0 }}
            >
              <Checkbox disabled>Designación</Checkbox>
            </Form.Item>
            <Form.Item
              name='caracteristica'
              initialValue={false}
              valuePropName='checked'
              style={{ marginBottom: 0 }}
            >
              <Checkbox>Descripción</Checkbox>
            </Form.Item>
            {errors.map((error, index) => (
              <div key={index} style={{ color: 'red' }}>
                {error}
              </div>
            ))}
            <Button type='primary' onClick={onAgruparProductos} style={{ marginTop: 16 }}>
              Confirmar
            </Button>
          </>
        )}
      </Form>
    </Modal>
  );
};
