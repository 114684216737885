import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { MarcaComercial } from 'models/MarcaComercial';
import httpService from 'services/httpService';
import { IMarcasComercialesState } from '../marcasComercialesStore';

export const updateMarcaComercial = createAsyncThunk('MarcasComerciales/Update', httpService);

export const updateMarcaComercialReducer = (
  builder: ActionReducerMapBuilder<IMarcasComercialesState>,
) => {
  builder.addCase(updateMarcaComercial.fulfilled, (state, _action) => {
    const index = state.data.findIndex(
      partidaArancelaria => partidaArancelaria.id === state.elementoSeleccionado?.id,
    );

    state.crudStatus = 'succeeded';
    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as MarcaComercial);
  });
  builder.addCase(updateMarcaComercial.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updateMarcaComercial.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
