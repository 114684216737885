import { Button, Col, Row, Space } from 'antd';
import { useDispatch } from 'react-redux';
import SearchFilter from '../../../components/SearchFilter/SearchFilter';
import {
  setBulkDeleteStatusIdle,
  setBulkUpdateStatusIdle,
  toggleBulkDeleteVisible,
  toggleBulkUpdateVisible,
} from '../store/productosStore';

interface IProps {
  setSku: (value: string) => void;
  setToggleDrawer: () => void;
  toggleBulkImportModal: () => void;
}

export const Toolbar = ({ setSku, setToggleDrawer, toggleBulkImportModal }: IProps) => {
  const dispatch = useDispatch();

  const toggleBulkUpdateModal = () => {
    dispatch(toggleBulkUpdateVisible());
    dispatch(setBulkUpdateStatusIdle());
  };

  const toggleBulkDeleteModal = () => {
    dispatch(toggleBulkDeleteVisible());
    dispatch(setBulkDeleteStatusIdle());
  };

  return (
    <Row gutter={[24, 24]} align='middle' justify='space-between' style={{ marginBottom: 24 }}>
      <Col>
        <SearchFilter setFilter={setSku} placeholder='Buscar producto' />
      </Col>
      <Col>
        <Space>
          <Button type='primary' onClick={setToggleDrawer}>
            Crear Producto
          </Button>
          <Button onClick={toggleBulkImportModal}>Crear por Bultos</Button>

          <Button onClick={toggleBulkUpdateModal}>Actualizar por Bultos</Button>
          <Button onClick={toggleBulkDeleteModal}>Eliminar por Bultos</Button>
        </Space>
      </Col>
    </Row>
  );
};
