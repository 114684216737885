import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IDevaDetallesState } from '../store';
import { DevaDetails } from '../../../../models/Deva';

export const updateProductoDeva = createAsyncThunk('DevaDetalles/UpdateDevaProducto', httpService);

export const updateDevaProductoReducer = (builder: ActionReducerMapBuilder<IDevaDetallesState>) => {
  builder.addCase(updateProductoDeva.fulfilled, (state, action: PayloadAction<DevaDetails>) => {
    const devaDetalles = state.devaDetalles;
    const devaDetallesResponse = action.payload;

    if (devaDetalles) {
      devaDetalles.importeFactura = devaDetallesResponse.importeFactura;
      devaDetalles.importeRealPagado = devaDetallesResponse.importeRealPagado;
      devaDetalles.valorAduana = devaDetallesResponse.valorAduana;

      const devaProductoActualizado = devaDetallesResponse.productos![0];
      if (devaDetalles.productos) {
        const index = devaDetalles.productos.findIndex(_ => _.id === devaProductoActualizado.id);
        if (index !== -1) {
          devaDetalles.productos.splice(index, 1, { ...devaProductoActualizado });
        }
      }
    }

    state.devaProductoDrawerIsVisible = false;
    state.devaProductoCrud = 'succeeded';
  });
  builder.addCase(updateProductoDeva.pending, (state, _action) => {
    state.devaProductoCrud = 'pending';
  });
  builder.addCase(updateProductoDeva.rejected, (state, _action) => {
    state.devaProductoCrud = 'failed';
  });
};
