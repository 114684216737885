import { Form } from 'antd';
import { useFilter } from 'hooks/useFilter';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { ConversionUnidadDeMedida } from '../../models/ConversionUnidadDeMedida';
import { UnidadesMedidasNetasState } from '../UnidadesMedidasNetas/store/unidadesMedidasNetasStore';
import { Columns } from './Columns';
import styles from './ConversionesUnidadesDeMedidas.module.less';
import { ModalUpsert } from './ModalUpsert/ModalUpsert';
import {
  ConversionesUnidadDeMedidasState,
  setConversionUnidadDeMedidaTemporal,
  setCrudStatusIdle,
  toggleModalVisible,
  toggleSeleccionarConversionUnidadDeMedida,
} from './store/conversionesUnidadesDeMedidasStore';
import { createConversionUnidadDeMedida } from './store/extraReducer/create';
import { deleteConversionUnidadDeMedida } from './store/extraReducer/delete';
import { getConversionesUnidadDeMedidas } from './store/extraReducer/get';
import { updateConversionUnidadDeMedida } from './store/extraReducer/update';
import { Toolbar } from './Toolbar/Toolbar';

interface IProps {}

export const ConversionesUnidadesDeMedidas: FC<IProps> = () => {
  const { data, status, crudStatus, modalVisible, elementoSeleccionado, apiURL } = useSelector(
    ConversionesUnidadDeMedidasState,
  );
  const { data: unidadesMedidasNetas } = useSelector(UnidadesMedidasNetasState);
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    status === 'idle' &&
      customDispatch({
        asyncThuckFuction: getConversionesUnidadDeMedidas,
        endPoint: '/api/ConversionUnidadesDeMedidas/GetAll',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    !window.document.title.includes('Conversión de Unidades de Medidas') &&
      dispatch(setTitleName('Conversión de Unidades de Medidas'));

    if (crudStatus === 'succeeded') {
      dispatch(setCrudStatusIdle());
      form.resetFields();
    }
  }, [crudStatus, dispatch, form]);

  const setUpdateSelection = (values: ConversionUnidadDeMedida) => {
    dispatch(toggleSeleccionarConversionUnidadDeMedida(values));
    dispatch(toggleModalVisible());
    form.setFieldsValue({ ...values });
  };

  const setDeleteSelection = (values: ConversionUnidadDeMedida) => {
    dispatch(toggleSeleccionarConversionUnidadDeMedida(values));
    onDelete(values.id);
  };

  const setToggleModalVisible = () => {
    dispatch(toggleSeleccionarConversionUnidadDeMedida(undefined));
    dispatch(toggleModalVisible());
  };

  const cols = Columns({
    onUpdate: setUpdateSelection,
    onDelete: setDeleteSelection,
  });

  const getCompleteData = (values: ConversionUnidadDeMedida): ConversionUnidadDeMedida => {
    const unidadOrigen = unidadesMedidasNetas.filter(x => x.id === values.unidadMedidaOrigenId)[0];
    const unidadDestino = unidadesMedidasNetas.filter(
      x => x.id === values.unidadMedidaDestinoId,
    )[0];

    return {
      ...values,
      unidadMedidaOrigen: `${unidadOrigen.nombre} - ${unidadOrigen.descripcion}`,
      unidadMedidaDestino: `${unidadDestino.nombre} - ${unidadDestino.descripcion}`,
    };
  };

  const onCreate = (values: ConversionUnidadDeMedida) => {
    dispatch(setConversionUnidadDeMedidaTemporal(getCompleteData(values)));
    customDispatch({
      asyncThuckFuction: createConversionUnidadDeMedida,
      endPoint: `${apiURL}/Create`,
      body: values,
    });
  };

  const onUpdate = (values: ConversionUnidadDeMedida) => {
    dispatch(setConversionUnidadDeMedidaTemporal(getCompleteData(values)));
    customDispatch({
      asyncThuckFuction: updateConversionUnidadDeMedida,
      endPoint: `${apiURL}/Update/${elementoSeleccionado?.id}`,
      body: values,
      method: 'PUT',
    });
  };

  const onDelete = (id: string) => {
    customDispatch({
      asyncThuckFuction: deleteConversionUnidadDeMedida,
      endPoint: `${apiURL}/Delete/${id}`,
      method: 'DELETE',
    });
  };

  const [onfilter, filterData] = useFilter<ConversionUnidadDeMedida>({
    data,
    filterPredicate: (item, filter) => {
      const value = item.unidadMedidaOrigen || item.unidadMedidaDestino;
      return value.toLowerCase().includes(filter.toLowerCase());
    },
  });

  return (
    <section className={styles.container}>
      <Toolbar setNombre={onfilter} setToggleModal={setToggleModalVisible} />
      <BaseTable
        cols={cols}
        data={filterData}
        status={status}
        size='small'
        style={{ height: 'calc(100% - 40px)' }}
      />
      <ModalUpsert
        visible={modalVisible}
        form={form}
        status={crudStatus}
        elementoSeleccionado={elementoSeleccionado}
        unidadesMedidasNetas={unidadesMedidasNetas}
        onCreate={onCreate}
        onUpdate={onUpdate}
        setCerrarModal={setToggleModalVisible}
      />
    </section>
  );
};
