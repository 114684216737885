import { UpdateDevaProducto } from 'models/Deva';
import { useAppSelector } from 'store/hooks';

export const useFormatData = () => {
  const { UnidadesMedida, MarcasComerciales, EstadosMercancias, Paises } = useAppSelector(
    state => ({
      UnidadesMedida: state.unidadesMedidasNetas.data,
      MarcasComerciales: state.marcasComerciales.data,
      EstadosMercancias: state.estadosMercancias.data,
      Paises: state.paises.data,
    }),
  );

  const parseData = (data: any) => {
    const fechaFacturaSplit = String(data.fechaFactura).split('/');
    const fechaFactura = `${fechaFacturaSplit[1]}/${fechaFacturaSplit[0]}/${fechaFacturaSplit[2]}`;

    return {
      unidadMedidaId: UnidadesMedida.find(u => u.nombre === data.unidadMedida)?.id,
      designacion: data.designacion,
      caracteristicas: data.caracteristicas,
      marcaId: MarcasComerciales.find(m => m.nombre === data.marca)?.id,
      estadoMercanciaId: EstadosMercancias.find(e => e.nombre === data.estadoMercancia)?.id,
      paisOrigenId: Paises.find(p => p.nombre === data.origen)?.id,
      partidaArancelaria: data.partidaArancelaria,
      numeroFactura: data.numeroFactura,
      fechaFactura: new Date(fechaFactura),
      cantidad: Number(data.cantidad?.toString().replace(',', '') ?? 0),
      valorUnitario: Number(data.valorUnitario?.toString().replace(',', '') ?? 0),
      valorTotal: Number(data.valorTotal?.toString().replace(',', '') ?? 0),
    } as UpdateDevaProducto;
  };

  return parseData;
};
