import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Grid, Menu, Space } from 'antd';
import { Fragment } from 'react';
import { useAuth0UserData } from '../../../hooks/useAuth0UserData';
import { LogoutButton } from '../../LogOutButton/LogOutButton';
import styles from './userOptions.module.less';

const { Item } = Menu;
const { useBreakpoint } = Grid;
const menu = (
  <Menu>
    <Item key='1'>
      <LogoutButton text='Cerrar Sesión' />
    </Item>
  </Menu>
);

const UserActions = () => {
  const user = useAuth0UserData();

  const breakPoint = useBreakpoint();

  return (
    <Fragment>
      <Dropdown overlay={menu} placement='bottomLeft' className={styles.dropdown}>
        <Space>
          <img className={styles.userImage} src={user?.picture} alt='Imagen de usuario' />
          {breakPoint.md ? (
            <Fragment>
              <Space direction='vertical' size='small'>
                {user?.isAdmin && (
                  <span style={{ color: 'gray', fontSize: '0.6rem' }}>(Admin)</span>
                )}
                <span className={styles.userName}>{user?.name}</span>
              </Space>
            </Fragment>
          ) : null}
          <DownOutlined className={styles.icon} />
        </Space>
      </Dropdown>
    </Fragment>
  );
};

export default UserActions;
