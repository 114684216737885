import { Button, Form, FormInstance, Select } from 'antd';
import { IDucaVentajaRequest } from 'models/Duca';
import { useAppSelector } from 'store/hooks';

const { Item } = Form;
const { Option } = Select;

interface IFormVentaja {
  form: FormInstance<IDucaVentajaRequest>;
  onFinish: (values: IDucaVentajaRequest) => void;
  onChange: (value: string, option: any) => void;
  disabled: boolean;
}

export const FormVentaja = ({ form, onFinish, onChange, disabled }: IFormVentaja) => {
  const Ventajas = useAppSelector(state => state.ducaDetalles.Ventajas);
  const rules = [{ required: true, message: 'Campo requerido' }];

  return (
    <Form
      form={form}
      layout='vertical'
      style={{ display: 'flex', alignItems: 'end', gap: 16 }}
      onFinish={disabled ? undefined : onFinish}
    >
      <Item label='Ventaja' name='ventajaId' rules={rules} style={{ width: '100%' }}>
        <Select showSearch optionFilterProp='children' onChange={onChange} allowClear>
          {Ventajas.map(tipo => (
            <Option key={tipo.id} value={tipo.id}>
              {`${tipo.codigo} - ${tipo.descripcion}`}
            </Option>
          ))}
        </Select>
      </Item>
      <Item>
        <Button type='primary' htmlType='submit' disabled={disabled}>
          Asignar
        </Button>
      </Item>
    </Form>
  );
};
