import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IUnidadesMedidasNetasState } from '../unidadesMedidasNetasStore';

export const deleteUnidadMedidaNeta = createAsyncThunk('UnidadesMedidasNetas/Delete', httpService);

export const deleteUnidadMedidaNetaReducer = (
  builder: ActionReducerMapBuilder<IUnidadesMedidasNetasState>,
) => {
  // Delete
  builder.addCase(deleteUnidadMedidaNeta.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.data.findIndex(
      unidadMedidaNeta => unidadMedidaNeta.id === state.elementoSeleccionado?.id,
    );
    state.data.splice(index, 1);
  });
  builder.addCase(deleteUnidadMedidaNeta.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deleteUnidadMedidaNeta.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
