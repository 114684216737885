import { Col, Row, Typography, Grid } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Logo } from './Logo/Logo';
import {
  selectCurrentView,
  selectCurrentCollapsedMenu,
  setCollapsedMenu,
} from '../Sidebar/store/sidebarStore';
import UserOptions from './UserOptions/UserOptions';
import styles from './navbar.module.less';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const Navbar = () => {
  const viewName = useSelector(selectCurrentView);
  const collapsed = useSelector(selectCurrentCollapsedMenu);
  const dispatch = useDispatch();
  const breakPoint = useBreakpoint();

  return (
    <header className={styles.header}>
      {!breakPoint.md ? (
        <div
          onClick={() => dispatch(setCollapsedMenu(!collapsed))}
          className={styles.menuContainer}
        >
          <MenuOutlined />
        </div>
      ) : null}
      <Logo isAuthenticated isCollapsed={collapsed} />
      <Row
        align='middle'
        justify='space-between'
        className={!breakPoint.md ? styles.nav : collapsed ? styles.nav : styles.navExpanded}
      >
        <Col>
          <Row align='middle'>
            <Title className={styles.empresaNombre} level={3}>
              {viewName}
            </Title>
          </Row>
        </Col>
        <Col>
          <UserOptions />
        </Col>
      </Row>
    </header>
  );
};

export default Navbar;
