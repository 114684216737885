import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UploadFile } from 'antd/lib/upload/interface';
import { PagedList } from 'models/PagedList';
import buildDefaultPagedList from 'utils/buildDefaultPagedList';
import { Producto } from '../../../models/Producto';
import { RootState } from '../../../store/store';
import { bulkDeleteProductosReducer } from './extraReducer/bulkDeleteProductos';
import { bulkImportProductosReducer } from './extraReducer/bulkImportProductos';
import { bulkUpdateProductosReducer } from './extraReducer/bulkUpdateProductos';
import { createProductoReducer } from './extraReducer/createProducto';
import { deleteProductoReducer } from './extraReducer/deleteProducto';
import { queryProductosReducer } from './extraReducer/queryProductos';
import { updateProductoReducer } from './extraReducer/updateProducto';

export interface IProductosState {
  data: Array<Producto>;
  pagedData: PagedList<Producto>;
  status: StatusApp.Status;
  crudStatus: StatusApp.Status;
  drawerVisible: boolean;
  elementoSeleccionado?: Producto;
  elementoTemporal?: Producto;
  apiURL: string;
  ultimoProductoCreado?: Producto;

  // Bulk Import
  bulkImportStatus: StatusApp.Status;
  bulkImportVisible: boolean;
  archivoBulkImport?: UploadFile<any>[];
  bulkImportFeedback?: string[];
  bulkImportCreatedProducts?: number;

  // Bulk Update
  bulkUpdateStatus: StatusApp.Status;
  bulkUpdateVisible: boolean;
  bulkUpdateFeedback?: string[];
  bulkImportUpdateProducts?: number;

  // Bulk Delete
  bulkDeleteStatus: StatusApp.Status;
  bulkDeleteVisible: boolean;
  bulkDeleteFeedback?: string[];
  bulkImportDeleteProducts?: number;
}

const initialState: IProductosState = {
  data: [],
  pagedData: buildDefaultPagedList<Producto>(),
  status: 'idle',
  crudStatus: 'idle',
  drawerVisible: false,
  apiURL: '/api/Productos',
  ultimoProductoCreado: undefined,

  // Bulk Import
  bulkImportStatus: 'idle',
  bulkImportVisible: false,

  // Bulk Update
  bulkUpdateStatus: 'idle',
  bulkUpdateVisible: false,

  // Bulk Update
  bulkDeleteStatus: 'idle',
  bulkDeleteVisible: false,
};

export const ProductosSlice = createSlice({
  name: 'Productos',
  initialState,
  reducers: {
    toggleSeleccionarProducto: (state, action: PayloadAction<Producto | undefined>) => {
      state.elementoSeleccionado = action.payload
        ? {
            ...action.payload,
            mostrarDescripcionConPresentacion: String(
              action.payload?.mostrarDescripcionConPresentacion,
            ),
          }
        : action.payload;
    },
    toggleDrawerVisible: state => {
      state.drawerVisible = !state.drawerVisible;
    },
    setProductoTemporal: (state, action: PayloadAction<Producto>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.drawerVisible = false;
    },
    addProducto: (state, action: PayloadAction<Producto>) => {
      state.data.push(action.payload);
    },
    resetUltimoProductoCreado: state => {
      state.ultimoProductoCreado = undefined;
    },

    // Bulk Import
    toggleBulkImportVisible: state => {
      state.bulkImportVisible = !state.bulkImportVisible;
    },
    setArchivoBulkImport: (state, action: PayloadAction<UploadFile<any>[] | undefined>) => {
      state.archivoBulkImport = action.payload;
    },
    setBulkImportFeedback: (state, action: PayloadAction<string[] | undefined>) => {
      state.bulkImportFeedback = action.payload;
    },
    setBulkImportStatusIdle: state => {
      state.bulkImportStatus = 'idle';
      state.archivoBulkImport = undefined;
    },

    // Bulk Update
    toggleBulkUpdateVisible: state => {
      state.bulkUpdateVisible = !state.bulkUpdateVisible;
    },
    setBulkUpdateFeedback: (state, action: PayloadAction<string[] | undefined>) => {
      state.bulkUpdateFeedback = action.payload;
    },
    setBulkUpdateStatusIdle: state => {
      state.bulkUpdateStatus = 'idle';
    },

    // Bulk Update
    toggleBulkDeleteVisible: state => {
      state.bulkDeleteVisible = !state.bulkDeleteVisible;
    },
    setBulkDeleteFeedback: (state, action: PayloadAction<string[] | undefined>) => {
      state.bulkDeleteFeedback = action.payload;
    },
    setBulkDeleteStatusIdle: state => {
      state.bulkDeleteStatus = 'idle';
    },
  },
  extraReducers: builder => {
    createProductoReducer(builder);
    deleteProductoReducer(builder);
    updateProductoReducer(builder);
    bulkImportProductosReducer(builder);
    bulkUpdateProductosReducer(builder);
    bulkDeleteProductosReducer(builder);
    queryProductosReducer(builder);
  },
});

export const {
  toggleSeleccionarProducto,
  toggleDrawerVisible,
  setProductoTemporal,
  setCrudStatusIdle,
  addProducto,
  resetUltimoProductoCreado,

  // Bulk Import
  toggleBulkImportVisible,
  setArchivoBulkImport,
  setBulkImportFeedback,
  setBulkImportStatusIdle,

  // Bulk Update
  toggleBulkUpdateVisible,
  setBulkUpdateFeedback,
  setBulkUpdateStatusIdle,

  // Bulk Delete
  toggleBulkDeleteVisible,
  setBulkDeleteFeedback,
  setBulkDeleteStatusIdle,
} = ProductosSlice.actions;

export const ProductosState = (state: RootState) => state.productos;

export default ProductosSlice.reducer;
