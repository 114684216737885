import { useAuth0, User } from '@auth0/auth0-react';

class Auth0UserData extends User {
  isAdmin?: boolean;
}

export const useAuth0UserData = (): Auth0UserData | undefined => {
  const { user } = useAuth0();

  if (!user) return user;

  // Extend with isAdmin prop
  return {
    ...user,
    isAdmin: user['https://rymlogistichn/is-admin'],
  };
};
