import { EditOutlined, ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Space, Typography } from 'antd';
import { DevasSecction } from 'components/Devas/DevasSecction/DevasSecction';
import { setOrdenDeTrabajoInCreation } from 'components/Devas/store/store';
import { DucaSecction } from 'components/Duca/DucaSecction/DucaSecction';
import { EstadoOrdenTrabajo } from 'enum/EstadoOrdenTrabajo';
import { Deva } from 'models/Deva';
import { OrdenDeTrabajo } from 'models/OrdenTrabajo';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { DatosGenerales } from './DatosGenerales/DatosGenerales';
import { DrawerOrdenTrabajoInfo } from './DrawerOrdenTrabajoInfo/DrawerOrdenTrabajoInfo';
import { EstatusBar } from './EstatusBar/EstatusBar';
import styles from './OrdenTrabajo.module.less';
import { closeOrdenTrabajo } from './store/extraReducers/closeOrdenTrabajo';
import { getOrdenTrabajoDetalles } from './store/extraReducers/getOrdenTrabajoDetalles';
import {
  setCurrentOrdenTrabajoId,
  setDrawerVisible,
  setTotales,
} from './store/ordenTrabajoDetallesStore';

const { Title } = Typography;

export const OrdenTrabajoDetalles: FC = () => {
  const { ordenId } = useParams<{ ordenId: string }>();
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const { apiURL, status, data, drawerVisible } = useAppSelector(
    state => state.ordenTrabajoDetalles,
  );
  const devas = useAppSelector(state => state.devas.devasDetalles);

  useEffect(() => {
    if (data) {
      !window.document.title.includes(`Orden - ${data.importador}`) &&
        dispatch(setTitleName(`Orden - ${data.importador ?? ''}`));

      dispatch(setOrdenDeTrabajoInCreation(false));
    }
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(setCurrentOrdenTrabajoId(ordenId));
    if (status === 'idle') {
      customDispatch({
        asyncThuckFuction: getOrdenTrabajoDetalles,
        endPoint: `${apiURL}/GetById/${ordenId}`,
      });
    }
  }, [apiURL, customDispatch, dispatch, ordenId, status]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentOrdenTrabajoId(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal = () => {
    dispatch(setDrawerVisible(false));
  };

  const onOpenModal = () => {
    dispatch(setDrawerVisible(true));
  };

  const confirmarCerrado = () => {
    Modal.confirm({
      title: 'Seguro que desea cerrar esta Orden de Trabajo?',
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá realizar más cambios',
      onOk() {
        customDispatch({
          asyncThuckFuction: closeOrdenTrabajo,
          method: 'PUT',
          endPoint: `${apiURL}/Close/${ordenId}`,
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // Calcular totales
  useEffect(() => {
    const totales = devas.reduce(
      (acc, current) => {
        acc.valorFOB += Number(current.valorFOB);
        acc.flete += Number(current.flete);
        acc.seguro += Number(current.seguro);
        acc.otrosGastos += Number(current.otrosGastos);
        acc.valorCIF += Number(current.valorCIF);

        return acc;
      },
      { valorFOB: 0, flete: 0, seguro: 0, otrosGastos: 0, valorCIF: 0 } as Deva,
    );

    dispatch(
      setTotales({
        fob: Number(totales.valorFOB.toFixed(2)),
        flete: Number(totales.flete.toFixed(2)),
        seguro: Number(totales.seguro.toFixed(2)),
        og: Number(totales.otrosGastos.toFixed(2)),
        cif: Number(totales.valorCIF.toFixed(2)),
      } as OrdenDeTrabajo),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devas]);

  const showEditButton = data?.estado !== EstadoOrdenTrabajo.Cerrada && (
    <Space>
      <Button
        type='primary'
        icon={<EditOutlined style={{ fontSize: '15px' }} twoToneColor='#fff' />}
        onClick={onOpenModal}
      >
        Editar
      </Button>

      <Button onClick={confirmarCerrado} danger icon={<LockOutlined />}>
        Cerrar Orden de Trabajo
      </Button>
    </Space>
  );

  return (
    <div className={styles.container}>
      <EstatusBar />

      <div className={`${styles.secction} ${styles.secctionFormOrdenDeTrabajo}`}>
        <Row justify='space-between'>
          <Title level={4} style={{ margin: '0' }}>
            Datos Generales
          </Title>
          {showEditButton}
        </Row>
        <DatosGenerales data={data} />
      </div>

      <DrawerOrdenTrabajoInfo visible={drawerVisible} setCerrarModal={onCloseModal} />

      <Col className={styles.secction}>
        <DevasSecction
          ordenTrabajoId={ordenId}
          cerrada={data?.estado === EstadoOrdenTrabajo.Cerrada}
          creando={false}
        />
      </Col>

      <Col className={styles.secction}>
        <DucaSecction ordenTrabajoId={ordenId} ducaId={data?.ducaId} creando={false} />
      </Col>
    </div>
  );
};
