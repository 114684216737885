import { Row, Typography } from 'antd';
import { CreateDeva } from '../CreateDeva/CreateDeva';
import { DevaTable } from '../DevasTable/DevaTable';
import { FormValoresTotales } from '../FormValoresTotales/FormValoresTotales';
import styles from './DevasSecction.module.less';

const { Title } = Typography;

interface IDevasSecction {
  ordenTrabajoId: string;
  cerrada?: boolean;
  creando: boolean;
}

export const DevasSecction = ({ ordenTrabajoId, cerrada = false, creando }: IDevasSecction) => {
  return (
    <div className={styles.container}>
      {!cerrada && (
        <div className={styles.secction}>
          <Title level={4} style={{ margin: 0 }}>
            Agregar Nueva Deva
          </Title>
          <CreateDeva ordenDeTrabajoId={ordenTrabajoId} />
        </div>
      )}

      <div className={styles.secction}>
        <Row justify='space-between'>
          <Title level={4} style={{ margin: 0 }}>
            Devas
          </Title>
          <FormValoresTotales
            disabled={cerrada}
            ordenTrabajoId={ordenTrabajoId}
            creando={creando}
          />
        </Row>
        <DevaTable creando={creando} ordenTrabajoId={ordenTrabajoId} cerrada={cerrada} />
      </div>
    </div>
  );
};
