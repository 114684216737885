import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { DevasReducer } from 'components/Devas/store/store';
import { ArchivoPreliquidacionReducer } from 'features/ArchivoPreliquidacion/store/store';
import { DevaDetallesReducer } from 'features/DevaDetalles/store/store';
import { DucaDetallesReducer } from 'features/DucaDetalles/store/store';
import sidebarReducer from '../components/Sidebar/store/sidebarStore';
import clasificacionesFenagReducer from '../features/ClasificacionFenag/store/clasificacionesFenagStore';
import conversionesUnidadesDeMedidasReducer from '../features/ConversionesUnidadesDeMedidas/store/conversionesUnidadesDeMedidasStore';
import designacionesProductosReducer from '../features/DesignacionesProductos/store/designacionesProductosStore';
import estadosMercanciasReducer from '../features/EstadosMecaderias/store/estadosMercanciasStore';
import { importadoresReducer } from '../features/Importadores/store/store';
import inicioReducer from '../features/Inicio/store/inicioStore';
import initialDataReducer from '../features/InitialData/store/initialDataStore';
import marcasComercialesReducer from '../features/MarcasComerciales/store/marcasComercialesStore';
import { OrdenDeTrabajoReducer } from '../features/OrdenDeTrabajo/store/store';
import { OrdenDeTrabajoTableReducer } from '../features/OrdenDeTrabajoTable/store/store';
import ordenTrabajoDetallesReducer from '../features/OrdenTrabajoDetalles/store/ordenTrabajoDetallesStore';
import paisesReducer from '../features/Paises/store/paisesStore';
import partidasArancelariasReducer from '../features/PartidasArancelarias/store/partidaArancelariaStore';
import productosReducer from '../features/Productos/store/productosStore';
import { ProveedoresReducer } from '../features/Proveedores/store/proveedoresStore';
import tratadosReducer from '../features/Tratados/store/tratadosStore';
import unidadesMedidasNetasReducer from '../features/UnidadesMedidasNetas/store/unidadesMedidasNetasStore';
import usuariosReducer from '../features/Usuarios/store/usuariosStore';

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    initialData: initialDataReducer,
    inicio: inicioReducer,
    proveedores: ProveedoresReducer,
    partidasArancelarias: partidasArancelariasReducer,
    marcasComerciales: marcasComercialesReducer,
    paises: paisesReducer,
    tratados: tratadosReducer,
    unidadesMedidasNetas: unidadesMedidasNetasReducer,
    estadosMercancias: estadosMercanciasReducer,
    clasificacionesFenag: clasificacionesFenagReducer,
    designacionesProductos: designacionesProductosReducer,
    productos: productosReducer,
    ordenDeTrabajoTable: OrdenDeTrabajoTableReducer,
    usuarios: usuariosReducer,
    ordenDeTrabajo: OrdenDeTrabajoReducer,
    ordenTrabajoDetalles: ordenTrabajoDetallesReducer,
    conversionesUnidadesDeMedidas: conversionesUnidadesDeMedidasReducer,
    importadores: importadoresReducer,
    devaDetalles: DevaDetallesReducer,
    devas: DevasReducer,
    ducaDetalles: DucaDetallesReducer,
    archivoPreliquidacion: ArchivoPreliquidacionReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
