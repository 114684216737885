import { Button, Drawer, Form } from 'antd';
import { CustomDatepicker } from 'components/FormControls/CustomDatePicker';
import { DropDownGrid } from 'components/FormControls/CustomDropDown';
import { CustomInput } from 'components/FormControls/CustomInput';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useDebounce } from 'hooks/useDebounce';
import { AddDevaProducto } from 'models/Deva';
import { QuerySpec } from 'models/QuerySpec';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { queryProductos } from '../../../features/Productos/store/extraReducer/queryProductos';
import { useEffect, useState } from 'react';
import { toggleDevaProductoDrawerVisible } from '../../../features/DevaDetalles/store/store';
import { queryPartidasArancelarias } from '../../../features/PartidasArancelarias/store/extraReducers/queryPartidasArancelarias';
import { createDevaProducto } from '../../../features/DevaDetalles/store/extraReducers/createDevaProducto';
import { useParams } from 'react-router-dom';

export const DevaProductoDrawer = () => {
  const { devaId } = useParams<{ devaId: string }>();
  const [itemsRelatedProductsDisabled, setItemsRelatedProductsDisabled] = useState<boolean>(true);
  const { devaProductoDrawerIsVisible, devaDetalles, devaProductoCrud } = useAppSelector(
    state => state.devaDetalles,
  );
  const {
    pagedData: { items: Productos },
    status: ProductosStatus,
  } = useAppSelector(state => state.productos);
  const { data: UnidadesMedidas, status: UnidadesMedidasStatus } = useAppSelector(
    state => state.unidadesMedidasNetas,
  );
  const {
    pagedData: { items: PartidasArancelarias },
    status: PartidasArancelariasStatus,
  } = useAppSelector(state => state.partidasArancelarias);
  const { data: DesignacionesProductos, status: DesignacionesProductosStatus } = useAppSelector(
    state => state.designacionesProductos,
  );
  const { data: MarcasComerciales, status: MarcasComercialesStatus } = useAppSelector(
    state => state.marcasComerciales,
  );
  const { data: EstadosMercancias, status: EstadosMercanciasStatus } = useAppSelector(
    state => state.estadosMercancias,
  );
  const { data: Paises, status: PaisesStatus } = useAppSelector(state => state.paises);

  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const [form] = Form.useForm<AddDevaProducto>();

  // Carga inicial de los productos
  useEffect(() => {
    if (!devaDetalles) return;
    dispatchProductos('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devaDetalles]);

  // Lipiar form al crearse exitosamente
  useEffect(() => {
    if (devaProductoCrud !== 'succeeded') return;
    form.resetFields();
    setItemsRelatedProductsDisabled(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devaProductoCrud]);

  const onClose = () => {
    dispatch(toggleDevaProductoDrawerVisible(false));
  };

  const onFinish = async () => {
    const values = await form.validateFields();
    values.devaId = devaId;
    onCreateDevaProduct(values);
  };

  const onCreateDevaProduct = (values: AddDevaProducto) => {
    customDispatch({
      asyncThuckFuction: createDevaProducto,
      endPoint: '/api/Devas/AddDevaProducto',
      body: { ...values },
      method: 'POST',
    });
  };

  const onSearchProducto = useDebounce((value: string) => {
    if (value.length) dispatchProductos(value);
  });

  const onChangeProducto = (id: string) => {
    setItemsRelatedProductsDisabled(false);
    const selectedProduct = Productos.find(_ => _.id === id);
    if (selectedProduct) {
      dispatchPartidasArancelarias(selectedProduct.partidaArancelaria);

      form.setFieldsValue({
        unidadMedidaId: selectedProduct.unidadMedidaId,
        partidaArancelariaId: selectedProduct.partidaArancelariaId,
        designacion: selectedProduct.designacionProducto,
        caracteristicas: selectedProduct.caracteristicas,
        marcaComercialId: selectedProduct.marcaComercialId,
        estadoMercanciaId: selectedProduct.estadoMercanciaId,
        paisOrigenId: selectedProduct.paisOrigenId,
      });
    }
  };

  const dispatchProductos = (filterValue: string) => {
    if (devaDetalles?.proveedor?.id) {
      customDispatch({
        asyncThuckFuction: queryProductos,
        endPoint: '/api/Productos/Query',
        body: {
          simpleTextFilter: filterValue,
          pageNumber: 1,
          pageSize: 50,
          filterGroups: [
            {
              columnName: 'proveedorId',
              filters: [
                {
                  filterValue: devaDetalles.proveedor.id,
                },
              ],
            },
          ],
        } as QuerySpec,
      });
    }
  };

  const onSearchPartidaArancelaria = useDebounce((value: string) => {
    if (value.length) dispatchPartidasArancelarias(value);
  });

  const dispatchPartidasArancelarias = (filterValue: string) => {
    customDispatch({
      asyncThuckFuction: queryPartidasArancelarias,
      endPoint: '/api/PartidasArancelarias/Query',
      body: {
        simpleTextFilter: filterValue,
        pageNumber: 1,
        pageSize: 50,
      } as QuerySpec,
    });
  };

  const layout = { span: 24 };

  return (
    <Drawer
      title='Agregar Producto a la Deva'
      width={520}
      closable={false}
      onClose={onClose}
      visible={devaProductoDrawerIsVisible}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={onFinish} type='primary'>
            Agregar
          </Button>
        </div>
      }
    >
      <Form form={form} name='AgregarProductoDeva' layout='vertical'>
        <CustomInput layout={layout} name='numeroFactura' label='Número de Factura' required />
        <CustomDatepicker layout={layout} name='fechaFactura' label='Fecha de Factura' required />
        <CustomInputNumber layout={layout} name='cantidad' label='Cantidad Comercial' required />
        <CustomInputNumber
          layout={layout}
          name='valorUnitario'
          label='Valor Unitario USD'
          required
        />
        <CustomInputNumber layout={layout} name='valorTotal' label='Valor Total' required />
        <DropDownGrid
          layout={layout}
          data={Productos}
          index='sku'
          name='productoId'
          label='Producto'
          loading={ProductosStatus === 'pending'}
          onSearch={onSearchProducto}
          onChange={value => onChangeProducto(value?.toString() ?? '')}
          required
        />
        <DropDownGrid
          layout={layout}
          data={UnidadesMedidas}
          name='unidadMedidaId'
          label='Unidad de Medida'
          loading={UnidadesMedidasStatus === 'pending'}
          disabled={itemsRelatedProductsDisabled}
          required
        />
        <DropDownGrid
          layout={layout}
          data={PartidasArancelarias}
          name='partidaArancelariaId'
          label='Partida Arancelaria'
          loading={PartidasArancelariasStatus === 'pending'}
          onSearch={onSearchPartidaArancelaria}
          disabled={itemsRelatedProductsDisabled}
          required
        />
        <DropDownGrid
          layout={layout}
          data={DesignacionesProductos}
          name='designacion'
          label='Designación del Producto'
          loading={DesignacionesProductosStatus === 'pending'}
          disabled={itemsRelatedProductsDisabled}
          required
        />
        <CustomInput
          layout={layout}
          name='caracteristicas'
          label='Caracteristicas'
          required
          disabled={itemsRelatedProductsDisabled}
        />
        <DropDownGrid
          layout={layout}
          data={MarcasComerciales}
          name='marcaComercialId'
          label='Marca Comercial'
          loading={MarcasComercialesStatus === 'pending'}
          disabled={itemsRelatedProductsDisabled}
          required
        />
        <DropDownGrid
          layout={layout}
          data={EstadosMercancias}
          name='estadoMercanciaId'
          label='Estado de la Mercancia'
          loading={EstadosMercanciasStatus === 'pending'}
          disabled={itemsRelatedProductsDisabled}
          required
        />
        <DropDownGrid
          layout={layout}
          data={Paises}
          name='paisOrigenId'
          label='Pais de Origen'
          loading={PaisesStatus === 'pending'}
          disabled={itemsRelatedProductsDisabled}
          required
        />
      </Form>
    </Drawer>
  );
};
