import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { FC, memo } from 'react';

interface ISearchFilter {
  setFilter: (value: string) => void;
  placeholder?: string;
}

const SearchFilter: FC<ISearchFilter> = ({ setFilter, placeholder }) => {
  const onChange = (event: any) => {
    setFilter(event.target.value);
  };

  return (
    <Input
      onChange={onChange}
      placeholder={placeholder ?? 'Buscar por nombre'}
      allowClear
      height={328}
      width={50}
      size='middle'
      suffix={<SearchOutlined />}
      autoFocus
    />
  );
};

export default memo(SearchFilter);
