import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IInitialState } from '../store';

export const getOrdenesTrabajo = createAsyncThunk('OrdenesTrabajo/GetAll', httpService);

export const getOrdenesTrabajoReducer = (builder: ActionReducerMapBuilder<IInitialState>) => {
  // GetAll Ordenes de Trabajo
  builder.addCase(getOrdenesTrabajo.fulfilled, (state, action) => {
    state.data = action.payload;
    state.status = 'idle';
  });
  builder.addCase(getOrdenesTrabajo.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getOrdenesTrabajo.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
