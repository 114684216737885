import { InboxOutlined } from '@ant-design/icons';
import { Col, Form, Modal as ModalAnt, Row, Typography } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadFile } from 'antd/lib/upload/interface';
import { setError } from 'features/InitialData/store/initialDataStore';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { PapierError } from 'models/PapierException';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { dummyRequest } from 'utils/dummyRequest';
import excelModelo from '../../../assets/Modelo.xlsx';
import { validarProductos } from '../store/extraReducer/validarProductos';
import {
  reset,
  setDevaInUpdate,
  setDrawerValidarProductosVisible,
  setValidationDevaMessage,
} from '../store/store';
import { importarProductosDeExcel } from '../utils/importarProdutosDeExcel';

const { Item } = Form;
const { Link, Text } = Typography;

interface IModalAgregarProductosProps {
  onClose: () => void;
}

export const ModalAgregarProductos = ({ onClose }: IModalAgregarProductosProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const [archivoExcel, setArchivoExcel] = useState<UploadFile<any>[] | undefined>(undefined);
  const { devaInUpdate, proveedor } = useAppSelector(state => state.devas);
  const UnidadesMedidasNetas = useAppSelector(state => state.unidadesMedidasNetas.data);

  const onShow = () => {
    setVisible(true);
  };

  const onOk = () => {
    form
      .validateFields()
      .then(async values => {
        if (values.productsList.fileList.length) {
          try {
            await importarProductosDeExcel(
              values.productsList.fileList[0].originFileObj,
              UnidadesMedidasNetas,
            );

            dispatch(
              setDevaInUpdate({
                devaId: devaInUpdate?.devaId ?? '',
                proveedor: devaInUpdate?.proveedor ?? '',
                productos: [],
              }),
            );
            onValidarProductos(proveedor.id, values.productsList.fileList[0].originFileObj);
            dispatch(
              setDrawerValidarProductosVisible({
                drawerValidarProductosVisible: true,
                createDevaWithModelFile: true,
              }),
            );
            form.resetFields();
            setVisible(false);
            setArchivoExcel(undefined);
          } catch (error) {
            if (error instanceof PapierError) {
              dispatch(
                setError({
                  error: true,
                  message: error.errorMessage,
                  duration: 5,
                }),
              );
            } else {
              console.error(error);
            }
          }
        }
      })
      .catch(error => console.log(error));
  };

  const onValidarProductos = (proveedorId: string, productos: any) => {
    dispatch(setValidationDevaMessage('Validando productos'));
    dispatch(
      setDrawerValidarProductosVisible({
        drawerValidarProductosVisible: true,
        createDevaWithModelFile: true,
      }),
    );
    const formData = new FormData();
    formData.append('productsList', productos);

    customDispatch({
      asyncThuckFuction: validarProductos,
      endPoint: `/api/Devas/ValidarProductos/${proveedorId}`,
      body: formData,
    });
  };

  const onCancel = () => {
    onClose();
    form.resetFields();
    setVisible(false);
    setArchivoExcel(undefined);
    dispatch(reset());
  };

  const Modal = (
    <ModalAnt title='Agregar Productos a la Deva' visible={visible} onOk={onOk} onCancel={onCancel}>
      <Text> Proveedor : </Text> <Text strong>{devaInUpdate?.proveedor}</Text>
      <Form form={form} name='agregarProductosForm' layout='vertical' style={{ marginTop: 16 }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Item
              name='productsList'
              rules={[
                {
                  required: true,
                  validator: (_rule: any, value: any) => {
                    if (value === undefined) {
                      return Promise.reject(`Por favor, selecciona un archivo`);
                    }

                    if (value.fileList.length === 0) {
                      return Promise.reject(`Por favor, selecciona un archivo`);
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Dragger
                multiple={false}
                fileList={archivoExcel}
                customRequest={f => dummyRequest(f)}
                accept='.xlsx, .xls'
                onChange={e => setArchivoExcel(e.fileList.length > 0 ? [e.file] : undefined)}
              >
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  Haga clic o arrastre el archivo a esta área para cargarlo
                </p>
              </Dragger>
            </Item>
            <Link href={excelModelo} download='Modelo.xlsx'>
              Descargar archivo de modelo para importar los datos
            </Link>
          </Col>
        </Row>
      </Form>
    </ModalAnt>
  );

  return { Modal, onShow };
};
