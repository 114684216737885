import { Button, Form, Modal, Typography } from 'antd';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { addDocumentoToItem } from 'features/DucaDetalles/store/store';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { IDucaDocumentoRequest } from 'models/Duca';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { FormDocumento } from './FormDocumento';
import { DataTypeDucaDocumento, TableAsignarDocumentos } from './TablaAsignarDocumentos';

const { Text } = Typography;

interface IModalAsignarDocumentos {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export const ModalAsignarDocumentos = ({ visible, setVisible }: IModalAsignarDocumentos) => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [data, setData] = useState<DataTypeDucaDocumento[]>([]);
  const { TiposDocumentos, items } = useAppSelector(state => state.ducaDetalles);
  const [form] = Form.useForm<IDucaDocumentoRequest>();

  useEffect(() => {
    const tempData = items
      .filter(item => item.estado !== EstadoDucaItem.Delete)
      .map(item => ({
        key: item.key,
        numeroItem: item.numeroItem,
        posicionArancelaria: item.posicionArancelaria,
        descripcion: item.descripcion,
        paisOrigen: item.paisOrigen,
        proveedor: item.proveedor,
        existencia: false,
      }));
    setData(tempData);
  }, [items]);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRow([]);
  };

  const onFinish = (values: IDucaDocumentoRequest) => {
    const documento = TiposDocumentos.find(x => x.id === values.documentoId);
    values.codigo = documento?.codigo ?? '';
    values.descripcion = documento?.descripcion ?? '';
    values.presencia = Boolean(values.presencia);
    values.key = GenerateKey();

    dispatch(addDocumentoToItem({ itemsKey: selectedRow, documento: values }));

    setSelectedRow([]);
    setData(data.map(x => ({ ...x, existencia: false })));
    form.resetFields();
  };

  const onChangeDocumento = (value: string, _option: any) => {
    setData(data.map(x => ({ ...x, existencia: onExisteAsignacion(x.key, value) })));
  };

  const onExisteAsignacion = (key: React.Key, documentoId: string): boolean => {
    const item = items.find(x => x.key === key);
    return item?.documentos?.some(x => x.documentoId === documentoId) ?? false;
  };

  return (
    <Modal
      title='Asignar Documentos'
      visible={visible}
      onCancel={onClose}
      width={900}
      footer={<Button onClick={onClose}>Cerrar</Button>}
    >
      <FormDocumento
        form={form}
        onFinish={onFinish}
        onChange={onChangeDocumento}
        disabled={selectedRow.length === 0}
      />
      <TableAsignarDocumentos
        data={data}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
      <Text>Los productos con un asterisco (*) ya tiene el documento que se trata de asignar</Text>
    </Modal>
  );
};
