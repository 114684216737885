import { Row } from 'antd';
import { CustomDatepicker } from 'components/FormControls/CustomDatePicker';
import { CustomInput } from 'components/FormControls/CustomInput';
import { GreenTitle } from 'components/FormControls/GreenTitle';
import { EstadoOrdenTrabajo } from 'enum/EstadoOrdenTrabajo';
import { useAppSelector } from 'store/hooks';

export const InformacionGeneralAduana = () => {
  const disabled = useAppSelector(
    state => state.devaDetalles.devaDetalles?.ordenDeTrabajo?.estado === EstadoOrdenTrabajo.Cerrada,
  );
  return (
    <section>
      <GreenTitle text='A. Información General de la Aduana' />
      <Row gutter={[24, 24]}>
        <CustomInput required={false} disabled label='1. Aduana de Ingreso' name='aduanaIngreso' />
        <CustomInput
          required={false}
          disabled
          label='2. Aduana de Despacho'
          name='aduanaDespacho'
        />
        <CustomInput
          required={false}
          disabled
          label='3. Declaración de Mercancías'
          name='declaracionMercancia'
        />
        <CustomDatepicker
          disabled={disabled}
          label='3.1 Fecha de Aceptación'
          name='fechaAceptacion'
          required={false}
        />
      </Row>
    </section>
  );
};
