import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const queryTipoDatoComplementario = createAsyncThunk(
  'DucaDetalles/TipoDatoComplementario/Query',
  httpService,
);

export const queryTipoDatoComplementarioReducer = (
  builder: ActionReducerMapBuilder<IDucaDetallesState>,
) => {
  builder.addCase(
    queryTipoDatoComplementario.fulfilled,
    (state, action: PayloadAction<PagedList<ConfiguracionesPetch>>) => {
      state.TipoDatosComplementarios = action.payload.items;
      state.TipoDatoComplementarioStatus = 'succeeded';
    },
  );

  builder.addCase(queryTipoDatoComplementario.pending, state => {
    state.TipoDatoComplementarioStatus = 'pending';
  });

  builder.addCase(queryTipoDatoComplementario.rejected, state => {
    state.TipoDatoComplementarioStatus = 'failed';
  });
};
