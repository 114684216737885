import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IPartidasArancelariasState } from '../partidaArancelariaStore';

export const deletePartidaArancelaria = createAsyncThunk(
  'PartidasArancelarias/Delete',
  httpService,
);

export const deletePartidaArancelariaReducer = (
  builder: ActionReducerMapBuilder<IPartidasArancelariasState>,
) => {
  // Delete
  builder.addCase(deletePartidaArancelaria.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.pagedData.items.findIndex(
      partidaArancelaria => partidaArancelaria.id === state.elementoSeleccionado?.id,
    );
    state.pagedData.items.splice(index, 1);
  });
  builder.addCase(deletePartidaArancelaria.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deletePartidaArancelaria.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
