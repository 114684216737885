import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IConversionUnidadDeMedidaState } from '../conversionesUnidadesDeMedidasStore';

export const getConversionesUnidadDeMedidas = createAsyncThunk(
  'ConversionUnidadesDeMedidas/GetAll',
  httpService,
);

export const getConversionesUnidadedDeMedidasReducer = (
  builder: ActionReducerMapBuilder<IConversionUnidadDeMedidaState>,
) => {
  builder.addCase(getConversionesUnidadDeMedidas.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.data = action.payload;
  });
  builder.addCase(getConversionesUnidadDeMedidas.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getConversionesUnidadDeMedidas.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
  });
};
