import { Form, Input, Spin, FormInstance, Select, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import { ConfiguracionesPetch } from '../../../models/configuracionesPetch';
import { Pais } from '../../../models/Pais';
import { Proveedor } from '../../../models/Proveedor';

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;

interface IProps {
  validarProveedor: boolean;
  visible: boolean;
  form: FormInstance;
  status: StatusApp.Status;
  elementoSeleccionado: Proveedor | undefined;
  paises: Array<Pais>;
  condicionesComerciales: Array<ConfiguracionesPetch>;
  onCreate: (values: Proveedor) => void;
  onUpdate: (values: Proveedor) => void;
  setCerrarDrawer: () => void;
}

export const ModalUpsertProveedor: FC<IProps> = ({
  validarProveedor,
  visible,
  form,
  status,
  elementoSeleccionado,
  paises,
  condicionesComerciales,
  onCreate,
  onUpdate,
  setCerrarDrawer,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const isUpdated = elementoSeleccionado !== undefined;

  useEffect(() => {
    setLoading(status === 'pending');
  }, [status]);

  const onValidateForm = () => {
    if (isUpdated) {
      form
        .validateFields()
        .then(values => onUpdate(values as Proveedor))
        .catch(error => console.log(error));
    } else {
      form
        .validateFields()
        .then(values => onCreate(values as Proveedor))
        .catch(error => console.log(error));
    }
  };

  const onClose = () => {
    if (!isLoading) {
      setCerrarDrawer();
    }
  };

  return (
    <Modal
      title={isUpdated ? 'Editar Proveedor' : 'Crear Proveedor'}
      onCancel={onClose}
      onOk={onValidateForm}
      visible={visible}
      maskClosable={false}
      closable={!isLoading}
      okButtonProps={{ disabled: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          name='Proveedores'
          layout='vertical'
          defaultValue={elementoSeleccionado as any}
          scrollToFirstError={{ behavior: 'smooth' }}
        >
          <Item
            name='nombre'
            label='Nombre'
            rules={[
              {
                required: true,
                message: 'Por favor ingresa un nombre para el proveedor.',
              },
            ]}
          >
            <Input disabled={validarProveedor} autoFocus={!validarProveedor} />
          </Item>
          <Item name='paisId' label='País' rules={[{ required: true, message: 'Campo requerido' }]}>
            <Select showSearch optionFilterProp='children'>
              {paises.map(pais => (
                <Option key={pais.id} value={pais.id}>
                  {pais.nombre}
                </Option>
              ))}
            </Select>
          </Item>
          <Item name='condicionComercialId' label='Condición Comercial'>
            <Select showSearch optionFilterProp='children'>
              {condicionesComerciales.map(condicionComercial => (
                <Option key={condicionComercial.id} value={condicionComercial.id}>
                  {condicionComercial.descripcion}
                </Option>
              ))}
            </Select>
          </Item>
          <Item name='otraCondicionComercial' label='Otra Condición Comercial'>
            <TextArea />
          </Item>
        </Form>
      </Spin>
    </Modal>
  );
};
