import { Col, FormItemProps, Select, Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { CSSProperties } from 'react';

const { Item } = Form;

interface IDropdown extends FormItemProps {
  label?: string;
  name: string;
  data: any[];
  loading?: boolean;
  onChange?: (value: SelectValue, option: any, name?: string) => void;
  layout?: { lg?: number; md?: number; xs?: number; span?: number };
  propSelectName?: string;
  placeholder?: string;
  render?: (item: any) => JSX.Element;
  onSearch?: (value: string) => void;
}

const { Option } = Select;
export const DropDownGrid = ({
  name,
  label,
  data,
  loading,
  layout,
  required,
  propSelectName,
  placeholder,
  onSearch,
  allowClear,
  ...rest
}: IDropdown) => {
  return (
    <Col span={6} {...layout}>
      <DropDown
        placeholder={placeholder}
        data={data}
        name={name}
        required={required}
        index={propSelectName}
        label={label}
        loading={loading}
        onSearch={onSearch}
        allowClear={allowClear}
        {...rest}
      />
    </Col>
  );
};

interface IDropdown extends FormItemProps {
  label?: string;
  name: string;
  data: any[];
  loading?: boolean;
  onChange?: (value: SelectValue, option: any, name?: string) => void;
  layout?: { lg?: number; md?: number; xs?: number; span?: number };
  style?: CSSProperties;
  index?: string;
  placeholder?: string;
  renderText?: (item: any) => string;
  disabled?: boolean;
  valueToSelect?: (item: any) => string;
  onSearch?: (value: string) => void;
  allowClear?: boolean;
}

export const DropDown = ({
  label,
  name,
  loading,
  data,
  onChange: change,
  style,
  required,
  index = 'nombre',
  renderText,
  disabled,
  placeholder,
  valueToSelect,
  onSearch,
  allowClear,
  ...rest
}: IDropdown) => {
  return (
    <Item style={style} label={label} name={name} rules={[{ required }]} {...rest}>
      <Select
        showSearch
        disabled={disabled}
        optionFilterProp='children'
        onChange={(v, o) => change && change(v, o, name)}
        loading={loading}
        placeholder={placeholder}
        onSearch={onSearch}
        allowClear={allowClear}
      >
        {data.map(item => (
          <Option key={item.id} value={valueToSelect ? valueToSelect(item) : item.id}>
            {renderText ? renderText(item) : item[index]}
          </Option>
        ))}
      </Select>
    </Item>
  );
};
