import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { DireccionProveedor } from 'models/Proveedor';
import httpService from 'services/httpService';
import { IInicioState } from '../../inicioStore';

export const createDireccionProveedorPendiente = createAsyncThunk(
  'Inicio/CreateDireccionProveedorPendiente',
  httpService,
);

export const createDireccionProveedorPendienteReducer = (
  builder: ActionReducerMapBuilder<IInicioState>,
) => {
  builder.addCase(
    createDireccionProveedorPendiente.fulfilled,
    (state, action: PayloadAction<string>) => {
      const direccionId = action.payload;
      const direccion = state.direccionTemporal as DireccionProveedor;

      // Eliminar direccion con el key 'new'
      const direccionKeyNewIndex = state.direcciones.findIndex(_ => _.key === 'new');
      if (direccionKeyNewIndex !== -1) {
        state.direcciones.splice(direccionKeyNewIndex, 1);
      }

      // Agregar la dirección al proveedore y el las direcciones para mostrarla en la tabla
      const proveedorIndex = state.proveedoresPendientes.findIndex(
        _ => _.id === direccion.proveedorId,
      );
      if (proveedorIndex !== -1) {
        state.proveedoresPendientes[proveedorIndex].direcciones.push({
          ...direccion,
          id: direccionId,
        });
        state.direcciones.push({ ...direccion, id: direccionId, key: GenerateKey() });
      }

      state.direccionTemporal = undefined;
      state.crudDireccionesStatus = 'succeeded';
    },
  );
  builder.addCase(createDireccionProveedorPendiente.pending, (state, _action) => {
    state.crudDireccionesStatus = 'pending';
  });
  builder.addCase(createDireccionProveedorPendiente.rejected, (state, _action) => {
    state.crudDireccionesStatus = 'failed';
  });
};
