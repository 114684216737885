import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Proveedor } from '../../../../models/Proveedor';
import { IProveedoresState } from '../proveedoresStore';
import httpService from '../../../../services/httpService';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';

export const getProveedores = createAsyncThunk('Proveedors/GetAll', httpService);

export const getProveedoresReducer = (builder: ActionReducerMapBuilder<IProveedoresState>) => {
  builder.addCase(getProveedores.fulfilled, (state, action: PayloadAction<Array<Proveedor>>) => {
    state.data = action.payload.map(item => {
      item.direcciones = item.direcciones.map(direccion => ({ ...direccion, key: GenerateKey() }));
      return item;
    });
    state.status = 'succeeded';
  });

  builder.addCase(getProveedores.pending, (state, _action) => {
    state.status = 'pending';
  });

  builder.addCase(getProveedores.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
