export const useDebounce = (cb: (value: string) => void, time = 300) => {
  let id: number | undefined | any;

  return (value: string) => {
    if (id) {
      clearTimeout(id);
    }
    id = setTimeout(() => cb(value), time);
  };
};

export const debounce = (
  cb: (args: any) => void,
  time: number = 500,
  immediate: boolean = false,
) => {
  let id: number | undefined | any;

  return (args: any) => {
    const later = () => {
      id = undefined;
      if (!immediate) cb(args);
    };

    const callNow = immediate && !id;

    clearTimeout(id);
    id = setTimeout(later, time);

    if (callNow) cb(args);
  };
};
