export interface PapierErrorResponse {
  errorMessage: string;
  friendlyMessage: string;
  requestId: string;
}

export class PapierError extends Error {
  public errorMessage: string;
  public friendlyMessage: string;
  public requestId: string;

  constructor(errorMessage: string, friendlyMessage: string = '', requestId: string = '') {
    super(errorMessage);
    this.errorMessage = errorMessage;
    this.friendlyMessage = friendlyMessage;
    this.requestId = requestId;
  }
}
