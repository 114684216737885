import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/InitialData/store/initialDataStore';
import { addProducto } from 'features/Productos/store/productosStore';
import { Producto } from 'models/Producto';
import httpService, { IUseFetch, ThunckApi } from '../../../../../services/httpService';
import { IInicioState } from '../../inicioStore';

export const updateProductoPendiente = createAsyncThunk(
  'Inicio/UpdateProductoPendiente',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      thunkApi.dispatch(addProducto(fetch.body as Producto));

      if (document.fullscreenElement === null) {
        thunkApi.dispatch(setError({ message: 'Producto aprobado', error: false }));
      }
    }
    return res;
  },
);

export const updateProductoPendienteReducer = (builder: ActionReducerMapBuilder<IInicioState>) => {
  builder.addCase(updateProductoPendiente.fulfilled, (state, _action) => {
    const producto = state.productoPendienteSeleccionado;
    const index = state.productosPendientes.findIndex(p => p.id === producto?.id);
    state.productosPendientes.splice(index, 1);
    state.productoPendienteSeleccionado = undefined;
    state.productosPendientesStatus = 'succeeded';
  });
  builder.addCase(updateProductoPendiente.pending, (state, _action) => {
    state.productosPendientesStatus = 'pending';
  });
  builder.addCase(updateProductoPendiente.rejected, (state, _action) => {
    state.productosPendientesStatus = 'failed';
  });
};
