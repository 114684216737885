import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DucaDetalles, IDucaItemRequest } from 'models/Duca';
import httpService from '../../../../services/httpService';
import { IArchivoPreliquidacionState } from '../store';

export const getItemsDuca = createAsyncThunk('DucaDetalles/GetDucaItems', httpService);

export const getItemsDucaReducer = (
  builder: ActionReducerMapBuilder<IArchivoPreliquidacionState>,
) => {
  builder.addCase(getItemsDuca.fulfilled, (state, action: PayloadAction<IDucaItemRequest[]>) => {
    if (state.data) {
      state.data = {
        ...state.data,
        items: action.payload,
      };
    } else {
      state.data = { items: action.payload } as DucaDetalles;
    }

    state.statusItems = 'succeeded';
  });
  builder.addCase(getItemsDuca.pending, (state, _action) => {
    state.statusItems = 'pending';
  });
  builder.addCase(getItemsDuca.rejected, (state, _action) => {
    state.statusItems = 'failed';
  });
};
