import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IClasificacionesFenagState } from '../clasificacionesFenagStore';

export const deleteClasificacionFenag = createAsyncThunk(
  'ClasificacionesFenag/Delete',
  httpService,
);

export const deleteClasificacionFenagReducer = (
  builder: ActionReducerMapBuilder<IClasificacionesFenagState>,
) => {
  builder.addCase(deleteClasificacionFenag.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.data.findIndex(
      clasificacionFenag => clasificacionFenag.id === state.elementoSeleccionado?.id,
    );
    state.data.splice(index, 1);
  });
  builder.addCase(deleteClasificacionFenag.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deleteClasificacionFenag.rejected, (state, action) => {
    state.crudStatus = 'failed';
    state.error = action.error.message;
  });
};
