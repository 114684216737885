import { useDispatch } from 'react-redux';
import { IUseFetch } from '../services/httpService';
import { useToken } from './useToken';

export interface UseCustomDispatch {
  asyncThuckFuction: (params: IUseFetch) => void;
  endPoint: string;
  body?: {};
  method?: IUseFetch['method'];
  onFinish?: () => void;
  extra?: {};
}

export const useCustomDispatch = () => {
  const token = useToken();
  const dispath = useDispatch();

  return ({ asyncThuckFuction, endPoint, body, method, onFinish, extra }: UseCustomDispatch) => {
    dispath(asyncThuckFuction({ endPoint, token, body, method, onFinish, extra }));
  };
};
