import { CSSProperties, FC } from 'react';
import './printLiquidacion.css';

interface IProps {
  children?: any;
  style?: CSSProperties;
  noBorder?: boolean;
  className?: string;
  width?: string | number;
}

export const DuaRow: FC<IProps> = ({ children, style, noBorder, className, width }: IProps) => (
  <div
    className={`row ${className ?? ''}`}
    style={{
      border: noBorder ? 'none' : style?.border,
      width: width ?? style?.width,
      ...style,
    }}
  >
    {children}
  </div>
);

export const DuaCol: FC<IProps> = ({ children, style, noBorder, className, width }: IProps) => (
  <div
    className={`col ${className ?? ''}`}
    style={{
      border: noBorder ? 'none' : style?.border,
      width: width ?? style?.width,
      ...style,
    }}
  >
    {children}
  </div>
);

export const DuaCell: FC<IProps> = ({ children, style, noBorder, className, width }: IProps) => (
  <div
    className={`cell ${className ?? ''}`}
    style={{
      border: noBorder ? 'none' : style?.border,
      width: width ?? style?.width,
      ...style,
    }}
  >
    {children}
  </div>
);

export const DuaData: FC<IProps> = ({ children, style, className }: IProps) => (
  <div className={`data ${className ?? ''}`} style={style}>
    {children}
  </div>
);
