import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Producto } from 'models/Producto';
import httpService from 'services/httpService';
import { IProductosState } from '../productosStore';

export const createProducto = createAsyncThunk('Productos/Create', httpService);

export const createProductoReducer = (builder: ActionReducerMapBuilder<IProductosState>) => {
  builder.addCase(
    createProducto.fulfilled,
    (
      state,
      action: PayloadAction<{
        productoId: string;
        designacionProductoId: string;
      }>,
    ) => {
      const newProducto = {
        ...state.elementoTemporal,
        id: action.payload.productoId,
        designacionProductoId: action.payload.designacionProductoId,
      } as Producto;
      state.ultimoProductoCreado = newProducto;
      state.pagedData.items.unshift(newProducto);
      state.pagedData.items.pop();
      state.crudStatus = 'succeeded';
    },
  );
  builder.addCase(createProducto.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createProducto.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
