import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const queryMotivoOperacionSuspensiva = createAsyncThunk(
  'DucaDetalles/MotivoOperacionSuspensiva/Query',
  httpService,
);

export const queryMotivoOperacionSuspensivaReducer = (
  builder: ActionReducerMapBuilder<IDucaDetallesState>,
) => {
  builder.addCase(
    queryMotivoOperacionSuspensiva.fulfilled,
    (state, action: PayloadAction<PagedList<ConfiguracionesPetch>>) => {
      state.MotivoOperacionSuspensiva = action.payload.items;
      state.MotivoOperacionSuspensivaStatus = 'succeeded';
    },
  );

  builder.addCase(queryMotivoOperacionSuspensiva.pending, state => {
    state.MotivoOperacionSuspensivaStatus = 'pending';
  });

  builder.addCase(queryMotivoOperacionSuspensiva.rejected, state => {
    state.MotivoOperacionSuspensivaStatus = 'failed';
  });
};
