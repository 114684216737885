export const normalizeFormNames = (prefix: string, objToPrefix?: object) => {
  if (!objToPrefix) return;
  const objectWithPrefixedKeys: { [key: string]: any } = {};

  Object.entries(objToPrefix).forEach(([key, value]) => {
    objectWithPrefixedKeys[`${prefix}${capitalizarPrimeraLetra(key)}`] = value;
  });

  return objectWithPrefixedKeys;
};

const capitalizarPrimeraLetra = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
