import { FormInstance } from 'antd';
import { ListadoDatosComplementarios } from 'components/Duca/TablasDrawerItem/ListadoDatosComplementarios/ListadoDatosComplementarios';
import { IDucaItemRequest } from 'models/Duca';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';

interface ITabPaneComplementarios {
  form: FormInstance<IDucaItemRequest>;
}

export const TabPaneComplementarios = ({ form }: ITabPaneComplementarios) => {
  const { items, itemEditingKey } = useAppSelector(state => state.ducaDetalles);

  const { TablaDatosComplementarios, datosComplementarios } = ListadoDatosComplementarios({
    data: items.find(item => item.key === itemEditingKey)?.datosComplementarios ?? [],
  });

  useEffect(() => {
    form.setFieldsValue({
      datosComplementarios,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosComplementarios]);

  return <TablaDatosComplementarios />;
};
