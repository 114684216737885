import { FormInstance } from 'antd';
import { CaracteristicasTransaccion } from './Secciones/CaracteristicasTransaccion';
import { InformacionGeneralAduana } from './Secciones/InformacionGeneralAduana';
import { InformacionGeneralImportador } from './Secciones/InformacionGeneralImportador';
import { InformacionGeneralIntermediario } from './Secciones/InformacionGeneralIntermediario';
import { InformacionGeneralProveedor } from './Secciones/InformacionGeneralProveedor';

interface IInformacionGeneral {
  form: FormInstance;
  onChangeIncoterm: (id: string) => void;
}

export const InformacionGeneral = ({ form, onChangeIncoterm }: IInformacionGeneral) => {
  return (
    <section>
      <InformacionGeneralAduana />
      <InformacionGeneralImportador />
      <InformacionGeneralProveedor form={form} />
      <InformacionGeneralIntermediario />
      <CaracteristicasTransaccion form={form} onChangeIncoterm={onChangeIncoterm} />
    </section>
  );
};
