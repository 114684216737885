import { FormInstance } from 'antd';
import { ListadoVentajas } from 'components/Duca/TablasDrawerItem/ListadoVentajas/ListadoVentajas';
import { IDucaItemRequest } from 'models/Duca';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';

interface ITabPaneVentajas {
  form: FormInstance<IDucaItemRequest>;
}

export const TabPaneVentajas = ({ form }: ITabPaneVentajas) => {
  const { items, itemEditingKey } = useAppSelector(state => state.ducaDetalles);
  const { TablaVentajas, ventajas } = ListadoVentajas({
    data: items.find(item => item.key === itemEditingKey)?.ventajas ?? [],
  });

  useEffect(() => {
    form.setFieldsValue({
      ventajas,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventajas]);

  return <TablaVentajas />;
};
