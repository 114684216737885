import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { UnidadMedidaNeta } from 'models/UnidadMedidaNeta';
import httpService from 'services/httpService';
import { IUnidadesMedidasNetasState } from '../unidadesMedidasNetasStore';

export const createUnidadMedidaNeta = createAsyncThunk('UnidadesMedidasNetas/Create', httpService);

export const createUnidadMedidaNetaReducer = (
  builder: ActionReducerMapBuilder<IUnidadesMedidasNetasState>,
) => {
  builder.addCase(createUnidadMedidaNeta.fulfilled, (state, action) => {
    state.crudStatus = 'succeeded';
    state.data.unshift({
      ...state.elementoTemporal,
      id: action.payload,
    } as UnidadMedidaNeta);
  });
  builder.addCase(createUnidadMedidaNeta.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createUnidadMedidaNeta.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
