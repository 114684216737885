import { Table, Typography } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { IDucaVentajaRequest } from 'models/Duca';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { ColumnasTablaAsignar, DataType } from '../TabPaneItems';
import { TablaVentajas } from './TablaVentajas';

const { Text } = Typography;

interface ITablaAsignarVentajas {
  data: DataType[];
  selectedRow: string[];
  setSelectedRow: (value: string[]) => void;
}

export const TablaAsignarVentajas = ({
  data,
  selectedRow,
  setSelectedRow,
}: ITablaAsignarVentajas) => {
  const [ventajas, setVentaja] = useState<IDucaVentajaRequest[]>([]);
  const [keyExpanded, setKeyExpanded] = useState<React.Key[]>([]);
  const items = useAppSelector(state => state.ducaDetalles.items);

  useEffect(() => {
    if (selectedRow.length === 0) {
      setVentaja([]);
      setKeyExpanded([]);
      return;
    }
  }, [selectedRow]);

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys: any, _selectedRows: DataType[]) => {
      setSelectedRow(selectedRowKeys);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.existencia,
    }),
  } as TableRowSelection<DataType>;

  return (
    <Table
      columns={[
        ...ColumnasTablaAsignar,
        { title: 'Tasa SAC', dataIndex: 'dai', width: 100, render: (value: number) => `${value}%` },
        {
          title: 'Tasa Tratado',
          dataIndex: 'tarifaTLC',
          width: 100,
          render: (_value, record) => {
            const rowSelected = selectedRow.find(key => key === record.key);
            if (rowSelected) {
              return (
                <Text type='warning' strong>
                  {record.tarifaTLC}%
                </Text>
              );
            }
            return record.tarifaTLCAplicada ? `${record.tarifaTLCAplicada}%` : '-';
          },
        },
      ]}
      dataSource={data}
      rowSelection={rowSelection}
      expandable={
        items.some(item => item.ventajas.length > 0)
          ? {
              expandedRowKeys: keyExpanded,
              expandedRowRender: () => <TablaVentajas data={ventajas} />,
              onExpand: (expanded, record) => {
                if (expanded) {
                  const result =
                    items
                      .find(x => x.key === record.key)
                      ?.ventajas?.filter(x => x.estado !== EstadoDucaItem.Delete) ?? [];
                  setVentaja(result);
                  setKeyExpanded([record.key]);
                } else {
                  setKeyExpanded([]);
                }
              },
              rowExpandable: record =>
                Number(items.find(x => x.key === record.key)?.ventajas?.length) > 0,
            }
          : undefined
      }
      pagination={false}
      size='small'
      bordered
      scroll={{ y: 300 }}
    />
  );
};
