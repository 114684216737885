import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';

export interface ViewName {
  view: string;
  collapsedMenu: boolean;
}

const initialState: ViewName = {
  view: 'Inicio',
  collapsedMenu: true,
};

export const currentView = createSlice({
  name: 'view',
  initialState,
  reducers: {
    setTitleName: (state, action) => {
      document.title = 'RYM Logistic - ' + action.payload;
      state.view = action.payload;
    },
    setCollapsedMenu: (state, action) => {
      state.collapsedMenu = action.payload;
    },
  },
});

export const selectCurrentView = (state: RootState) => state.sidebar.view;
export const selectCurrentCollapsedMenu = (state: RootState) => state.sidebar.collapsedMenu;

export const { setTitleName, setCollapsedMenu } = currentView.actions;

export default currentView.reducer;
