import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { setError } from 'features/InitialData/store/initialDataStore';
import { Deva } from 'models/Deva';
import httpService, { IUseFetch, ThunckApi } from 'services/httpService';
import { IDevasState } from '../store';
import { setValoresTotalesOrdenDeTrabajo } from 'features/OrdenDeTrabajo/store/store';

export const createDeva = createAsyncThunk(
  'Devas/CreateDeva',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      thunkApi.dispatch(
        setValoresTotalesOrdenDeTrabajo({
          TotalFOB: res.totalFOB,
          TotalFlete: res.totalFlete,
          TotalSeguro: res.totalSeguro,
          TotalOtrosGastos: res.totalOtrosGastos,
          TotalCIF: res.totalCIF,
        }),
      );
      thunkApi.dispatch(setError({ message: 'Se creó correctamente', error: false }));
    }
    return res;
  },
);

interface IResponse {
  totalFlete: number;
  totalSeguro: number;
  totalOtrosGastos: number;
  totalCIF: number;
  devas: Deva[];
}

export const createDevaReducer = (builder: ActionReducerMapBuilder<IDevasState>) => {
  builder.addCase(createDeva.fulfilled, (state, action: PayloadAction<IResponse>) => {
    if (state.ordenDeTrabajoInCreation) {
      state.devasOfOrdenDeTrabajo = action.payload.devas.map(x => ({ ...x, key: GenerateKey() }));
    } else {
      state.devasDetalles = action.payload.devas.map(x => ({ ...x, key: GenerateKey() }));
    }
    state.devaInCreation = undefined;
    state.createDevaStatus = 'succeeded';
  });
  builder.addCase(createDeva.pending, (state, _action) => {
    state.createDevaStatus = 'pending';
    state.productosPendientesCreateStatus = 'idle';
  });
  builder.addCase(createDeva.rejected, (state, _action) => {
    state.createDevaStatus = 'failed';
  });
};
