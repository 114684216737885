import { Col, Form, FormItemProps, Input } from 'antd';
import { Rule } from 'antd/lib/form';

const { Item } = Form;
const { TextArea } = Input;

interface ICustomTextareaInput extends FormItemProps {
  layout?: { lg?: number; md?: number; xs?: number; span?: number };
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: string) => void;
  initialValue?: string | number | string[] | undefined;
  rules?: Rule[] | undefined;
}

export const CustomTextarea = ({
  label,
  name,
  disabled,
  layout,
  required = false,
  onChange,
  initialValue,
  rules,
  ...rest
}: ICustomTextareaInput) => {
  // const disabledByStatus = useAppSelector();
  return (
    <Col span={6} {...layout}>
      <Item
        label={label}
        name={name}
        initialValue={initialValue}
        rules={
          required
            ? [
                {
                  required,
                  message: `Ingrese un valor para ${label}`,
                  validator: (_rule, value) => {
                    if (value < 0) {
                      return Promise.reject(`${label} no puede ser menor a 0`);
                    }
                    if (String(value).length === 0 || value === '' || !value) {
                      return Promise.reject(`${label} no puede estar vacío`);
                    }
                    return Promise.resolve();
                  },
                },
              ]
            : rules
        }
        {...rest}
        required={required}
      >
        <TextArea disabled={disabled} onChange={e => onChange?.(e.target.value)} />
      </Item>
    </Col>
  );
};
