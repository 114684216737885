import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IDevaDetallesState } from '../store';
import { DevaDetails } from '../../../../models/Deva';

export const createDevaProducto = createAsyncThunk('DevaDetalles/CreateDevaProducto', httpService);

export const createDevaProductoReducer = (builder: ActionReducerMapBuilder<IDevaDetallesState>) => {
  builder.addCase(createDevaProducto.fulfilled, (state, action: PayloadAction<DevaDetails>) => {
    const devaDetalles = state.devaDetalles;
    const devaDetallesResponse = action.payload;

    if (devaDetalles) {
      devaDetalles.importeFactura = devaDetallesResponse.importeFactura;
      devaDetalles.importeRealPagado = devaDetallesResponse.importeRealPagado;
      devaDetalles.valorAduana = devaDetallesResponse.valorAduana;

      const devaProductoCreado = devaDetallesResponse.productos![0];
      if (devaDetalles.productos) {
        devaDetalles.productos.push(devaProductoCreado);
      } else {
        devaDetalles.productos = [devaProductoCreado];
      }
    }

    state.devaProductoDrawerIsVisible = false;
    state.devaProductoCrud = 'succeeded';
  });
  builder.addCase(createDevaProducto.pending, (state, _action) => {
    state.devaProductoCrud = 'pending';
  });
  builder.addCase(createDevaProducto.rejected, (state, _action) => {
    state.devaProductoCrud = 'failed';
  });
};
