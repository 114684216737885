import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarcaComercial } from '../../../models/MarcaComercial';
import { RootState } from '../../../store/store';
import { createMarcaComercialReducer } from './extraReducers/createMarcaComercial';
import { getMarcasComercialesReducer } from './extraReducers/getMarcasComerciales';
import { updateMarcaComercialReducer } from './extraReducers/updateMarcaComercial';
import { deleteMarcaComercialReducer } from './extraReducers/deleteMarcaComercial';

export interface IMarcasComercialesState {
  data: Array<MarcaComercial>;
  status: StatusApp.Status;
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado: MarcaComercial | undefined;
  elementoTemporal: MarcaComercial | undefined;
  apiURL: string;
}

const initialState: IMarcasComercialesState = {
  data: [],
  status: 'idle',
  crudStatus: 'idle',
  modalVisible: false,
  elementoSeleccionado: undefined,
  elementoTemporal: undefined,
  apiURL: '/api/MarcasComerciales',
};

export const marcasComercialesSlice = createSlice({
  name: 'MarcasComerciales',
  initialState,
  reducers: {
    toggleSeleccionarMarcaComercial: (state, action: PayloadAction<MarcaComercial | undefined>) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setMarcaComercialTemporal: (state, action: PayloadAction<MarcaComercial>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
  },
  extraReducers: builder => {
    getMarcasComercialesReducer(builder);
    createMarcaComercialReducer(builder);
    updateMarcaComercialReducer(builder);
    deleteMarcaComercialReducer(builder);
  },
});

export const {
  toggleSeleccionarMarcaComercial,
  toggleModalVisible,
  setMarcaComercialTemporal,
  setCrudStatusIdle,
} = marcasComercialesSlice.actions;

export const MarcasComercialesState = (state: RootState) => state.marcasComerciales;

export default marcasComercialesSlice.reducer;
