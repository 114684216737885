import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DireccionProveedor } from 'models/Proveedor';
import httpService from 'services/httpService';
import { IInicioState } from '../../inicioStore';

export const deleteDireccionProveedorPendiente = createAsyncThunk(
  'Inicio/DeleteDireccionProveedorPendiente',
  httpService,
);

export const deleteDireccionProveedorPendienteReducer = (
  builder: ActionReducerMapBuilder<IInicioState>,
) => {
  builder.addCase(
    deleteDireccionProveedorPendiente.fulfilled,
    (state, _action: PayloadAction<boolean>) => {
      const direccionTemporal = state.direccionTemporal as DireccionProveedor;

      const proveedorIndex = state.proveedoresPendientes.findIndex(
        _ => _.id === direccionTemporal.proveedorId,
      );
      if (proveedorIndex !== -1) {
        const direccionIndexInProveedor = state.proveedoresPendientes[
          proveedorIndex
        ].direcciones.findIndex(_ => _.id === direccionTemporal.id);
        if (direccionIndexInProveedor !== -1) {
          state.proveedoresPendientes[proveedorIndex].direcciones.splice(
            direccionIndexInProveedor,
            1,
          );
        }
      }

      const direccionIndexInDirecciones = state.direcciones.findIndex(
        _ => _.id === direccionTemporal.id,
      );
      if (direccionIndexInDirecciones !== -1) {
        state.direcciones.splice(direccionIndexInDirecciones, 1);
      }

      state.direccionTemporal = undefined;
      state.crudDireccionesStatus = 'succeeded';
    },
  );
  builder.addCase(deleteDireccionProveedorPendiente.pending, (state, _action) => {
    state.crudDireccionesStatus = 'pending';
  });
  builder.addCase(deleteDireccionProveedorPendiente.rejected, (state, _action) => {
    state.crudDireccionesStatus = 'failed';
  });
};
