import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DireccionProveedor, Proveedor } from '../../../models/Proveedor';
import { RootState } from '../../../store/store';
import { createDireccionProveedorReducer } from './extraReducers/createDireccionProveedorReducer';
import { createProveedorReducer } from './extraReducers/createProveedorReducer';
import { deleteDireccionProveedorReducer } from './extraReducers/deleteDireccionProveedorReducer';
import { deleteProveedorReducer } from './extraReducers/deleteProveedorReducer';
import { getProveedoresReducer } from './extraReducers/getProveedoresReducer';
import { updateDireccionProveedorReducer } from './extraReducers/updateDireccionProveedorReducer';
import { updateProveedorReducer } from './extraReducers/updateProveedorReducer';

export interface IProveedoresState {
  data: Array<Proveedor>;
  status: StatusApp.Status;
  apiURL: string;
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado?: Proveedor;
  elementoTemporal?: Proveedor;

  // Direcciones
  crudDireccionesStatus: StatusApp.Status;
  modalDireccionesVisible: boolean;
  direccionTemporal?: DireccionProveedor;
  direccionEditingKey: string;
  direcciones: DireccionProveedor[];
}

const initialState: IProveedoresState = {
  data: [],
  status: 'idle',
  crudStatus: 'idle',
  crudDireccionesStatus: 'idle',
  modalVisible: false,
  modalDireccionesVisible: false,
  direccionEditingKey: '',
  direcciones: [],
  apiURL: '/api/Proveedores',
};

export const proveedoresSlice = createSlice({
  name: 'Proveedores',
  initialState,
  reducers: {
    toggleSeleccionarProveedor: (state, action: PayloadAction<Proveedor | undefined>) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setProveedorTemporal: (state, action: PayloadAction<Proveedor>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
    addProveedor: (state, action: PayloadAction<Proveedor>) => {
      state.data.push(action.payload);
    },
    toggleModalDireccionesVisible: state => {
      state.modalDireccionesVisible = !state.modalDireccionesVisible;
    },
    setDireccionTemporal: (state, action: PayloadAction<DireccionProveedor>) => {
      state.direccionTemporal = action.payload;
    },
    setDireccionEdigingKey: (state, action: PayloadAction<string>) => {
      state.direccionEditingKey = action.payload;
    },
    setDirecciones: (state, action: PayloadAction<DireccionProveedor[]>) => {
      state.direcciones = action.payload;
    },
    addDireccion: (state, action: PayloadAction<DireccionProveedor>) => {
      state.direcciones.push(action.payload);
    },
    setCrudDireccionesStatus: (state, action: PayloadAction<StatusApp.Status>) => {
      state.crudDireccionesStatus = action.payload;
    },
  },
  extraReducers: builder => {
    getProveedoresReducer(builder);
    createProveedorReducer(builder);
    updateProveedorReducer(builder);
    deleteProveedorReducer(builder);

    // Direcciones
    createDireccionProveedorReducer(builder);
    updateDireccionProveedorReducer(builder);
    deleteDireccionProveedorReducer(builder);
  },
});

export const {
  toggleSeleccionarProveedor,
  toggleModalVisible,
  setProveedorTemporal,
  setCrudStatusIdle,
  addProveedor,

  // Direcciones
  toggleModalDireccionesVisible,
  setDireccionTemporal,
  setDireccionEdigingKey,
  setDirecciones,
  addDireccion,
  setCrudDireccionesStatus,
} = proveedoresSlice.actions;

export const ProveedoresState = (state: RootState) => state.proveedores;

export const ProveedoresReducer = proveedoresSlice.reducer;
