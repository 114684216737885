import { Typography } from 'antd';
import SkeletonInput from 'antd/lib/skeleton/Input';
import { OrdenDeTrabajo } from 'models/OrdenTrabajo';
import { formatCurrency } from 'utils/formatCurrency';
import { formatDate } from 'utils/formatDate';
import styles from './DatosGenerales.module.less';

export const DatosGenerales = ({ data = {} as OrdenDeTrabajo }: { data?: OrdenDeTrabajo }) => {
  const ReadOnlyItem = ({ label, value }: { label: string; value: string | number }) => {
    return (
      <div className={styles.readOnlyItem}>
        <Typography.Text className={styles.label}>{label}</Typography.Text>
        {!data.folioPreimpreso ? (
          <SkeletonInput active className={styles.skeleton} />
        ) : String(value).length > 0 ? (
          <Typography.Text className={styles.value}>{value}</Typography.Text>
        ) : (
          <span style={{ height: 22 }} />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className={styles.row}>
        <ReadOnlyItem label='Folio Preimpreso' value={data.folioPreimpreso} />
        <ReadOnlyItem label='Importador' value={data.importador} />
        <ReadOnlyItem label='Aduana de Ingreso' value={data.aduanaDeIngreso} />
        <ReadOnlyItem label='Aduana de Despacho' value={data.aduanaDeDespacho} />
        <ReadOnlyItem label='Contenedor' value={data.contenedor} />
        <ReadOnlyItem label='Clase de Bulto' value={data.claseBulto} />
      </div>
      <Typography.Title style={{ margin: '24px 0 16px 0' }} level={4}>
        Información del Flete
      </Typography.Title>
      <div className={styles.row}>
        <ReadOnlyItem label='Peso BL (KG)' value={formatCurrency(data.pesoBl)} />
        <ReadOnlyItem label='Número de bultos' value={formatCurrency(data.numBultos)} />
        <ReadOnlyItem label='Total de piezas' value={formatCurrency(data.totalPiezas)} />
        <ReadOnlyItem label='FOB' value={formatCurrency(data.fob)} />
        <ReadOnlyItem label='Flete' value={formatCurrency(data.flete)} />
        <ReadOnlyItem label='Seguro' value={formatCurrency(data.seguro)} />
        <ReadOnlyItem label='O.G' value={formatCurrency(data.og)} />
        <ReadOnlyItem label='CIF' value={formatCurrency(data.cif)} />
      </div>

      <Typography.Title style={{ margin: '24px 0 16px 0' }} level={4}>
        Embarque y Exportación
      </Typography.Title>
      <div className={styles.row}>
        <ReadOnlyItem label='País de Embarque' value={data.paisEmbarque} />
        <ReadOnlyItem label='País de Exportación' value={data.paisExportacion} />
        <ReadOnlyItem
          label='Fecha de Exportación'
          value={data.fechaExportacion ? formatDate(data.fechaExportacion, 'en-GB', null) : ''}
        />
      </div>
    </div>
  );
};
