import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Tag, Tooltip, Typography } from 'antd';
import { hardReset as devasHardReset } from 'components/Devas/store/store';
import { downloadDuca } from 'components/Duca/store/extraReducer/downloadDuca';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { FC } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BaseTable } from '../../../components/BaseTable/BaseTable';
import { EstadoOrdenTrabajo } from '../../../enum/EstadoOrdenTrabajo';
import { OrdenDeTrabajo } from '../../../models/OrdenTrabajo';
import { formatUTCToLocalDate } from '../../../utils/formatDate';
import { resetOrdenDetalles } from '../../OrdenTrabajoDetalles/store/ordenTrabajoDetallesStore';
import { deleteOrdenTrabajo } from '../store/extraReducers/deleteOrdenTrabajo';
import { getDevaJson } from '../store/extraReducers/downloadDevasJson';
import { OrdenTrabajoState } from '../store/store';
import { Columns } from './Columns';
import styles from './OrdenesTrabajoTable.module.less';

const { Text } = Typography;

export const OrdenesTrabajoTable: FC = () => {
  const { data, status } = useSelector(OrdenTrabajoState);
  const { user } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();

  const setSeleccionarOrdenTrabajo = (ordenTrabajo: OrdenDeTrabajo) => {
    dispatch(devasHardReset());
    dispatch(resetOrdenDetalles('idle'));
    history.push(`/ordenDeTrabajo/${ordenTrabajo.id}`);
  };

  const downloadDevas = (ordenTrabajoId: string) => {
    customDispatch({
      asyncThuckFuction: getDevaJson,
      endPoint: `/api/OrdenesDeTrabajo/ExportOrdenDeTrabajo/${ordenTrabajoId}`,
    });
  };

  const callDeleteOrdenDeTrabajo = (ordenTrabajoId: string) => {
    customDispatch({
      asyncThuckFuction: deleteOrdenTrabajo,
      endPoint: `/api/OrdenesDeTrabajo/Delete/${ordenTrabajoId}`,
      method: 'DELETE',
    });
  };

  const onDownloadDuca = (ducaId: string) => {
    customDispatch({
      asyncThuckFuction: downloadDuca,
      endPoint: `/api/Ducas/ExportToJson/${ducaId}`,
    });
  };

  const cols = Columns({
    onUpdate: setSeleccionarOrdenTrabajo,
    onDownloadDevas: downloadDevas,
    onDelete: callDeleteOrdenDeTrabajo,
    onDownloadDuca,
  });

  const tagReturn = (value: string) => {
    switch (value) {
      case '0':
        return <Tag color='orange'>Borrador</Tag>;
      case '1':
        return <Tag color='blue'>En Progreso</Tag>;
      case '2':
        return <Tag color='cyan'>Cerrada</Tag>;
      case '3':
        return <Tag color='magenta'>En Espera Aprobacion</Tag>;
      case '4':
        return <Tag color='magenta'>Inconsistente</Tag>;
      default:
        return <Tag color='red'>No Identificado</Tag>;
    }
  };

  const primaryData = data.filter(
    o =>
      (o.estado === EstadoOrdenTrabajo.Borrador ||
        o.estado === EstadoOrdenTrabajo.EnProgreso ||
        o.estado === EstadoOrdenTrabajo.Inconsistente ||
        o.estado === EstadoOrdenTrabajo.EnEsperaAprobacion) &&
      o.nombreCreadoPor === user?.name,
  );

  const secondaryData = data.filter(
    o => o.estado === EstadoOrdenTrabajo.Cerrada || o.nombreCreadoPor !== user?.name,
  );

  const handleFullScreen = useFullScreenHandle();

  return (
    <FullScreen handle={handleFullScreen} className={styles.container}>
      <div className={styles.toolbar}>
        <Text strong className={styles.titleText}>
          Ordenes de Trabajo
        </Text>

        {handleFullScreen.active ? (
          <Tooltip title='Salir de pantalla completa' placement='left'>
            <FullscreenExitOutlined style={{ fontSize: 24 }} onClick={handleFullScreen.exit} />
          </Tooltip>
        ) : (
          <Tooltip title='Ver tabla en pantalla completa' placement='left'>
            <FullscreenOutlined style={{ fontSize: 24 }} onClick={handleFullScreen.enter} />
          </Tooltip>
        )}
      </div>

      <BaseTable
        cols={cols}
        data={[...primaryData, ...secondaryData].map(x => ({
          ...x,
          estado: tagReturn(x.estado.toString()),
          fechaCreacion: formatUTCToLocalDate(x.fechaCreacion.toLocaleString()),
        }))}
        status={status}
        size='small'
        style={{
          height: data.length > 0 ? 'calc(100% - 40px)' : 'calc(100% - 40px)',
        }}
        id='OrdenesDeTrabajoTabla'
        pagination={{ style: { marginBottom: 0 } }}
      />
    </FullScreen>
  );
};
