import {
  CalculatorOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Menu, Popconfirm } from 'antd';
import Dropdown from 'antd/lib/dropdown';

export function DropdownCellRenderer(props: any) {
  const onDetails = (record: any) => {
    props?.values[0]?.onDetails?.(record);
  };

  const onDelete = (key: string) => {
    props?.values[0]?.onDelete?.(key);
  };

  const onCalculoRapido = (key: string) => {
    props?.values[0]?.onCalculoRapido?.(key);
  };

  const onCalculoRapidoLitrosDeclarados = (key: string) => {
    props?.values[0]?.onCalculoRapidoLitrosDeclarados?.(key);
  };

  return (
    !props.data?.pinnedBottom && (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key='detalles' onClick={() => onDetails(props?.data)} icon={<EyeOutlined />}>
              Detalles
            </Menu.Item>
            {!props?.values[0]?.ordenCerrada && props?.data?.ipcFactor > 0 && (
              <Menu.Item
                key='calculoRapido'
                onClick={() => onCalculoRapido(props?.data?.key)}
                icon={<CalculatorOutlined />}
              >
                Calculo Rapido
              </Menu.Item>
            )}
            {!props?.values[0]?.ordenCerrada && props?.data?.litrosDeclarados && (
              <Menu.Item
                key='calculoRapidoLitrosDeclarados'
                onClick={() => onCalculoRapidoLitrosDeclarados(props?.data?.key)}
                icon={<CalculatorOutlined />}
              >
                Calculo Rapido Litros Declarados
              </Menu.Item>
            )}
            {!props?.values[0]?.ordenCerrada && (
              <Popconfirm
                title='¿Estás seguro de eliminar este producto?'
                okText='Eliminar'
                cancelText='Cancelar'
                onConfirm={() => onDelete(props?.data?.key)}
              >
                <Menu.Item key='eliminar' icon={<DeleteOutlined />} danger>
                  Eliminar
                </Menu.Item>
              </Popconfirm>
            )}
          </Menu>
        }
        trigger={['click']}
        placement='bottomRight'
      >
        <EllipsisOutlined
          style={{
            fontSize: 20,
            verticalAlign: 'top',
          }}
        />
      </Dropdown>
    )
  );
}
