import { DucaDetalles, IDucaDetallesRequest } from 'models/Duca';

export const validarCambios = (
  formData: IDucaDetallesRequest,
  detallesData: DucaDetalles | undefined,
): boolean => {
  const detallesDucaToRequest = {
    regimenAduaneroId: detallesData?.caratula.regimenAduaneroId,
    idManifiestoEntregaRapida: detallesData?.caratula.idManifiestoEntregaRapida ?? '',
    proveedorId: detallesData?.caratula.proveedorId,
    contratoProveedorDestinatario: detallesData?.caratula.contratoProveedorDestinatario ?? '',
    entidadMediacion: detallesData?.caratula.entidadMediacion ?? '',
    depositoAlmacenamientoId: detallesData?.caratula.depositoAlmacenamientoId,
    paisOrigenId: detallesData?.caratula.paisOrigenId,
    paisProcedenciaDestinoId: detallesData?.caratula.paisProcedenciaDestinoId,
    formaPagoId: detallesData?.caratula.formaPagoId,
    condicionEntregaId: detallesData?.caratula.condicionEntregaId,
    aduanaTransitoDestinoId: detallesData?.caratula.aduanaTransitoDestinoId,
    modalidadEspecialId: detallesData?.caratula.modalidadEspecialId,
    depositoAduanaTransitoId: detallesData?.caratula.depositoAduanaTransitoId,
    plazoDiasMeses: detallesData?.caratula.plazoDiasMeses,
    rutaTransitoId: detallesData?.caratula.rutaTransitoId,
    motivoOperacionSuspensivaId: detallesData?.caratula.motivoOperacionSuspensivaId,
    observaciones: detallesData?.caratula.observaciones,
    prorratear: detallesData?.caratula.prorratear,
    divisaFacturaId: detallesData?.caratula.divisaFacturaId,
    divisaSeguroId: detallesData?.caratula.divisaSeguroId,
    divisaFleteId: detallesData?.caratula.divisaFleteId,
    manifiesto: detallesData?.bultos?.manifiesto ?? '',
    tituloTransporte: detallesData?.bultos?.tituloTransporte ?? '',
    indicadorCancelacionGlobal: detallesData?.bultos?.indicadorCancelacionGlobal,
    bultosLineas: detallesData?.bultos?.bultosLineas?.map(x => ({ ...x, key: '' })) ?? [],
    documentos: detallesData?.documentos?.map(x => ({ ...x, key: '' })) ?? [],
    datosComplementarios: detallesData?.datosComplementarios?.map(x => ({ ...x, key: '' })) ?? [],
    items:
      detallesData?.items?.map(x => ({
        ...x,
        key: '',
        documentos: x.documentos?.map(y => ({ ...y, key: '' })),
        ventajas: x.ventajas?.map(y => ({ ...y, key: '' })),
        datosComplementarios: x.datosComplementarios?.map(y => ({ ...y, key: '' })),
      })) ?? [],
  } as IDucaDetallesRequest;

  const formDataToRequest = {
    regimenAduaneroId: formData.regimenAduaneroId,
    idManifiestoEntregaRapida: formData.idManifiestoEntregaRapida ?? '',
    proveedorId: formData.proveedorId,
    contratoProveedorDestinatario: formData.contratoProveedorDestinatario ?? '',
    entidadMediacion: formData.entidadMediacion ?? '',
    depositoAlmacenamientoId: formData.depositoAlmacenamientoId,
    paisOrigenId: formData.paisOrigenId,
    paisProcedenciaDestinoId: formData.paisProcedenciaDestinoId,
    formaPagoId: formData.formaPagoId,
    condicionEntregaId: formData.condicionEntregaId,
    aduanaTransitoDestinoId: formData.aduanaTransitoDestinoId,
    modalidadEspecialId: formData.modalidadEspecialId,
    depositoAduanaTransitoId: formData.depositoAduanaTransitoId,
    plazoDiasMeses: formData.plazoDiasMeses,
    rutaTransitoId: formData.rutaTransitoId,
    motivoOperacionSuspensivaId: formData.motivoOperacionSuspensivaId,
    observaciones: formData.observaciones,
    prorratear: formData.prorratear,
    divisaFacturaId: formData.divisaFacturaId,
    divisaSeguroId: formData.divisaSeguroId,
    divisaFleteId: formData.divisaFleteId,
    manifiesto: formData?.manifiesto ?? '',
    tituloTransporte: formData?.tituloTransporte ?? '',
    indicadorCancelacionGlobal: formData?.indicadorCancelacionGlobal,
    bultosLineas: formData?.bultosLineas?.map(x => ({ ...x, key: '' })) ?? [],
    documentos: formData?.documentos?.map(x => ({ ...x, key: '' })) ?? [],
    datosComplementarios: formData?.datosComplementarios?.map(x => ({ ...x, key: '' })) ?? [],
    items:
      formData?.items?.map(x => ({
        ...x,
        key: '',
        documentos: x.documentos?.map(y => ({ ...y, key: '' })),
        ventajas: x.ventajas?.map(y => ({ ...y, key: '' })),
        datosComplementarios: x.datosComplementarios?.map(y => ({ ...y, key: '' })),
      })) ?? [],
  } as IDucaDetallesRequest;

  return JSON.stringify(detallesDucaToRequest) !== JSON.stringify(formDataToRequest);
};
