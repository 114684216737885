import { createAsyncThunk } from '@reduxjs/toolkit';
import { setDevaTotal, setIncotermToDeva } from 'components/Devas/store/store';
import { setError } from 'features/InitialData/store/initialDataStore';
import { IDevaDetallesRequest } from 'models/Deva';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';

export const updateDevaDetalles = createAsyncThunk(
  'DevaDetails/updateDevaDetalles',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);
    if (res) {
      thunkApi.dispatch(
        setDevaTotal({
          deva: {
            importeTransporte: (fetch.body as any)?.importeTransporte,
            importeSeguro: (fetch.body as any)?.importeSeguro,
            importeCargaManipulacion: (fetch.body as any)?.importeCargaManipulacion,
            valorAduana: (fetch.body as any)?.valorAduana,
          } as IDevaDetallesRequest,
          id: (fetch.body as any)?.id,
        }),
      );

      thunkApi.dispatch(
        setIncotermToDeva({
          devaId: (fetch.body as any)?.id,
          incotermId: (fetch.body as any)?.incotermId,
        }),
      );

      thunkApi.dispatch(setError({ message: 'Detalles guardados correctamente.', error: false }));
    }

    return res;
  },
);
