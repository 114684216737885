import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ClasificacionFenag } from '../../models/ClasificacionFenag';

interface IProps {
  onUpdate: (values: ClasificacionFenag) => void;
  onDelete: (values: ClasificacionFenag) => void;
}

const lpsFormatter = new Intl.NumberFormat('es-HN', { style: 'currency', currency: 'HNL' });

export const Columns = ({ onUpdate, onDelete }: IProps): ColumnsType<any> => {
  return [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      width: 200,
    },
    {
      title: 'Cuota por libra',
      dataIndex: 'cuotaPorLibra',
      width: 150,
      render: (text: number) => lpsFormatter.format(text),
    },
    {
      title: 'Prefijos posición arancelaria',
      dataIndex: 'prefijosPosicionArancelaria',
      render: (prefijosPosicionArancelaria: Array<string>) => {
        const totalCountOfItems = prefijosPosicionArancelaria?.length || 0;
        const shouldShowPlus = totalCountOfItems > 5;
        return (
          <div>
            {prefijosPosicionArancelaria.slice(0, 5).map((prefijo: string) => (
              <Tag key={prefijo}>{prefijo}</Tag>
            ))}
            {shouldShowPlus && `+${totalCountOfItems - 5}`}
          </div>
        );
      },
    },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 50,
      render: (_: any, record: ClasificacionFenag) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='editar' onClick={() => onUpdate(record)}>
                <Button type='primary' ghost block>
                  Editar
                </Button>
              </Menu.Item>
              <Popconfirm
                title='¿Estás seguro de eliminar esta clasificación fenag?'
                okText='Eliminar'
                cancelText='Cancelar'
                onConfirm={() => onDelete(record)}
              >
                <Menu.Item key='Eliminar'>
                  <Button danger block>
                    Eliminar
                  </Button>
                </Menu.Item>
              </Popconfirm>
            </Menu>
          }
          trigger={['click']}
          placement='bottomRight'
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Dropdown>
      ),
    },
  ];
};
