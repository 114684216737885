import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/InitialData/store/initialDataStore';
import { addProducto } from 'features/Productos/store/productosStore';
import { Producto } from 'models/Producto';
import httpService, { IUseFetch, ThunckApi } from '../../../../../services/httpService';
import { IInicioState } from '../../inicioStore';

export const updateProductosPendientes = createAsyncThunk(
  'Inicio/UpdateProductosPendientes',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      (fetch.body as Producto[]).map(p => thunkApi.dispatch(addProducto(p)));

      if (document.fullscreenElement === null) {
        thunkApi.dispatch(setError({ message: 'Productos aprobados', error: false }));
      }
    }
    return res;
  },
);

export const updateProductosPendienteReducer = (builder: ActionReducerMapBuilder<IInicioState>) => {
  // Update Productos Pendientes
  builder.addCase(updateProductosPendientes.fulfilled, (state, _action) => {
    state.productosPendientes = [];
    state.productosPendientesStatus = 'succeeded';
  });
  builder.addCase(updateProductosPendientes.pending, (state, _action) => {
    state.productosPendientesStatus = 'pending';
  });
  builder.addCase(updateProductosPendientes.rejected, (state, _action) => {
    state.productosPendientesStatus = 'failed';
  });
};
