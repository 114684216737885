import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { Producto } from '../../../models/Producto';
import { DireccionProveedor, Proveedor } from '../../../models/Proveedor';
import { deleteProductoPendienteReducer } from './extraReducers/producto/delete';
import { getProductosReducer } from './extraReducers/producto/get';
import { updateProductoPendienteReducer } from './extraReducers/producto/update';
import { updateProductosPendienteReducer } from './extraReducers/producto/updateAll';
import { deleteProveedorPendienteReducer } from './extraReducers/proveedor/delete';
import { getProveedoresPendientesReducer } from './extraReducers/proveedor/get';
import { updateProveedoresPendienteReducer } from './extraReducers/proveedor/updateAll';
import { updateProveedorPendienteReducer } from './extraReducers/proveedor/update';
import { createDireccionProveedorPendienteReducer } from './extraReducers/proveedor/createDireccionProveedorPendienteReducer';
import { updateDireccionProveedorPendienteReducer } from './extraReducers/proveedor/updateDireccionProveedorPendienteReducer';
import { deleteDireccionProveedorPendienteReducer } from './extraReducers/proveedor/deleteDireccionProveedorPendienteReducer';

export interface IInicioState {
  error?: string;
  proveedoresPendientesStatus: StatusApp.Status;
  getProveedoresPendientesStatus: StatusApp.Status;
  proveedoresPendientes: Array<Proveedor>;
  proveedorPendienteSeleccionado: Proveedor | undefined;
  productosPendientesStatus: StatusApp.Status;
  getProductosPendientesStatus: StatusApp.Status;
  productosPendientes: Array<Producto>;
  productoPendienteSeleccionado: Producto | undefined;

  // Direcciones Proveedores Pendientes
  crudDireccionesStatus: StatusApp.Status;
  modalDireccionesVisible: boolean;
  direccionTemporal?: DireccionProveedor;
  direccionEditingKey: string;
  direcciones: DireccionProveedor[];
}

const initialState: IInicioState = {
  proveedoresPendientesStatus: 'idle',
  getProveedoresPendientesStatus: 'idle',
  proveedoresPendientes: [],
  proveedorPendienteSeleccionado: undefined,
  productosPendientesStatus: 'idle',
  getProductosPendientesStatus: 'idle',
  productosPendientes: [],
  productoPendienteSeleccionado: undefined,

  // Direcciones Proveedores Pendientes
  crudDireccionesStatus: 'idle',
  modalDireccionesVisible: false,
  direccionEditingKey: '',
  direcciones: [],
};

export const inicioSlice = createSlice({
  name: 'Inicio',
  initialState,
  reducers: {
    setProductoPendienteSeleccionado: (state, action: PayloadAction<Producto | undefined>) => {
      state.productoPendienteSeleccionado = action.payload;
    },
    setEditProductoPendiente: (state, action: PayloadAction<Producto>) => {
      const producto = action.payload;
      const index = state.productosPendientes.findIndex(
        p => p.id === state.productoPendienteSeleccionado?.id,
      );
      state.productosPendientes.splice(index, 1, {
        ...state.productoPendienteSeleccionado,
        ...producto,
      });
      state.productoPendienteSeleccionado = undefined;
    },
    resetProductosPendientes: state => {
      state.productosPendientes = [];
    },
    setProveedorPendienteSeleccionado: (state, action: PayloadAction<Proveedor | undefined>) => {
      state.proveedorPendienteSeleccionado = action.payload;
    },
    setEditProveedorPendiente: (state, action: PayloadAction<Proveedor>) => {
      const proveedor = action.payload;
      const index = state.proveedoresPendientes.findIndex(
        p => p.id === state.proveedorPendienteSeleccionado?.id,
      );
      state.proveedoresPendientes.splice(index, 1, {
        ...state.proveedorPendienteSeleccionado,
        ...proveedor,
      });
      state.proveedorPendienteSeleccionado = undefined;
    },
    resetProveedoresPendientes: state => {
      state.proveedoresPendientes = [];
    },
    resetError: state => {
      state.error = undefined;
    },

    // Direcciones Proveedores Pendientes
    toggleModalDireccionesProveedorPendienteVisible: state => {
      state.modalDireccionesVisible = !state.modalDireccionesVisible;
    },
    setDireccionProveedorPendienteTemporal: (state, action: PayloadAction<DireccionProveedor>) => {
      state.direccionTemporal = action.payload;
    },
    setDireccionProveedorPendienteEdigingKey: (state, action: PayloadAction<string>) => {
      state.direccionEditingKey = action.payload;
    },
    setDireccionesProveedorPendiente: (state, action: PayloadAction<DireccionProveedor[]>) => {
      state.direcciones = action.payload;
    },
    addDireccionProveedorPendiente: (state, action: PayloadAction<DireccionProveedor>) => {
      state.direcciones.push(action.payload);
    },
  },
  extraReducers: builder => {
    getProductosReducer(builder);
    deleteProductoPendienteReducer(builder);
    updateProductoPendienteReducer(builder);
    updateProductosPendienteReducer(builder);

    deleteProveedorPendienteReducer(builder);
    getProveedoresPendientesReducer(builder);
    updateProveedoresPendienteReducer(builder);
    updateProveedorPendienteReducer(builder);

    // Direcciones Proveedores Pendientes
    createDireccionProveedorPendienteReducer(builder);
    updateDireccionProveedorPendienteReducer(builder);
    deleteDireccionProveedorPendienteReducer(builder);
  },
});

export const {
  setProductoPendienteSeleccionado,
  setEditProductoPendiente,
  resetProductosPendientes,
  setProveedorPendienteSeleccionado,
  setEditProveedorPendiente,
  resetProveedoresPendientes,

  // Direcciones Proveedores Pendientes
  toggleModalDireccionesProveedorPendienteVisible,
  setDireccionProveedorPendienteTemporal,
  setDireccionProveedorPendienteEdigingKey,
  setDireccionesProveedorPendiente,
  addDireccionProveedorPendiente,
} = inicioSlice.actions;

export const InicioState = (state: RootState) => state.inicio;

export default inicioSlice.reducer;
