import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tratado } from '../../../models/Tratado';
import { RootState } from '../../../store/store';
import { createTratadoReducer } from './extraReducer/createTratado';
import { deleteTratadoReducer } from './extraReducer/deleteProduct';
import { getTratadosReducer } from './extraReducer/getTratados';
import { updateTratadoReducer } from './extraReducer/updateTratado';

export interface ITratadosState {
  data: Array<Tratado>;
  status: StatusApp.Status;
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado: Tratado | undefined;
  elementoTemporal: Tratado | undefined;
  apiURL: string;
}

const initialState: ITratadosState = {
  data: [],
  status: 'idle',
  crudStatus: 'idle',
  modalVisible: false,
  elementoSeleccionado: undefined,
  elementoTemporal: undefined,
  apiURL: '/api/Tratados',
};

export const tratadosSlice = createSlice({
  name: 'Tratados',
  initialState,
  reducers: {
    toggleSeleccionarTratado: (state, action: PayloadAction<Tratado | undefined>) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setTratadoTemporal: (state, action: PayloadAction<Tratado>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
  },
  extraReducers: builder => {
    getTratadosReducer(builder);
    createTratadoReducer(builder);
    deleteTratadoReducer(builder);
    updateTratadoReducer(builder);
  },
});

export const {
  toggleSeleccionarTratado,
  toggleModalVisible,
  setTratadoTemporal,
  setCrudStatusIdle,
} = tratadosSlice.actions;

export const TratadosState = (state: RootState) => state.tratados;

export default tratadosSlice.reducer;
