import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Producto } from '../../models/Producto';

interface IProps {
  onUpdate: (values: Producto) => void;
  onDelete: (values: Producto) => void;
  onDetails: (values: Producto) => void;
  isAdmin: boolean;
}

export const Columns = ({ onUpdate, onDelete, onDetails, isAdmin }: IProps): ColumnsType<any> => {
  return [
    {
      title: 'SKU (Modelo)',
      dataIndex: 'sku',
      width: 200,
    },
    {
      title: 'Proveedor',
      dataIndex: 'proveedor',
      width: 200,
    },
    {
      title: 'Partida Arancelaria',
      dataIndex: 'partidaArancelaria',
      width: 200,
    },
    {
      title: 'Designacion de Producto',
      dataIndex: 'designacionProducto',
      width: 200,
    },
    {
      title: 'Caracteristicas',
      dataIndex: 'caracteristicas',
      width: 200,
    },
    {
      title: 'Caracteristica Principal',
      dataIndex: 'caracteristicaPrincipal',
      width: 200,
    },
    {
      title: 'Caracteristica Secundaria',
      dataIndex: 'caracteristicaSecundaria',
      width: 200,
    },
    {
      title: 'Marca Comercial',
      dataIndex: 'marcaComercial',
      width: 300,
    },
    {
      title: 'Pais de Origen',
      dataIndex: 'paisOrigen',
      width: 200,
    },
    {
      title: 'Unidad de Medida',
      dataIndex: 'unidadMedida',
      width: 200,
    },
    {
      title: 'Estados de Mercancia',
      dataIndex: 'estadoMercancia',
      width: 200,
    },
    {
      title: 'Mostrar Descripcion con Presentación',
      dataIndex: 'mostrarDescripcionConPresentacion',
      width: 300,
      render: (_: any, record: Producto) =>
        record.mostrarDescripcionConPresentacion ? 'Si' : 'No',
    },
    {
      title: 'Clasificación Fenag',
      dataIndex: 'clasificacionFenag',
      width: 200,
    },
    {
      title: 'Codigo de Barras',
      dataIndex: 'codigoDeBarras',
      width: 200,
    },
    {
      title: 'Codigo Fitosanitario',
      dataIndex: 'codigoFitosanitario',
      width: 200,
    },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 50,
      render: (_: any, record: Producto) =>
        isAdmin ? (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='editar' onClick={() => onUpdate(record)}>
                  <Button type='primary' ghost block>
                    Editar
                  </Button>
                </Menu.Item>
                <Popconfirm
                  title='¿Estás seguro de eliminar este producto?'
                  okText='Eliminar'
                  cancelText='Cancelar'
                  onConfirm={() => onDelete(record)}
                >
                  <Menu.Item key='eliminar'>
                    <Button danger block>
                      Eliminar
                    </Button>
                  </Menu.Item>
                </Popconfirm>
              </Menu>
            }
            trigger={['click']}
            placement='bottomRight'
          >
            <EllipsisOutlined
              style={{
                fontSize: 20,
                verticalAlign: 'top',
              }}
            />
          </Dropdown>
        ) : (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='editar' onClick={() => onDetails(record)}>
                  <Button type='primary' ghost block>
                    Ver Detalles
                  </Button>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
            placement='bottomRight'
          >
            <EllipsisOutlined
              style={{
                fontSize: 20,
                verticalAlign: 'top',
              }}
            />
          </Dropdown>
        ),
    },
  ];
};
