import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Tabs } from 'antd';
import { setTitleName } from 'components/Sidebar/store/sidebarStore';
import { setStatusOrdenTrabajoDetalles } from 'features/OrdenTrabajoDetalles/store/ordenTrabajoDetallesStore';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { IDevaDetallesRequest } from 'models/Deva';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import styles from './DevaDetails.module.less';
import { Productos } from './Productos/Productos';
import { RegistroDeclaracionValores } from './RegistroDeclaracionValores/RegistroDeclaracionValores';
import { getDevaById } from './store/extraReducers/getDetalleDeva';
import { devaDetallesHardReset } from './store/store';

const { TabPane } = Tabs;

export type TParams = {
  nombreProveedor: string;
  devaId: string;
  ordenId: string;
};

export const DevaDetails = () => {
  const { devaId, nombreProveedor, ordenId } = useParams<TParams>();
  const dispatch = useDispatch();
  const [form] = Form.useForm<IDevaDetallesRequest>();

  useEffect(() => {
    !window.document.title.includes(nombreProveedor) && dispatch(setTitleName(nombreProveedor));
  }, [dispatch, nombreProveedor]);

  const customDispath = useCustomDispatch();
  useEffect(() => {
    customDispath({
      asyncThuckFuction: getDevaById,
      endPoint: `/api/Devas/GetById/${devaId}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    return () => {
      dispatch(devaDetallesHardReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { replace } = useHistory();
  const { state } = useLocation<{ creando: boolean }>();

  const onRegresarAOrdenDeTrabajo = () => {
    if (state?.creando)
      replace({
        pathname: `/ordenDeTrabajo`,
      });
    else {
      dispatch(setStatusOrdenTrabajoDetalles('idle'));
      replace({
        pathname: `/ordenDeTrabajo/${ordenId}`,
      });
    }
  };

  const operations = (
    <Button icon={<ArrowLeftOutlined />} onClick={onRegresarAOrdenDeTrabajo}>
      Regresar a orden de Trabajo
    </Button>
  );

  return (
    <section className={styles.container}>
      <Tabs
        className={styles.tabsContainer}
        defaultActiveKey='1'
        tabBarExtraContent={operations}
        destroyInactiveTabPane={false}
      >
        <TabPane tab='Productos' key='1'>
          <Productos />
        </TabPane>
        <TabPane tab='Registro de declaración de valores' key='2'>
          <RegistroDeclaracionValores form={form} />
        </TabPane>
      </Tabs>
    </section>
  );
};
