import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Producto } from 'models/Producto';
import { httpServiceFormData } from 'services/httpService';
import { IProductosState } from '../productosStore';

export const bulkUpdateProductos = createAsyncThunk('Productos/BulkUpdate', httpServiceFormData);

interface IResponse {
  productosActualizados: Producto[];
  feedbackMensajes: string[];
}

export const bulkUpdateProductosReducer = (builder: ActionReducerMapBuilder<IProductosState>) => {
  builder.addCase(bulkUpdateProductos.fulfilled, (state, action: PayloadAction<IResponse>) => {
    state.bulkUpdateStatus = 'succeeded';
    state.pagedData.items = state.pagedData.items.map(i => {
      const itemUpdated = action.payload.productosActualizados?.find(x => x.id === i.id);
      if (itemUpdated) {
        return { ...itemUpdated };
      }

      return i;
    });
    state.bulkUpdateFeedback = action.payload.feedbackMensajes;
    state.bulkImportUpdateProducts = action.payload.productosActualizados?.length;
  });
  builder.addCase(bulkUpdateProductos.pending, (state, _action) => {
    state.bulkUpdateStatus = 'pending';
  });
  builder.addCase(bulkUpdateProductos.rejected, (state, _action) => {
    state.bulkUpdateStatus = 'failed';
  });
};
