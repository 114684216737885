import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Aduana } from 'models/Aduana';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';
import { IInitialDataState } from '../initialDataStore';

export const getAduanas = createAsyncThunk(
  'Aduanas/GetAall',
  async (fetchOp: IUseFetch, thunkApi: ThunckApi) => {
    const res = httpService(fetchOp, thunkApi);
    return res;
  },
);

export const getAduanasReducer = (builder: ActionReducerMapBuilder<IInitialDataState>) => {
  builder.addCase(getAduanas.fulfilled, (state, action: PayloadAction<Aduana[]>) => {
    state.Aduanas = action.payload;
    state.AduanasStatus = 'succeeded';
  });
  builder.addCase(getAduanas.pending, state => {
    state.AduanasStatus = 'pending';
  });
  builder.addCase(getAduanas.rejected, state => {
    state.AduanasStatus = 'failed';
  });
};
