import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { httpServiceFormData } from 'services/httpService';
import { IProductosState } from '../productosStore';

export const bulkDeleteProductos = createAsyncThunk('Productos/BulkDelete', httpServiceFormData);

interface IResponse {
  productosEliminados: string[];
  feedbackMensajes: string[];
}

export const bulkDeleteProductosReducer = (builder: ActionReducerMapBuilder<IProductosState>) => {
  builder.addCase(bulkDeleteProductos.fulfilled, (state, action: PayloadAction<IResponse>) => {
    state.bulkDeleteStatus = 'succeeded';
    action.payload.productosEliminados.forEach(i => {
      const index = state.pagedData.items.findIndex(x => x.id === i);
      if (index > -1) {
        state.pagedData.items.splice(index, 1);
      }
    });
    state.bulkDeleteFeedback = action.payload.feedbackMensajes;
    state.bulkImportDeleteProducts = action.payload.productosEliminados?.length;
  });
  builder.addCase(bulkDeleteProductos.pending, (state, _action) => {
    state.bulkDeleteStatus = 'pending';
  });
  builder.addCase(bulkDeleteProductos.rejected, (state, _action) => {
    state.bulkDeleteStatus = 'failed';
  });
};
