export const FormatoPartidaArancelaria = (posicionArancelaria: string) => {
  let formato = '';
  for (let i = 0; i < posicionArancelaria.length; i++) {
    if (i >= 0 && i < 4) {
      formato += posicionArancelaria[i];
    }

    if (i > 3 && i % 2 === 0) {
      formato += `.${posicionArancelaria[i]}`;
    }

    if (i > 3 && i % 2 === 1) {
      formato += posicionArancelaria[i];
    }
  }

  return formato;
};
