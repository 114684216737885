import { AutoComplete, Button, Drawer, Form, Spin } from 'antd';
import { CustomDatepicker } from 'components/FormControls/CustomDatePicker';
import { DropDownGrid } from 'components/FormControls/CustomDropDown';
import { CustomInput } from 'components/FormControls/CustomInput';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useDebounce } from 'hooks/useDebounce';
import { IDevaProductoDetalleRequest } from 'models/Deva';
import { QuerySpec } from 'models/QuerySpec';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { queryProductos } from '../../../features/Productos/store/extraReducer/queryProductos';
import { useEffect } from 'react';
import { setDrawerAgregarProductoVisible } from '../store/store';
import { validarProducto } from '../store/extraReducer/validarProducto';

const { Item } = Form;

export const DrawerAgregarProducto = () => {
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const [form] = Form.useForm<IDevaProductoDetalleRequest>();

  const { agregarProductoDrawerIsVisible, validarAgregarProductoStatus, proveedor } =
    useAppSelector(_ => _.devas);
  const {
    pagedData: { items: Productos },
  } = useAppSelector(state => state.productos);
  const { data: UnidadesMedidas, status: UnidadesMedidasStatus } = useAppSelector(
    state => state.unidadesMedidasNetas,
  );

  // Carga inicial de los productos
  useEffect(() => {
    if (!agregarProductoDrawerIsVisible) return;
    dispatchProductos('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agregarProductoDrawerIsVisible]);

  // Lipiar form al crearse exitosamente
  useEffect(() => {
    if (validarAgregarProductoStatus !== 'succeeded') return;
    onClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validarAgregarProductoStatus]);

  const onClose = () => {
    dispatch(setDrawerAgregarProductoVisible(false));
    form.resetFields();
  };

  const onFinish = async () => {
    const values = await form.validateFields();
    onValidarProducto(values);
  };

  const onValidarProducto = (values: IDevaProductoDetalleRequest) => {
    customDispatch({
      asyncThuckFuction: validarProducto,
      endPoint: '/api/Devas/ValidarProducto',
      body: { proveedorId: proveedor.id, producto: values },
      method: 'POST',
    });
  };

  const onSearchProducto = useDebounce((value: string) => {
    if (value.length) dispatchProductos(value);
  });

  const dispatchProductos = (filterValue: string) => {
    customDispatch({
      asyncThuckFuction: queryProductos,
      endPoint: '/api/Productos/Query',
      body: {
        simpleTextFilter: filterValue,
        pageNumber: 1,
        pageSize: 50,
        filterGroups: [
          {
            columnName: 'proveedorId',
            filters: [
              {
                filterValue: proveedor.id,
              },
            ],
          },
        ],
      } as QuerySpec,
    });
  };

  const layout = { span: 24 };

  return (
    <Drawer
      title='Agregar Producto a la Deva'
      width={520}
      closable={false}
      onClose={onClose}
      visible={agregarProductoDrawerIsVisible}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={onFinish} type='primary'>
            Agregar
          </Button>
        </div>
      }
    >
      <Spin spinning={validarAgregarProductoStatus === 'pending'}>
        <Form form={form} name='AgregarProductoDeva' layout='vertical'>
          <CustomInput layout={layout} name='numeroFactura' label='Número de Factura' required />
          <CustomDatepicker layout={layout} name='fechaFactura' label='Fecha de Factura' required />
          <Item name='modelo' label='Modelo' required>
            <AutoComplete
              options={Productos.map(producto => ({
                key: producto.id,
                value: producto.sku,
              }))}
              filterOption
              onSearch={onSearchProducto}
            />
          </Item>
          <CustomInputNumber layout={layout} name='cantidad' label='Cantidad Comercial' required />
          <CustomInputNumber
            layout={layout}
            name='valorUnitario'
            label='Valor Unitario USD'
            required
          />
          <CustomInputNumber layout={layout} name='valorTotal' label='Valor Total' required />
          <DropDownGrid
            layout={layout}
            data={UnidadesMedidas}
            index='nombre'
            name='unidadMedida'
            label='Unidad de Medida'
            loading={UnidadesMedidasStatus === 'pending'}
            renderText={_ => `${_.nombre} - ${_.descripcion}`}
            valueToSelect={_ => _.nombre}
            required
          />
        </Form>
      </Spin>
    </Drawer>
  );
};
