import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { LugarEmbarque } from 'models/LugarEmbarque';
import { PagedList } from 'models/PagedList';
import httpService from '../../../../services/httpService';
import { IDevaDetallesState } from '../store';

export const queryLugaresEmbarque = createAsyncThunk(
  'DevaDetalles/QueryLugaresEmbarque',
  httpService,
);

export const queryLugaresEmbarqueReducer = (
  builder: ActionReducerMapBuilder<IDevaDetallesState>,
) => {
  builder.addCase(
    queryLugaresEmbarque.fulfilled,
    (state, action: PayloadAction<PagedList<LugarEmbarque>>) => {
      state.lugaresEmbarque = action.payload.items;
      state.lugaresEmbarqueStatus = 'succeeded';
    },
  );
  builder.addCase(queryLugaresEmbarque.pending, (state, _action) => {
    state.lugaresEmbarqueStatus = 'pending';
  });
  builder.addCase(queryLugaresEmbarque.rejected, (state, _action) => {
    state.lugaresEmbarqueStatus = 'failed';
  });
};
