import { Button, Drawer, Form, Row, Tabs } from 'antd';
import { CustomNotification } from 'components/Notification/Notification';
import { getImpuestos } from 'features/DucaDetalles/store/extraReducer/getImpuestos';
import { setDrawerItemVisible, setSaveDucaItem } from 'features/DucaDetalles/store/store';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { IDucaItemRequest } from 'models/Duca';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import styles from './DetallesItem.module.less';
import { TabPaneComplementarios } from './Secciones/TabPaneComplementarios';
import { TabPaneDetalles } from './Secciones/TabPaneDetalles';
import { TabPaneDocumentos } from './Secciones/TabPaneDocumentos';
import { TabPaneVentajas } from './Secciones/TabPaneVentajas';

const { TabPane } = Tabs;

export const DetallesItem = () => {
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const { drawerItemVisible, items, ducaDetalles } = useAppSelector(state => state.ducaDetalles);
  const [form] = Form.useForm<IDucaItemRequest>();

  const setVisible = (visible: boolean) => {
    dispatch(setDrawerItemVisible(visible));
  };

  const onClose = () => {
    setVisible(false);
  };

  const onShow = (values: IDucaItemRequest) => {
    setVisible(true);
    form.setFieldsValue(values);
  };

  const onSave = () => {
    form
      .validateFields()
      .then(values => {
        const key = form.getFieldValue('key');
        const item = items.find(i => i.key === key);
        values.tipoItem = form.getFieldValue('tipoItem');
        values.estadoMercancia = form.getFieldValue('estadoMercancia');
        values.paisOrigen = form.getFieldValue('paisOrigen');
        values.paisProcedenciaDestino = form.getFieldValue('paisProcedenciaDestino');
        values.paisAdquisicion = form.getFieldValue('paisAdquisicion');
        values.cuotaArancelaria = form.getFieldValue('cuotaArancelaria');
        values.unidadComercial = form.getFieldValue('unidadComercial');
        values.unidadEstadistica = form.getFieldValue('unidadEstadistica');
        values.documentos = form.getFieldValue('documentos');
        values.ventajas = form.getFieldValue('ventajas');
        values.datosComplementarios = form.getFieldValue('datosComplementarios');
        values.pesoNeto = Number(values.pesoNeto);
        values.pesoBruto = Number(values.pesoBruto);
        values.cantidadBultos = Number(values.cantidadBultos);
        values.cantidadComercial = Number(values.cantidadComercial);
        values.cantidadEstadistica = Number(values.cantidadEstadistica);
        values.importeFOB = Number(values.importeFOB);
        values.importeOtrosGastos = Number(values.importeOtrosGastos);
        values.importeSeguro = Number(values.importeSeguro);
        values.importeFlete = Number(values.importeFlete);
        values.ajusteAIncluir = Number(values.ajusteAIncluir);

        dispatch(
          setSaveDucaItem({
            record: {
              ...item,
              ...values,
            },
            key,
          }),
        );
        setVisible(false);

        if (item && item?.paisOrigenId !== values.paisOrigenId) {
          customDispatch({
            asyncThuckFuction: getImpuestos,
            endPoint: `/api/Ducas/GetImpuestosProducto`,
            method: 'POST',
            body: {
              PosicionArancelariaId: item.posicionArancelariaId,
              PaisOrigenId: values.paisOrigenId,
              Key: key,
            },
          });
        }
      })
      .catch(error => {
        console.log(error);
        CustomNotification({
          error: true,
          message: 'Faltan algunos campos requeridos en los Detalles.',
          duration: 2,
          onClose: () => {},
        });
        form.scrollToField(error.errorFields[0]?.name, {
          behavior: 'smooth',
          block: 'center',
        });
      });
  };

  const footer = (
    <Row justify='end'>
      <Button onClick={onClose} style={{ marginRight: 8 }}>
        Cancelar
      </Button>
      {!ducaDetalles?.ordenCerrada && (
        <Button type='primary' onClick={onSave}>
          Confirmar
        </Button>
      )}
    </Row>
  );

  const DraweDetallesItem = () => (
    <Drawer
      title='Detalles del Item'
      visible={drawerItemVisible}
      onClose={onClose}
      width={800}
      footer={footer}
      bodyStyle={{ padding: 0 }}
    >
      <Form layout='vertical' form={form}>
        <Tabs type='card'>
          <TabPane tab='Detalles' key='1' className={styles.tabPane}>
            <TabPaneDetalles />
          </TabPane>
          <TabPane tab='Documentos' key='2' className={styles.tabPane}>
            <TabPaneDocumentos form={form} />
          </TabPane>
          <TabPane tab='Ventajas' key='3' className={styles.tabPane}>
            <TabPaneVentajas form={form} />
          </TabPane>
          <TabPane tab='Complementarios' key='4' className={styles.tabPane}>
            <TabPaneComplementarios form={form} />
          </TabPane>
        </Tabs>
      </Form>
    </Drawer>
  );

  return { DraweDetallesItem, onShow };
};
