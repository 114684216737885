import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DireccionProveedor } from '../../../../models/Proveedor';
import { IProveedoresState } from '../proveedoresStore';
import httpService from '../../../../services/httpService';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';

export const createDireccionProveedor = createAsyncThunk('Proveedors/CreateDireccion', httpService);

export const createDireccionProveedorReducer = (
  builder: ActionReducerMapBuilder<IProveedoresState>,
) => {
  builder.addCase(createDireccionProveedor.fulfilled, (state, action: PayloadAction<string>) => {
    const direccionId = action.payload;
    const direccion = state.direccionTemporal as DireccionProveedor;

    // Eliminar direccion con el key 'new'
    const direccionKeyNewIndex = state.direcciones.findIndex(_ => _.key === 'new');
    if (direccionKeyNewIndex !== -1) {
      state.direcciones.splice(direccionKeyNewIndex, 1);
    }

    // Agregar la dirección al proveedore y el las direcciones para mostrarla en la tabla
    const proveedorIndex = state.data.findIndex(_ => _.id === direccion.proveedorId);
    if (proveedorIndex !== -1) {
      if (state.data[proveedorIndex].direcciones) {
        state.data[proveedorIndex].direcciones.push({ ...direccion, id: direccionId });
      } else {
        const proveedor = state.data[proveedorIndex];
        state.data[proveedorIndex] = {
          ...proveedor,
          direcciones: [{ ...direccion, id: direccionId }],
        };
      }
      state.direcciones.push({ ...direccion, id: direccionId, key: GenerateKey() });
    }

    state.direccionTemporal = undefined;
    state.crudDireccionesStatus = 'succeeded';
  });
  builder.addCase(createDireccionProveedor.pending, (state, _action) => {
    state.crudDireccionesStatus = 'pending';
  });
  builder.addCase(createDireccionProveedor.rejected, (state, _action) => {
    state.crudDireccionesStatus = 'failed';
  });
};
