import { Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { IDucaDatoComplementarioRequest } from 'models/Duca';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { ColumnasTablaAsignar, DataType } from '../TabPaneItems';
import { TablaDatosComplementarios } from './TablaDatosComplementarios';

interface ITablaAsignarDatosComplementarios {
  data: DataType[];
  selectedRow: string[];
  setSelectedRow: (value: string[]) => void;
}

export const TablaAsignarDatosComplementarios = ({
  data,
  selectedRow,
  setSelectedRow,
}: ITablaAsignarDatosComplementarios) => {
  const [datosComplementarios, setDatosComplementarios] = useState<
    IDucaDatoComplementarioRequest[]
  >([]);
  const [keyExpanded, setKeyExpanded] = useState<React.Key[]>([]);
  const items = useAppSelector(state => state.ducaDetalles.items);

  useEffect(() => {
    if (selectedRow.length === 0) {
      setDatosComplementarios([]);
      setKeyExpanded([]);
      return;
    }
  }, [selectedRow]);

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys: string[], _selectedRows: DataType[]) => {
      setSelectedRow(selectedRowKeys);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.existencia,
    }),
  } as TableRowSelection<DataType>;

  return (
    <Table
      columns={ColumnasTablaAsignar}
      dataSource={data}
      rowSelection={rowSelection}
      expandable={
        items.some(item => item.datosComplementarios.length > 0)
          ? {
              expandedRowKeys: keyExpanded,
              expandedRowRender: () => <TablaDatosComplementarios data={datosComplementarios} />,
              onExpand: (expanded, record) => {
                if (expanded) {
                  const result =
                    items
                      .find(x => x.key === record.key)
                      ?.datosComplementarios?.filter(x => x.estado !== EstadoDucaItem.Delete) ?? [];
                  setDatosComplementarios(result);
                  setKeyExpanded([record.key]);
                } else {
                  setKeyExpanded([]);
                }
              },
              rowExpandable: record =>
                Number(items.find(x => x.key === record.key)?.datosComplementarios?.length) > 0,
            }
          : undefined
      }
      pagination={false}
      size='small'
      bordered
      scroll={{ y: 300 }}
    />
  );
};
