import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DireccionProveedor } from '../../../../models/Proveedor';
import { IProveedoresState } from '../proveedoresStore';
import httpService from '../../../../services/httpService';

export const deleteDireccionProveedor = createAsyncThunk('Proveedors/DeleteDireccion', httpService);

export const deleteDireccionProveedorReducer = (
  builder: ActionReducerMapBuilder<IProveedoresState>,
) => {
  builder.addCase(deleteDireccionProveedor.fulfilled, (state, _action: PayloadAction<boolean>) => {
    const direccionTemporal = state.direccionTemporal as DireccionProveedor;

    const proveedorIndex = state.data.findIndex(_ => _.id === direccionTemporal.proveedorId);
    if (proveedorIndex !== -1) {
      const direccionIndexInProveedor = state.data[proveedorIndex].direcciones.findIndex(
        _ => _.id === direccionTemporal.id,
      );
      if (direccionIndexInProveedor !== -1) {
        state.data[proveedorIndex].direcciones.splice(direccionIndexInProveedor, 1);
      }
    }

    const direccionIndexInDirecciones = state.direcciones.findIndex(
      _ => _.id === direccionTemporal.id,
    );
    if (direccionIndexInDirecciones !== -1) {
      state.direcciones.splice(direccionIndexInDirecciones, 1);
    }

    state.direccionTemporal = undefined;
    state.crudDireccionesStatus = 'succeeded';
  });
  builder.addCase(deleteDireccionProveedor.pending, (state, _action) => {
    state.crudDireccionesStatus = 'pending';
  });
  builder.addCase(deleteDireccionProveedor.rejected, (state, _action) => {
    state.crudDireccionesStatus = 'failed';
  });
};
