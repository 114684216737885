import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EstadoEntidad } from 'enum/EstadoEntidad';
import { IDevaProductoDetalleRequest } from 'models/Deva';
import { Producto } from 'models/Producto';
import httpService from 'services/httpService';
import { IDevasState, ProductoValidado } from '../store';

export const validarProducto = createAsyncThunk('Devas/ValidarProducto', httpService);

interface IResponse {
  completo: boolean;
  estado: EstadoEntidad;
  product: IDevaProductoDetalleRequest;
  coincidencias: Array<Producto>;
}

export const validarProductoReducer = (builder: ActionReducerMapBuilder<IDevasState>) => {
  builder.addCase(validarProducto.fulfilled, (state, action: PayloadAction<IResponse>) => {
    const product = action.payload;

    if (state.devaInCreation) state.devaInCreation.productos.push(product.product);

    if (state.devaInUpdate) state.devaInUpdate.productos.push(product.product);

    state.productosValidados.push({
      completo: product.completo,
      estado: product.estado,
      product: {
        id: product.product.productoId,
        sku: product.product.modelo,
        partidaArancelariaId: '',
        partidaArancelaria: '',
        designacionProductoId: '',
        designacionProducto: '',
        marcaComercialId: '',
        paisOrigenId: '',
        unidadMedidaId: '',
        estadoMercanciaId: '',
        mostrarDescripcionConPresentacion: false,
      } as Producto,
      coincidencias: product.coincidencias,
    } as ProductoValidado);

    state.validarAgregarProductoStatus = 'succeeded';
  });
  builder.addCase(validarProducto.pending, (state, _action) => {
    state.validarAgregarProductoStatus = 'pending';
  });
  builder.addCase(validarProducto.rejected, (state, _action) => {
    state.validarAgregarProductoStatus = 'failed';
  });
};
