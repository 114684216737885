import { Form } from 'antd';
import { useFilter } from 'hooks/useFilter';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { DesignacionProducto } from '../../models/DesignacionProducto';
import { Columns } from './Columns';
import styles from './DesignacionesProductos.module.less';
import { ModalUpsert } from './ModalUpsert/ModalUpsert';
import {
  DesignacionesProductosState,
  setCrudStatusIdle,
  setDesignacionProductoTemporal,
  toggleModalVisible,
  toggleSeleccionarDesignacionProducto,
} from './store/designacionesProductosStore';
import { createDesignacionProducto } from './store/extraReducer/create';
import { deleteDesignacionProducto } from './store/extraReducer/delete';
import { getDesignacionesProductos } from './store/extraReducer/get';
import { updateDesignacionProducto } from './store/extraReducer/update';
import { Toolbar } from './Toolbar/Toolbar';

export const DesignacionesProductos: FC = () => {
  const { data, status, crudStatus, modalVisible, elementoSeleccionado, apiURL } = useSelector(
    DesignacionesProductosState,
  );
  const customDispatch = useCustomDispatch();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    status === 'idle' &&
      customDispatch({
        asyncThuckFuction: getDesignacionesProductos,
        endPoint: '/api/DesignacionesProductos/GetAll',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    !window.document.title.includes('Designaciones de Productos') &&
      dispatch(setTitleName('Designaciones de Productos'));

    if (crudStatus === 'succeeded') {
      dispatch(setCrudStatusIdle());
      form.resetFields();
    }
  }, [crudStatus, dispatch, form]);

  const setUpdateSelection = (values: DesignacionProducto) => {
    dispatch(toggleSeleccionarDesignacionProducto(values));
    setToggleModalVisible();
    form.setFieldsValue({ nombre: values.nombre });
  };

  const setDeleteSelection = (values: DesignacionProducto) => {
    dispatch(toggleSeleccionarDesignacionProducto(values));
    onDelete(values.id);
  };

  const setToggleModalVisible = () => {
    dispatch(toggleModalVisible());
  };

  const cols = Columns({
    onUpdate: setUpdateSelection,
    onDelete: setDeleteSelection,
  });

  const onCreate = (values: DesignacionProducto) => {
    dispatch(setDesignacionProductoTemporal(values));
    customDispatch({
      asyncThuckFuction: createDesignacionProducto,
      endPoint: `${apiURL}/Create`,
      body: values,
    });
  };

  const onUpdate = (values: DesignacionProducto) => {
    dispatch(setDesignacionProductoTemporal(values));
    customDispatch({
      asyncThuckFuction: updateDesignacionProducto,
      endPoint: `${apiURL}/Update/${elementoSeleccionado?.id}`,
      body: values,
      method: 'PUT',
    });
  };

  const onDelete = (id: string) => {
    customDispatch({
      asyncThuckFuction: deleteDesignacionProducto,
      endPoint: `${apiURL}/Delete/${id}`,
      method: 'DELETE',
    });
  };

  const [onfilter, filteredData] = useFilter<DesignacionProducto>({
    data,
    filterPredicate: (item, filter) => item.nombre.toLowerCase().includes(filter.toLowerCase()),
  });

  return (
    <section className={styles.container}>
      <Toolbar setNombre={onfilter} setToggleModal={setToggleModalVisible} />
      <BaseTable
        cols={cols}
        data={filteredData}
        status={status}
        size='small'
        style={{ height: 'calc(100% - 40px)' }}
      />
      <ModalUpsert
        visible={modalVisible}
        form={form}
        status={crudStatus}
        elementoSeleccionado={elementoSeleccionado}
        onCreate={onCreate}
        onUpdate={onUpdate}
        setCerrarModal={setToggleModalVisible}
      />
    </section>
  );
};
