import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { ConfiguracionesPetch } from '../../../../models/configuracionesPetch';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const queryRutaTransito = createAsyncThunk('DucaDetalles/RutaTransito/Query', httpService);

export const queryRutaTransitoReducer = (builder: ActionReducerMapBuilder<IDucaDetallesState>) => {
  builder.addCase(
    queryRutaTransito.fulfilled,
    (state, action: PayloadAction<PagedList<ConfiguracionesPetch>>) => {
      state.RutasTransito = action.payload.items;
      state.RutasTransitoStatus = 'succeeded';
    },
  );

  builder.addCase(queryRutaTransito.pending, state => {
    state.RutasTransitoStatus = 'pending';
  });

  builder.addCase(queryRutaTransito.rejected, state => {
    state.RutasTransitoStatus = 'failed';
  });
};
