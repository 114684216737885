import { Select } from 'antd';
import { ConfiguracionesPetch } from 'models/configuracionesPetch';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useAppSelector } from 'store/hooks';

const { Option } = Select;

export const AgCuotaArancelariaCellEditor = forwardRef((props: any, ref) => {
  const { CuotasArancelarias } = useAppSelector(state => state.ducaDetalles);
  const [value, setValue] = useState<ConfiguracionesPetch | undefined>(
    CuotasArancelarias.find(c => c.descripcion === props.value),
  );

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value?.descripcion;
      },
    };
  });

  const onChange = (e: string) => {
    setValue(CuotasArancelarias.find(c => c.id === e));
  };

  return (
    <div>
      <Select
        showSearch
        optionFilterProp='children'
        defaultValue={value?.id}
        onChange={id => onChange(id)}
        style={{ width: '100%' }}
      >
        {CuotasArancelarias.map(cuotaArancelaria => (
          <Option key={cuotaArancelaria.id} value={cuotaArancelaria.id}>
            {cuotaArancelaria.descripcion}
          </Option>
        ))}
      </Select>
    </div>
  );
});
