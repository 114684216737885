import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { Proveedor } from '../../../../../models/Proveedor';
import httpService from '../../../../../services/httpService';
import { IInicioState } from '../../inicioStore';

export const getProveedoresPendientes = createAsyncThunk(
  'Inicio/GetProveedorPendiente',
  httpService,
);

export const getProveedoresPendientesReducer = (builder: ActionReducerMapBuilder<IInicioState>) => {
  // Get Proveedores Pendientes
  builder.addCase(
    getProveedoresPendientes.fulfilled,
    (state, action: PayloadAction<Array<Proveedor>>) => {
      state.proveedoresPendientes = action.payload;
      state.proveedoresPendientes = action.payload.map(item => {
        item.direcciones = item.direcciones.map(direccion => ({
          ...direccion,
          key: GenerateKey(),
        }));
        return item;
      });
      state.getProveedoresPendientesStatus = 'idle';
    },
  );
  builder.addCase(getProveedoresPendientes.pending, (state, _action) => {
    state.getProveedoresPendientesStatus = 'pending';
  });
  builder.addCase(getProveedoresPendientes.rejected, (state, _action) => {
    state.getProveedoresPendientesStatus = 'failed';
  });
};
