import { Form, FormInstance, Modal, Row, Spin } from 'antd';
import { DropDownGrid } from 'components/FormControls/CustomDropDown';
import { CustomInput } from 'components/FormControls/CustomInput';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { CustomTextarea } from 'components/FormControls/CustomTextarea';
import { InvisibleItem } from 'components/FormControls/InvisibleItem';
import { TipoISCIPC } from 'enum/TipoISCIPC';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { PartidaArancelaria } from '../../../models/PartidaArancelaria';
import {
  toggleModalVisible,
  toggleSeleccionarPartidaArancelaria,
} from '../store/partidaArancelariaStore';

interface IProps {
  form: FormInstance;
  onCreate: (values: PartidaArancelaria) => void;
  onUpdate: (values: PartidaArancelaria) => void;
}

export const ModalUpsert: FC<IProps> = ({ form, onCreate, onUpdate }) => {
  const [ocupaLitrosDeclarados, setOcupaLitrosDeclarados] = useState<boolean>(false);
  const { modalVisible, elementoSeleccionado, crudStatus } = useAppSelector(
    state => state.partidasArancelarias,
  );
  const UnidadesMedida = useAppSelector(state => state.unidadesMedidasNetas.data);
  const dispatch = useDispatch();

  // Validar si se debe de mostrar el modal de litros declarados
  useEffect(() => {
    if (elementoSeleccionado) {
      const unidadMedida = UnidadesMedida.find(
        _ => _.id === elementoSeleccionado.unidadMedidaEstadisticaId,
      );

      if (unidadMedida && unidadMedida.nombre.toLowerCase() === 'lit') {
        setOcupaLitrosDeclarados(true);
      } else {
        setOcupaLitrosDeclarados(false);
      }
    } else {
      setOcupaLitrosDeclarados(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const onCerrarModal = () => {
    dispatch(toggleModalVisible());
    dispatch(toggleSeleccionarPartidaArancelaria(undefined));
  };

  const onChangeUnidadMedida = (e: any) => {
    const unidadMedidaSeleccionada = UnidadesMedida.find(_ => _.id === e);

    if (unidadMedidaSeleccionada) {
      // Validar si la unidad de medida es Litros
      if (unidadMedidaSeleccionada.nombre.toLowerCase() === 'lit') {
        setOcupaLitrosDeclarados(true);
      } else {
        setOcupaLitrosDeclarados(false);
      }

      form.setFieldsValue({
        unidadMedidaEstadistica: `${unidadMedidaSeleccionada?.nombre} - ${unidadMedidaSeleccionada?.descripcion}`,
      });
    } else {
      form.setFieldsValue({ unidadMedidaEstadistica: '' });
    }
  };

  return (
    <Modal
      title={elementoSeleccionado ? 'Editar Partida Arancelaria' : 'Crear Partida Arancelaria'}
      visible={modalVisible}
      onOk={
        elementoSeleccionado
          ? () => {
              form
                .validateFields()
                .then(values => onUpdate(values as PartidaArancelaria))
                .catch(error => console.log(error));
            }
          : () => {
              form
                .validateFields()
                .then(values => onCreate(values as PartidaArancelaria))
                .catch(error => console.log(error));
            }
      }
      onCancel={() => {
        form.resetFields();
        onCerrarModal();
      }}
    >
      <Spin spinning={crudStatus === 'pending' ? true : false}>
        <Form
          form={form}
          name='PartidaArancelaria'
          layout='vertical'
          defaultValue={elementoSeleccionado as any}
        >
          <CustomInput
            name='nombre'
            label='Nombre'
            required
            type='text'
            maxLength={12}
            layout={{ span: 24 }}
          />
          <CustomTextarea
            name='descripcion'
            label='Descripción'
            required
            rules={[{ message: 'Por favor ingresa una descripción para la partida arancelaria.' }]}
            layout={{ span: 24 }}
          />

          <Row gutter={[24, 24]}>
            <CustomInputNumber
              name='dai'
              label='DAI'
              layout={{ span: 8 }}
              style={{ width: '100%' }}
              ruler={{ required: false }}
            />
            <CustomInputNumber
              name='isv'
              label='ISV'
              layout={{ span: 8 }}
              style={{ width: '100%' }}
              ruler={{ required: false }}
            />
            <CustomInputNumber
              name='isc'
              label='ISC'
              layout={{ span: 8 }}
              ruler={{ required: false }}
            />
          </Row>
          <Row gutter={[24, 24]}>
            <CustomInputNumber
              name='ipc'
              label='IPC'
              layout={{ span: 12 }}
              ruler={{ required: false }}
            />
            <DropDownGrid
              name='tipoIPC'
              label='Tipo de IPC'
              data={[
                { id: TipoISCIPC.Porcentaje, value: TipoISCIPC.Porcentaje, nombre: 'Porcentaje' },
                {
                  id: TipoISCIPC.Factor,
                  value: TipoISCIPC.Factor,
                  nombre: 'Centavos x Unidad Comercial',
                },
              ]}
              initialValue={TipoISCIPC.Porcentaje}
              required={false}
              onChange={e => onChangeUnidadMedida(e)}
              layout={{ span: 12 }}
            />
          </Row>
          <InvisibleItem name='unidadMedidaEstadistica' />
          <Row gutter={[24, 24]}>
            {!ocupaLitrosDeclarados ? (
              <DropDownGrid
                name='unidadMedidaEstadisticaId'
                label='Unidad Medida Estadistica'
                data={UnidadesMedida}
                required={false}
                onChange={e => onChangeUnidadMedida(e)}
                renderText={item => `${item.nombre} - ${item.descripcion}`}
                layout={{ span: 24 }}
              />
            ) : (
              <>
                <DropDownGrid
                  name='unidadMedidaEstadisticaId'
                  label='Unidad Medida Estadistica'
                  data={UnidadesMedida}
                  required={false}
                  onChange={e => onChangeUnidadMedida(e)}
                  renderText={item => `${item.nombre} - ${item.descripcion}`}
                  layout={{ span: 12 }}
                />
                <DropDownGrid
                  name='litrosDeclarados'
                  label='Ocupa litros declarados?'
                  data={[
                    { id: true, value: true, nombre: 'Si' },
                    { id: false, value: false, nombre: 'No' },
                  ]}
                  onChange={e => onChangeUnidadMedida(e)}
                  layout={{ span: 12 }}
                  rules={[
                    {
                      message: 'Por favor selecciona si ocupa los litros declarados',
                      required: true,
                    },
                  ]}
                />
              </>
            )}
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
