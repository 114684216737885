import { Form, FormInstance, Input, Modal, Spin } from 'antd';
import { FC } from 'react';
import { Usuario } from '../../../models/Usuario';

const { Item } = Form;

interface IProps {
  visible: boolean;
  form: FormInstance;
  status: StatusApp.Status;
  elementoSeleccionado: Usuario | undefined;
  onCreate: (values: Usuario) => void;
  onUpdate: (values: Usuario) => void;
  setCerrarModal: () => void;
}

export const ModalUpsert: FC<IProps> = ({
  visible,
  form,
  status,
  elementoSeleccionado,
  onCreate,
  onUpdate,
  setCerrarModal,
}) => {
  return (
    <Modal
      title={elementoSeleccionado ? 'Editar Usuario' : 'Crear Usuario'}
      visible={visible}
      onOk={
        elementoSeleccionado
          ? () => {
              form
                .validateFields()
                .then(values => onUpdate(values as Usuario))
                .catch(error => console.log(error));
            }
          : () => {
              form
                .validateFields()
                .then(values => onCreate(values as Usuario))
                .catch(error => console.log(error));
            }
      }
      onCancel={() => {
        form.resetFields();
        setCerrarModal();
      }}
    >
      <Spin spinning={status === 'pending' ? true : false}>
        <Form
          form={form}
          name='Usuario'
          layout='vertical'
          defaultValue={elementoSeleccionado as any}
        >
          <Item
            name='nombreDisplay'
            label='Nombre'
            rules={[
              {
                required: true,
                message: 'Por favor ingresa un nombre para el usuario.',
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name='email'
            label='Email'
            rules={[
              {
                required: true,
                message: 'Por favor ingresa un email para el usuario.',
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name='oAuthId'
            label='Auth0 Id'
            rules={[
              {
                required: true,
                message: 'Por favor ingresa un Id de Auth0 para el usuario.',
              },
            ]}
          >
            <Input />
          </Item>
        </Form>
      </Spin>
    </Modal>
  );
};
