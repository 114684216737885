import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IDesignacionesProductosState } from '../designacionesProductosStore';

export const deleteDesignacionProducto = createAsyncThunk(
  'DesignacionesProductos/Delete',
  httpService,
);

export const deleteDesignacionProductoReducer = (
  builder: ActionReducerMapBuilder<IDesignacionesProductosState>,
) => {
  builder.addCase(deleteDesignacionProducto.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.data.findIndex(
      designacionProducto => designacionProducto.id === state.elementoSeleccionado?.id,
    );
    state.data.splice(index, 1);
  });
  builder.addCase(deleteDesignacionProducto.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deleteDesignacionProducto.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
