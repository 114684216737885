import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { EstadoMercancia } from '../../../../models/EstadoMercancia';
import httpService from '../../../../services/httpService';
import { IEstadosMercanciasState } from '../estadosMercanciasStore';

export const createEstadoMercancia = createAsyncThunk('EstadosMercancias/Create', httpService);

export const createEstadoMercanciaReducer = (
  builder: ActionReducerMapBuilder<IEstadosMercanciasState>,
) => {
  builder.addCase(createEstadoMercancia.fulfilled, (state, action) => {
    state.crudStatus = 'succeeded';
    state.data.unshift({
      ...state.elementoTemporal,
      id: action.payload,
    } as EstadoMercancia);
  });
  builder.addCase(createEstadoMercancia.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createEstadoMercancia.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
