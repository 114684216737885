import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UploadFile } from 'antd/lib/upload/interface';
import { Deva, IDevaDetallesRequest, IDevaRequest, IDevaRequestUpdate } from 'models/Deva';
import { Proveedor } from 'models/Proveedor';
import { RootState } from 'store/store';
import { EstadoEntidad } from '../../../enum/EstadoEntidad';
import { Producto } from '../../../models/Producto';
import { agregarProductosReducer } from './extraReducer/agregarProductos';
import { bulkImportProductosReducer } from './extraReducer/bulkImportProductos';
import { crearProductosPendientesReducer } from './extraReducer/crearProductosPendientes';
import { createDevaReducer } from './extraReducer/createDeva';
import { deleteDevaReducer } from './extraReducer/deleteDeva';
import { prorratearValoresTotalesReducer } from './extraReducer/prorratearValoresTotales';
import { validarProductosReducer } from './extraReducer/validarProductos';
import { validarProductoReducer } from './extraReducer/validarProducto';
import { validarProductosSinArchivoReducer } from './extraReducer/validarProductosSinArchivo';

export interface ProductoValidado {
  completo: boolean;
  estado: EstadoEntidad;
  product: Producto;
  coincidencias: Array<Producto>;
}

export interface IDevasState {
  ordenDeTrabajoInCreation: boolean;
  validationDevaMessage: string;
  devaInCreation: IDevaRequest | undefined;
  productosValidados: Array<ProductoValidado>;
  productosValidadosStatus: StatusApp.Status;
  productoPendienteSeleccionado: Producto | undefined;
  productosPendientesCreateStatus: StatusApp.Status;
  createDevaStatus: StatusApp.Status;
  deleteDevaStatus: StatusApp.Status;
  proveedor: Proveedor;
  devasDetalles: Deva[];
  devaInUpdate: IDevaRequestUpdate | undefined;
  drawerValidarProductosVisible: boolean;
  createDevaWithModelFile: boolean;
  devasOfOrdenDeTrabajo: Deva[];
  valoresTotalesDetalles?: { flete: number; seguro: number; otrosGastos: number };
  valoresTotalesOrdenDeTrabajo?: { flete: number; seguro: number; otrosGastos: number };
  prorratearValoresTotalesStatus: StatusApp.Status;
  agregarProductoDrawerIsVisible: boolean;
  validarAgregarProductoStatus: StatusApp.Status;

  // BulkImport
  bulkImportStatus: StatusApp.Status;
  bulkImportVisible: boolean;
  archivoBulkImport: UploadFile<any>[] | undefined;
  bulkImportFeedback: string[] | undefined;
  bulkImportCreatedProducts: number | undefined;
}

const initialState: IDevasState = {
  ordenDeTrabajoInCreation: false,
  validationDevaMessage: '',
  devaInCreation: undefined,
  productosValidados: [],
  productosValidadosStatus: 'idle',
  productoPendienteSeleccionado: undefined,
  productosPendientesCreateStatus: 'idle',
  createDevaStatus: 'idle',
  deleteDevaStatus: 'idle',
  proveedor: {} as Proveedor,
  devasDetalles: [],
  devaInUpdate: undefined,
  drawerValidarProductosVisible: false,
  createDevaWithModelFile: false,
  devasOfOrdenDeTrabajo: [],
  prorratearValoresTotalesStatus: 'idle',
  agregarProductoDrawerIsVisible: false,
  validarAgregarProductoStatus: 'idle',

  // BulkImport
  bulkImportStatus: 'idle',
  bulkImportVisible: false,
  archivoBulkImport: undefined,
  bulkImportFeedback: undefined,
  bulkImportCreatedProducts: undefined,
};

export const devasSlice = createSlice({
  name: 'Devas',
  initialState,
  reducers: {
    setOrdenDeTrabajoInCreation: (state, action: PayloadAction<boolean>) => {
      state.ordenDeTrabajoInCreation = action.payload;
    },
    setProductoCoincidenciaSeleccionada: (
      state,
      action: PayloadAction<{ producto: Producto; coincidencia: Producto }>,
    ) => {
      const payloadProducto = action.payload.producto;
      const payloadCoincidencia = action.payload.coincidencia;

      if (state.devaInCreation) {
        state.devaInCreation.productos = state.devaInCreation.productos.map(p => {
          if (p.modelo === payloadProducto.sku) {
            return { ...p, modelo: payloadCoincidencia.sku, productoId: payloadCoincidencia.id };
          }
          return p;
        });
      }

      if (state.devaInUpdate) {
        state.devaInUpdate.productos = state.devaInUpdate.productos.map(p => {
          if (p.modelo === payloadProducto.sku) {
            return { ...p, modelo: payloadCoincidencia.sku, productoId: payloadCoincidencia.id };
          }
          return p;
        });
      }

      state.productosValidados = state.productosValidados.map(p => {
        if (p.product.sku === payloadProducto.sku) {
          return {
            completo: true,
            estado: payloadCoincidencia.estado,
            product: {
              id: payloadCoincidencia.id,
              sku: payloadCoincidencia.sku,
              proveedor: payloadCoincidencia.proveedor,
            },
            coincidencias: [] as Producto[],
          } as ProductoValidado;
        }
        return p;
      });
    },
    setProductoInformacionCompleta: (state, action: PayloadAction<Producto>) => {
      const payload = action.payload;
      const productosfiltrados = state.productosValidados
        .filter(p => p.product.sku === payload.sku)
        .map(
          _p =>
            ({
              completo: true,
              estado: EstadoEntidad.NoCreado,
              product: { ...payload, proveedor: payload.proveedorId },
            } as ProductoValidado),
        );

      state.productosValidados = [
        ...productosfiltrados,
        ...state.productosValidados.filter(p => p.product.sku !== payload.sku),
      ];
    },
    setProductosPendientesStatus: (state, action: PayloadAction<StatusApp.Status>) => {
      state.productosPendientesCreateStatus = action.payload;
    },
    reset: state => {
      state.validationDevaMessage = '';
      state.devaInCreation = undefined;
      state.productosValidados = [];
      state.productosValidadosStatus = 'idle';
      state.productoPendienteSeleccionado = undefined;
      state.productosPendientesCreateStatus = 'idle';
      state.createDevaStatus = 'idle';
      state.proveedor = {} as Proveedor;
      state.devaInUpdate = undefined;
      state.drawerValidarProductosVisible = false;

      // BulkImport
      state.bulkImportStatus = 'idle';
      state.bulkImportVisible = false;
      state.archivoBulkImport = undefined;
      state.bulkImportFeedback = undefined;
      state.bulkImportCreatedProducts = undefined;
    },
    hardReset: state => {
      state.validationDevaMessage = '';
      state.devaInCreation = undefined;
      state.productosValidados = [];
      state.productosValidadosStatus = 'idle';
      state.productoPendienteSeleccionado = undefined;
      state.productosPendientesCreateStatus = 'idle';
      state.createDevaStatus = 'idle';
      state.proveedor = {} as Proveedor;
      state.devasDetalles = [];
      state.devaInUpdate = undefined;

      // BulkImport
      state.bulkImportStatus = 'idle';
      state.bulkImportVisible = false;
      state.archivoBulkImport = undefined;
      state.bulkImportFeedback = undefined;
      state.bulkImportCreatedProducts = undefined;
    },
    setProductoPendienteSeleccionado: (state, action: PayloadAction<Producto | undefined>) => {
      state.productoPendienteSeleccionado = action.payload;
    },
    setValidationDevaMessage: (state, action: PayloadAction<string>) => {
      state.validationDevaMessage = action.payload;
    },
    setProveedor: (state, action: PayloadAction<Proveedor>) => {
      state.proveedor = action.payload;
    },
    setDevaInCreation: (state, action: PayloadAction<IDevaRequest>) => {
      state.devaInCreation = {
        ...action.payload,
        productos: action.payload.productos,
      };
    },
    setDevaInUpdate: (state, action: PayloadAction<IDevaRequestUpdate | undefined>) => {
      const payload = action.payload;
      state.devaInUpdate = payload
        ? {
            ...payload,
            productos: payload?.productos ?? [],
          }
        : undefined;
    },
    setDevas: (state, action: PayloadAction<Deva[]>) => {
      if (state.ordenDeTrabajoInCreation) state.devasOfOrdenDeTrabajo = action.payload;
      else state.devasDetalles = action.payload;
    },
    setDevaTotal: (
      state,
      action: PayloadAction<{
        deva: IDevaDetallesRequest;
        id: string;
      }>,
    ) => {
      if (state.ordenDeTrabajoInCreation) {
        state.devasOfOrdenDeTrabajo = state.devasOfOrdenDeTrabajo.map((deva: any) => {
          if (deva.id === action.payload.id) {
            return {
              ...deva,
              flete: action.payload.deva.importeTransporte,
              seguro: action.payload.deva.importeSeguro,
              otrosGastos: action.payload.deva.importeCargaManipulacion,
              valorCIF: action.payload.deva.valorAduana,
            };
          }
          return deva;
        });
      } else {
        state.devasDetalles = state.devasDetalles.map((deva: any) => {
          if (deva.id === action.payload.id) {
            return {
              ...deva,
              flete: action.payload.deva.importeTransporte,
              seguro: action.payload.deva.importeSeguro,
              otrosGastos: action.payload.deva.importeCargaManipulacion,
              valorCIF: action.payload.deva.valorAduana,
            };
          }
          return deva;
        });
      }
    },
    setDrawerValidarProductosVisible: (
      state,
      action: PayloadAction<{
        drawerValidarProductosVisible: boolean;
        createDevaWithModelFile: boolean;
      }>,
    ) => {
      state.drawerValidarProductosVisible = action.payload.drawerValidarProductosVisible;
      state.createDevaWithModelFile = action.payload.createDevaWithModelFile;
    },
    setIncotermToDeva: (state, action: PayloadAction<{ devaId: string; incotermId: string }>) => {
      if (state.ordenDeTrabajoInCreation) {
        state.devasOfOrdenDeTrabajo = state.devasOfOrdenDeTrabajo.map((deva: any) => {
          if (deva.id === action.payload.devaId) {
            return {
              ...deva,
              incotermId: action.payload.incotermId,
            };
          }
          return deva;
        });
      } else {
        state.devasDetalles = state.devasDetalles.map((deva: any) => {
          if (deva.id === action.payload.devaId) {
            return {
              ...deva,
              incotermId: action.payload.incotermId,
            };
          }
          return deva;
        });
      }
    },
    setValoresTotales: (
      state,
      action: PayloadAction<{ flete: number; seguro: number; otrosGastos: number }>,
    ) => {
      if (state.ordenDeTrabajoInCreation) {
        state.valoresTotalesOrdenDeTrabajo = action.payload;
      } else {
        state.valoresTotalesDetalles = action.payload;
      }
    },
    setDrawerAgregarProductoVisible: (state, action: PayloadAction<boolean>) => {
      state.agregarProductoDrawerIsVisible = action.payload;
    },

    // BulkImport
    toggleBulkImportVisible: state => {
      state.bulkImportVisible = !state.bulkImportVisible;
    },
    setArchivoBulkImport: (state, action: PayloadAction<UploadFile<any>[] | undefined>) => {
      state.archivoBulkImport = action.payload;
    },
    setBulkImportFeedback: (state, action: PayloadAction<string[] | undefined>) => {
      state.bulkImportFeedback = action.payload;
    },
    setBulkImportStatusIdle: state => {
      state.bulkImportStatus = 'idle';
      state.archivoBulkImport = undefined;
    },
  },
  extraReducers: builder => {
    validarProductosReducer(builder);
    validarProductoReducer(builder);
    validarProductosSinArchivoReducer(builder);
    crearProductosPendientesReducer(builder);
    createDevaReducer(builder);
    deleteDevaReducer(builder);
    agregarProductosReducer(builder);
    prorratearValoresTotalesReducer(builder);

    // BulkImport
    bulkImportProductosReducer(builder);
  },
});

export const {
  setOrdenDeTrabajoInCreation,
  setProductoCoincidenciaSeleccionada,
  setProductoInformacionCompleta,
  setProductosPendientesStatus,
  reset,
  hardReset,
  setProductoPendienteSeleccionado,
  setValidationDevaMessage,
  setProveedor,
  setDevaInCreation,
  setDevas,
  setDevaTotal,
  setDevaInUpdate,
  setDrawerValidarProductosVisible,
  setIncotermToDeva,
  setValoresTotales,
  setDrawerAgregarProductoVisible,

  // BulkImport
  toggleBulkImportVisible,
  setArchivoBulkImport,
  setBulkImportFeedback,
  setBulkImportStatusIdle,
} = devasSlice.actions;

export const DevasState = (state: RootState) => state.devas;

export const DevasReducer = devasSlice.reducer;
