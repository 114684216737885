import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Importador } from '../../../models/Importador';
import { createImportadorReducer } from './extraReducers/create';
import { deleteImportadorReducer } from './extraReducers/delete';
import { getImportadoresReducer } from './extraReducers/get';
import { updateImportadorReducer } from './extraReducers/update';

export interface IImportadoresState {
  status: StatusApp.Status;
  data: Importador[];
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado?: Importador;
  elementoTemporal?: Importador;
  error?: string;
}

const initialState: IImportadoresState = {
  status: 'idle',
  data: [],
  crudStatus: 'idle',
  modalVisible: false,
};

export const ImportadoresSlice = createSlice({
  name: 'importadores',
  initialState,
  reducers: {
    setSelectedImportador: (state, action: PayloadAction<Importador | undefined>) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setImportadorTemporal: (state, action: PayloadAction<Importador>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
    resetError: state => {
      state.error = undefined;
    },
  },
  extraReducers: builder => {
    getImportadoresReducer(builder);
    createImportadorReducer(builder);
    updateImportadorReducer(builder);
    deleteImportadorReducer(builder);
  },
});

export const {
  setCrudStatusIdle,
  setImportadorTemporal,
  toggleModalVisible,
  setSelectedImportador,
} = ImportadoresSlice.actions;

export const importadoresReducer = ImportadoresSlice.reducer;
