import {
  ApartmentOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BookOutlined,
  BoxPlotOutlined,
  ContainerOutlined,
  DeploymentUnitOutlined,
  GiftOutlined,
  GlobalOutlined,
  GoldOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RobotOutlined,
  RocketOutlined,
  SettingOutlined,
  ShoppingOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Grid, Layout, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAuth0UserData } from '../../hooks/useAuth0UserData';
import styles from './Sidebar.module.less';
import { selectCurrentCollapsedMenu, setCollapsedMenu, setTitleName } from './store/sidebarStore';

const { Sider } = Layout;
const { useBreakpoint } = Grid;
const { SubMenu } = Menu;

const Sidebar = () => {
  const user = useAuth0UserData();
  const dispatch = useDispatch();
  const breakPoint = useBreakpoint();
  const collapsed = useSelector(selectCurrentCollapsedMenu);

  const onCollapse = () => {
    dispatch(setCollapsedMenu(!collapsed));
  };

  const setViewTitle = (title: string) => dispatch(setTitleName(title));

  return (
    <Sider
      collapsed={collapsed}
      collapsedWidth={breakPoint.md ? 60 : 0}
      className={`${styles.sidebarContainer} ${
        !breakPoint.md ? styles.sidebarContainerMobile : ''
      }`}
      width={200}
    >
      <Menu defaultOpenKeys={['home']} mode='inline' theme='dark'>
        <Menu.Item key='home' icon={<AppstoreOutlined />} onClick={() => setViewTitle('Inicio')}>
          <Link to='/'>Inicio</Link>
        </Menu.Item>
        <Menu.Item
          key='ordenDeTrabajo'
          icon={<ContainerOutlined />}
          onClick={() => setViewTitle('Orden De Trabajo')}
        >
          <Link to='/ordenDeTrabajo'>Iniciar Orden de Trabajo</Link>
        </Menu.Item>

        <SubMenu key='settings-submenu' icon={<SettingOutlined />} title='Configuraciones'>
          <Menu.Item key='productos' icon={<GiftOutlined />}>
            <Link to='/productos'>Productos</Link>
          </Menu.Item>
          {user?.isAdmin && (
            <>
              <Menu.Item key='importadores' icon={<TeamOutlined />}>
                <Link to='/importadores'>Importadores</Link>
              </Menu.Item>
              <Menu.Item key='proveedores' icon={<RocketOutlined />}>
                <Link to='/proveedores'>Proveedores</Link>
              </Menu.Item>
              <Menu.Item key='partidasArancelarias' icon={<BookOutlined />}>
                <Link to='/partidasarancelarias'>Partidas Arancelarias</Link>
              </Menu.Item>
              <Menu.Item key='marcasComerciales' icon={<RobotOutlined />}>
                <Link to='/marcascomerciales'>Marcas Comerciales</Link>
              </Menu.Item>
              <Menu.Item key='paises' icon={<GlobalOutlined />}>
                <Link to='/paises'>Países</Link>
              </Menu.Item>
              <Menu.Item key='tratados' icon={<AuditOutlined />}>
                <Link to='/tratados'>Tratados</Link>
              </Menu.Item>
              <Menu.Item key='unidadesMedidasnetas' icon={<GoldOutlined />}>
                <Link to='/unidadesmedidasnetas'>Unidades de Medidas Netas</Link>
              </Menu.Item>
              <Menu.Item key='conversionesunidades' icon={<BoxPlotOutlined />}>
                <Link to='/conversionesunidades'>Conversión de Unidades de Medidas</Link>
              </Menu.Item>
              <Menu.Item key='estadosMercancias' icon={<ShoppingOutlined />}>
                <Link to='/estadosmercancias'>Estados de Mercancías</Link>
              </Menu.Item>
              <Menu.Item key='clasificacionesFenag' icon={<ApartmentOutlined />}>
                <Link to='/clasificacionesfenag'>Clasificaciones Fenag</Link>
              </Menu.Item>
              <Menu.Item key='designacionesProductos' icon={<DeploymentUnitOutlined />}>
                <Link to='/designacionesproductos'>Designaciones de Productos</Link>
              </Menu.Item>
              <Menu.Item key='usuarios' icon={<UserOutlined />}>
                <Link to='/usuarios'>Usuarios</Link>
              </Menu.Item>
            </>
          )}
        </SubMenu>
      </Menu>
      <div className={styles.iconButtonCollapse} onClick={onCollapse}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
    </Sider>
  );
};

export default Sidebar;
