import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Pais } from 'models/Pais';
import httpService from 'services/httpService';
import { IPaisesState } from '../paisesStore';

export const createPais = createAsyncThunk('Paises/Create', httpService);

export const createPaisReducer = (builder: ActionReducerMapBuilder<IPaisesState>) => {
  // Create
  builder.addCase(createPais.fulfilled, (state, action) => {
    const temporalElement = state.elementoTemporal;
    state.crudStatus = 'succeeded';
    state.data.unshift({
      ...temporalElement,
      combinado: String(temporalElement?.abreviatura) + ' - ' + String(temporalElement?.nombre),
      id: action.payload,
    } as Pais);
  });
  builder.addCase(createPais.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createPais.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
