import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IImportadoresState } from '../store';

export const createImportador = createAsyncThunk('create/Importador', httpService);

export const createImportadorReducer = (builder: ActionReducerMapBuilder<IImportadoresState>) => {
  builder.addCase(createImportador.fulfilled, (state, action: PayloadAction<string>) => {
    state.modalVisible = false;

    if (!state.elementoTemporal) {
      state.status = 'failed';
      return;
    }

    state.data.unshift({ ...state.elementoTemporal, id: action.payload });
    state.status = 'succeeded';
  });

  builder.addCase(createImportador.pending, state => {
    state.status = 'pending';
  });

  builder.addCase(createImportador.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
  });
};
