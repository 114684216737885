import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService, { IUseFetch } from '../../../../services/httpService';
import { IImportadoresState } from '../store';

export const deleteImportador = createAsyncThunk(
  'delete/Importador',
  async (props: IUseFetch, ThunkApi) => {
    await httpService(props, ThunkApi);
    return props.body;
  },
);

export const deleteImportadorReducer = (builder: ActionReducerMapBuilder<IImportadoresState>) => {
  builder.addCase(deleteImportador.fulfilled, state => {
    state.modalVisible = false;

    if (!state.elementoTemporal) {
      state.status = 'failed';
      return;
    }
    const id = state.elementoTemporal?.id;
    const index = state.data.findIndex(imp => imp.id === id);
    state.data.splice(index, 1);
    state.status = 'succeeded';
  });

  builder.addCase(deleteImportador.pending, state => {
    state.status = 'pending';
  });

  builder.addCase(deleteImportador.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
  });
};
