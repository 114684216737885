import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { GenerateKey } from 'features/DucaDetalles/utils/generateKey';
import { IDucaItemRequest } from 'models/Duca';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

export const importarItems = createAsyncThunk('DucaDetalles/ImportarItems', httpService);

export const importarItemsReducer = (builder: ActionReducerMapBuilder<IDucaDetallesState>) => {
  builder.addCase(importarItems.fulfilled, (state, action: PayloadAction<IDucaItemRequest[]>) => {
    const oldItems = state.items
      .filter(x => x.id !== null)
      .map(x => ({
        ...x,
        estado: EstadoDucaItem.Delete,
        documentos: x.documentos.map(documento => ({ ...documento, id: documento.id })),
        ventajas: x.ventajas.map(ventaja => ({ ...ventaja, id: ventaja.id })),
        datosComplementarios: x.datosComplementarios.map(datoComplementario => ({
          ...datoComplementario,
          id: datoComplementario.id,
        })),
      }));
    const newItems = action.payload.map(item => ({
      ...item,
      key: GenerateKey(),
      documentos: [],
      ventajas: item.ventajas.map(x => ({ ...x, key: GenerateKey() })),
      datosComplementarios: [],
    }));
    state.items = [...newItems, ...oldItems];
    state.itemStatus = 'succeeded';
    state.modalVisibleAsignarVentaja = true;
  });
  builder.addCase(importarItems.pending, (state, _action) => {
    state.itemStatus = 'pending';
  });
  builder.addCase(importarItems.rejected, (state, _action) => {
    state.itemStatus = 'failed';
  });
};
