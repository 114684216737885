import { Producto } from 'models/Producto';
import XLSX from 'xlsx';

export const generarExcelProductosNoCreados = (productos: Producto[]) => {
  // Creamos un libro en blanco
  const wb = XLSX.utils.book_new();

  // Creamos data valida para insertar en una hoja
  const ws = XLSX.utils.aoa_to_sheet([
    [
      'Modelo',
      'Proveedor',
      'Numero de Partida',
      'Marca Comercial',
      'Designacion de Producto',
      'Caracteristicas',
      'Caracteristica Principal',
      'Caracteristica Secundaria',
      'Pais de Origen',
      'Origen confirmado',
      'Unidad de Medida',
      'Estado de Mercancia',
      'UPC',
      'Codigo Fitosanitario',
    ],
    ...productos.map(p => [p.sku, p.proveedor, p.partidaArancelaria]),
  ]);

  // Declaramos la hoja e insertamos la data a la misma
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Definir el ancho de las columnas
  wb.Sheets.Sheet1['!cols'] = [
    { wpx: 150 },
    { wpx: 200 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 140 },
    { wpx: 90 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 115 },
    { wpx: 25 },
    { wpx: 110 },
  ];

  // Generar el archivo excel
  XLSX.writeFile(wb, 'BulkImport_Productos.xlsx', { compression: true, bookSST: true });
};
