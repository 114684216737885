import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { PartidaArancelaria } from 'models/PartidaArancelaria';
import httpService from 'services/httpService';
import { IPartidasArancelariasState } from '../partidaArancelariaStore';

export const createPartidaArancelaria = createAsyncThunk(
  'PartidasArancelarias/Create',
  httpService,
);

export const createPartidaArancelariaReducer = (
  builder: ActionReducerMapBuilder<IPartidasArancelariasState>,
) => {
  // Create
  builder.addCase(createPartidaArancelaria.fulfilled, (state, action) => {
    state.crudStatus = 'succeeded';
    state.pagedData.items.unshift({
      ...state.elementoTemporal,
      id: action.payload,
    } as PartidaArancelaria);
  });
  builder.addCase(createPartidaArancelaria.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createPartidaArancelaria.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
