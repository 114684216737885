import { Modal } from 'antd';
import { BaseTable } from 'components/BaseTable/BaseTable';
import { getDevaJson } from 'features/OrdenDeTrabajoTable/store/extraReducers/downloadDevasJson';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { Deva, IDevaRequestUpdate } from 'models/Deva';
import { Proveedor } from 'models/Proveedor';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { ModalAgregarProductos } from '../ModalAgregarProductos/ModalAgregarProductos';
import { deleteDeva } from '../store/extraReducer/deleteDeva';
import { setDevaInUpdate, setProveedor } from '../store/store';
import { cols } from './cols';

interface IDevaTable {
  creando: boolean;
  ordenTrabajoId: string;
  cerrada: boolean;
}

export const DevaTable = ({ creando, ordenTrabajoId, cerrada = false }: IDevaTable) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const { ordenDeTrabajoInCreation, devasDetalles, devasOfOrdenDeTrabajo, deleteDevaStatus } =
    useAppSelector(state => state.devas);

  const onSeeDetails = (selected: Deva) => {
    push({
      pathname: `/ordenDeTrabajo/${ordenTrabajoId}/detallesdeva/${selected.id}/${selected.proveedor}`,
      state: { creando },
    });
  };

  const downloadDevas = (deva: Deva) => {
    if (devasDetalles.length === 0) {
      customDispatch({
        asyncThuckFuction: getDevaJson,
        endPoint: `/api/Devas/ExportToJson/${deva.id}`,
      });
    } else {
      const incotermsCounts: any = {};
      devasDetalles
        .filter(d => d.incotermId !== null)
        .map(d => d.incotermId)
        .forEach(x => {
          incotermsCounts[x ?? ''] = (incotermsCounts[x ?? ''] || 0) + 1;
        });
      const incoterm = Object.keys(incotermsCounts)
        .map(key => ({ id: key, cantidad: incotermsCounts[key] }))
        .sort((a, b) => b.cantidad - a.cantidad)[0];

      if (incoterm?.id !== deva?.incotermId) {
        Modal.confirm({
          title: 'Deva con un incoterm distinto',
          content: '¿Desea descargar la Deva de todos maneras?',
          okText: 'Descargar',
          onOk() {
            customDispatch({
              asyncThuckFuction: getDevaJson,
              endPoint: `/api/Devas/ExportToJson/${deva.id}`,
            });
          },
        });
      } else {
        customDispatch({
          asyncThuckFuction: getDevaJson,
          endPoint: `/api/Devas/ExportToJson/${deva.id}`,
        });
      }
    }
  };

  const onDelete = (devaId: string) => {
    customDispatch({
      asyncThuckFuction: deleteDeva,
      endPoint: `/api/Devas/Delete/${devaId}`,
      method: 'DELETE',
    });
  };

  const onModalAgrearProductosClose = () => {
    dispatch(setDevaInUpdate(undefined));
  };

  const { Modal: ModalAgregarProducto, onShow: setVisibleModalAgregarProducto } =
    ModalAgregarProductos({ onClose: onModalAgrearProductosClose });

  const onAgregarProductos = (devaId: string, proveedor: string, proveedorId: string) => {
    dispatch(setDevaInUpdate({ devaId, proveedor } as IDevaRequestUpdate));
    dispatch(setProveedor({ id: proveedorId, nombre: proveedor } as Proveedor));
    setVisibleModalAgregarProducto();
  };

  const columns = cols({
    onDetails: onSeeDetails,
    onDownload: downloadDevas,
    onDelete,
    onAgregarProductos,
    cerrada,
  });

  const devas = ordenDeTrabajoInCreation ? devasOfOrdenDeTrabajo : devasDetalles;

  return (
    <div>
      <BaseTable
        cols={columns}
        data={devas}
        status={deleteDevaStatus === 'pending' ? 'pending' : 'succeeded'}
        size='small'
        style={{ marginTop: 0 }}
        pagination={{ style: { marginBottom: 0 } }}
      />
      {ModalAgregarProducto}
    </div>
  );
};
