import { notification, Typography } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface INotification {
  error: boolean;
  message: string;
  duration?: number;
  onClose: () => void;
}

export const CustomNotification = ({ error, message, duration, onClose }: INotification) => {
  if (message.length === 0) return;

  const props: ArgsProps = {
    message: '',
    description: (
      <Text style={{ color: '#fff' }}>{message || 'Ocurrió un error al realizar la acción'}</Text>
    ),
    onClose,
    className: error ? 'error' : 'success',
    style: {
      borderRadius: 8,
      padding: 16,
      backgroundColor: error ? '#FA541C' : '#73D13D',
      color: '#fff',
    },
    duration: duration ?? 2,
    icon: error ? (
      <CloseCircleOutlined style={{ color: '#fff', position: 'relative', top: '-3px' }} />
    ) : (
      <CheckCircleOutlined style={{ color: '#fff', position: 'relative', top: '-3px' }} />
    ),
  };

  error ? notification.error(props) : notification.success(props);
};
