import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Tratado } from 'models/Tratado';
import httpService from 'services/httpService';
import { ITratadosState } from '../tratadosStore';

export const createTratado = createAsyncThunk('Tratados/Create', httpService);

export const createTratadoReducer = (builder: ActionReducerMapBuilder<ITratadosState>) => {
  builder.addCase(createTratado.fulfilled, (state, action) => {
    state.crudStatus = 'succeeded';
    state.data.unshift({
      ...state.elementoTemporal,
      id: action.payload,
    } as Tratado);
  });
  builder.addCase(createTratado.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(createTratado.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
