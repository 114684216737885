import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnidadMedidaNeta } from '../../../models/UnidadMedidaNeta';
import { RootState } from '../../../store/store';
import { createUnidadMedidaNetaReducer } from './extraReducer/createUnidades';
import { deleteUnidadMedidaNetaReducer } from './extraReducer/deleteUnidades';
import { getUnidadesMedidasNetasReducer } from './extraReducer/getUnidades';
import { updateUnidadMedidaNetaReducer } from './extraReducer/updateUnidades';

export interface IUnidadesMedidasNetasState {
  data: Array<UnidadMedidaNeta>;
  status: StatusApp.Status;
  crudStatus: StatusApp.Status;
  modalVisible: boolean;
  elementoSeleccionado: UnidadMedidaNeta | undefined;
  elementoTemporal: UnidadMedidaNeta | undefined;
  apiURL: string;
}

const initialState: IUnidadesMedidasNetasState = {
  data: [],
  status: 'idle',
  crudStatus: 'idle',
  modalVisible: false,
  elementoSeleccionado: undefined,
  elementoTemporal: undefined,
  apiURL: '/api/UnidadesMedidasNetas',
};

export const unidadesMedidasNetasSlice = createSlice({
  name: 'UnidadesMedidasNetas',
  initialState,
  reducers: {
    toggleSeleccionarUnidadMedidaNeta: (
      state,
      action: PayloadAction<UnidadMedidaNeta | undefined>,
    ) => {
      state.elementoSeleccionado = action.payload;
    },
    toggleModalVisible: state => {
      state.modalVisible = !state.modalVisible;
    },
    setUnidadMedidaNetaTemporal: (state, action: PayloadAction<UnidadMedidaNeta>) => {
      state.elementoTemporal = action.payload;
    },
    setCrudStatusIdle: state => {
      state.crudStatus = 'idle';
      state.elementoSeleccionado = undefined;
      state.elementoTemporal = undefined;
      state.modalVisible = false;
    },
  },
  extraReducers: builder => {
    createUnidadMedidaNetaReducer(builder);
    deleteUnidadMedidaNetaReducer(builder);
    getUnidadesMedidasNetasReducer(builder);
    updateUnidadMedidaNetaReducer(builder);
  },
});

export const {
  toggleSeleccionarUnidadMedidaNeta,
  toggleModalVisible,
  setUnidadMedidaNetaTemporal,
  setCrudStatusIdle,
} = unidadesMedidasNetasSlice.actions;

export const UnidadesMedidasNetasState = (state: RootState) => state.unidadesMedidasNetas;

export default unidadesMedidasNetasSlice.reducer;
