import { useAuth0 } from '@auth0/auth0-react';
import { useInitialData } from 'hooks/useInitialData';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { initialDataToLoad } from './initialDataToLoad';

import { setInitialData } from './store/initialDataStore';

export const InitialData = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const history = useHistory();

  useInitialData(initialDataToLoad);

  useEffect(() => {
    dispatch(setInitialData(true));
    getAccessTokenSilently()
      .then(_res => {
        if (isAuthenticated) {
          dispatch(setInitialData(false));
        }
      })
      .catch(_error => history.push('/logout'));
  }, [dispatch, getAccessTokenSilently, history, isAuthenticated]);

  return <Fragment />;
};
