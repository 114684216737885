import { FormInstance, Typography } from 'antd';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { EstadoOrdenTrabajo } from 'enum/EstadoOrdenTrabajo';
import { Incoterm } from 'models/Incoterm';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { Table, Trow, TrowText } from '../../components/Table';

const { Title } = Typography;

interface IDeterminacionValor {
  form: FormInstance;
  incotermSeleccionado: Incoterm | undefined;
}

export const DeterminacionValor = ({ form, incotermSeleccionado }: IDeterminacionValor) => {
  const { Configuraciones } = useAppSelector(state => state.initialData);
  const { disabled } = useAppSelector(state => ({
    disabled:
      state.devaDetalles.devaDetalles?.ordenDeTrabajo?.estado === EstadoOrdenTrabajo.Cerrada,
  }));

  useEffect(() => {
    if (incotermSeleccionado) {
      sum42_43();
      sum44_45();
      sum46();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incotermSeleccionado]);

  const onSelectChange = (targetInputName: string, values: number[]) => {
    const totalValues = values.reduce((acc, val) => Number(acc) + Number(val), 0);
    form.setFields([
      {
        name: targetInputName,
        value: Number(totalValues?.toFixed(Configuraciones?.precisionDecimal ?? 4)),
      },
    ]);
  };

  const selectInputValue = (name: string) => {
    const value = Number(form.getFieldValue(name));
    return value ? Number(value?.toFixed(Configuraciones?.precisionDecimal ?? 4)) : 0;
  };

  const sum = (targetInputName: string, inputsToSum: string[]) => {
    const values = inputsToSum.map(inputName => selectInputValue(inputName));
    onSelectChange(targetInputName, values);
  };

  const sum39_41 = () => {
    sum('importeRealPagado', ['importeFactura', 'montoPagosInd']);
    sum46();
  };

  const sum42_43 = () => {
    sum('importeAjustes', [
      'importeContraprestacion',
      'importeReversion',
      'gastosComisiones',
      'gastosEmbalajes',
      'valorMaterialesIncorporados',
      'valorHerramientasProd',
      'valorMaterialesConsumidos',
      'valorIngenieriaExt',
      'valorDerLicencia',
      'importeTransporte',
      'importeCargaManipulacion',
      'importeSeguro',
    ]);
    sum46();
  };

  const sum44_45 = () => {
    sum('totalDeducciones', [
      'importeAsistenciaTecnica',
      'importeTransportePuerto',
      'importeImpuestos',
      'importeIntereses',
      'importeOtrasDeducciones',
    ]);
    sum46();
  };

  const sum46 = () => {
    const Total41 = form.getFieldValue('importeRealPagado');
    const Total43 = form.getFieldValue('importeAjustes');
    const Total45 = form.getFieldValue('totalDeducciones');
    const valorAduana = Number(Total41) + Number(Total43) - Number(Total45);
    const precisionDecimal = Number(Configuraciones?.precisionDecimal ?? 4);

    form.setFieldsValue({ valorAduana: Number(valorAduana.toFixed(precisionDecimal)) });
  };

  const onCalcularSeguro = () => {
    const valorAduana = Number(form.getFieldValue('importeFactura'));
    const valorSeguroCalculado = valorAduana * 0.015;
    const precisionDecimal = Number(Configuraciones?.precisionDecimal ?? 4);

    form.setFieldsValue({ importeSeguro: Number(valorSeguroCalculado.toFixed(precisionDecimal)) });
    sum42_43();
  };

  return (
    <section>
      <Title style={{ color: '#2F80ED' }} level={4}>
        II. Determinación del Valor en aduana, en Pesos Centroamericanos
      </Title>
      <Table columns={['#', 'BASE DE CÁLCULO', 'VALOR EN PESO C.A.(USD)']}>
        <InputRow
          onChange={sum39_41}
          number='39'
          name='importeFactura'
          condicion='Precio según factura'
          disabled
        />
        <InputRow
          onChange={sum39_41}
          number='40'
          name='montoPagosInd'
          condicion='Pagos indirectos y/o descuentos retroactivos'
          disabled={disabled}
        />
        <InputRow
          name='importeRealPagado'
          number='41'
          disabled
          condicion='Precio realmente pagado o por pagar por las mercancías importadas (39 + 40)'
        />
        {/* 42 */}
        <TrowText
          number='42'
          condicion='ADICIONES AL PRECIO REALMENTE PAGADO O POR PAGAR POR LAS MERCANCÍAS IMPORTADAS'
        />
        <InputRow
          onChange={sum42_43}
          name='importeContraprestacion'
          number='42.1'
          condicion='Montro de la condición o contraprestación a que se refiere la casilla 25.1'
          disabled={disabled}
        />
        <InputRow
          onChange={sum42_43}
          name='importeReversion'
          number='42.2'
          condicion='Monto de la reversión a la que se refiere la casilla 25'
          disabled={disabled}
        />
        <InputRow
          onChange={sum42_43}
          name='gastosComisiones'
          number='42.3'
          condicion='Gastos por comisiones y correlajes, salvo los de comisiones de compra'
          disabled={disabled}
        />
        <InputRow
          onChange={sum42_43}
          name='gastosEmbalajes'
          number='42.4'
          condicion='Gastos y costos de envases y embalajes'
          disabled={disabled}
        />
        <InputRow
          onChange={sum42_43}
          name='valorMaterialesIncorporados'
          number='42.5'
          condicion='Valor de los materiales consumidos en la producción de las mercancías importadas'
          disabled={disabled}
        />
        <InputRow
          onChange={sum42_43}
          name='valorHerramientasProd'
          number='42.6'
          condicion='Valor de las herramientos, matrices, moldes, y elementos análogos utilizados para la producción de las mercancías'
          disabled={disabled}
        />
        <InputRow
          onChange={sum42_43}
          name='valorMaterialesConsumidos'
          number='42.7'
          condicion='Valor de los materiales consumidos en la producción de las mercancías importadas'
          disabled={disabled}
        />
        <InputRow
          onChange={sum42_43}
          name='valorIngenieriaExt'
          number='42.8'
          condicion='Valor de ingeniería, creación y perferccionamiento, trabajos artísticos, diseños y planos y croquis realizados fuera del país de importación y necesarios para la producción de mercancías importadas'
          disabled={disabled}
        />
        <InputRow
          onChange={sum42_43}
          name='valorDerLicencia'
          number='42.9'
          condicion='Valor de los cánones y derechos de licencia, a que se refiere la casilla 29.1'
          disabled={disabled}
        />
        <InputRow
          onChange={sum42_43}
          name='importeTransporte'
          number='42.10'
          condicion='Gastos de transporte de la mercadería importada hasta el puerto o lugar de importación'
          disabled={disabled === true ? true : !incotermSeleccionado?.fleteDeclarado}
        />
        <InputRow
          onChange={sum42_43}
          name='importeCargaManipulacion'
          number='42.11'
          condicion='Gastos de carga, descarga y manipulación ocasionadas por el transporte de las mercaderías importadas hasta el puerto o lugar de importación'
          disabled={disabled}
        />
        <InputRow
          onChange={sum42_43}
          name='importeSeguro'
          number='42.12'
          condicion='Costos del Seguro'
          extraActionText='Calcular el 1.5%'
          extraAction={onCalcularSeguro}
          extraActionDisabled={!incotermSeleccionado?.seguroDeclarado}
          disabled={disabled === true ? true : !incotermSeleccionado?.seguroDeclarado}
        />
        <InputRow
          name='importeAjustes'
          disabled
          number='43'
          condicion='Total de ajustes al precio realmente pagado o por pagar (sumatoria de 42.1 a 42.12)'
        />
        {/* 44 */}
        <TrowText
          number='44'
          condicion='DEDUCCIONES AL PRECIO REALMENTE PAGADO O POR PAGAR POR LAS MERCANCÍAS IMPORTADAS'
        />
        <InputRow
          name='importeAsistenciaTecnica'
          number='44.1'
          onChange={sum44_45}
          condicion='Gastos de construcción, armado, montaje, mantenimiento o asistencia técnica realizados después de la importación, en relación con las mercancías importadas'
          disabled={disabled}
        />
        <InputRow
          name='importeTransportePuerto'
          number='44.2'
          onChange={sum44_45}
          condicion='Gastos de construcción, armado, montaje, mantenimiento o asistencia técnica realizados después de la importación, en relación con las mercancías importadas'
          disabled={disabled}
        />
        <InputRow
          name='importeImpuestos'
          number='44.3'
          onChange={sum44_45}
          condicion='	Derechos e impuestos aplicables en el país de importación'
          disabled={disabled}
        />
        <InputRow
          name='importeIntereses'
          number='44.4'
          onChange={sum44_45}
          condicion='Monto de intereses'
          disabled={disabled}
        />
        <InputRow
          name='importeOtrasDeducciones'
          number='44.5'
          onChange={sum44_45}
          condicion='Otras deducciones legalmente aplicables'
          disabled={disabled}
        />
        <InputRow
          name='totalDeducciones'
          number='45'
          disabled
          condicion='Total deducciones al precio realmente pagado o por pagar por las mercancías importadas'
        />
        <InputRow
          name='valorAduana'
          number='46'
          disabled
          condicion='VALOR EN ADUANA (41 + 43 - 45)'
        />
      </Table>
    </section>
  );
};

interface TRow {
  name: string;
  condicion: string;
  number: string;
  onChange?: (value: number) => void;
  disabled?: boolean;
  extraActionDisabled?: boolean;
  extraActionText?: string;
  extraAction?: () => void;
}

const InputRow = ({
  number,
  condicion,
  name,
  onChange,
  disabled,
  extraActionDisabled,
  extraActionText,
  extraAction,
}: TRow) => {
  return (
    <Trow
      key={name}
      number={number ? number : String(name)}
      condicion={condicion}
      extraActionDisable={extraActionDisabled}
      extraActionText={extraActionText}
      extraAction={extraAction}
    >
      <CustomInputNumber
        type='normal'
        name={name}
        disabled={disabled}
        style={{ marginBottom: 0, width: '100%' }}
        onChange={onChange}
        layout={{ span: 24 }}
        initialValue={0}
        min={0}
        ruler={{ required: true }}
      />
    </Trow>
  );
};
