import {
  DeleteOutlined,
  EllipsisOutlined,
  EyeOutlined,
  SaveOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { tableColumnTextFilter } from 'utils/tableColumnFilter';
import { Proveedor } from '../../../models/Proveedor';

interface IProps {
  onSave: (values: Proveedor) => void;
  onDetails: (values: Proveedor) => void;
  onDelete: (values: Proveedor) => void;
  onShowDirecciones: (values: Proveedor) => void;
}

export const Columns = ({
  onSave,
  onDetails,
  onDelete,
  onShowDirecciones,
}: IProps): ColumnsType<any> => {
  return [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      width: 200,
      ...tableColumnTextFilter<Proveedor>('nombre'),
    },
    {
      title: 'País',
      dataIndex: 'pais',
      width: 200,
      ...tableColumnTextFilter<Proveedor>('pais'),
    },
    {
      title: 'Condición Comercial',
      dataIndex: 'condicionComercial',
      width: 200,
      ...tableColumnTextFilter<Proveedor>('condicionComercial'),
    },
    {
      title: 'Otra Condición Comercial',
      dataIndex: 'otraCondicionComercial',
      width: 250,
      ...tableColumnTextFilter<Proveedor>('otraCondicionComercial'),
    },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 50,
      render: (_: any, record: Proveedor) => (
        <Dropdown
          getPopupContainer={() =>
            document.getElementById('ProveedoresPendientesTabla') as HTMLElement
          }
          placement='bottomRight'
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item key='save' icon={<SaveOutlined />} onClick={() => onSave(record)}>
                Aprobar
              </Menu.Item>
              <Menu.Item key='details' icon={<EyeOutlined />} onClick={() => onDetails(record)}>
                Detalles
              </Menu.Item>
              <Menu.Item
                key='direcciones'
                icon={<UnorderedListOutlined />}
                onClick={() => onShowDirecciones(record)}
              >
                Direcciones
              </Menu.Item>
              <Popconfirm
                title='¿Estás seguro de eliminar este Proveedor?'
                okText='Eliminar'
                cancelText='Cancelar'
                onConfirm={() => onDelete(record)}
                getPopupContainer={() =>
                  document.getElementById('ProveedoresPendientesTabla') as HTMLElement
                }
              >
                <Menu.Item key='delete' icon={<DeleteOutlined />} danger>
                  Eliminar
                </Menu.Item>
              </Popconfirm>
            </Menu>
          }
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Dropdown>
      ),
    },
  ];
};
