import { FormInstance } from 'antd';
import { DrawerUpsert } from 'features/Productos/DrawerUpsert/DrawerUpsert';
import { Producto } from 'models/Producto';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { setProductoInformacionCompleta } from '../store/store';

interface IProps {
  visible: boolean;
  form: FormInstance;
  productoSeleccionado: Producto | undefined;
  setProductoSeleccionado: (producto: Producto | undefined) => void;
  setVisible: (visible: boolean) => void;
}

export const DrawerUpsertProductos = ({
  visible,
  form,
  productoSeleccionado,
  setProductoSeleccionado,
  setVisible,
}: IProps) => {
  const dispatch = useDispatch();
  const { data: DesignacionesProductos } = useAppSelector(state => state.designacionesProductos);
  const { data: MarcasComerciales } = useAppSelector(state => state.marcasComerciales);
  const PartidasArancelarias = useAppSelector(state => state.partidasArancelarias.pagedData.items);

  const onProductoInformacionCompleta = (producto: Producto) => {
    const designacionProducto = DesignacionesProductos.filter(
      d => d.nombre === producto.designacionProducto,
    )[0];
    const marcaComercial = MarcasComerciales.filter(m => m.nombre === producto.marcaComercial)[0];

    const partidaArancelaria =
      PartidasArancelarias.find(i => i.nombre === producto.partidaArancelariaId) ??
      PartidasArancelarias.find(i => i.id === producto.partidaArancelariaId) ??
      undefined;

    producto = {
      ...producto,
      codigoDeBarras: producto.codigoDeBarras ?? '',
      codigoFitosanitario: producto.codigoFitosanitario ?? '',
      mostrarDescripcionConPresentacion: Boolean(producto.mostrarDescripcionConPresentacion),
      partidaArancelaria: partidaArancelaria?.nombre ?? '',
      partidaArancelariaId: partidaArancelaria?.id ?? '',
      designacionProductoId:
        designacionProducto !== undefined
          ? designacionProducto.id
          : '00000000-0000-0000-0000-000000000000',
      marcaComercialId:
        marcaComercial !== undefined ? marcaComercial.id : '00000000-0000-0000-0000-000000000000',
    };
    dispatch(setProductoInformacionCompleta(producto));
  };

  const cancelDrawer = () => {
    setVisible(false);
    setProductoSeleccionado(undefined);
    form.resetFields();
  };

  return (
    <DrawerUpsert
      validarProducto
      visible={visible}
      form={form}
      status={'succeeded'}
      elementoSeleccionado={productoSeleccionado}
      onCreate={(producto: Producto) => {
        onProductoInformacionCompleta(producto);
        cancelDrawer();
      }}
      onUpdate={(producto: Producto) => {
        onProductoInformacionCompleta(producto);
        cancelDrawer();
      }}
      setCerrarModal={() => cancelDrawer()}
    />
  );
};
