import { Col, Form, InputNumber } from 'antd';
import { FormItemProps, Rule } from 'antd/lib/form';
import React, { ReactNode } from 'react';
import { useAppSelector } from 'store/hooks';

declare type InputNumberType = 'normal' | 'full';

interface ICustomInputNumber extends FormItemProps {
  name: string;
  label?: string;
  type?: InputNumberType;
  initialValue?: number;
  ruler?: Rule;
  layout?: { lg?: number; md?: number; xs?: number; span?: number };
  disabled?: boolean;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  presicion?: number;
  addonBefore?: ReactNode;
  onChange?: (value: number) => void;
}

export const CustomInputNumber = ({
  name,
  label,
  type = 'full',
  initialValue = 0,
  ruler,
  disabled,
  layout,
  min,
  max,
  autoFocus,
  presicion,
  onChange,
  addonBefore,
  ...rest
}: ICustomInputNumber) => {
  const Configuraciones = useAppSelector(state => state.initialData.Configuraciones);

  const formItem = (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      rules={[
        ruler || { required: false },
        ({ setFieldsValue }) => ({
          validator(_, value) {
            try {
              if (typeof value === 'object') {
                setFieldsValue({ [name]: 0 });
              } else {
                const precisionDecimal = Configuraciones?.precisionDecimal;
                const newValue = Number(value?.toFixed(presicion ?? precisionDecimal));
                const finalValue = newValue;
                setFieldsValue({ [name]: finalValue });

                return Promise.resolve();
              }
            } catch (error) {
              console.log(error);
            }

            return Promise.resolve();
          },
        }),
      ]}
      {...rest}
    >
      <InputNumber
        style={{ width: '100%', color: disabled ? '#666' : '#000', marginBottom: 0 }}
        type='number'
        disabled={disabled}
        onChange={onChange}
        min={min}
        max={max}
        autoFocus={autoFocus}
        addonBefore={addonBefore}
      />
    </Form.Item>
  );

  return type === 'normal' ? (
    formItem
  ) : (
    <Col span={6} {...layout}>
      {formItem}
    </Col>
  );
};
