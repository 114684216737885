import { IDucaItemRequest } from 'models/Duca';
import XLSX, { ColInfo } from 'xlsx';

export const generarExcelItems = (productos: IDucaItemRequest[]) => {
  // Creamos un libro en blanco
  const wb = XLSX.utils.book_new();

  // Creamos data valida para insertar en una hoja
  const ws = XLSX.utils.aoa_to_sheet([
    [
      'Numero Item',
      'Positición Arancelaria',
      'Descripción',
      'Comentarios',
      'Cantidad Comercial / Cantidad Declarada',
      'Unidad Comercial',
      'Cantidad Estadistica',
      'Unidad Estadistica',
      'Fob Otra Moneda',
      'Valor FOB $ / Importe Factura',
      'Flete $',
      'Seguro $',
      'Otros $',
      'Valor CIF $',
      'Grav. Adv % S/CIF',
      'Total Derechos',
      'ISV',
      'IPC',
      'ISC',
      'Forma de Liquidación',
      'Peso Bruto',
      'Peso Neto',
      'Cantidad Bultos',
      'Estado de Mercancia',
      'Declaración a Cancelar',
      'Nro. Item a Cancelar',
      'Tipo Item',
      'Pais Origen',
      'Pais Procedencia Destino',
      'Pais Adquisición',
      'Cuota Arancelaria',
      'Titulo Manifiesto Currier',
      'Id Matriz Insumos',
      'Número Item Asociado',
      'Ajuste a Incluir',
      'Número Certificado Importación',
      'Convenio Perfeccionamiento',
      'Número Exoneración Aduana',
    ],
    ...productos.map(p => [
      p.numeroItem,
      p.posicionArancelaria,
      p.descripcion,
      p.comentario,
      NumberFormat(p.cantidadComercial),
      p.unidadComercial,
      NumberFormat(p.cantidadComercial),
      p.unidadEstadistica,
      NumberFormat(p.fobOtraMoneda),
      NumberFormat(p.importeFOB),
      NumberFormat(p.importeFlete),
      NumberFormat(p.importeSeguro),
      NumberFormat(p.importeOtrosGastos),
      NumberFormat(p.valorCIF),
      NumberFormat(p.gravadoPorcentajeCIF),
      NumberFormat(p.totalDerechos),
      NumberFormat(p.isv),
      NumberFormat(p.ipc),
      NumberFormat(p.isc),
      NumberFormat(p.formaLiquidacion),
      NumberFormat(p.pesoBruto),
      NumberFormat(p.pesoNeto),
      NumberFormat(p.cantidadBultos),
      p.estadoMercancia,
      p.declaracionCancelar,
      p.numeroItemCancelar,
      p.tipoItem,
      p.paisOrigen,
      p.paisProcedenciaDestino,
      p.paisAdquisicion,
      p.cuotaArancelaria,
      p.tituloManifiestoCurrier,
      p.idMatrizInsumos,
      p.numeroItemAsociado,
      p.ajusteAIncluir,
      p.numeroResolucion,
      p.convenioPerfectoActivo,
      p.numeroExoneracionAduana,
    ]),
  ]);

  // Declaramos la hoja e insertamos la data a la misma
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Definir el ancho de las columnas
  wb.Sheets.Sheet1['!cols'] = Array(38)
    .fill({} as ColInfo)
    .map(_i => ({ wpx: 100 }));

  // Generar el archivo excel
  XLSX.writeFile(wb, 'Duca_Items.xlsx', { compression: true, bookSST: true });
};

export const NumberFormat = (value: number) => Number(value.toFixed(4));
