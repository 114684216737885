import { Select } from 'antd';
import { queryPartidasArancelarias } from 'features/PartidasArancelarias/store/extraReducers/queryPartidasArancelarias';
import { useCustomDispatch } from 'hooks/useCustomDispatch';
import { useDebounce } from 'hooks/useDebounce';
import { PartidaArancelaria } from 'models/PartidaArancelaria';
import { QuerySpec } from 'models/QuerySpec';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useAppSelector } from 'store/hooks';

const { Option } = Select;

export const AgPartidasArancelariasCellEditor = forwardRef((props: any, ref) => {
  const {
    pagedData: { items: PartidasArancelarias },
    status,
  } = useAppSelector(state => state.partidasArancelarias);
  const posiblesNombre = props.data.partidaArancelariaId
    ? ['partidaArancelariaId', 'partidaArancelaria']
    : ['posicionArancelariaId', 'posicionArancelaria'];
  const [value, setValue] = useState<PartidaArancelaria>({
    id: props.data[posiblesNombre[0]],
    nombre: props.data[posiblesNombre[1]],
  } as PartidaArancelaria);
  const customDispatch = useCustomDispatch();

  const onSearchPartidaArancelaria = useDebounce((e: string) => {
    dispatchPartidasArancelaria(e);
  });

  const dispatchPartidasArancelaria = (filterValue: string) => {
    customDispatch({
      asyncThuckFuction: queryPartidasArancelarias,
      endPoint: '/api/PartidasArancelarias/Query',
      body: {
        simpleTextFilter: filterValue,
        pageNumber: 1,
        pageSize: 50,
      } as QuerySpec,
    });
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (props.colDef.field === 'posicionArancelaria') {
          props.data.posicionArancelariaId = value.id;
        }

        if (props.colDef.field === 'partidaArancelaria') {
          props.data.partidaArancelariaId = value.id;
        }

        return value?.nombre;
      },
    };
  });

  const onChange = (e: string) => {
    setValue(PartidasArancelarias.find(p => p.id === e) as PartidaArancelaria);
  };

  return (
    <div>
      <Select
        showSearch
        optionFilterProp='children'
        onSearch={onSearchPartidaArancelaria}
        loading={status === 'pending'}
        defaultValue={value.id}
        onChange={e => onChange(e)}
        style={{ width: '100%' }}
      >
        <Option key={value.id} value={value.id}>
          {value.nombre}
        </Option>
        {PartidasArancelarias.filter(p => p.id !== value.id).map(partidaArancelaria => (
          <Option key={partidaArancelaria.id} value={partidaArancelaria.id}>
            {partidaArancelaria.nombre}
          </Option>
        ))}
      </Select>
    </div>
  );
});
