import { Col, Divider, Form, Modal, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { InputDisabled } from 'components/FormControls/InputDisabled';
import { setItemCalculoRapido } from 'features/DucaDetalles/store/store';
import { IDucaItemRequest } from 'models/Duca';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { formatCurrency } from 'utils/formatCurrency';

interface TotalCalculoRapido {
  key: string;
  impuesto: string;
  porcentaje: number;
  valor: number;
}

interface ItemRequestCalculoRapido extends IDucaItemRequest {
  size: number;
  pack: number;
  tasaCambioDolar: number;
}

interface IModalCalculoRapido {
  visible: boolean;
  item: IDucaItemRequest | undefined;
  onClose: () => void;
}

export const ModalCalculoRapido = ({ visible, item, onClose }: IModalCalculoRapido) => {
  const [totalCalculoRapido, setTotalCalculoRapido] = useState<TotalCalculoRapido[]>([]);
  const { Configuraciones, Incoterm: Incoterms } = useAppSelector(state => state.initialData);
  const { ConversionOnzasLitros, ducaDetalles, condicionEntregaId, datosComplementarios } =
    useAppSelector(state => state.ducaDetalles);
  const [form] = Form.useForm<ItemRequestCalculoRapido>();
  const dispatch = useDispatch();

  // Valores Iniciales
  useEffect(() => {
    if (item) {
      setTotalCalculoRapido([
        {
          key: 'dai',
          impuesto: 'DAI',
          porcentaje: NumberFormat(item?.gravadoPorcentajeCIF),
          valor: NumberFormat(item?.totalDerechos),
        },
        {
          key: 'isc',
          impuesto: 'Impuesto Selectivo Consumo',
          porcentaje: NumberFormat(item?.iscPorcentaje),
          valor: NumberFormat(item?.isc),
        },
        {
          key: 'ipc',
          impuesto: 'Impuesto Producción Consumo',
          porcentaje: NumberFormat(item?.ipcFactor),
          valor: NumberFormat(item?.ipc),
        },
        {
          key: 'isv',
          impuesto: 'Impuesto Sobre Ventas',
          porcentaje: NumberFormat(item?.isvPorcentaje),
          valor: NumberFormat(item?.isv),
        },
      ]);
      const datoComplementarioDescVentas = item.datosComplementarios.find(
        _ => _.codigo === 'DESCVENTAS',
      );
      form.setFieldsValue({
        ...item,
        importeFOB: NumberFormat(item.importeFOB),
        importeFlete: NumberFormat(item.importeFlete),
        importeSeguro: NumberFormat(item.importeSeguro),
        importeOtrosGastos: NumberFormat(item.importeOtrosGastos),
        valorCIF: NumberFormat(item.valorCIF),
        size: NumberFormat(item.size),
        pack: NumberFormat(item.pack),
        cantidadComercial: NumberFormat(item.cantidadComercial),
        precioVentaDistribuidor: NumberFormat(item.precioVentaDistribuidor),
        tasaCambioDolar: NumberFormat(ducaDetalles?.tasaCambioDolar ?? 0),
        descuentoVentas: NumberFormat(Number(datoComplementarioDescVentas?.valor ?? 0)),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const Columns: ColumnsType<TotalCalculoRapido> = [
    {
      dataIndex: 'impuesto',
      title: 'Impuesto',
    },
    {
      dataIndex: 'porcentaje',
      title: '%',
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      render: value => formatCurrency(value),
    },
  ];

  const recalcularCIF = () => {
    const { importeFOB, importeFlete, importeSeguro, importeOtrosGastos } = form.getFieldsValue();
    const valorCIF = importeFOB + importeFlete + importeSeguro + importeOtrosGastos;

    form.setFieldsValue({ valorCIF: NumberFormat(valorCIF) });
    recalcularImpuestos();
  };

  const recalcularLitros = () => {
    const { size, pack, cantidadComercial } = form.getFieldsValue();
    const FactorOnzasLitros = ConversionOnzasLitros?.factor ?? 0;
    const totalOnzas = size * pack * cantidadComercial;
    const totalLitros = totalOnzas / FactorOnzasLitros;

    form.setFieldsValue({
      cantidadEstadistica: Number(totalLitros.toFixed(Configuraciones?.precisionDecimal)),
    });

    recalcularImpuestos();
  };

  const recalcularImpuestos = () => {
    const { importeFOB, importeFlete, importeSeguro, importeOtrosGastos, cantidadEstadistica } =
      form.getFieldsValue();
    const TotalFOB = ducaDetalles?.caratula.totalFactura ?? 0;
    const TotalFlete = ducaDetalles?.caratula.totalFlete ?? 0;
    const TasaCambioDolar = ducaDetalles?.tasaCambioDolar ?? 0;
    const incotermId =
      condicionEntregaId.length > 0
        ? condicionEntregaId
        : ducaDetalles?.caratula.condicionEntregaId;
    const incoterm = Incoterms.find(i => i.id === incotermId);
    const datoComplementarioC300Valor = datosComplementarios?.find(d => d.codigo === 'C300')?.valor;
    const datoComplementarioC305Valor = datosComplementarios.find(d => d.codigo === 'C305')?.valor;
    const factor = importeFOB / TotalFOB;
    const gravadoPorcentajeCIF = (item?.gravadoPorcentajeCIF ?? 0) / 100;
    const iscPorcentaje = (item?.iscPorcentaje ?? 0) / 100;
    const ipcFactor = item?.ipcFactor ?? 0;
    const isvPorcentaje = (item?.isvPorcentaje ?? 0) / 100;
    let CIFImponible = 0;
    let TotalDerechos = 0;
    let Isc = 0;
    let Ipc = 0;
    let Isv = 0;
    let TotalDerechosLempiras = 0;
    let IscLempiras = 0;
    let IpcLempiras = 0;
    let IsvLempiras = 0;
    const tempCantidadEstadistica = cantidadEstadistica === 0 ? 1 : cantidadEstadistica;
    const Pvd = ((item?.importeFOB ?? 0) / tempCantidadEstadistica) * TasaCambioDolar;

    if (incoterm && (datoComplementarioC300Valor || datoComplementarioC305Valor)) {
      let newFlete = 0;

      if (incoterm.fleteDeclarado && datoComplementarioC305Valor) {
        newFlete = factor * (TotalFlete * 0.25);
      }

      if (!incoterm.fleteDeclarado && datoComplementarioC300Valor && datoComplementarioC305Valor) {
        newFlete = factor * (Number(datoComplementarioC300Valor) * 0.25);
      }

      CIFImponible = importeFOB + newFlete + importeSeguro + importeOtrosGastos;
      TotalDerechos = CIFImponible * gravadoPorcentajeCIF;
      Isc = CIFImponible * iscPorcentaje;
      Ipc = cantidadEstadistica * ipcFactor;

      // Calcular ISV
      if (
        item?.posicionArancelaria.startsWith('2202') ||
        item?.posicionArancelaria.startsWith('2203')
      ) {
        Isv = (Pvd / (1 + isvPorcentaje)) * isvPorcentaje * cantidadEstadistica;
      } else {
        Isv = ((CIFImponible + TotalDerechos + Isc) * TasaCambioDolar + Ipc) * isvPorcentaje;
      }

      TotalDerechosLempiras = TotalDerechos * TasaCambioDolar;
      IscLempiras = Isc * TasaCambioDolar;
      IpcLempiras = Ipc;
      IsvLempiras = Isv;
    } else {
      CIFImponible = importeFOB + importeFlete + importeSeguro + importeOtrosGastos;
      TotalDerechos = CIFImponible * gravadoPorcentajeCIF;
      Isc = CIFImponible * iscPorcentaje;
      Ipc = cantidadEstadistica * ipcFactor;

      // Calcular ISV
      if (
        item?.posicionArancelaria.startsWith('2202') ||
        item?.posicionArancelaria.startsWith('2203')
      ) {
        Isv = ((Pvd ?? 0) / (1 + isvPorcentaje)) * isvPorcentaje * cantidadEstadistica;
      } else {
        Isv = ((CIFImponible + TotalDerechos + Isc) * TasaCambioDolar + Ipc) * isvPorcentaje;
      }

      TotalDerechosLempiras = TotalDerechos * TasaCambioDolar;
      IscLempiras = Isc * TasaCambioDolar;
      IpcLempiras = Ipc;
      IsvLempiras = Isv;
    }

    form.setFieldsValue({
      cifImponible: CIFImponible,
      precioVentaDistribuidor: NumberFormat(Pvd),
    });

    setTotalCalculoRapido([
      ...totalCalculoRapido.map(i => {
        if (i.key === 'dai') return { ...i, valor: NumberFormat(TotalDerechosLempiras) };
        if (i.key === 'isc') return { ...i, valor: NumberFormat(IscLempiras) };
        if (i.key === 'ipc') return { ...i, valor: NumberFormat(IpcLempiras) };
        if (i.key === 'isv') return { ...i, valor: NumberFormat(IsvLempiras) };
        return i;
      }),
    ]);
  };

  const onChangeLitros = (value: number) => {
    const index = totalCalculoRapido.findIndex(x => x.key === 'ipc');

    if (index !== -1) {
      const newTotalCalculoRapido = [...totalCalculoRapido];
      const newIPC = value * Number(item?.ipcFactor);
      newTotalCalculoRapido[index].valor = NumberFormat(newIPC);

      setTotalCalculoRapido(newTotalCalculoRapido);
    }

    recalcularImpuestos();
  };

  const onChangePVD = (value: number) => {
    const { cantidadEstadistica, cifImponible } = form.getFieldsValue();
    const TasaCambioDolar = ducaDetalles?.tasaCambioDolar ?? 0;
    const isvPorcentaje = (item?.isvPorcentaje ?? 0) / 100;
    const TotalDerechos = totalCalculoRapido.find(x => x.key === 'dai')?.valor ?? 0;
    const Isc = totalCalculoRapido.find(x => x.key === 'isc')?.valor ?? 0;
    const Ipc = totalCalculoRapido.find(x => x.key === 'ipc')?.valor ?? 0;
    const index = totalCalculoRapido.findIndex(x => x.key === 'isv');

    if (index !== -1) {
      const newTotalCalculoRapido = [...totalCalculoRapido];

      let newISV = 0;

      // Calcular ISV
      if (
        item?.posicionArancelaria.startsWith('2202') ||
        item?.posicionArancelaria.startsWith('2203')
      ) {
        newISV = (value / (1 + isvPorcentaje)) * isvPorcentaje * cantidadEstadistica;
      } else {
        newISV = (cifImponible * TasaCambioDolar + TotalDerechos + Isc + Ipc) * isvPorcentaje;
      }

      newTotalCalculoRapido[index].valor = NumberFormat(newISV);
      setTotalCalculoRapido(newTotalCalculoRapido);
    }
  };

  const onChangeFOBFleteSeguroOtrosGastos = (_value: number) => recalcularCIF();

  const onSave = () => {
    const importeFOB: number = form.getFieldValue('importeFOB');
    const importeFlete: number = form.getFieldValue('importeFlete');
    const importeSeguro: number = form.getFieldValue('importeSeguro');
    const importeOtrosGastos: number = form.getFieldValue('importeOtrosGastos');
    const valorCIF: number = form.getFieldValue('valorCIF');
    const cifImponible: number = form.getFieldValue('cifImponible');
    const size: number = form.getFieldValue('size');
    const pack: number = form.getFieldValue('pack');
    const cantidadComercial: number = form.getFieldValue('cantidadComercial');
    const cantidadEstadistica: number = form.getFieldValue('cantidadEstadistica');
    const precioVentaDistribuidor: number = form.getFieldValue('precioVentaDistribuidor');
    const descuentoVentas: number = form.getFieldValue('descuentoVentas');
    const totalDerechos: number = Number(totalCalculoRapido.find(x => x.key === 'dai')?.valor ?? 0);
    const isc: number = Number(totalCalculoRapido.find(x => x.key === 'isc')?.valor ?? 0);
    const ipc: number = Number(totalCalculoRapido.find(x => x.key === 'ipc')?.valor ?? 0);
    const isv: number = Number(totalCalculoRapido.find(x => x.key === 'isv')?.valor ?? 0);

    if (item) {
      dispatch(
        setItemCalculoRapido({
          key: item?.key ?? '',
          record: {
            ...item,
            importeFOB,
            importeFlete,
            importeSeguro,
            importeOtrosGastos,
            valorCIF,
            cifImponible,
            size,
            pack,
            cantidadComercial,
            cantidadEstadistica,
            precioVentaDistribuidor,
            totalDerechos,
            isc,
            ipc,
            isv,
            descuentoVentas,
          },
        }),
      );
    }

    onClose();
  };

  return (
    <Modal
      title='Calculo Rapido'
      visible={visible}
      onCancel={onClose}
      okText='Guardar'
      onOk={onSave}
      width={600}
    >
      <Form form={form} layout='vertical'>
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <InputDisabled name='posicionArancelaria' label='Posición Arancelaria' />
          </Col>
          <Col span={16}>
            <InputDisabled name='descripcion' label='Descripción' />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <CustomInputNumber
            name='importeFOB'
            label='FOB'
            layout={{ span: 6 }}
            onChange={onChangeFOBFleteSeguroOtrosGastos}
          />
          <CustomInputNumber
            name='importeFlete'
            label='Flete'
            layout={{ span: 6 }}
            onChange={onChangeFOBFleteSeguroOtrosGastos}
          />
          <CustomInputNumber
            name='importeSeguro'
            label='Seguro'
            layout={{ span: 6 }}
            onChange={onChangeFOBFleteSeguroOtrosGastos}
          />
          <CustomInputNumber
            name='importeOtrosGastos'
            label='Otros Gastos'
            layout={{ span: 6 }}
            onChange={onChangeFOBFleteSeguroOtrosGastos}
          />
        </Row>
        <Row gutter={[24, 24]}>
          <CustomInputNumber name='valorCIF' label='CIF' disabled layout={{ span: 16 }} />
          <CustomInputNumber
            name='tasaCambioDolar'
            label='TasaCambioDolar'
            disabled
            layout={{ span: 8 }}
          />
        </Row>
        <Divider>Datos para bebidas</Divider>
        <Row gutter={[24, 24]}>
          <CustomInputNumber
            name='size'
            label='Size'
            onChange={_e => recalcularLitros()}
            layout={{ span: 8 }}
          />
          <CustomInputNumber
            name='pack'
            label='Pack'
            onChange={_e => recalcularLitros()}
            layout={{ span: 8 }}
          />
          <CustomInputNumber
            name='cantidadComercial'
            label='Cantidad'
            onChange={_e => recalcularLitros()}
            layout={{ span: 8 }}
          />
        </Row>
        <Row gutter={[24, 24]}>
          <CustomInputNumber
            name='cantidadEstadistica'
            label='Litros'
            layout={{ span: 8 }}
            onChange={onChangeLitros}
          />
          <CustomInputNumber
            name='precioVentaDistribuidor'
            label='Precio Venta Distribuidor'
            layout={{ span: 8 }}
            onChange={e => onChangePVD(e)}
          />
          <CustomInputNumber
            name='descuentoVentas'
            label='Descuento de Ventas'
            layout={{ span: 8 }}
          />
        </Row>
        <Table
          dataSource={totalCalculoRapido}
          columns={Columns}
          size='small'
          pagination={false}
          bordered
        />
      </Form>
    </Modal>
  );
};

export const NumberFormat = (value: number, presition?: number) =>
  Number(value.toFixed(presition ?? 4));
