import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { UseCustomDispatch, useCustomDispatch } from './useCustomDispatch';

export const useInitialData = (initialData: UseCustomDispatch[], condition: boolean = true) => {
  const customDispatch = useCustomDispatch();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) return;
    if (!condition) return;
    initialData.forEach(({ asyncThuckFuction, endPoint, method, body }) => {
      customDispatch({ asyncThuckFuction, endPoint, method, body });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
};
