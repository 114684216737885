import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PagedList } from 'models/PagedList';
import { Producto } from 'models/Producto';
import httpService from 'services/httpService';
import { IProductosState } from '../productosStore';

export const queryProductos = createAsyncThunk('Productos/Query', httpService);

export const queryProductosReducer = (builder: ActionReducerMapBuilder<IProductosState>) => {
  builder.addCase(queryProductos.fulfilled, (state, action: PayloadAction<PagedList<Producto>>) => {
    state.status = 'succeeded';
    state.pagedData = action.payload;
  });
  builder.addCase(queryProductos.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(queryProductos.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
