import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/InitialData/store/initialDataStore';
import httpService, { IUseFetch, ThunckApi } from 'services/httpService';
import { IOrdenTrabajoDetallesState } from '../ordenTrabajoDetallesStore';

export const updateOrdenTrabajoDetalle = createAsyncThunk(
  'OrdenTrabajoDetalles/updateOrdenTrabajo',
  async (fetch: IUseFetch, thunkApi: ThunckApi) => {
    const res = await httpService(fetch, thunkApi);

    if (res) {
      // thunkApi.dispatch(setOrdenTrabajoForm(undefined));
      thunkApi.dispatch(
        setError({ message: 'Información General y Flete actualizado.', error: false }),
      );
    }

    return res;
  },
);

export const updateOrdenTrabajoDetalleReducer = (
  builder: ActionReducerMapBuilder<IOrdenTrabajoDetallesState>,
) => {
  builder.addCase(updateOrdenTrabajoDetalle.fulfilled, (state, _action) => {
    state.drawerVisible = false;
    state.status = 'idle';
  });
  builder.addCase(updateOrdenTrabajoDetalle.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(updateOrdenTrabajoDetalle.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
