import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Proveedor } from '../../models/Proveedor';

interface IProps {
  onUpdate: (values: Proveedor) => void;
  onDelete: (values: Proveedor) => void;
  onShowDirecciones: (values: Proveedor) => void;
}

export const Columns = ({ onUpdate, onDelete, onShowDirecciones }: IProps): ColumnsType<any> => {
  return [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      width: 200,
    },
    {
      title: 'País',
      dataIndex: 'pais',
      width: 200,
    },
    {
      title: 'Condición Comercial',
      dataIndex: 'condicionComercial',
      width: 200,
    },
    {
      title: 'Otra Condición Comercial',
      dataIndex: 'otraCondicionComercial',
      width: 300,
    },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 50,
      render: (_: any, record: Proveedor) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='editar' icon={<EditOutlined />} onClick={() => onUpdate(record)}>
                Editar
              </Menu.Item>
              <Menu.Item
                key='direcciones'
                icon={<UnorderedListOutlined />}
                onClick={() => onShowDirecciones(record)}
              >
                Direcciones
              </Menu.Item>
              <Popconfirm
                title='¿Estás seguro de eliminar este proveedor?'
                okText='Eliminar'
                cancelText='Cancelar'
                onConfirm={() => onDelete(record)}
              >
                <Menu.Item icon={<DeleteOutlined />} key='eliminar' danger>
                  Eliminar
                </Menu.Item>
              </Popconfirm>
            </Menu>
          }
          trigger={['click']}
          placement='bottomRight'
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Dropdown>
      ),
    },
  ];
};
