import { Button, Col, Row } from 'antd';
import SearchFilter from '../../../components/SearchFilter/SearchFilter';
import { useDebounce } from '../../../hooks/useDebounce';

interface IProps {
  setNombre: (value: string) => void;
  setToggleModal: () => void;
}

export const Toolbar = ({ setNombre, setToggleModal }: IProps) => {
  const setDebouncedNombre = useDebounce(setNombre);
  const setDebouncedValue = (value: string) => setDebouncedNombre(value);

  return (
    <Row gutter={[24, 24]} align='middle' justify='space-between' style={{ marginBottom: 24 }}>
      <Col>
        <SearchFilter setFilter={setDebouncedValue} />
      </Col>
      <Col>
        <Button type='primary' onClick={setToggleModal}>
          Crear Usuario
        </Button>
      </Col>
    </Row>
  );
};
