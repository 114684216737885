import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Tratado } from 'models/Tratado';
import httpService from 'services/httpService';
import { ITratadosState } from '../tratadosStore';

export const updateTratado = createAsyncThunk('Tratados/Update', httpService);

export const updateTratadoReducer = (builder: ActionReducerMapBuilder<ITratadosState>) => {
  builder.addCase(updateTratado.fulfilled, (state, _action) => {
    const index = state.data.findIndex(tratado => tratado.id === state.elementoSeleccionado?.id);

    state.crudStatus = 'succeeded';
    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as Tratado);
  });
  builder.addCase(updateTratado.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updateTratado.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
