import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Producto } from 'models/Producto';
import httpService from 'services/httpService';
import { IProductosState } from '../productosStore';

export const updateProducto = createAsyncThunk('Productos/Update', httpService);

export const updateProductoReducer = (builder: ActionReducerMapBuilder<IProductosState>) => {
  builder.addCase(updateProducto.fulfilled, (state, _action) => {
    const index = state.pagedData.items.findIndex(
      producto => producto.id === state.elementoSeleccionado?.id,
    );

    state.crudStatus = 'succeeded';
    state.pagedData.items.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as Producto);
  });
  builder.addCase(updateProducto.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updateProducto.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
