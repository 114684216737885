import { Typography } from 'antd';
import { DropDown } from 'components/FormControls/CustomDropDown';
import { CustomInput } from 'components/FormControls/CustomInput';
import { EstadoOrdenTrabajo } from 'enum/EstadoOrdenTrabajo';
import { validationRulesForDropdown } from 'features/DevaDetalles/utils/validationRulesForDropdown';
import { validationRulesForInput } from 'features/DevaDetalles/utils/validationRulesForInput';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { Table, Trow } from '../../components/Table';

const { Title } = Typography;

export const CondicionesTransaccion = () => {
  const [condicionesState, setCondionesState] = useState<{ [key: string]: boolean }>({
    restricciones: true,
    contraPrestacion: true,
    vinculacionCompVend: true,
    pagoDescuentos: true,
    existenCanones: true,
  });
  const deva = useAppSelector(state => state.devaDetalles.devaDetalles);

  useEffect(() => {
    setCondionesState({
      restricciones: !deva?.restricciones,
      contraPrestacion: !deva?.contraPrestacion,
      vinculacionCompVend: !deva?.vinculacionCompVend,
      pagoDescuentos: !deva?.pagoDescuentos,
      existenCanones: !deva?.existenCanones,
    });
  }, [deva]);

  const disabled = useAppSelector(
    state => state.devaDetalles.devaDetalles?.ordenDeTrabajo?.estado === EstadoOrdenTrabajo.Cerrada,
  );

  const onSelectChange = (_: any, value: any, name?: string) => {
    const values = value as { value: number; children: string };

    setCondionesState({ ...condicionesState, [String(name)]: values.children !== 'Si' });
  };

  const bynarySelect = [
    { id: true, nombre: 'Si' },
    { id: false, nombre: 'No' },
  ];

  return (
    <section>
      <Title style={{ color: '#2F80ED' }} level={4}>
        II. Condiciones de transaccion
      </Title>
      <Table columns={['#', 'Condición', 'Valor']}>
        <Trow
          key={'24'}
          number='24'
          condicion='Existen restricciones a la cesión o utilización de las mercancías por el comprador, distintas de las excepciones previstas en el artículo 1.1 a) del Acuerdo'
        >
          <DropDown
            placeholder='Seleccione una opción'
            style={{ margin: 0, width: '100%' }}
            layout={{ span: 24 }}
            name='restricciones'
            data={bynarySelect}
            onChange={onSelectChange}
            rules={validationRulesForDropdown}
            disabled={disabled}
          />
        </Trow>
        <Trow key={'24.1'} number='24.1' condicion='Indicar en que consiste la o las restricciones'>
          <CustomInput
            disabled={condicionesState.restricciones}
            layout={{ span: 24 }}
            name='descRestricciones'
            style={{ marginBottom: 0 }}
            required={false}
            rules={condicionesState.restricciones ? undefined : validationRulesForInput}
          />
        </Trow>
        <Trow
          key={'25'}
          number='25'
          condicion='Depende la venta o el precio de alguna condición o contraprestación, con relación a las mercancías a valorar'
        >
          <DropDown
            placeholder='Seleccione una opción'
            style={{ margin: 0, width: '100%' }}
            layout={{ span: 24 }}
            name='contraPrestacion'
            data={bynarySelect}
            onChange={onSelectChange}
            rules={validationRulesForDropdown}
            disabled={disabled}
          />
        </Trow>
        <Trow
          key={'25.1'}
          number='25.1'
          condicion='Indicar en que consiste la condición o contrapresentación, y si es cuantificable consignar el monto en la casilla Nro. 42.1'
        >
          <CustomInput
            disabled={condicionesState.contraPrestacion}
            layout={{ span: 24 }}
            name='descContraPrestacion'
            style={{ marginBottom: 0 }}
            required={false}
            rules={condicionesState.contraPrestacion ? undefined : validationRulesForInput}
          />
        </Trow>
        <Trow
          key={'26'}
          number='26'
          condicion='Está la venta condicionada a revertir directa o indirectamente al vendedor parte alguna del producto de la reventa o de cualquier cesión o utilización posterior de las mercancías, por el comprador, en caso afirmativo, declara el monto de la reversión en la casilla Nro. 42'
        >
          <DropDown
            required
            placeholder='Seleccione una opción'
            style={{ margin: 0, width: '100%' }}
            layout={{ span: 24 }}
            name='ventaCondicionada'
            data={bynarySelect}
            rules={validationRulesForDropdown}
            disabled={disabled}
          />
        </Trow>
        <Trow
          key={'27'}
          number='27'
          condicion='Está la venta condicionada a revertir directa o indirectamente al vendedor parte alguna del producto de la reventa o de cualquier cesión o utilización posterior de las mercancías, por el comprador, en caso afirmativo, declara el monto de la reversión en la casilla Nro. 42'
        >
          <DropDown
            placeholder='Seleccione una opción'
            style={{ margin: 0, width: '100%' }}
            layout={{ span: 24 }}
            name='vinculacionCompVend'
            data={bynarySelect}
            onChange={onSelectChange}
            rules={validationRulesForDropdown}
            disabled={disabled}
          />
        </Trow>
        <Trow
          key={'27.1'}
          number='27.1'
          condicion='Indicar en que consiste la condición o contrapresentación, y si es cuantificable consignar el monto en la casilla Nro. 42.1'
        >
          <CustomInput
            disabled={condicionesState.vinculacionCompVend}
            layout={{ span: 24 }}
            name='tipoVinculacion'
            style={{ marginBottom: 0 }}
            required={false}
            rules={condicionesState.vinculacionCompVend ? undefined : validationRulesForInput}
          />
        </Trow>
        <Trow
          key={'27.2'}
          number='27.2'
          condicion='Indicar si la vinculación ha influido en el precio'
        >
          <DropDown
            placeholder='Seleccione una opción'
            disabled={condicionesState.vinculacionCompVend || disabled}
            style={{ margin: 0, width: '100%' }}
            layout={{ span: 24 }}
            name='vincInfluenciaPrecio'
            data={bynarySelect}
            onChange={onSelectChange}
            rules={condicionesState.vinculacionCompVend ? undefined : validationRulesForDropdown}
          />
        </Trow>
        <Trow
          key={'28'}
          number='28'
          condicion='Existen pagos indirectos y/o descuentos retroactivos'
        >
          <DropDown
            placeholder='Seleccione una opción'
            style={{ margin: 0, width: '100%' }}
            layout={{ span: 24 }}
            name='pagoDescuentos'
            data={bynarySelect}
            onChange={onSelectChange}
            rules={validationRulesForDropdown}
            disabled={disabled}
          />
        </Trow>
        <Trow
          key={'28.1'}
          number='28.1'
          condicion='Indicar en que concepto y el monto declarado en la casilla Nro. 40'
        >
          <CustomInput
            disabled={condicionesState.pagoDescuentos || disabled}
            layout={{ span: 24 }}
            name='conceptoPorPagosDesc'
            style={{ marginBottom: 0 }}
            required={false}
            rules={condicionesState.pagoDescuentos ? undefined : validationRulesForInput}
          />
        </Trow>
        <Trow
          key={'29'}
          number='29'
          condicion='Existen cánones y derechos de licencia que el comprador tenga que pagar directa o indirectamente'
        >
          <DropDown
            placeholder='Seleccione una opción'
            style={{ margin: 0, width: '100%' }}
            layout={{ span: 24 }}
            name='existenCanones'
            data={bynarySelect}
            onChange={onSelectChange}
            rules={validationRulesForDropdown}
            disabled={disabled}
          />
        </Trow>
        <Trow
          key={'29.1'}
          number='29.1'
          condicion='Indicar en que concepto y el monto declarado en la casilla Nro. 40'
        >
          <CustomInput
            disabled={condicionesState.existenCanones || disabled}
            layout={{ span: 24 }}
            name='razonPago'
            style={{ marginBottom: 0 }}
            required={false}
            rules={condicionesState.existenCanones ? undefined : validationRulesForInput}
          />
        </Trow>
      </Table>
    </section>
  );
};
