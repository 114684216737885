import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Configuraciones } from 'models/Configuraciones';
import httpService, { IUseFetch, ThunckApi } from '../../../../services/httpService';
import { IInitialDataState } from '../initialDataStore';

export const getConfiguraciones = createAsyncThunk(
  'Configuraciones/Get',
  async (fetchOp: IUseFetch, thunkApi: ThunckApi) => {
    const res = httpService(fetchOp, thunkApi);
    return res;
  },
);

export const getConfiguracionesReducer = (builder: ActionReducerMapBuilder<IInitialDataState>) => {
  builder.addCase(getConfiguraciones.fulfilled, (state, action: PayloadAction<Configuraciones>) => {
    state.Configuraciones = {
      ...action.payload,
      precisionDecimal: Number(action.payload.precisionDecimal),
    };
    state.ConfiguracionesStatus = 'succeeded';
  });
  builder.addCase(getConfiguraciones.pending, state => {
    state.ConfiguracionesStatus = 'pending';
  });
  builder.addCase(getConfiguraciones.rejected, state => {
    state.ConfiguracionesStatus = 'failed';
  });
};
