import { AutoComplete } from 'antd';
import { DesignacionProducto } from 'models/DesignacionProducto';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useAppSelector } from 'store/hooks';

export const AgDesignacionCellEditor = forwardRef((props: any, ref) => {
  const { data: DesignacionesProductos } = useAppSelector(state => state.designacionesProductos);
  const [value, setValue] = useState<DesignacionProducto | undefined>(
    DesignacionesProductos.find(p => p.nombre === props.value),
  );

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (props.colDef.field === 'designacion') {
          props.data.designacionId = value?.id;
        }

        return value?.nombre;
      },
    };
  });

  const onChange = (e: string) => {
    setValue(DesignacionesProductos.find(p => p.nombre === e));
  };

  return (
    <div>
      <AutoComplete
        onChange={e => onChange(e)}
        defaultValue={value?.nombre}
        options={DesignacionesProductos.map(designacion => ({
          key: designacion.id,
          value: designacion.nombre,
        }))}
        style={{ width: '100%' }}
        filterOption
      />
    </div>
  );
});
