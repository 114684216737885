import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Pais } from 'models/Pais';
import httpService from 'services/httpService';
import { IPaisesState } from '../paisesStore';

export const updatePais = createAsyncThunk('Paises/Update', httpService);

export const updatePaisReducer = (builder: ActionReducerMapBuilder<IPaisesState>) => {
  // Update
  builder.addCase(updatePais.fulfilled, (state, _action) => {
    const index = state.data.findIndex(pais => pais.id === state.elementoSeleccionado?.id);
    const temporalElement = state.elementoTemporal;

    state.crudStatus = 'succeeded';
    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...temporalElement,
      combinado: String(temporalElement?.abreviatura) + ' - ' + String(temporalElement?.nombre),
    } as Pais);
  });
  builder.addCase(updatePais.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updatePais.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
