import { OrdenesTrabajoTable } from 'features/OrdenDeTrabajoTable/OrdenesTrabajoTable/OrdenesTrabajoTable';
import { getOrdenesTrabajo } from 'features/OrdenDeTrabajoTable/store/extraReducers/getOrdenesTrabajo';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useAuth0UserData } from '../../hooks/useAuth0UserData';
import { useCustomDispatch, UseCustomDispatch } from '../../hooks/useCustomDispatch';
import styles from './Inicio.module.less';
import { ProductosPendientes } from './ProductosPendientes/ProductosPendientes';
import { ProveedoresPendientes } from './ProveedoresPendientes/ProveedoresPendientes';
import { getProductosPendientes } from './store/extraReducers/producto/get';
import { getProveedoresPendientes } from './store/extraReducers/proveedor/get';

export const Inicio: FC = () => {
  const user = useAuth0UserData();
  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const { getProductosPendientesStatus, getProveedoresPendientesStatus } = useAppSelector(
    state => state.inicio,
  );
  const getOrdenesTrabajoEstatus = useAppSelector(state => state.ordenDeTrabajoTable.status);

  useEffect(() => {
    !window.document.title.includes('Inicio') && dispatch(setTitleName('Inicio'));

    if (
      getProductosPendientesStatus === 'idle' &&
      getProveedoresPendientesStatus === 'idle' &&
      getOrdenesTrabajoEstatus === 'idle'
    ) {
      initData.map(item => customDispatch({ ...item }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={user?.isAdmin ? styles.containerMaster : styles.containerMasterNonAdmin}
      style={{ gap: user?.isAdmin ? 24 : 0 }}
    >
      {user?.isAdmin && (
        <>
          <ProveedoresPendientes />
          <ProductosPendientes />
        </>
      )}

      <OrdenesTrabajoTable />
    </div>
  );
};

const initData: UseCustomDispatch[] = [
  {
    asyncThuckFuction: getProductosPendientes,
    endPoint: '/api/Productos/GetPendientes',
  },
  {
    asyncThuckFuction: getProveedoresPendientes,
    endPoint: '/api/Proveedores/GetPendientes',
  },
  {
    asyncThuckFuction: getOrdenesTrabajo,
    endPoint: '/api/OrdenesDeTrabajo/GetAll',
  },
];
