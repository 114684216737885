import { DeleteOutlined, DownloadOutlined, EllipsisOutlined, EyeOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useAppSelector } from 'store/hooks';
import { EstadoOrdenTrabajo } from '../../../enum/EstadoOrdenTrabajo';
import { OrdenDeTrabajo } from '../../../models/OrdenTrabajo';
import { tableColumnDateFilter, tableColumnTextFilter } from '../../../utils/tableColumnFilter';

interface IProps {
  onUpdate: (values: OrdenDeTrabajo) => void;
  onDownloadDevas: (ordenTrabajoId: string) => void;
  onDelete: (ordenTrabajoId: string) => void;
  onDownloadDuca: (ducaId: string) => void;
}

export const Columns = ({
  onUpdate,
  onDownloadDevas,
  onDelete,
  onDownloadDuca,
}: IProps): ColumnsType<any> => {
  const { data: Usuarios } = useAppSelector(state => state.usuarios);

  return [
    {
      title: 'Folio Preimpreso',
      dataIndex: 'folioPreimpreso',
      ellipsis: true,
      width: 300,
      ...tableColumnTextFilter<OrdenDeTrabajo>('folioPreimpreso'),
    },
    {
      title: 'Cliente',
      dataIndex: 'importador',
      ellipsis: true,
      width: 300,
      ...tableColumnTextFilter<OrdenDeTrabajo>('importador'),
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'fechaCreacion',
      width: 200,
      ...tableColumnDateFilter<OrdenDeTrabajo>('fechaCreacion', 'OrdenesDeTrabajoTabla'),
    },
    {
      title: 'Aduana Ingreso',
      dataIndex: 'aduanaDeIngreso',
      width: 200,
      ...tableColumnTextFilter<OrdenDeTrabajo>('aduanaDeIngreso'),
    },
    {
      title: 'Aduana Despacho',
      dataIndex: 'aduanaDeDespacho',
      width: 200,
      ...tableColumnTextFilter<OrdenDeTrabajo>('aduanaDeDespacho'),
    },
    {
      title: 'Contenedor',
      dataIndex: 'contenedor',
      width: 200,
      ...tableColumnTextFilter<OrdenDeTrabajo>('contenedor'),
    },
    {
      title: 'Creado Por',
      dataIndex: 'nombreCreadoPor',
      width: 200,
      filters: Usuarios.map(item => ({
        text: item.nombreDisplay,
        value: item.nombreDisplay,
      })),
      onFilter: (value: any, record: any) => record.nombreCreadoPor.indexOf(value) === 0,
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      width: 160,
      fixed: 'right',
      filters: [
        { text: 'En Progreso', value: 1 },
        { text: 'Borrador', value: 0 },
        { text: 'Cerrada', value: 2 },
        { text: 'En Espera Aprobación', value: 3 },
        { text: 'Inconsistente', value: 4 },
      ],
      onFilter: (value: any, record: any) => {
        const estado = (record.estado as any).props.children.replace(' ', '');
        return estado.indexOf(EstadoOrdenTrabajo[value]) === 0;
      },
    },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 50,
      render: (_: any, record: OrdenDeTrabajo) => (
        <Dropdown
          getPopupContainer={() => document.getElementById('OrdenesDeTrabajoTabla') as HTMLElement}
          overlay={
            <Menu>
              <Menu.Item key='editar' onClick={() => onUpdate(record)} icon={<EyeOutlined />}>
                Ver Detalles
              </Menu.Item>
              {record.countDevas > 0 && record.downloadDevas ? (
                <Menu.Item
                  key='descargarDeva2'
                  onClick={() => onDownloadDevas(record.id)}
                  icon={<DownloadOutlined />}
                >
                  Descargar Devas
                </Menu.Item>
              ) : (
                <Menu.Item key='descargarDevas2' disabled icon={<DownloadOutlined />}>
                  <Tooltip placement='left' title='Está orden de trabajo todavía no tiene devas'>
                    Descargar Devas
                  </Tooltip>
                </Menu.Item>
              )}

              {record.ducaId?.length ? (
                <Menu.Item
                  key='descargarDuca1'
                  onClick={() => onDownloadDuca(record.ducaId ?? '')}
                  icon={<DownloadOutlined />}
                >
                  Descargar Duca
                </Menu.Item>
              ) : (
                <Menu.Item key='descargarDuca2' disabled icon={<DownloadOutlined />}>
                  <Tooltip placement='left' title='Faltan detalles para descargar la duca'>
                    Descargar Duca
                  </Tooltip>
                </Menu.Item>
              )}

              <Popconfirm
                title='¿Estás seguro de eliminar este producto?'
                okText='Eliminar'
                cancelText='Cancelar'
                onConfirm={() => onDelete(record.id)}
                getPopupContainer={() =>
                  document.getElementById('OrdenesDeTrabajoTabla') as HTMLElement
                }
              >
                <Menu.Item key='delete' icon={<DeleteOutlined />} danger>
                  Eliminar
                </Menu.Item>
              </Popconfirm>
            </Menu>
          }
          trigger={['click']}
          placement='bottomRight'
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Dropdown>
      ),
    },
  ];
};
