import moment from 'moment';

type TimeStyle = 'medium' | 'short' | 'full' | 'long' | undefined;

const config: Intl.DateTimeFormatOptions = {
  dateStyle: 'short',
  hour12: true,
};

export const formatDate = (
  value: string | Date,
  lenguaje: string = 'es',
  timeStyle: TimeStyle | null = 'short',
) => {
  return new Intl.DateTimeFormat(
    lenguaje,
    timeStyle ? { ...config, timeStyle: timeStyle } : { ...config },
  ).format(new Date(value));
};

export const formatUTCToLocalDate = (
  date: string,
  format = 'DD/MM/YY hh:mm a',
  defaultValue = '',
): string => {
  return date ? moment.utc(date).local().format(format) : defaultValue;
};
