import { Button, Drawer, Form, FormInstance } from 'antd';
import { InputDisabled } from 'components/FormControls/InputDisabled';
import { TextAreaDisabled } from 'components/FormControls/TextareaDisabled';

interface IDrawerDetalles {
  visible: boolean;
  form: FormInstance;
  setCerrarModal: () => void;
}

export const DrawerDetalles = ({ visible, form, setCerrarModal }: IDrawerDetalles) => {
  return (
    <Drawer
      title='Detalles del Producto'
      width={600}
      onClose={setCerrarModal}
      visible={visible}
      bodyStyle={{ paddingBottom: 0 }}
      maskClosable={false}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={setCerrarModal} style={{ marginRight: 8 }} type='primary' ghost>
            Cerrar
          </Button>
        </div>
      }
    >
      <Form form={form} name='ProductosDetalles' layout='vertical'>
        <InputDisabled name='sku' label='SKU (Modelo)' />
        <InputDisabled name='proveedor' label='Proveedor' />
        <InputDisabled name='partidaArancelaria' label='Partida Arancelaria' />
        <InputDisabled name='designacionProducto' label='Designación del Producto' />
        <TextAreaDisabled name='caracteristicas' label='Características' />
        <TextAreaDisabled name='caracteristicaPrincipal' label='Característica Principal' />
        <TextAreaDisabled name='caracteristicaSecundaria' label='Característica Secundaria' />
        <InputDisabled name='marcaComercial' label='Marca Comercial' />
        <InputDisabled name='paisOrigen' label='Pais Origen' />
        <InputDisabled name='unidadMedida' label='Unidad de Medida' />
        <InputDisabled name='estadoMercancia' label='Estado de Mercancia' />
        <InputDisabled
          name='mostrarDescripcionConPresentacion'
          label='Mostrar Descripción con Presentación'
        />
        <InputDisabled name='codigoDeBarras' label='Codigo de Barras' />
        <InputDisabled name='codigoFitosanitario' label='Codigo Fitosanitario' />
      </Form>
    </Drawer>
  );
};
