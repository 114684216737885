import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { UnidadMedidaNeta } from 'models/UnidadMedidaNeta';
import httpService from 'services/httpService';
import { IUnidadesMedidasNetasState } from '../unidadesMedidasNetasStore';

export const updateUnidadMedidaNeta = createAsyncThunk('UnidadesMedidasNetas/Update', httpService);

export const updateUnidadMedidaNetaReducer = (
  builder: ActionReducerMapBuilder<IUnidadesMedidasNetasState>,
) => {
  builder.addCase(updateUnidadMedidaNeta.fulfilled, (state, _action) => {
    const index = state.data.findIndex(
      unidadMedidaNeta => unidadMedidaNeta.id === state.elementoSeleccionado?.id,
    );

    state.crudStatus = 'succeeded';
    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as UnidadMedidaNeta);
  });
  builder.addCase(updateUnidadMedidaNeta.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updateUnidadMedidaNeta.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
