import { ColDef } from 'ag-grid-community';
import { IDucaItemRequest } from 'models/Duca';

interface IColumns {
  onDetails: (values: IDucaItemRequest) => void;
  onDelete: (key: string) => void;
  onCalculoRapido: (key: string) => void;
  onCalculoRapidoLitrosDeclarados: (key: string) => void;
  ordenCerrada: boolean;
}

export const Columns = ({
  onDetails,
  onDelete,
  onCalculoRapido,
  onCalculoRapidoLitrosDeclarados,
  ordenCerrada,
}: IColumns): Array<ColDef> => [
  {
    headerName: 'Numero Item',
    field: 'numeroItem',
    width: 140,
    filter: 'agNumberColumnFilter',
    rowDrag: true,
    editable: false,
  },
  {
    headerName: 'Positición Arancelaria',
    field: 'posicionArancelaria',
    width: 190,
    cellEditor: 'AgPartidasArancelariasCellEditor',
  },
  { headerName: 'Descripción', field: 'descripcion', width: 300, cellEditor: 'AgInputCellEditor' },
  { headerName: 'Comentarios', field: 'comentario', width: 300, cellEditor: 'AgInputCellEditor' },
  {
    headerName: 'Cantidad Comercial / Cantidad Declarada',
    field: 'cantidadComercial',
    width: 320,
    editable: false,
  },
  {
    headerName: 'Unidad Comercial',
    field: 'unidadComercial',
    width: 190,
    cellEditor: 'AgUnidadesMedidaCellEditor',
  },
  { headerName: 'Cantidad Estadistica', field: 'cantidadEstadistica', width: 190, editable: false },
  {
    headerName: 'Unidad Estadistica',
    field: 'unidadEstadistica',
    width: 190,
    cellEditor: 'AgUnidadesMedidaCellEditor',
  },
  { headerName: 'Fob Otra Moneda', field: 'fobOtraMoneda', width: 180, editable: false },
  { headerName: 'Valor FOB $ / Importe Factura', field: 'importeFOB', width: 240, editable: false },
  { headerName: 'Flete $', field: 'importeFlete', width: 100, editable: false },
  { headerName: 'Seguro $', field: 'importeSeguro', width: 110, editable: false },
  { headerName: 'Otros $', field: 'importeOtrosGastos', width: 100, editable: false },
  { headerName: 'Valor CIF $', field: 'valorCIF', width: 120, editable: false },
  { headerName: 'Grav. Adv % S/CIF', field: 'gravadoPorcentajeCIF', width: 180, editable: false },
  { headerName: 'Total Derechos', field: 'totalDerechos', editable: false },
  { headerName: 'ISV', field: 'isv', width: 100, editable: false },
  { headerName: 'IPC', field: 'ipc', width: 100, editable: false },
  { headerName: 'ISC', field: 'isc', width: 100, editable: false },
  {
    headerName: 'Forma de Liquidación',
    field: 'formaLiquidacion',
    width: 190,
    cellEditor: 'AgInputNumberCellEditor',
  },
  {
    headerName: 'Peso Bruto',
    field: 'pesoBruto',
    width: 140,
    cellEditor: 'AgInputNumberCellEditor',
  },
  { headerName: 'Peso Neto', field: 'pesoNeto', width: 140, cellEditor: 'AgInputNumberCellEditor' },
  { headerName: 'Cantidad Bultos', field: 'cantidadBultos', editable: false },
  {
    headerName: 'Estado de Mercancia',
    field: 'estadoMercancia',
    width: 180,
    cellEditor: 'AgEstadoMercanciaCellEditor',
  },
  {
    headerName: 'Declaración a Cancelar',
    field: 'declaracionCancelar',
    width: 210,
    cellEditor: 'AgInputCellEditor',
  },
  {
    headerName: 'Nro. Item a Cancelar',
    field: 'numeroItemCancelar',
    width: 210,
    cellEditor: 'AgInputNumberCellEditor',
  },
  { headerName: 'Tipo Item', field: 'tipoItem', width: 200, cellEditor: 'AgTipoItemCellEditor' },
  { headerName: 'Pais Origen', field: 'paisOrigen', cellEditor: 'AgPaisesCellEditor' },
  {
    headerName: 'Pais Procedencia Destino',
    field: 'paisProcedenciaDestino',
    width: 230,
    cellEditor: 'AgPaisesCellEditor',
  },
  {
    headerName: 'Pais Adquisición',
    field: 'paisAdquisicion',
    width: 170,
    cellEditor: 'AgPaisesCellEditor',
  },
  {
    headerName: 'Cuota Arancelaria',
    field: 'cuotaArancelaria',
    width: 180,
    cellEditor: 'AgCuotaArancelariaCellEditor',
  },
  {
    headerName: 'Titulo Manifiesto Currier',
    field: 'tituloManifiestoCurrier',
    width: 230,
    cellEditor: 'AgInputCellEditor',
  },
  {
    headerName: 'Id Matriz Insumos',
    field: 'idMatrizInsumos',
    width: 200,
    cellEditor: 'AgInputCellEditor',
  },
  {
    headerName: 'Número Item Asociado',
    field: 'numeroItemAsociado',
    width: 200,
    cellEditor: 'AgInputNumberCellEditor',
  },
  {
    headerName: 'Ajuste a Incluir',
    field: 'ajusteAIncluir',
    cellEditor: 'AgInputNumberCellEditor',
  },
  {
    headerName: 'Número Certificado Importación',
    field: 'numeroResolucion',
    width: 250,
    cellEditor: 'AgInputCellEditor',
  },
  {
    headerName: 'Convenio Perfeccionamiento',
    field: 'convenioPerfectoActivo',
    width: 240,
    cellEditor: 'AgInputCellEditor',
  },
  {
    headerName: 'Número Exoneración Aduana',
    field: 'numeroExoneracionAduana',
    width: 240,
    cellEditor: 'AgInputCellEditor',
  },
  {
    headerName: ' ',
    field: 'opciones',
    cellRenderer: 'dropdownCellRenderer',
    cellRendererParams: {
      values: [
        { onDetails, onDelete, onCalculoRapido, onCalculoRapidoLitrosDeclarados, ordenCerrada },
      ],
    },
    width: 50,
    filter: false,
    pinned: 'right',
    editable: false,
  },
];
