import { Form, FormInstance, Input, Modal, Spin } from 'antd';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { FC, useEffect, useState } from 'react';
import { ClasificacionFenag } from '../../../models/ClasificacionFenag';
import EditableTagGroup from '../EditableTagGroup/EditableTagGroup';

const { Item } = Form;

interface IProps {
  visible: boolean;
  form: FormInstance;
  status: StatusApp.Status;
  elementoSeleccionado: ClasificacionFenag | undefined;
  onCreate: (values: ClasificacionFenag) => void;
  onUpdate: (values: ClasificacionFenag) => void;
  setCerrarModal: () => void;
}

export const ModalUpsert: FC<IProps> = ({
  visible,
  form,
  status,
  elementoSeleccionado,
  onCreate,
  onUpdate,
  setCerrarModal,
}) => {
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    setTags(elementoSeleccionado?.prefijosPosicionArancelaria || []);
  }, [setTags, elementoSeleccionado]);

  return (
    <Modal
      title={elementoSeleccionado ? 'Editar Clasificación Fenag' : 'Crear Clasificación Fenag'}
      keyboard={false}
      visible={visible}
      onOk={
        elementoSeleccionado
          ? () => {
              form
                .validateFields()
                .then(values => {
                  onUpdate({
                    ...values,
                    prefijosPosicionArancelaria: tags,
                  } as ClasificacionFenag);
                  setTags([]);
                })
                .catch(error => console.log(error));
            }
          : () => {
              form
                .validateFields()
                .then(values => {
                  onCreate({
                    ...values,
                    prefijosPosicionArancelaria: tags,
                  } as ClasificacionFenag);
                  setTags([]);
                })
                .catch(error => console.log(error));
            }
      }
      onCancel={() => {
        form.resetFields();
        setTags([]);
        setCerrarModal();
      }}
    >
      <Spin spinning={status === 'pending' ? true : false}>
        <Form
          form={form}
          name='ClasificacionFenag'
          layout='vertical'
          defaultValue={elementoSeleccionado as any}
        >
          <Item
            name='nombre'
            label='Nombre'
            rules={[
              {
                required: true,
                message: 'Por favor ingresa un nombre para la clasificación fenag.',
              },
            ]}
          >
            <Input />
          </Item>
          <CustomInputNumber name='cuotaPorLibra' label='Cuota por libra (Lps.)' type='normal' />
          <Item
            name='prefijosPosicionArancelaria'
            label='Prefijos Posicion Arancelaria. (Haga doble click para editar)'
          >
            <EditableTagGroup tags={tags} setTags={setTags} />
          </Item>
        </Form>
      </Spin>
    </Modal>
  );
};
