import { Table } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { CSSProperties } from 'react';
import styles from './BaseTable.module.less';
import './heigthTable.css';

interface IBaseTable {
  cols: ColumnsType<any>;
  data: object[];
  status: StatusApp.Status;
  size?: SizeType;
  style?: CSSProperties;
  paginationSize?: 'small' | 'default' | undefined;
  pagination?: TablePaginationConfig;
  id?: string;
}

export const BaseTable = ({
  cols,
  data,
  status,
  size,
  style,
  paginationSize,
  pagination,
  id,
}: IBaseTable) => {
  return (
    <div className={styles.scrollContainer} style={style} id={id}>
      <Table
        getPopupContainer={
          id?.length ? () => document.getElementById(id) as HTMLElement : undefined
        }
        columns={cols}
        dataSource={data.map(x => ({ ...x, key: (x as any).key ?? (x as any).id }))}
        loading={status === 'pending'}
        className={styles.table}
        scroll={{
          y: 'auto',
        }}
        bordered
        size={size}
        pagination={
          pagination ?? {
            size: paginationSize,
            defaultPageSize: 20,
          }
        }
      />
    </div>
  );
};
