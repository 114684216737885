import { FormInstance } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { EstadoDucaItem } from 'enum/EstadoDucaItem';
import { useDebounce } from 'hooks/useDebounce';
import { IDucaDocumentoRequest } from 'models/Duca';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { setSaveDucaDocumento } from '../store/store';
import { Bulto } from './Bulto/Bulto';
import { Caratula } from './Caratula/Caratula';
import { DatosComplementarios } from './DatosComplementarios/DatosComplementarios';
import { Documentos } from './Documentos/Documentos';

interface ITabPaneGeneralProps {
  form: FormInstance;
}

export const TabPaneGeneral = ({ form }: ITabPaneGeneralProps) => {
  const dispatch = useDispatch();
  const [bultoRequeridos, setBultoRequerido] = useState<boolean>(true);
  const { ModalidadesEspeciales, ducaDetalles, documentos } = useAppSelector(
    state => state.ducaDetalles,
  );
  const TiposDocumentos = useAppSelector(state => state.ducaDetalles.TiposDocumentos);

  useEffect(() => {
    if (ducaDetalles?.caratula) {
      const modalidadEspecial = ModalidadesEspeciales.find(
        x => x.id === ducaDetalles?.caratula.modalidadEspecialId,
      );

      if (modalidadEspecial?.codigo === 'AI') {
        setBultoRequerido(false);
      } else {
        setBultoRequerido(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ducaDetalles, ModalidadesEspeciales]);

  const onChangeModalidadEspecial = (value: SelectValue) => {
    const modalidadEspecial = ModalidadesEspeciales.find(x => x.id === value);

    if (modalidadEspecial?.codigo === 'AI') {
      setBultoRequerido(false);
    } else {
      setBultoRequerido(true);
    }
  };

  const onChangeTituloTransporte = useDebounce((value: string) => {
    if (value.trimStart().length > 0) {
      const documento = documentos.find(x => x.codigo === '004');
      if (documento) {
        dispatch(
          setSaveDucaDocumento({ record: { ...documento, referencia: value }, key: documento.key }),
        );
      } else {
        const tipoDocumento = TiposDocumentos.find(x => x.codigo === '004');
        if (tipoDocumento) {
          dispatch(
            setSaveDucaDocumento({
              record: {
                key: 'new',
                documentoId: tipoDocumento.id,
                codigo: tipoDocumento.codigo,
                descripcion: tipoDocumento.descripcion,
                referencia: value,
                presencia: true,
                estado: EstadoDucaItem.Create,
              } as IDucaDocumentoRequest,
              key: 'new',
            }),
          );
        }
      }
    }
  });

  return (
    <div>
      <Caratula form={form} onChangeModalidadEspecial={onChangeModalidadEspecial} />
      <Bulto
        form={form}
        requerido={bultoRequeridos}
        onChangeTituloTransporte={onChangeTituloTransporte}
      />
      <Documentos />
      <DatosComplementarios />
    </div>
  );
};
