import { Select } from 'antd';
import { Pais } from 'models/Pais';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useAppSelector } from 'store/hooks';

const { Option } = Select;

export const AgPaisesCellEditor = forwardRef((props: any, ref) => {
  const { data: Paises } = useAppSelector(state => state.paises);
  const [value, setValue] = useState<Pais | undefined>(Paises.find(p => p.nombre === props.value));

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (props.colDef.field === 'origen') {
          props.data.paisAdquisicionId = value?.id;
        }

        if (props.colDef.field === 'paisOrigen') {
          props.data.paisOrigenId = value?.id;
          props.data.paisOrigenOrganizacionId = value?.organizacionId;
        }

        if (props.colDef.field === 'paisProcedenciaDestino') {
          props.data.paisProcedenciaDestinoId = value?.id;
        }

        if (props.colDef.field === 'paisAdquisicion') {
          props.data.paisAdquisicionId = value?.id;
        }

        return value?.nombre;
      },
    };
  });

  const onChange = (e: string) => {
    setValue(Paises.find(p => p.id === e));
  };

  return (
    <div>
      <Select
        showSearch
        optionFilterProp='children'
        defaultValue={value?.id}
        onChange={e => onChange(e)}
        style={{ width: '100%' }}
      >
        {Paises.map(pais => (
          <Option key={pais.id} value={pais.id}>
            {pais.nombre}
          </Option>
        ))}
      </Select>
    </div>
  );
});
