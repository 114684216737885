import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdenDeTrabajo } from '../../../models/OrdenTrabajo';
import { RootState } from '../../../store/store';
import { deleteOrdenTrabajoReducer } from './extraReducers/deleteOrdenTrabajo';
import { getOrdenesTrabajoReducer } from './extraReducers/getOrdenesTrabajo';

export interface IInitialState {
  apiURL: string;
  status: StatusApp.Status;
  data: Array<OrdenDeTrabajo>;
  ordenTrabajoSeleccionada: OrdenDeTrabajo | undefined;
}

const initialState: IInitialState = {
  apiURL: '/api/OrdenesDeTrabajo',
  status: 'idle',
  data: [],
  ordenTrabajoSeleccionada: undefined,
};

export const ordenDeTrabajoTableSlice = createSlice({
  name: 'OrdenTrabajoTable',
  initialState,
  reducers: {
    addOrdenTrabajo: (state, action: PayloadAction<OrdenDeTrabajo>) => {
      state.data.unshift(action.payload);
    },
  },
  extraReducers: builder => {
    getOrdenesTrabajoReducer(builder);
    deleteOrdenTrabajoReducer(builder);
  },
});

export const { addOrdenTrabajo } = ordenDeTrabajoTableSlice.actions;

export const OrdenTrabajoState = (state: RootState) => state.ordenDeTrabajoTable;
export const OrdenDeTrabajoTableReducer = ordenDeTrabajoTableSlice.reducer;
