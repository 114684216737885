import {
  DeleteOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Deva } from 'models/Deva';
import { formatCurrency } from 'utils/formatCurrency';

interface ICols {
  onDetails: (selected: Deva) => void;
  onDownload: (devaId: Deva) => void;
  onDelete: (devaId: string) => void;
  onAgregarProductos: (devaId: string, proveedor: string, proveedorId: string) => void;
  cerrada: boolean;
}

export const cols = ({ onDetails, onDownload, onDelete, onAgregarProductos, cerrada }: ICols) => {
  return [
    {
      title: 'Proveedor',
      dataIndex: 'proveedor',
      ellipsis: true,
      width: 200,
    },
    {
      title: 'Número de Factura',
      dataIndex: 'numFactura',
      width: 100,
      render: (_, record) => {
        if (record.numeroFactura) return Array.from(new Set(record.numeroFactura)).join(', ');
        return '-';
      },
    },
    {
      title: 'Valor FOB',
      dataIndex: 'valorFOB',
      ellipsis: true,
      width: 100,
      render: (_, record) => {
        if (record.valorFOB) return formatCurrency(record.valorFOB);
        return '0';
      },
    },
    {
      title: 'Flete',
      dataIndex: 'flete',
      ellipsis: true,
      width: 100,
      render: (_, record) => {
        if (record.flete) return formatCurrency(record.flete);
        return '0';
      },
    },
    {
      title: 'Seguro',
      dataIndex: 'seguro',
      ellipsis: true,
      width: 100,
      render: (_, record) => {
        if (record.seguro) return formatCurrency(record.seguro);
        return '0';
      },
    },
    {
      title: 'Otros Gastos',
      dataIndex: 'otrosGastos',
      ellipsis: true,
      width: 100,
      render: (_, record) => {
        if (record.otrosGastos) return formatCurrency(record.otrosGastos);
        return '0';
      },
    },
    {
      title: 'Valor CIF',
      dataIndex: 'valorCIF',
      ellipsis: true,
      width: 100,
      render: (_, record) => {
        if (record.valorCIF) return formatCurrency(record.valorCIF);
        return '0';
      },
    },
    {
      dataIndex: 'opciones',
      fixed: 'right',
      width: 40,
      render: (_: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='detalles' onClick={() => onDetails(record)} icon={<EyeOutlined />}>
                Detalles
              </Menu.Item>
              {record.incotermId !== null ? (
                <Menu.Item
                  key='editar'
                  onClick={() => onDownload(record)}
                  icon={<DownloadOutlined />}
                >
                  Descargar DEVA
                </Menu.Item>
              ) : (
                <Menu.Item
                  key='editar'
                  disabled
                  icon={<DownloadOutlined />}
                  title='Faltan datos para descargar la Deva'
                >
                  Descargar DEVA
                </Menu.Item>
              )}
              {!cerrada && (
                <>
                  <Menu.Item
                    key='agregarProductos'
                    onClick={() =>
                      onAgregarProductos(record.id, record.proveedor, record.proveedorId)
                    }
                    icon={<PlusOutlined />}
                  >
                    Agregar Productos
                  </Menu.Item>
                  <Popconfirm
                    title='¿Estás seguro de eliminar esta deva?'
                    okText='Eliminar'
                    cancelText='Cancelar'
                    onConfirm={() => onDelete(record.id)}
                  >
                    <Menu.Item key='eliminar' icon={<DeleteOutlined />} danger>
                      Eliminar
                    </Menu.Item>
                  </Popconfirm>
                </>
              )}
            </Menu>
          }
          trigger={['click']}
          placement='bottomRight'
        >
          <EllipsisOutlined
            style={{
              fontSize: 20,
              verticalAlign: 'top',
            }}
          />
        </Dropdown>
      ),
    },
  ] as ColumnsType<any>;
};
