import { getDesignacionesProductos } from 'features/DesignacionesProductos/store/extraReducer/get';
import { getEstadosMercancias } from 'features/EstadosMecaderias/store/extraReducer/get';
import { getImportadores } from 'features/Importadores/store/extraReducers/get';
import { getMarcasComerciales } from 'features/MarcasComerciales/store/extraReducers/getMarcasComerciales';
import { getPaises } from 'features/Paises/store/extraReducers/getPaises';
import { getProveedores } from 'features/Proveedores/store/extraReducers/getProveedoresReducer';
import { getTratados } from 'features/Tratados/store/extraReducer/getTratados';
import { getUnidadesMedidasNetas } from 'features/UnidadesMedidasNetas/store/extraReducer/getUnidades';
import { getUsuarios } from 'features/Usuarios/store/extraReducer/getUsuarios';
import { UseCustomDispatch } from 'hooks/useCustomDispatch';
import { getAduanas } from './store/extraReducers/getAduanas';
import { getClasesBulto } from './store/extraReducers/getClasesBulto';
import { getCondicionesComerciales } from './store/extraReducers/getCondicionesComerciales';
import { getConfiguraciones } from './store/extraReducers/getConfiguraciones';
import { getFormasDePago } from './store/extraReducers/getFormaDePago';
import { getFormasDeEnvio } from './store/extraReducers/getFormasDeEnvio';
import { getIncoterms } from './store/extraReducers/getIncoterms';
import { getMonedasDePago } from './store/extraReducers/getMonedasDePago';
import { getNivelesComerciales } from './store/extraReducers/getNivelesComerciales';
import { getOrganizacionesPaises } from './store/extraReducers/getOrganizacionesPaises';
import { getTiposIntermediario } from './store/extraReducers/getTiposIntermediario';

const InicioData: UseCustomDispatch[] = [
  {
    asyncThuckFuction: getConfiguraciones,
    endPoint: '/api/Configuraciones/GetConfiguraciones',
    method: 'GET',
  },
  {
    asyncThuckFuction: getUsuarios,
    endPoint: '/api/Usuarios/GetAll',
  },
  {
    asyncThuckFuction: getPaises,
    endPoint: '/api/Paises/GetAll',
  },
  {
    asyncThuckFuction: getMarcasComerciales,
    endPoint: '/api/MarcasComerciales/GetAll',
  },
  {
    asyncThuckFuction: getProveedores,
    endPoint: '/api/Proveedores/GetAll',
  },
  {
    asyncThuckFuction: getTratados,
    endPoint: '/api/Tratados/GetAll',
  },
  {
    asyncThuckFuction: getUnidadesMedidasNetas,
    endPoint: '/api/UnidadesMedidasNetas/GetAll',
  },
  {
    asyncThuckFuction: getAduanas,
    endPoint: '/api/OrdenesDeTrabajo/GetAllAduana',
  },
  {
    asyncThuckFuction: getCondicionesComerciales,
    endPoint: '/api/ConfiguracionesPech/GetCondicionesComerciales',
  },
  {
    asyncThuckFuction: getFormasDePago,
    endPoint: '/api/ConfiguracionesPech/GetFormasDePago',
  },
  {
    asyncThuckFuction: getFormasDeEnvio,
    endPoint: '/api/ConfiguracionesPech/GetFormasDeEnvio',
  },
  {
    asyncThuckFuction: getIncoterms,
    endPoint: '/api/ConfiguracionesPech/GetIncoterms',
  },
  {
    asyncThuckFuction: getMonedasDePago,
    endPoint: '/api/ConfiguracionesPech/GetMonedasDePago',
  },
  {
    asyncThuckFuction: getNivelesComerciales,
    endPoint: '/api/ConfiguracionesPech/GetNivelesComerciales',
  },
  {
    asyncThuckFuction: getTiposIntermediario,
    endPoint: '/api/ConfiguracionesPech/GetTiposIntermediario',
  },
  {
    asyncThuckFuction: getEstadosMercancias,
    endPoint: '/api/EstadosMercancias/GetAll',
  },
  {
    asyncThuckFuction: getImportadores,
    endPoint: '/api/Importadores/GetAll',
  },
  {
    asyncThuckFuction: getClasesBulto,
    endPoint: '/api/ConfiguracionesSarah/ClasesBulto/GetAll',
  },
  {
    asyncThuckFuction: getDesignacionesProductos,
    endPoint: '/api/DesignacionesProductos/GetAll',
  },
  {
    asyncThuckFuction: getOrganizacionesPaises,
    endPoint: '/api/Paises/GetAllOrganizacionesPaises',
  },
];

export const initialDataToLoad = InicioData;
