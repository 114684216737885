import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { ITratadosState } from '../tratadosStore';

export const getTratados = createAsyncThunk('Tratados/GetAll', httpService);

export const getTratadosReducer = (builder: ActionReducerMapBuilder<ITratadosState>) => {
  builder.addCase(getTratados.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.data = action.payload;
  });
  builder.addCase(getTratados.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getTratados.rejected, (state, _action) => {
    state.status = 'failed';
  });
};
