import { SerializedError } from '@reduxjs/toolkit';
import { setError } from 'features/InitialData/store/initialDataStore';
import { IUseFetch, ThunckApi } from './httpService';

export const httpServiceBlob = async ({ endPoint, token }: IUseFetch, thunckApi: ThunckApi) => {
  const api: string = String(process.env.REACT_APP_API_URL);

  const headers = {
    Authorization: `Bearer ${await token}`,
  };

  const config: RequestInit = {
    method: 'GET',
    headers: {
      ...headers,
    },
  };

  try {
    const response = await fetch(`${api}${endPoint}`, config);

    if (!response.ok) throw response;
    const blob: Blob = await response.blob();
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(response.headers.get('Content-Disposition') ?? '');
    const fileName = matches?.length ? matches[1].replace(/['"]/g, '') : 'no-name';

    saveFile(blob, fileName);
  } catch (error: any) {
    const err = {
      message: error?.FriendlyMessage,
      name: error?.ErrorMessage || 'Error ',
    } as SerializedError;
    thunckApi.dispatch(
      setError({
        error: true,
        message: String(err?.message || 'Error de conexión'),
      }),
    );
    throw err;
  }
};

const saveFile = (blob: Blob, name: string) => {
  const file: string = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = file;
  a.download = name;
  a.click();
  URL.revokeObjectURL(file);
};
