import { Button, Form, FormInstance, Input, Select } from 'antd';
import { IDucaDocumentoRequest } from 'models/Duca';
import { useAppSelector } from 'store/hooks';

const { Item } = Form;
const { Option } = Select;

interface IFormDocumento {
  form: FormInstance<IDucaDocumentoRequest>;
  onFinish: (values: IDucaDocumentoRequest) => void;
  onChange: (value: string, option: any) => void;
  disabled: boolean;
}

export const FormDocumento = ({ form, onFinish, onChange, disabled }: IFormDocumento) => {
  const TiposDocumentos = useAppSelector(state => state.ducaDetalles.TiposDocumentos);
  const rules = [{ required: true, message: 'Campo requerido' }];

  return (
    <Form
      form={form}
      layout='vertical'
      style={{ display: 'flex', alignItems: 'end', gap: 16 }}
      onFinish={disabled ? undefined : onFinish}
    >
      <Item label='Documento' name='documentoId' rules={rules}>
        <Select
          style={{ width: 300 }}
          showSearch
          optionFilterProp='children'
          onChange={onChange}
          allowClear
        >
          {TiposDocumentos.map(tipo => (
            <Option key={tipo.id} value={tipo.id}>
              {`${tipo.codigo} - ${tipo.descripcion}`}
            </Option>
          ))}
        </Select>
      </Item>
      <Item label='Referencia' name='referencia' rules={rules}>
        <Input style={{ width: 315 }} />
      </Item>
      <Item label='Presencia' name='presencia' rules={rules}>
        <Select style={{ width: 110 }}>
          <Option key='true' value='1'>
            Sí
          </Option>
          <Option key='false' value='0'>
            No
          </Option>
        </Select>
      </Item>
      <Item>
        <Button type='primary' htmlType='submit' disabled={disabled}>
          Asignar
        </Button>
      </Item>
    </Form>
  );
};
