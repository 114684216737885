import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IClasificacionesFenagState } from '../clasificacionesFenagStore';

export const getClasificacionesFenag = createAsyncThunk('ClasificacionesFenag/GetAll', httpService);

export const getClasificacionesFenagReducer = (
  builder: ActionReducerMapBuilder<IClasificacionesFenagState>,
) => {
  builder.addCase(getClasificacionesFenag.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.data = action.payload;
  });
  builder.addCase(getClasificacionesFenag.pending, (state, _action) => {
    state.status = 'pending';
  });
  builder.addCase(getClasificacionesFenag.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
  });
};
