import { Col, Divider, Form, Modal, Row } from 'antd';
import { CustomInputNumber } from 'components/FormControls/CustomInputNumber';
import { InputDisabled } from 'components/FormControls/InputDisabled';
import { setItemCalculoRapido } from 'features/DucaDetalles/store/store';
import { IDucaItemRequest } from 'models/Duca';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';

interface ItemRequestCalculoRapidoLitrosDeclarados extends IDucaItemRequest {
  size: number;
  pack: number;
  tasaCambioDolar: number;
}

interface IModalCalculoRapidoLitrosDeclarados {
  visible: boolean;
  item: IDucaItemRequest | undefined;
  onClose: () => void;
}

export const ModalCalculoRapidoLitrosDeclarados = ({
  visible,
  item,
  onClose,
}: IModalCalculoRapidoLitrosDeclarados) => {
  const { Configuraciones } = useAppSelector(state => state.initialData);
  const { ConversionOnzasLitros } = useAppSelector(state => state.ducaDetalles);
  const [form] = Form.useForm<ItemRequestCalculoRapidoLitrosDeclarados>();
  const dispatch = useDispatch();

  // Valores Iniciales
  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        ...item,
        size: NumberFormat(item.size),
        pack: NumberFormat(item.pack),
        cantidadEstadistica: NumberFormat(item.cantidadEstadistica),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const recalcularLitros = () => {
    const { size, pack, cantidadComercial } = form.getFieldsValue();
    const FactorOnzasLitros = ConversionOnzasLitros?.factor ?? 0;
    const totalOnzas = size * pack * cantidadComercial;
    const totalLitros = totalOnzas / FactorOnzasLitros;

    form.setFieldsValue({
      cantidadEstadistica: Number(totalLitros.toFixed(Configuraciones?.precisionDecimal)),
    });
  };

  const onSave = () => {
    const size: number = form.getFieldValue('size');
    const pack: number = form.getFieldValue('pack');
    const cantidadEstadistica: number = form.getFieldValue('cantidadEstadistica');

    if (item) {
      dispatch(
        setItemCalculoRapido({
          key: item?.key ?? '',
          record: {
            ...item,
            size,
            pack,
            cantidadEstadistica,
          },
        }),
      );
    }

    onClose();
  };

  return (
    <Modal
      title='Calculo Rapido para Litros Declarados'
      visible={visible}
      onCancel={onClose}
      okText='Guardar'
      onOk={onSave}
      width={600}
    >
      <Form form={form} layout='vertical'>
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <InputDisabled name='posicionArancelaria' label='Posición Arancelaria' />
          </Col>
          <Col span={16}>
            <InputDisabled name='descripcion' label='Descripción' />
          </Col>
        </Row>
        <Divider>Datos para bebidas</Divider>
        <Row gutter={[24, 24]}>
          <CustomInputNumber
            name='size'
            label='Size'
            onChange={_e => recalcularLitros()}
            layout={{ span: 12 }}
          />
          <CustomInputNumber
            name='pack'
            label='Pack'
            onChange={_e => recalcularLitros()}
            layout={{ span: 12 }}
          />
        </Row>
        <Row gutter={[24, 24]}>
          <CustomInputNumber
            name='cantidadComercial'
            label='Cantidad'
            onChange={_e => recalcularLitros()}
            layout={{ span: 12 }}
          />
          <CustomInputNumber
            name='cantidadEstadistica'
            label='Litros'
            layout={{ span: 12 }}
            onChange={_e => recalcularLitros()}
          />
        </Row>
      </Form>
    </Modal>
  );
};

export const NumberFormat = (value: number, presition?: number) =>
  Number(value.toFixed(presition ?? 4));
