import { Form } from 'antd';
import { useFilter } from 'hooks/useFilter';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { setTitleName } from '../../components/Sidebar/store/sidebarStore';
import { useCustomDispatch } from '../../hooks/useCustomDispatch';
import { Importador } from '../../models/Importador';
import { useAppSelector } from '../../store/hooks';
import { Columns } from './Columns';
import { DrawerUpsert } from './DrawerUpsert/DrawerUpsert';
import styles from './Importadores.module.less';
import { updateImportador } from './store//extraReducers/update';
import {
  setCrudStatusIdle,
  setImportadorTemporal,
  setSelectedImportador,
  toggleModalVisible,
} from './store/store';
import { Toolbar } from './Toolbar/Toolbar';

export const Importadores = () => {
  const { status, data, crudStatus, elementoSeleccionado, modalVisible } = useAppSelector(
    state => state.importadores,
  );

  const dispatch = useDispatch();
  const customDispatch = useCustomDispatch();
  const [form] = Form.useForm<Importador>();

  const onCloseModal = () => {
    dispatch(toggleModalVisible());
    dispatch(setSelectedImportador(undefined));
    form.resetFields();
  };

  const openDrawerAndFill = (record: Importador) => {
    dispatch(toggleModalVisible());
    dispatch(setSelectedImportador(record));
    form.setFieldsValue(record);
  };

  const onUpdate = (record: Importador) => {
    dispatch(setImportadorTemporal(record));
    customDispatch({
      asyncThuckFuction: updateImportador,
      endPoint: `/api/Importadores/Update/${elementoSeleccionado?.id}`,
      body: record,
      method: 'PUT',
    });
  };

  const cols = Columns({ onUpdate: openDrawerAndFill });

  useEffect(() => {
    !window.document.title.includes('Importadores') && dispatch(setTitleName('Importadores'));

    if (crudStatus === 'succeeded') {
      dispatch(setCrudStatusIdle());
      form.resetFields();
    }
  }, [crudStatus, dispatch, form]);

  const [onfilter, filteredData] = useFilter<Importador>({
    data,
    filterPredicate: (item, filter) => item.nombre.toLowerCase().includes(filter.toLowerCase()),
  });

  return (
    <section className={styles.container}>
      <Toolbar setNombre={onfilter} />
      <BaseTable
        cols={cols}
        data={filteredData}
        status={status}
        size='small'
        style={{ height: 'calc(100% - 40px)' }}
      />
      <DrawerUpsert
        form={form}
        visible={modalVisible}
        elementoSeleccionado={elementoSeleccionado}
        status={status}
        setCerrarModal={onCloseModal}
        onUpdate={onUpdate}
      />
    </section>
  );
};
