import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Proveedor } from '../../../../models/Proveedor';
import { IProveedoresState } from '../proveedoresStore';
import httpService from '../../../../services/httpService';

export const updateProveedor = createAsyncThunk('Proveedors/Update', httpService);

export const updateProveedorReducer = (builder: ActionReducerMapBuilder<IProveedoresState>) => {
  builder.addCase(updateProveedor.fulfilled, (state, _action) => {
    const index = state.data.findIndex(
      proveedor => proveedor.id === state.elementoSeleccionado?.id,
    );

    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as Proveedor);

    state.crudStatus = 'succeeded';
  });
  builder.addCase(updateProveedor.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updateProveedor.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
