import { Col, Layout } from 'antd';
import { FC } from 'react';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import styles from './Layout.module.less';

const { Content } = Layout;

const LayoutContainer: FC = ({ children }: any) => {
  return (
    <Layout className={styles.layout}>
      <Navbar />
      <Layout>
        <Sidebar />
        <Content className={styles.content}>
          <Col className={styles.contentChildren}>{children}</Col>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutContainer;
