import { ColDef } from 'ag-grid-community';

interface IColumns {
  onDelete: (id: string) => void;
}

export const Columns = ({ onDelete }: IColumns): Array<ColDef> => [
  {
    headerName: 'PDA',
    field: 'pda',
    width: 180,
    editable: false,
  },
  {
    headerName: 'Número de Factura',
    field: 'numeroFactura',
    width: 180,
    editable: true,
    cellEditor: 'AgInputCellEditor',
  },
  {
    headerName: 'Fecha de Factura',
    field: 'fechaFactura',
    width: 170,
    editable: true,
    cellEditor: 'AgDateCellEditor',
    filter: 'agDateColumnFilter',
    filterParams,
  },
  {
    headerName: 'Clasificación Arancelaria',
    field: 'partidaArancelaria',
    width: 210,
    editable: true,
    cellEditor: 'AgPartidasArancelariasCellEditor',
  },
  {
    headerName: 'Cantidad Comercial',
    field: 'cantidad',
    width: 180,
    editable: true,
    cellEditor: 'AgInputNumberCellEditor',
  },
  {
    headerName: 'Unidad de Medida',
    field: 'unidadMedida',
    width: 170,
    editable: true,
    cellEditor: 'AgUnidadesMedidaCellEditor',
  },
  {
    headerName: 'Designación o Identificación de las marcancías',
    field: 'designacion',
    width: 340,
    editable: true,
    cellEditor: 'AgDesignacionCellEditor',
  },
  {
    headerName: 'Carácteristicas de las mercancías',
    field: 'caracteristicas',
    width: 250,
    editable: true,
    cellEditor: 'AgInputCellEditor',
  },
  {
    headerName: 'Marca',
    field: 'marca',
    width: 180,
    editable: true,
    cellEditor: 'AgMarcasComercialesCellEditor',
  },
  { headerName: 'Código Modelo y/o Estilo', field: 'modelo', width: 210, editable: false },
  {
    headerName: 'Estado de las Mercancías',
    field: 'estadoMercancia',
    width: 210,
    editable: true,
    cellEditor: 'AgEstadoMercanciaCellEditor',
  },
  {
    headerName: 'Origen de las mercancías',
    field: 'origen',
    width: 210,
    editable: true,
    cellEditor: 'AgPaisesCellEditor',
  },
  {
    headerName: 'Valor Unitario USD',
    field: 'valorUnitario',
    width: 170,
    editable: true,
    cellEditor: 'AgInputNumberCellEditor',
  },
  {
    headerName: 'Valor Total',
    field: 'valorTotal',
    width: 120,
    editable: true,
    cellEditor: 'AgInputNumberCellEditor',
  },
  {
    headerName: ' ',
    field: 'opciones',
    cellRenderer: 'dropdownCellRenderer',
    cellRendererParams: {
      values: [{ onDelete }],
    },
    width: 65,
    filter: false,
    pinned: 'right',
    editable: false,
  },
];

const filterParams = {
  comparator(filterLocalDateAtMidnight: any, cellValue: any) {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split('/');
    const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
