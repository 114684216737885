import { IDucaItemRequest } from 'models/Duca';
import { Fragment } from 'react';
import { useAppSelector } from 'store/hooks';
import { DuaCell, DuaCol, DuaData, DuaRow } from './DuaTable';
import { FormatoPartidaArancelaria } from './utils/FormatoPartidaArancelaria';
import { GetDecimals } from './utils/GetDecimals';
import { NumberFormat } from './utils/NumberFormat';

interface IContinuacionPartidasAdicionales {
  ultimaPDA: number;
  productos: IDucaItemRequest[];
}

export const ContinuacionPartidasAdicionales = ({
  ultimaPDA,
  productos,
}: IContinuacionPartidasAdicionales) => {
  const data = useAppSelector(state => state.archivoPreliquidacion.data);
  const border = '1px solid #000';
  const date = new Date();

  return (
    <DuaCol noBorder style={{ height: 'auto' }}>
      <DuaRow
        className='rowDoubleLine'
        style={{ display: 'grid', gridTemplateColumns: '16% 42% 25% 17%', textAlign: 'center' }}
      >
        <DuaCol>
          <DuaCell>N° Impreso y serie del Formato Principal</DuaCell>
        </DuaCol>
        <DuaCol>
          <DuaRow noBorder style={{ borderBottom: border }}>
            <DuaCol width={'70%'}>
              <DuaCell noBorder style={{ padding: 0 }}>
                N° Unico de Declaracion (1)
              </DuaCell>
            </DuaCol>
            <DuaCol width={'30%'}>
              <DuaCell>FECHA DE ADMISION</DuaCell>
            </DuaCol>
          </DuaRow>
          <DuaRow noBorder>
            <DuaCol width={'70%'}>
              <DuaRow noBorder>
                <DuaCol width={'32%'}>
                  <DuaCell style={{ padding: 0 }}>CODIGO REGIMEN</DuaCell>
                </DuaCol>
                <DuaCol width={'14%'}>
                  <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                    CODIGO ADUANA
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'10%'}>
                  <DuaCell style={{ padding: 0 }}>AÑO</DuaCell>
                </DuaCol>
                <DuaCol width={'44%'}>
                  <DuaCell>N° CORRELATIVO</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'30%'}>
              <DuaCell className='cellDoubleLine' style={{ paddingTop: 3 }}>
                DIA MES AÑO
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>

        <DuaCol>
          <DuaCell>AGENTE ADUANERO (6)</DuaCell>
        </DuaCol>
        <DuaCol>
          <DuaCell>R.T.N. (7) COD</DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ textAlign: 'center' }}>
        <DuaCol width={'16%'}>
          <DuaCell>
            {/* Numero de Preimpreso */}
            <DuaData>{data?.caratula.numeroPreImpreso}</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'42%'}>
          <DuaRow noBorder>
            <DuaCol width={'70%'}>
              <DuaRow noBorder>
                <DuaCol width={'32%'}>
                  <DuaCell>
                    {/* Codigo Regimen */}
                    <DuaData>{data?.caratula.regimenAduaneroCodigo}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'14%'}>
                  <DuaCell>
                    {/* Codigo Aduana */}
                    <DuaData>{data?.caratula.aduanaDespachoCodigo}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'10%'}>
                  <DuaCell style={{ padding: 0 }}>
                    {/* Año */}
                    <DuaData>{date.getFullYear()}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'44%'}>
                  <DuaCell>
                    {/* N° Correlativo */}
                    <DuaData>&nbsp;</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'30%'}>
              <DuaCell>
                {/* Dia Mes Año */}
                <DuaData>{`${date.getDate()} / ${date.getMonth()} / ${date.getFullYear()}`}</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaCell>
            {/* Agente Aduanero */}
            <DuaData>&nbsp;</DuaData>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'17%'}>
          <DuaRow noBorder>
            <DuaCol width={'65%'}>
              <DuaCell>
                {/* RTN */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'35%'}>
              <DuaCell>
                {/* Codigo */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>
      <DuaRow>
        <DuaCol width={'16%'}>
          <DuaCell>&nbsp;</DuaCell>
        </DuaCol>
        <DuaCol width={'42%'}>
          <DuaRow noBorder>
            <DuaCol width={'70%'}>
              <DuaRow noBorder>
                <DuaCol width={'32%'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'14%'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'10%'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'44%'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'30%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'25%'}>
          <DuaCell>&nbsp;</DuaCell>
        </DuaCol>
        <DuaCol width={'17%'}>
          <DuaRow noBorder>
            <DuaCol width={'65%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'35%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
      </DuaRow>
      <DuaRow
        className='rowDoubleLine'
        style={{ display: 'grid', gridTemplateColumns: '58% 21% 21%', textAlign: 'center' }}
      >
        <DuaCol>
          <DuaCell>VIENEN PARTIDAS ADICIONALES DEL FORMATO PRINCIPAL</DuaCell>
        </DuaCol>
        <DuaCol>
          <DuaCell>TOTAL PARTIDAS:</DuaCell>
        </DuaCol>
        <DuaCol>
          <DuaCell>TOTAL KILOS BRUTOS: </DuaCell>
        </DuaCol>
      </DuaRow>

      {/* VI. DECLARACION DE LA MERCANCIA */}
      <DuaRow>
        <DuaCol>
          <DuaCell>VI. DECLARACION DE LA MERCANCIA</DuaCell>
        </DuaCol>
      </DuaRow>
      <DuaRow style={{ textAlign: 'center' }}>
        <DuaCol width={'20%'}>
          <DuaCell>BULTOS</DuaCell>
          <DuaRow noBorder>
            <DuaCol width={'13%'}>
              <DuaCell style={{ padding: 0 }}>PDA</DuaCell>
            </DuaCol>
            <DuaCol width={'28%'}>
              <DuaCell style={{ padding: 0 }}>CANTIDAD</DuaCell>
            </DuaCol>
            <DuaCol width={'21%'}>
              <DuaCell className='cellDoubleLine' style={{ padding: 0 }}>
                CODIGO UNIDAD
              </DuaCell>
            </DuaCol>
            <DuaCol width={'38%'}>
              <DuaCell>KILOSBRUTOS</DuaCell>
            </DuaCol>
          </DuaRow>
        </DuaCol>
        <DuaCol width={'10%'} style={{ position: 'absolute', left: '20%' }}>
          <DuaCell>POSICION ARANCELARIA</DuaCell>
        </DuaCol>
        <DuaCol
          width={'36%'}
          style={{ position: 'absolute', left: '30%', justifyContent: 'flex-start', paddingTop: 3 }}
        >
          <DuaCell>
            DESCRIPCION DE LA MERCANCIA CARACTERISTICAS FISICAS Y TECNICAS (NO ARANCELARIA)
          </DuaCell>
        </DuaCol>
        <DuaCol width={'10%'} style={{ position: 'absolute', left: '66%' }}>
          <DuaCell>VALOR FOB $</DuaCell>
        </DuaCol>
        <DuaCol width={'10%'} style={{ position: 'absolute', left: '76%' }}>
          <DuaCell>VALOR CIF $</DuaCell>
        </DuaCol>
        <DuaCol
          width={'4%'}
          style={{
            position: 'absolute',
            left: '86%',
            justifyContent: 'flex-start',
            paddingTop: 3,
          }}
        >
          <DuaCell className='cellDoubleLine' style={{ lineHeight: '6px', padding: 0 }}>
            <div>GRAV.</div>
            <br />
            <div>ADV</div>
            <br />
            <div>% S/CIF</div>
          </DuaCell>
        </DuaCol>
        <DuaCol width={'10%'} style={{ position: 'absolute', left: '90%' }}>
          <DuaCell>TOTAL DERECHOS</DuaCell>
        </DuaCol>
      </DuaRow>

      {/* Productos */}
      {productos.map((producto, index) => (
        <Fragment key={index}>
          <DuaRow style={{ textAlign: 'center' }}>
            <DuaCol width={'20%'}>
              <DuaRow noBorder>
                <DuaCol width={'13%'}>
                  <DuaCell style={{ padding: 0 }}>
                    {/* PDA */}
                    <DuaData>{index + ultimaPDA}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'28%'}>
                  <DuaCell style={{ padding: 0 }}>
                    {/* Cantidad */}
                    <DuaData>{NumberFormat(producto?.cantidadComercial)}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'21%'}>
                  <DuaCell style={{ padding: 0 }}>
                    {/* Codigo Unidad */}
                    <DuaData>{producto?.unidadComercialCodigo}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'38%'}>
                  <DuaCell>
                    {/* Kilos Brutos */}
                    <DuaData>{NumberFormat(producto?.pesoBruto)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaCell>
                {/* Posicion Arancelaria */}
                <DuaData className='posicionArancelaria'>
                  {FormatoPartidaArancelaria(producto?.posicionArancelaria)}
                </DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'36%'} style={{ textAlign: 'start' }}>
              <DuaCell>
                {/* Descripcion */}
                <DuaData>{producto?.descripcion}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* Valor Fob Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(producto.importeFOB))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* Valor Fob Decimales */}
                    <DuaData>.{GetDecimals(producto.importeFOB)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* Valor Cif Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(producto.valorCIF))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* Valor Cif Decimales */}
                    <DuaData>.{GetDecimals(producto.valorCIF)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'4%'}>
              <DuaCell>
                {/* Dai */}
                <DuaData>{producto.gravadoPorcentajeCIF}</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'} style={{ textAlign: 'end' }}>
                  <DuaCell>
                    {/* Total Derechos Enteros */}
                    <DuaData>{NumberFormat(Math.trunc(producto.totalDerechos))}</DuaData>
                  </DuaCell>
                </DuaCol>
                <DuaCol width={'30px'} style={{ textAlign: 'start' }}>
                  <DuaCell>
                    {/* Total Derechos Decimales */}
                    <DuaData>.{GetDecimals(producto.totalDerechos)}</DuaData>
                  </DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow style={{ textAlign: 'center' }}>
            <DuaCol width={'20%'}>
              <DuaRow noBorder>
                <DuaCol width={'13%'}>
                  <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'28%'}>
                  <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'21%'}>
                  <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'38%'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'36%'}>
              <DuaCell>
                {/* Descripcion */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'4%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
          <DuaRow>
            <DuaCol width={'20%'}>
              <DuaRow noBorder>
                <DuaCol width={'13%'}>
                  <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'28%'}>
                  <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'21%'}>
                  <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'38%'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'36%'}>
              <DuaCell>
                {/* Descripcion */}
                <DuaData>&nbsp;</DuaData>
              </DuaCell>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
            <DuaCol width={'4%'}>
              <DuaCell>&nbsp;</DuaCell>
            </DuaCol>
            <DuaCol width={'10%'}>
              <DuaRow noBorder>
                <DuaCol width={'calc(100% - 30px)'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
                <DuaCol width={'30px'}>
                  <DuaCell>&nbsp;</DuaCell>
                </DuaCol>
              </DuaRow>
            </DuaCol>
          </DuaRow>
        </Fragment>
      ))}

      {/* Productos Vacios */}
      {Array(16 - productos.length)
        .fill(0)
        .map((_, index) => (
          <Fragment key={index}>
            <DuaRow>
              <DuaCol width={'20%'}>
                <DuaRow noBorder>
                  <DuaCol width={'13%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'28%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'21%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'38%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'36%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'4%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
            </DuaRow>
            <DuaRow style={{ textAlign: 'center' }}>
              <DuaCol width={'20%'}>
                <DuaRow noBorder>
                  <DuaCol width={'13%'}>
                    <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'28%'}>
                    <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'21%'}>
                    <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'38%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'36%'}>
                <DuaCell>
                  {/* Descripcion */}
                  <DuaData>&nbsp;</DuaData>
                </DuaCell>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'4%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
            </DuaRow>
            <DuaRow>
              <DuaCol width={'20%'}>
                <DuaRow noBorder>
                  <DuaCol width={'13%'}>
                    <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'28%'}>
                    <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'21%'}>
                    <DuaCell style={{ padding: 0 }}>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'38%'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'36%'}>
                <DuaCell>
                  {/* Descripcion */}
                  <DuaData>&nbsp;</DuaData>
                </DuaCell>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
              <DuaCol width={'4%'}>
                <DuaCell>&nbsp;</DuaCell>
              </DuaCol>
              <DuaCol width={'10%'}>
                <DuaRow noBorder>
                  <DuaCol width={'calc(100% - 30px)'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                  <DuaCol width={'30px'}>
                    <DuaCell>&nbsp;</DuaCell>
                  </DuaCol>
                </DuaRow>
              </DuaCol>
            </DuaRow>
          </Fragment>
        ))}
    </DuaCol>
  );
};
