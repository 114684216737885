/* eslint-disable no-template-curly-in-string */
import { FC } from 'react';
import {
  Button,
  Drawer,
  Form,
  FormInstance,
  Input,
  Spin,
  Select,
  FormItemProps,
  FormProps,
  Row,
} from 'antd';

import { Importador } from '../../../models/Importador';
import { useAppSelector } from '../../../store/hooks';
import { Rule } from 'rc-field-form/lib/interface';

const { Item } = Form;
const { Option } = Select;

interface IProps {
  visible: boolean;
  form: FormInstance<Importador>;
  status: StatusApp.Status;
  elementoSeleccionado: Importador | undefined;
  onUpdate: (values: Importador) => void;
  setCerrarModal: () => void;
}

export const DrawerUpsert: FC<IProps> = ({
  visible,
  form,
  status,
  elementoSeleccionado,
  onUpdate,
  setCerrarModal,
}) => {
  const update = () => {
    form
      .validateFields()
      .then(values => onUpdate(values))
      .catch(error => console.log(error));
  };

  const nivelesComerciales = useAppSelector(state => state.initialData.NivelesComerciales);
  const paises = useAppSelector(state =>
    state.paises.data.map(pais => ({
      id: pais.id,
      descripcion: pais.nombre,
    })),
  );

  const validateMessages: FormProps['validateMessages'] = {
    required: 'Por favor ingresar ${label}',
    types: {
      email: 'Por favor ingresar un ${label} válido',
      number: 'Por favor ingresar un ${label}  Válido ',
    },
    number: {
      min: 'El valor mínimo de  ${label} es ${min} ',
    },
  };

  const numberValidate: Rule = {
    required: true,
    type: 'number',
    transform: v => Number(v),
    min: 1,
  };

  return (
    <Drawer
      title={elementoSeleccionado ? 'Editar Importador' : 'Crear Importador'}
      width={600}
      onClose={setCerrarModal}
      visible={visible}
      bodyStyle={{ paddingBottom: 0 }}
      maskClosable={false}
      footer={
        <Row justify='end'>
          <Button onClick={setCerrarModal} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={update} type='primary'>
            {elementoSeleccionado ? 'Guardar' : 'Crear'}
          </Button>
        </Row>
      }
    >
      <Spin spinning={status === 'pending' ? true : false}>
        <Form
          form={form}
          name='Importadores'
          layout='vertical'
          defaultValue={elementoSeleccionado?.otroNivelComercial as any}
          validateMessages={validateMessages}
          scrollToFirstError={{ behavior: 'smooth' }}
        >
          <CustomItem name='clienteTamsId' label='Id Tams' required={false} disabled />
          <CustomItem name='nombre' label='Nombre' required={false} />
          <CustomItem name='rtn' label='Rtn' required={false} />
          <CustomItem
            name='numeroDeRegistro'
            label='Número de registro'
            rules={[numberValidate]}
            autoFocus
          />
          <CustomItem name='direccion' label='Dirección' required={false} />
          <CustomItem name='ciudad' label='Ciudad' />
          <CustomSelect name='paisId' label='País' values={paises} required={false} />
          <CustomItem name='telefono' label='Teléfono' required={false} />
          <CustomItem name='fax' label='Fax' required={false} />
          <CustomItem name='email' label='Email' required={false} />
          <CustomSelect
            label='Nivel comercial'
            name='nivelComercialId'
            values={nivelesComerciales}
            required={false}
          />
          <CustomItem name='otroNivelComercial' label='Otro Nivel Comercial' required={false} />
        </Form>
      </Spin>
    </Drawer>
  );
};

interface ICustomItem extends FormItemProps {
  disabled?: boolean;
  autoFocus?: boolean;
}

const CustomItem = ({ disabled, required = true, autoFocus, ...rest }: ICustomItem) => {
  return (
    <Item rules={[{ required }]} {...rest}>
      <Input disabled={disabled} autoFocus={autoFocus} />
    </Item>
  );
};

interface ICustomSelect extends FormItemProps {
  values: any[];
  label: string;
}

const CustomSelect = ({ values, ...rest }: ICustomSelect) => (
  <Item rules={[{ required: true }]} {...rest}>
    <Select showSearch optionFilterProp='children'>
      {values.map(value => (
        <Option key={value.id} value={value.id}>
          {value.descripcion}
        </Option>
      ))}
    </Select>
  </Item>
);
