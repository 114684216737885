import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpService from '../../../../services/httpService';
import { IDucaDetallesState } from '../store';

interface IResponse {
  key: string;
  dai: number;
  tarifaTLC: number;
}

export const getImpuestos = createAsyncThunk('DucaDetalles/GetImpuestos', httpService);

export const getImpuestosReducer = (builder: ActionReducerMapBuilder<IDucaDetallesState>) => {
  builder.addCase(getImpuestos.fulfilled, (state, action: PayloadAction<IResponse>) => {
    const itemIndex = state.items.findIndex(item => item.key === action.payload.key);
    if (itemIndex !== -1) {
      state.items[itemIndex].dai = action.payload.dai;
      state.items[itemIndex].tarifaTLC = action.payload.tarifaTLC;
      state.items[itemIndex].tarifaTLCAplicada = undefined;
    }
  });
};
