import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'services/httpService';
import { IMarcasComercialesState } from '../marcasComercialesStore';

export const deleteMarcaComercial = createAsyncThunk('MarcasComerciales/Delete', httpService);

export const deleteMarcaComercialReducer = (
  builder: ActionReducerMapBuilder<IMarcasComercialesState>,
) => {
  builder.addCase(deleteMarcaComercial.fulfilled, (state, _action) => {
    state.crudStatus = 'succeeded';
    const index = state.data.findIndex(
      partidaArancelaria => partidaArancelaria.id === state.elementoSeleccionado?.id,
    );
    state.data.splice(index, 1);
  });
  builder.addCase(deleteMarcaComercial.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(deleteMarcaComercial.rejected, (state, _action) => {
    state.crudStatus = 'failed';
  });
};
