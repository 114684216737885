import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ClasificacionFenag } from '../../../../models/ClasificacionFenag';
import httpService from '../../../../services/httpService';
import { IClasificacionesFenagState } from '../clasificacionesFenagStore';

export const updateClasificacionFenag = createAsyncThunk(
  'ClasificacionesFenag/Update',
  httpService,
);

export const updateClasificacionFenagReducer = (
  builder: ActionReducerMapBuilder<IClasificacionesFenagState>,
) => {
  builder.addCase(updateClasificacionFenag.fulfilled, (state, _action) => {
    const index = state.data.findIndex(
      clasificacionFenag => clasificacionFenag.id === state.elementoSeleccionado?.id,
    );

    state.crudStatus = 'succeeded';
    state.data.splice(index, 1, {
      ...state.elementoSeleccionado,
      ...state.elementoTemporal,
    } as ClasificacionFenag);
  });
  builder.addCase(updateClasificacionFenag.pending, (state, _action) => {
    state.crudStatus = 'pending';
  });
  builder.addCase(updateClasificacionFenag.rejected, (state, action) => {
    state.crudStatus = 'failed';
    state.error = action.error.message;
  });
};
