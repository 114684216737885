import { Row } from 'antd';
import { CustomInput } from 'components/FormControls/CustomInput';
import { GreenTitle } from 'components/FormControls/GreenTitle';

export const InformacionGeneralImportador = () => {
  return (
    <section>
      <GreenTitle text='B. Información General del Importador' />
      <Row gutter={[24, 24]}>
        <CustomInput
          required={false}
          disabled
          layout={{ span: 12 }}
          label='4. Nombre o Razón Social'
          name='importadorNombre'
        />
        <CustomInput
          required={false}
          disabled
          label='5. Registro Tributario (RTN)'
          name='importadorRtn'
        />
        <CustomInput
          required={false}
          disabled
          label='6. Número de Registro'
          name='importadorNumeroDeRegistro'
        />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInput
          required={false}
          disabled
          layout={{ span: 12 }}
          label='7. Dirección'
          name='importadorDireccion'
        />
        <CustomInput required={false} disabled label='Ciudad' name='importadorCiudad' />
        <CustomInput required={false} disabled label='País' name='importadorPais' />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInput
          required={false}
          disabled
          layout={{ span: 12 }}
          label='Correo Electrónico'
          name='importadorEmail'
        />
        <CustomInput required={false} disabled label='Teléfono' name='importadorTelefono' />
        <CustomInput required={false} disabled label='Fax' name='importadorFax' />
      </Row>
      <Row gutter={[24, 24]}>
        <CustomInput
          required={false}
          disabled
          label='8. Nivel Comercial'
          name='importadorNivelComercial'
        />
        <CustomInput
          required={false}
          disabled
          label='Otro Nivel Comercial'
          name='importadorOtroNivelComercial'
        />
      </Row>
    </section>
  );
};
